"use strict";
// import { IDiagnosis } from "./types";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ICD10 = void 0;
exports.ICD10 = [
    {
        value: "J02",
        label: "التهاب الأنف والبلعوم الحاد - Acute Nasopharyngitis",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "ENT",
            "InternalMedicine",
            "Emergency Pediatric",
        ],
    },
    {
        value: "J31.2",
        label: "التهاب الأنف والبلعوم المزمن - Chronic Nasopharyngitis",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "ENT",
            "Emergency Pediatric",
        ],
    },
    {
        value: "J30.1",
        label: "التهاب الأنف التحسسي - Allergic  Rhinitis",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "ENT",
            "Emergency Pediatric",
        ],
    },
    {
        value: "J31.0",
        label: "التهاب الأنف المزمن - Chronic  Rhinitis",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "ENT",
            "Emergency Pediatric",
        ],
    },
    {
        value: "J11",
        label: " النزلة الوافدة - Influenza, virus not identified",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "ENT",
            "InternalMedicine",
            "Emergency Pediatric",
        ],
    },
    {
        value: "J01",
        label: "التهاب الجيوب الأنفية الحاد - Acute sinusitis",
        clinics: ["InternalMedicine", "General", "ENT", "InternalMedicine"],
    },
    {
        value: "J32",
        label: "التهاب الجيوب المزمن - Chronic sinusitis",
        clinics: ["InternalMedicine", "General", "ENT"],
    },
    { value: "J33", label: "بوليبات أنفية - Nasal polyp", clinics: ["ENT"] },
    {
        value: "J34.2",
        label: "انحراف الوتيرة - Deviated nasal septum",
        clinics: ["ENT"],
    },
    {
        value: "J34.3",
        label: "ضخامة القرينات الأنفية - Hypertrophy of nasal turbinates",
        clinics: ["ENT"],
    },
    {
        value: "J34.0",
        label: " خراج و دمل الانف - Abscess, furuncle and carbuncle of nose",
        clinics: ["ENT"],
    },
    {
        value: "J02.9",
        label: " التهاب البلعوم الحاد - Acute pharyngitis, unspecified",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "ENT",
            "Emergency Pediatric",
            "Emergency General",
        ],
    },
    {
        value: "J03",
        label: "التهاب اللوزتين الحاد - Streptococcal tonsillitis",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "ENT",
            "InternalMedicine",
            "Emergency Pediatric",
        ],
    },
    {
        value: "J36",
        label: "خراج ما حول اللوزة - Peritonsillar abscess",
        clinics: ["ENT"],
    },
    {
        value: "J35.3",
        label: " ضخامة اللوزتين و الناميات - Hypertrophy of tonsils with hypertrophy of adenoids",
        clinics: ["ENT"],
    },
    {
        value: "J04.0",
        label: "التهاب الحنجرة الحاد - Acute laryngitis",
        clinics: ["InternalMedicine", "ENT"],
    },
    {
        value: "J04.1",
        label: "إلتهاب الرغامى الحاد - Acute tracheitis",
        clinics: ["ENT"],
    },
    {
        value: "J20.9",
        label: "التهاب القصبات الحاد - Acute bronchitis, unspecified",
        clinics: [
            "InternalMedicine",
            "Pediatric",
            "Emergency Pediatric",
            "Emergency General",
            "Pulmonology",
        ],
    },
    {
        value: "J21",
        label: " التهاب القصَبات الشعْريَّة - Acute bronchiolitis",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "J18.9",
        label: "ذات رئة - Pneumonia, unspecified",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "InternalMedicine",
            "Emergency Pediatric",
            "Emergency General",
            "ThoracicSurgery",
        ],
    },
    {
        value: "A37.0",
        label: "السعال الديكي - Whooping cough due to Bordetella pertussis",
        clinics: ["General", "Pediatric", "Emergency Pediatric"],
    },
    {
        value: "A15.0",
        label: "السل الرئوي - Tuberculosis of lung, confirmed by sputum microscopy with or without culture",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Emergency Pediatric",
        ],
    },
    {
        value: "J38.0",
        label: "شلل الحبال الصوتية  - Paralysis of vocal cords and larynx",
        clinics: ["Pediatric", "ENT", "Emergency Pediatric"],
    },
    {
        value: "J38.2",
        label: " عقيدات الحبال الصوتية - Nodules of vocal cords",
        clinics: ["ENT"],
    },
    {
        value: "J38.4",
        label: "وذمة الحنجرة - Oedema of larynx",
        clinics: ["Pediatric", "ENT", "Emergency Pediatric"],
    },
    {
        value: "J38.5",
        label: " تشنج الحنجرة - Laryngeal spasm",
        clinics: ["Pediatric", "ENT", "Emergency Pediatric"],
    },
    {
        value: "J42",
        label: "التهاب القصبات المزمن  - Unspecified chronic bronchitis",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "InternalMedicine",
            "Emergency Pediatric",
        ],
    },
    {
        value: "J47",
        label: " توسع القصبات - Bronchiectasis",
        clinics: ["InternalMedicine", "General", "InternalMedicine"],
    },
    {
        value: "J43",
        label: "نفاخ رئوي - Emphysema",
        clinics: ["InternalMedicine", "General", "InternalMedicine"],
    },
    {
        value: "J45",
        label: "الربو - Asthma",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "InternalMedicine",
            "Emergency Pediatric",
            "Emergency General",
        ],
    },
    {
        value: "J69.0",
        label: " ذات رئة استنشاقية  - Aspiration pneumonia",
        clinics: ["InternalMedicine", "Pediatric", "Emergency Pediatric"],
    },
    {
        value: "J81",
        label: "وذمة رئوية - Pulmonary oedema",
        clinics: [
            "InternalMedicine",
            "Pediatric",
            "InternalMedicine",
            "Emergency Pediatric",
            "Emergency Medicine",
        ],
    },
    {
        value: "J90",
        label: "انصباب الجنب - Pleural effusion",
        clinics: [
            "InternalMedicine",
            "General",
            "InternalMedicine",
            "ThoracicSurgery",
        ],
    },
    {
        value: "J93",
        label: "ريح صدرية - Pneumothorax",
        clinics: [
            "InternalMedicine",
            "Pediatric",
            "GeneralSurgery",
            "Cardiothoracic",
            "Emergency Pediatric",
        ],
    },
    {
        value: "S27.0",
        label: "ريح صدرية رضية - Traumatic pneumothorax",
        clinics: ["PediatricSurgery", "GeneralSurgery", "Cardiothoracic"],
    },
    {
        value: "J86",
        label: " تقيّح الجنب - Pyothorax",
        clinics: ["InternalMedicine", "GeneralSurgery", "Cardiothoracic"],
    },
    {
        value: "J94.1",
        label: " تليف الجنب - Fibrothorax",
        clinics: [
            "Cardiothoracic",
            "Cardiothoracic",
            "InternalMedicine",
            "ThoracicSurgery",
        ],
    },
    {
        value: "J94.2",
        label: "Haemothorax - انصباب الجنب المدمى",
        clinics: [
            "PediatricSurgery",
            "GeneralSurgery",
            "Cardiothoracic",
            "Cardiothoracic",
        ],
    },
    {
        value: "S27.1",
        label: "Traumatic haemothorax - انصباب الجنب المدمى الرضي",
        clinics: ["PediatricSurgery", "GeneralSurgery", "Cardiothoracic"],
    },
    {
        value: "A09.9",
        label: "التهاب المعدة و الأمعاء - Gastroenteritis and colitis of unspecified origin",
        clinics: [
            "PediatricSurgery",
            "InternalMedicine",
            "General",
            "Pediatric",
            "GeneralSurgery",
            "GeneralSurgery",
            "InternalMedicine",
            "Emergency Pediatric",
        ],
    },
    {
        value: "A01",
        label: "التيفوئيد والحمى نظيرة التيفية - Typhoid and paratyphoid fevers",
        clinics: [
            "PediatricSurgery",
            "InternalMedicine",
            "General",
            "Pediatric",
            "GeneralSurgery",
            "GeneralSurgery",
            "InternalMedicine",
            "Emergency Pediatric",
        ],
    },
    {
        value: "A23",
        label: "الحمى المالطية - Brucellosis",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "GeneralSurgery",
            "InternalMedicine",
            "Emergency Pediatric",
        ],
    },
    {
        value: "A06",
        label: "الداء الأميبي - Amoebiasis",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "InternalMedicine",
            "Emergency Pediatric",
        ],
    },
    {
        value: "B82.0",
        label: "الديدان الطفيلية - Intestinal helminthiasis, unspecified",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Emergency Pediatric",
        ],
    },
    {
        value: "A06.0",
        label: " الاسهال الدموي الحاد (الشيغلا الزحارية) - Acute bloody Diarrhea (suspected Shigella)",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Emergency Pediatric",
        ],
    },
    {
        value: "A00",
        label: "الاسهال المائي الحاد (اشتباه الكوليرا) - AWD (Suspected Cholera)",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "InternalMedicine",
            "Emergency Pediatric",
        ],
    },
    {
        value: "A05.9",
        label: " تسمم جرثومي غذائي - Bacterial foodborne intoxication, unspecified",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Emergency Pediatric",
        ],
    },
    {
        value: "K12.0",
        label: "القلاع الفموي الناكس - Recurrent oral aphthae",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "GeneralSurgery",
            "ENT",
            "Emergency Pediatric",
        ],
    },
    {
        value: "K11.2",
        label: "الْتِهابُ الغُدَّةِ اللُّعابِيَّة - Sialoadenitis ",
        clinics: ["ENT"],
    },
    {
        value: "K11.3",
        label: "خراج الغدة اللعابية - Abscess of salivary gland",
        clinics: ["ENT"],
    },
    {
        value: "K11.5",
        label: "حصيات الغدة اللعابية - Sialolithiasis",
        clinics: ["PediatricSurgery", "GeneralSurgery", "ENT"],
    },
    {
        value: "K11.6",
        label: "قيلة مخاطية في الغدة اللعابية - Mucocele of salivary gland",
        clinics: ["PediatricSurgery", "GeneralSurgery", "ENT"],
    },
    {
        value: "K12.2",
        label: "خراج و التهاب النسيج الخلوي في الفم - Cellulitis and abscess of mouth",
        clinics: ["PediatricSurgery", "GeneralSurgery", "ENT"],
    },
    {
        value: "K20",
        label: " التهاب المري - Oesophagitis",
        clinics: [
            "InternalMedicine",
            "General",
            "GeneralSurgery",
            "InternalMedicine",
        ],
    },
    {
        value: "K21",
        label: "القلس المعدي المريئي - Gastro-oesophageal reflux disease",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "GeneralSurgery",
            "Emergency Pediatric",
        ],
    },
    {
        value: "K22.1",
        label: "قرحة المري - Ulcer of oesophagus",
        clinics: [
            "PediatricSurgery",
            "InternalMedicine",
            "General",
            "Pediatric",
            "GeneralSurgery",
            "Cardiothoracic",
            "GeneralSurgery",
            "InternalMedicine",
            "Emergency Pediatric",
        ],
    },
    {
        value: "Q39.6",
        label: "رتج مريئي  - Diverticulum of oesophagus",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "GeneralSurgery",
            "Cardiothoracic",
            "Emergency Pediatric",
        ],
    },
    {
        value: "I85",
        label: "دوالي المري - Oesophageal varices",
        clinics: ["InternalMedicine", "GeneralSurgery"],
    },
    {
        value: "K22.0",
        label: "اللاارتخائية - Achalasia",
        clinics: [
            "PediatricSurgery",
            "InternalMedicine",
            "General",
            "GeneralSurgery",
            "Cardiothoracic",
            "GeneralSurgery",
            "InternalMedicine",
            "ThoracicSurgery",
        ],
    },
    {
        value: "K25",
        label: " قرحة المعدة - Gastric ulcer",
        clinics: ["InternalMedicine", "General", "GeneralSurgery"],
    },
    {
        value: "K26",
        label: " قرحة الاثني عشر - Duodenal ulcer",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "GeneralSurgery",
            "Emergency Pediatric",
        ],
    },
    {
        value: "K35",
        label: " التهاب الزائدة الدودية الحاد - Acute appendicitis",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "GeneralSurgery",
            "Emergency Pediatric",
        ],
    },
    {
        value: "A04.7",
        label: " التهاب الكولون الغشائي الكاذب - Enterocolitis due to Clostridium difficile",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "GeneralSurgery",
            "Emergency Pediatric",
        ],
    },
    {
        value: "K30",
        label: " عسر الهضم - Indigestion",
        clinics: [
            "PediatricSurgery",
            "InternalMedicine",
            "General",
            "Pediatric",
            "GeneralSurgery",
            "GeneralSurgery",
            "InternalMedicine",
            "Emergency Pediatric",
        ],
    },
    {
        value: "K40",
        label: " فتق إربي - Inguinal hernia",
        clinics: [
            "PediatricSurgery",
            "GeneralSurgery",
            "Urology",
            "Pediatric",
            "InternalMedicine",
            "Emergency Pediatric",
        ],
    },
    {
        value: "K41",
        label: "فتق فخذي - Femoral hernia",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "K42",
        label: "فتق السرة - Umbilical hernia",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "GeneralSurgery",
            "InternalMedicine",
            "Emergency Pediatric",
        ],
    },
    {
        value: "K43",
        label: "  فتق بطني - Ventral hernia",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "GeneralSurgery",
            "Emergency Pediatric",
        ],
    },
    {
        value: "K44",
        label: "فتق حجابي - Diaphragmatic hernia",
        clinics: ["InternalMedicine", "General", "GeneralSurgery"],
    },
    {
        value: "K50",
        label: "داء كرون - Crohn disease",
        clinics: [
            "PediatricSurgery",
            "InternalMedicine",
            "General",
            "GeneralSurgery",
            "GeneralSurgery",
            "InternalMedicine",
        ],
    },
    {
        value: "K51",
        label: "التهاب القولون التقرحي - Ulcerative colitis",
        clinics: ["InternalMedicine", "General", "GeneralSurgery"],
    },
    {
        value: "K58",
        label: "متلازمة الأمعاء الهيوجة - Irritable bowel syndrome",
        clinics: [
            "PediatricSurgery",
            "InternalMedicine",
            "General",
            "GeneralSurgery",
            "GeneralSurgery",
        ],
    },
    {
        value: "K90.0",
        label: "سوء امتصاص معوي - Intestinal malabsorption",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Emergency Pediatric",
        ],
    },
    {
        value: "K90.0",
        label: "الداء الزلاقي - Coeliac disease",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Emergency Pediatric",
        ],
    },
    {
        value: "K56.1",
        label: "الانغلاف المعوي - Intussusception",
        clinics: [
            "PediatricSurgery",
            "InternalMedicine",
            "General",
            "Pediatric",
            "GeneralSurgery",
            "GeneralSurgery",
            "Emergency Pediatric",
        ],
    },
    {
        value: "K59.0",
        label: "امساك - Constipation",
        clinics: [
            "PediatricSurgery",
            "InternalMedicine",
            "General",
            "Pediatric",
            "GeneralSurgery",
            "GeneralSurgery",
            "InternalMedicine",
            "Emergency Pediatric",
        ],
    },
    {
        value: "K60.0",
        label: "شق شرجي حاد - Acute Anal fissure",
        clinics: [
            "PediatricSurgery",
            "InternalMedicine",
            "General",
            "Pediatric",
            "GeneralSurgery",
            "GeneralSurgery",
            "Emergency Pediatric",
        ],
    },
    {
        value: "K60.1",
        label: "شق شرجي مزمن - Chronic Anal fissure",
        clinics: [
            "PediatricSurgery",
            "InternalMedicine",
            "General",
            "Pediatric",
            "GeneralSurgery",
            "GeneralSurgery",
            "Emergency Pediatric",
        ],
    },
    {
        value: "I84",
        label: "البواسير - Hemorrhoids",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "GeneralSurgery",
            "Emergency Pediatric",
        ],
    },
    {
        value: "B15",
        label: "  A التهاب الكبد الحاد - Acute Hepatitis A",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "InternalMedicine",
            "Emergency Pediatric",
        ],
    },
    {
        value: "B16",
        label: " B التهاب الكبد  - Hepatitis B",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "InternalMedicine",
            "Emergency Pediatric",
        ],
    },
    {
        value: "B17.1",
        label: " C التهاب كبد - Hepatitis C",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "InternalMedicine",
            "Emergency Pediatric",
        ],
    },
    {
        value: "K72.9",
        label: "قصور كبدي - Hepatic failure, unspecified",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Emergency Pediatric",
        ],
    },
    {
        value: "K74",
        label: "تشمع و تليف الكبد - Fibrosis and cirrhosis of liver",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "InternalMedicine",
            "Emergency Pediatric",
        ],
    },
    {
        value: "K80",
        label: "تحصي صفراوي - Cholelithiasis",
        clinics: ["InternalMedicine", "General", "GeneralSurgery"],
    },
    {
        value: "K81.0",
        label: " التهاب المرارة الحاد - Acute cholecystitis",
        clinics: [
            "PediatricSurgery",
            "InternalMedicine",
            "General",
            "GeneralSurgery",
            "GeneralSurgery",
        ],
    },
    {
        value: "K81.1",
        label: " التهاب المرارة المزمن - Chronic cholecystitis",
        clinics: [
            "PediatricSurgery",
            "InternalMedicine",
            "General",
            "GeneralSurgery",
            "GeneralSurgery",
        ],
    },
    {
        value: "K83.0",
        label: " التهاب الأقنية الصفراوية - Cholangitis",
        clinics: [
            "InternalMedicine",
            "General",
            "GeneralSurgery",
            "GeneralSurgery",
            "InternalMedicine",
        ],
    },
    {
        value: "K85",
        label: " التهاب البنكرياس الحاد  - Acute pancreatitis",
        clinics: [
            "PediatricSurgery",
            "InternalMedicine",
            "General",
            "GeneralSurgery",
            "GeneralSurgery",
        ],
    },
    {
        value: "K86.1",
        label: " التهاب البنكرياس المزمن - Chronic pancreatitis",
        clinics: [
            "InternalMedicine",
            "General",
            "GeneralSurgery",
            "GeneralSurgery",
        ],
    },
    {
        value: "K65",
        label: " التهاب البريتوان - Peritonitis",
        clinics: [
            "PediatricSurgery",
            "InternalMedicine",
            "GeneralSurgery",
            "GeneralSurgery",
        ],
    },
    {
        value: "B67.8",
        label: " داء الكيسات المائية الكبدية - Echinococcosis, unspecified, of liver",
        clinics: [
            "InternalMedicine",
            "General",
            "GeneralSurgery",
            "GeneralSurgery",
        ],
    },
    {
        value: "I10",
        label: "ارتفاع ضغط الدم الأساسي - Essential (primary) hypertension",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Cardiothoracic",
            "InternalMedicine",
            "Emergency Pediatric",
            "Nephrology",
            "Emergency General",
        ],
    },
    {
        value: "I20",
        label: " خناق الصدر - Angina pectoris",
        clinics: ["InternalMedicine", "General"],
    },
    {
        value: "I21",
        label: " احتشاء عضلة القلب  - Myocardial infarction",
        clinics: ["InternalMedicine", "General"],
    },
    {
        value: "I25",
        label: "نقص تروية قلبية مزمن - Chronic ischaemic heart disease",
        clinics: ["InternalMedicine", "General"],
    },
    {
        value: "I30",
        label: " التهاب التامور الحاد - Acute pericarditis",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Cardiothoracic",
            "Emergency Pediatric",
        ],
    },
    {
        value: "I33.9",
        label: "  التهاب الشغاف القلبي - Acute endocarditis, unspecified",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Cardiothoracic",
            "Emergency Pediatric",
        ],
    },
    {
        value: "I27.9",
        label: "داء قلب رئوي - Pulmonary heart disease, unspecified",
        clinics: ["InternalMedicine", "General", "Cardiothoracic"],
    },
    {
        value: "I26",
        label: " صمّة رئوية - Pulmonary embolism",
        clinics: [
            "InternalMedicine",
            "General",
            "Cardiothoracic",
            "Cardiothoracic",
            "InternalMedicine",
        ],
    },
    {
        value: "I26",
        label: " اعتلال عضلة القلب الضخامي الانسدادي - Obstructive hypertrophic cardiomyopathy ",
        clinics: ["InternalMedicine", "General"],
    },
    {
        value: "I50",
        label: "قصور القلب - Heart Failure ",
        clinics: ["InternalMedicine", "General"],
    },
    {
        value: "I37",
        label: " اضطرابات الصمام الرئوي - Pulmonary valve disorders",
        clinics: [
            "InternalMedicine",
            "Pediatric",
            "Cardiothoracic",
            "Cardiothoracic",
            "Emergency Pediatric",
        ],
    },
    {
        value: "I39.1",
        label: " اضطرابات الصمام الأبهري - Aortic valve disorders",
        clinics: [
            "InternalMedicine",
            "Pediatric",
            "Cardiothoracic",
            "Emergency Pediatric",
        ],
    },
    {
        value: "I39.0",
        label: " اضطرابات الصمام التاجي - Mitral valve disorders",
        clinics: [
            "InternalMedicine",
            "Pediatric",
            "Cardiothoracic",
            "Emergency Pediatric",
        ],
    },
    {
        value: "I39.2",
        label: " اضطرابات الصمام مثلث الشرف - Tricuspid valve disorders",
        clinics: [
            "InternalMedicine",
            "Pediatric",
            "Cardiothoracic",
            "Emergency Pediatric",
        ],
    },
    {
        value: "I01",
        label: " حمى رثوية حادة مع إصابة قلبية - Rheumatic fever with mention of heart involvement",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Cardiothoracic",
            "InternalMedicine",
            "Emergency Pediatric",
        ],
    },
    {
        value: "I00",
        label: " حمى رثوية حادة بدون إصابة قلبية - Rheumatic fever without mention of heart involvement",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "InternalMedicine",
            "Emergency Pediatric",
        ],
    },
    {
        value: "R00.0",
        label: "تسرعات القلب - Tachycardia, unspecified",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Cardiothoracic",
            "Emergency Pediatric",
        ],
    },
    {
        value: "I49.9",
        label: " اضطرابات النظم القلبية - Cardiac arrhythmia, unspecified",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Cardiothoracic",
            "Emergency Pediatric",
            "Emergency Medicine",
        ],
    },
    {
        value: "Q21.1",
        label: "الفتحة بين الأذينتين - Atrial septal defect",
        clinics: [
            "InternalMedicine",
            "Pediatric",
            "Cardiothoracic",
            "Emergency Pediatric",
        ],
    },
    {
        value: "Q21.0",
        label: "الفتحة بين البطينين - Ventricular septal defect",
        clinics: [
            "InternalMedicine",
            "Pediatric",
            "Cardiothoracic",
            "Emergency Pediatric",
        ],
    },
    {
        value: "Q25.0",
        label: "القناة الشريانية السالكة - Patent ductus arteriosus",
        clinics: [
            "InternalMedicine",
            "Pediatric",
            "Cardiothoracic",
            "Emergency Pediatric",
        ],
    },
    {
        value: "Q21.3",
        label: "رباعي فالوت - Tetralogy of Fallot",
        clinics: [
            "InternalMedicine",
            "Pediatric",
            "Cardiothoracic",
            "Emergency Pediatric",
        ],
    },
    {
        value: "I82.9",
        label: " الخثار الوريدي العميق - Deep Venous Thrombosis Embolism and thrombosis of unspecified vein",
        clinics: [
            "InternalMedicine",
            "General",
            "GeneralSurgery",
            "VascularSurgery",
        ],
    },
    {
        value: "I83",
        label: "  دوالي أوردة الأطراف السفلية - Varicose veins of lower extremities",
        clinics: [
            "InternalMedicine",
            "General",
            "GeneralSurgery",
            "VascularSurgery",
            "VascularSurgery",
        ],
    },
    {
        value: "I71.9",
        label: "أم دم أبهرية دون تمزق - Aortic aneurysm of unspecified site, without mention of rupture",
        clinics: ["GeneralSurgery", "VascularSurgery", "Cardiothoracic"],
    },
    {
        value: "I71.0",
        label: " تسلّخ الأبهر - Dissection of aorta",
        clinics: [
            "PediatricSurgery",
            "GeneralSurgery",
            "VascularSurgery",
            "Cardiothoracic",
            "VascularSurgery",
        ],
    },
    {
        value: "N39.0",
        label: " التهاب المسالك البولية - Urinary tract infection, site not specified",
        clinics: [
            "PediatricSurgery",
            "InternalMedicine",
            "General",
            "Pediatric",
            "Urology",
            "Emergency Pediatric",
        ],
    },
    {
        value: "A54.0",
        label: "التهاب الإحليل السيلاني  - Gonococcal infection of lower genitourinary tract without periurethral or accessory gland abscess",
        clinics: ["InternalMedicine", "General", "Urology"],
    },
    {
        value: "A55",
        label: "  ورم حبيبي لمفي بالكلاميديا - Chlamydial lymphogranuloma (venereum)",
        clinics: [
            "InternalMedicine",
            "Urology",
            "Gynecology",
            "InternalMedicine",
            "Emergency Gynecology",
        ],
    },
    {
        value: "A58",
        label: "  الورم الحبيبي الإربي - Granuloma inguinale",
        clinics: [
            "InternalMedicine",
            "Urology",
            "Gynecology",
            "Emergency Gynecology",
        ],
    },
    {
        value: "A57",
        label: " قُرَيح (القَرْح اللَيِّن)    - Chancroid ",
        clinics: ["InternalMedicine", "Urology"],
    },
    {
        value: "A53.9",
        label: " الزهري (السفلس) - Syphilis, unspecified",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "GeneralSurgery",
            "Urology",
            "Gynecology",
            "InternalMedicine",
            "Emergency Pediatric",
            "Emergency Gynecology",
        ],
    },
    {
        value: "A60",
        label: "الحلأ البسيط الشرجي التناسلي - Anogenital herpesviral [herpes simplex] infection",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Urology",
            "Gynecology",
            "Emergency Pediatric",
            "Emergency Gynecology",
        ],
    },
    {
        value: "A63.0",
        label: "  ثآليل شرجية تناسلية - Anogenital (venereal) warts",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Urology",
            "Gynecology",
            "Emergency Pediatric",
            "Emergency Gynecology",
        ],
    },
    {
        value: "N17",
        label: " الفشل الكلوي الحاد - Acute renal failure",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "InternalMedicine",
            "Emergency Pediatric",
            "Nephrology",
        ],
    },
    {
        value: "N19",
        label: " الفشل الكلوي المزمن - Chronic renal failure",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Emergency Pediatric",
        ],
    },
    {
        value: "N13",
        label: "  انسداد طرق بولية مع جزر بولي  - Obstructive and reflux uropathy",
        clinics: ["Pediatric", "Urology", "Emergency Pediatric"],
    },
    {
        value: "N10",
        label: " التهاب الحويضة والكلية الحاد - Acute Pyelonephritis",
        clinics: [
            "PediatricSurgery",
            "InternalMedicine",
            "General",
            "Pediatric",
            "GeneralSurgery",
            "Urology",
            "InternalMedicine",
            "Emergency Pediatric",
        ],
    },
    {
        value: "N11",
        label: " التهاب الحويضة والكلية المزمن - Chronic Pyelonephritis",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Urology",
            "InternalMedicine",
            "Emergency Pediatric",
        ],
    },
    {
        value: "N20.0",
        label: "حصاة كلى - Calculus of kidney",
        clinics: [
            "PediatricSurgery",
            "InternalMedicine",
            "General",
            "Pediatric",
            "GeneralSurgery",
            "Urology",
            "Emergency Pediatric",
            "Emergency General",
        ],
    },
    {
        value: "N20.1",
        label: " حصاة الحالب - Calculus of ureter",
        clinics: [
            "PediatricSurgery",
            "InternalMedicine",
            "General",
            "Pediatric",
            "GeneralSurgery",
            "Urology",
            "Emergency Pediatric",
        ],
    },
    {
        value: "N21.0",
        label: " حصاة المثانة - Calculus in bladder",
        clinics: [
            "PediatricSurgery",
            "InternalMedicine",
            "General",
            "Pediatric",
            "GeneralSurgery",
            "Urology",
            "Emergency Pediatric",
        ],
    },
    {
        value: "N23",
        label: "القولنج الكلوي - Renal colic",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "GeneralSurgery",
            "Urology",
            "InternalMedicine",
            "Emergency Pediatric",
            "Nephrology",
            "Emergency General",
        ],
    },
    {
        value: "N28.1",
        label: " كيسة مكتسبة في الكلية - Cyst of kidney, acquired",
        clinics: ["Urology", "Nephrology"],
    },
    {
        value: "Q61.0",
        label: "كيسة خلقية وحيدة في الكلية - Congenital single renal cyst",
        clinics: ["Pediatric", "Urology", "Emergency Pediatric"],
    },
    {
        value: "Q61.3",
        label: "الكلية متعددة الكيسات - Polycystic kidney, unspecified",
        clinics: [
            "PediatricSurgery",
            "InternalMedicine",
            "Pediatric",
            "Urology",
            "Emergency Pediatric",
            "Nephrology",
        ],
    },
    {
        value: "N35",
        label: "تضيق الإحليل - Urethral stricture",
        clinics: ["Urology"],
    },
    {
        value: "Q64.7",
        label: " تشوهات الإحليل والمثانة الخلقية - congenital malformations of bladder and urethra",
        clinics: ["Urology"],
    },
    {
        value: "N39.3",
        label: "سلس البول الجهدي - Stress incontinence",
        clinics: [
            "PediatricSurgery",
            "Gynecology",
            "Urology",
            "Gynecology",
            "Emergency Gynecology",
        ],
    },
    {
        value: "N40",
        label: " تضخم البروستات - Hyperplasia of prostate",
        clinics: ["Urology", "Nephrology"],
    },
    {
        value: "N41.0",
        label: " التهاب البروستات الحاد - Acute Prostatitis",
        clinics: ["Urology", "Nephrology"],
    },
    {
        value: "N41.1",
        label: " التهاب البروستات المزمن - Chronic Prostatitis",
        clinics: ["Urology"],
    },
    {
        value: "I86.1",
        label: " دوالي الحبل المنوي - Scrotal varices",
        clinics: ["General", "Urology"],
    },
    {
        value: "N43.3",
        label: " قيلة منوية - Spermatocele",
        clinics: ["PediatricSurgery", "Urology"],
    },
    {
        value: "Q55.2",
        label: "تشوهات خلقية في الخصية والصفن  - congenital malformations of testis and scrotum",
        clinics: ["Urology"],
    },
    {
        value: "Q53",
        label: " خصية هاجرة - Undescended testicle",
        clinics: ["Pediatric", "GeneralSurgery", "Urology", "Emergency Pediatric"],
    },
    {
        value: "E10",
        label: "السكري المعتمد على الأنسولين - Insulin-dependent diabetes mellitus",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "InternalMedicine",
            "Emergency Pediatric",
        ],
    },
    {
        value: "E03.9",
        label: "قصور الغدة الدرقية - Hypothyroidism, unspecified",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "GeneralSurgery",
            "GeneralSurgery",
            "InternalMedicine",
            "Emergency Pediatric",
            "Endocrinology",
        ],
    },
    {
        value: "E05",
        label: " فرط نشاط الغدة الدرقية - Thyrotoxicosis [hyperthyroidism]",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "GeneralSurgery",
            "Emergency Pediatric",
        ],
    },
    {
        value: "E05.0",
        label: " داء غريفز - Thyrotoxicosis with diffuse goitre",
        clinics: [
            "InternalMedicine",
            "General",
            "GeneralSurgery",
            "GeneralSurgery",
            "InternalMedicine",
        ],
    },
    {
        value: "E06.3",
        label: "داء هاشيموتو - Hashimoto Disease",
        clinics: [
            "PediatricSurgery",
            "InternalMedicine",
            "GeneralSurgery",
            "GeneralSurgery",
            "InternalMedicine",
        ],
    },
    {
        value: "E06.0",
        label: " التهاب الدرق الحاد - Acute Thyroditis",
        clinics: [
            "PediatricSurgery",
            "InternalMedicine",
            "GeneralSurgery",
            "GeneralSurgery",
            "InternalMedicine",
        ],
    },
    {
        value: "E05.1",
        label: " العقدة الدرقية السامة - Thyrotoxicosis with toxic single thyroid nodule",
        clinics: [
            "InternalMedicine",
            "GeneralSurgery",
            "GeneralSurgery",
            "InternalMedicine",
        ],
    },
    {
        value: "E20",
        label: " قصور جارات الدرق - Hypoparathyroidism",
        clinics: [
            "PediatricSurgery",
            "InternalMedicine",
            "Pediatric",
            "GeneralSurgery",
            "GeneralSurgery",
            "InternalMedicine",
            "Emergency Pediatric",
        ],
    },
    {
        value: "E21",
        label: "فرط نشاط جارات الدرق او اي اضطراب في  جارات الدرق  - Hyperparathyroidism and other disorders of parathyroid gland",
        clinics: [
            "InternalMedicine",
            "Pediatric",
            "GeneralSurgery",
            "Emergency Pediatric",
        ],
    },
    {
        value: "E22",
        label: " فرط الوظيفة للغدة النخامية - Hyperfunction of pituitary gland",
        clinics: ["InternalMedicine", "Pediatric", "Emergency Pediatric"],
    },
    {
        value: "E23",
        label: "  قصور الوظيفة للغدة النخامية - Hypofunction of pituitary gland",
        clinics: ["InternalMedicine", "Pediatric", "Emergency Pediatric"],
    },
    {
        value: "E24",
        label: " متلازمة كوشينغ - Cushing syndrome",
        clinics: ["InternalMedicine", "Pediatric", "Emergency Pediatric"],
    },
    {
        value: "E25.0",
        label: " الاضطرابات الكظرية التناسلية الخلقية  - Congenital adrenogenital disorders",
        clinics: [
            "InternalMedicine",
            "Pediatric",
            "Emergency Pediatric",
            "Endocrinology",
        ],
    },
    {
        value: "E26",
        label: "فرط الالدوستيرونية,متلازمة كون - Hyperaldosteronism",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "InternalMedicine",
            "Emergency Pediatric",
        ],
    },
    {
        value: "E28",
        label: " اختلال وظيفة المبيض الغديّة - Ovarian dysfunction",
        clinics: ["InternalMedicine"],
    },
    {
        value: "E29",
        label: " اختلال وظيفة الخصية الغديّة - Testicular dysfunction",
        clinics: ["InternalMedicine"],
    },
    {
        value: "E30",
        label: " اضطرابات البلوغ - Disorders of puberty",
        clinics: ["InternalMedicine", "Pediatric", "Emergency Pediatric"],
    },
    {
        value: "E43",
        label: " سوء التغذية الحاد الشديد - Unspecified severe protein-energy malnutrition",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Emergency Pediatric",
        ],
    },
    {
        value: "E44",
        label: " سوء التغذية الحاد المتوسط - Protein-energy malnutrition of moderate and mild degree",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Emergency Pediatric",
        ],
    },
    {
        value: "E50",
        label: " عوز فيتامين أ - Vitamin A deficiency",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Emergency Pediatric",
        ],
    },
    {
        value: "E52",
        label: "داء بلاجرا: عوز فيتامين ب 3 - Niacin deficiency [pellagra]",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Emergency Pediatric",
        ],
    },
    {
        value: "E53",
        label: "عوز  فيتامينات ب الأخرى - Deficiency of other B group vitamins",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Emergency Pediatric",
        ],
    },
    {
        value: "E54",
        label: " عوز فيتامين سي - Ascorbic acid deficiency",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Emergency Pediatric",
        ],
    },
    {
        value: "E55",
        label: " عوز فيتامين د - Vitamin D deficiency",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Emergency Pediatric",
        ],
    },
    {
        value: "E58",
        label: " عوز الكالسيوم الغذائي - Dietary calcium deficiency",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Emergency Pediatric",
        ],
    },
    {
        value: "E60",
        label: "عوز الزنك الغذائي - Dietary zinc deficiency",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Emergency Pediatric",
        ],
    },
    {
        value: "E88",
        label: "الاضطرابات الاستقلابية - other Metabolic disorders",
        clinics: ["InternalMedicine", "Pediatric", "Emergency Pediatric"],
    },
    {
        value: "H01.0",
        label: "التهاب الجفن - Blepharitis",
        clinics: ["General", "Ophthalmology", "Ophthalmology"],
    },
    {
        value: "H10",
        label: "التهاب الملتحمة - Conjunctivitis",
        clinics: ["General", "Ophthalmology", "Ophthalmology"],
    },
    {
        value: "H04.0",
        label: "التهاب الغدد الدمعية - Dacryoadenitis ",
        clinics: ["Ophthalmology", "Ophthalmology"],
    },
    {
        value: "H15.0",
        label: "التهاب الصلبة - Scleritis",
        clinics: ["Ophthalmology", "Ophthalmology"],
    },
    {
        value: "H16",
        label: "التهاب القرنية - Keratitis",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H20",
        label: " التهاب القزحية والجسم الهدبي - Iridocyclitis",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H30.9",
        label: " التهاب المشيمية والشبكية - Chorioretinal inflammation, unspecified",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H44.0",
        label: "  التهاب باطن المقلة القيحي - Purulent endophthalmitis",
        clinics: ["Ophthalmology", "Ophthalmology"],
    },
    {
        value: "H05.0",
        label: " التهاب الحجاج الحاد - Acute Inflammation of orbit",
        clinics: ["Ophthalmology", "Ophthalmology"],
    },
    {
        value: "H05.1",
        label: " التهاب الحجاج المزمن - Chronic inflammatory disorders of orbit",
        clinics: ["Ophthalmology", "Ophthalmology"],
    },
    {
        value: "H02.0",
        label: "شتر داخلي - Entropion",
        clinics: ["Ophthalmology", "Ophthalmology"],
    },
    {
        value: "H02.1",
        label: "شتر خارجي - Ectropion",
        clinics: ["Ophthalmology", "Ophthalmology"],
    },
    {
        value: "H02.4",
        label: "  اطراق الجفن - Ptosis of eyelid",
        clinics: ["Ophthalmology", "Ophthalmology"],
    },
    {
        value: "H04.5",
        label: " تضيق مجرى الدمع - Stenosis of lacrimal passages",
        clinics: ["Ophthalmology", "Ophthalmology"],
    },
    {
        value: "H26.9",
        label: "  ساد  - Cataract, unspecified",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H40",
        label: "الزرق - Glaucoma",
        clinics: ["Ophthalmology", "Ophthalmology"],
    },
    {
        value: "H43.1",
        label: "نزف الزجاجي - Vitreous haemorrhage",
        clinics: ["Ophthalmology", "Ophthalmology"],
    },
    {
        value: "S05.5",
        label: "جسم اجنبي في العين - retained (old) intraocular foreign body",
        clinics: ["Ophthalmology"],
    },
    {
        value: "S50.9",
        label: "  حول - Strabismus, unspecified",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H52.0",
        label: "مد البصر - Hypermetropia",
        clinics: ["Ophthalmology", "Ophthalmology"],
    },
    {
        value: "H52.1",
        label: "قصر النظر - Myopia",
        clinics: ["Ophthalmology", "Ophthalmology"],
    },
    {
        value: "H53.6",
        label: "العشى الليلي - Night blindness",
        clinics: ["Ophthalmology", "Ophthalmology"],
    },
    {
        value: "H54.0",
        label: "عمى ثنائي الجانب  - Blindness, binocular",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H54.4",
        label: "عمى أحادي الجانب  - Blindness, monocular",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H65.0",
        label: "التهاب الأذن الوسطى  الحاد المصلي  - Acute serous otitis media",
        clinics: ["General", "ENT"],
    },
    {
        value: "H66",
        label: "التهاب الأذن الوسطى  القيحي - suppurative otitis media, unspecified",
        clinics: ["General", "ENT"],
    },
    {
        value: "H65.9",
        label: " التهاب الأذن الوسطى غير القيحي  - Nonsuppurative otitis media, unspecified",
        clinics: ["General", "ENT"],
    },
    {
        value: "H60",
        label: "  التهاب أذن خارجية     - Otitis externa",
        clinics: ["General", "ENT", "InternalMedicine"],
    },
    {
        value: "H62.2",
        label: "فطار أذني - Otitis externa in mycoses",
        clinics: ["ENT"],
    },
    {
        value: "H70.0",
        label: "التهاب الخشاء الحاد - Acute mastoiditis",
        clinics: ["ENT"],
    },
    {
        value: "H70.1",
        label: "التهاب الخشاء المزمن - Chronic mastoiditis",
        clinics: ["ENT"],
    },
    {
        value: "H71",
        label: "ورم كوليستيرولي - Cholesteatoma of middle ear",
        clinics: ["ENT"],
    },
    {
        value: "H72",
        label: "انثقاب غشاء الطبل - Perforation of tympanic membrane",
        clinics: ["General", "ENT"],
    },
    {
        value: "H81.9",
        label: "الدوارالدهليزي   - Disorder of vestibular function, unspecified-Vertiginous syndrome",
        clinics: ["ENT"],
    },
    {
        value: "H81.2",
        label: "التهاب العصب الدهليزي - Vestibular neuronitis",
        clinics: ["ENT"],
    },
    {
        value: "H81.1",
        label: "دوار الوضعة الانتيابي الحميد - Benign paroxysmal vertigo",
        clinics: ["InternalMedicine", "General", "ENT"],
    },
    {
        value: "H81.0",
        label: "داء منيير - Meniere",
        clinics: ["InternalMedicine", "General", "ENT"],
    },
    {
        value: "H81.4",
        label: "الدوار من منشأ مركزي - Vertigo of central origin",
        clinics: ["ENT"],
    },
    {
        value: "H90.2",
        label: " فقدان السمع التوصيلي - Conductive hearing loss",
        clinics: ["ENT"],
    },
    {
        value: "H90.5",
        label: " فقدان السمع الحسي العصبي - Sensorineural hearing loss",
        clinics: ["ENT"],
    },
    {
        value: "H61.2",
        label: "صِمْلاَخٌ مُنْحَشِر - Impacted cerumen",
        clinics: ["General", "ENT"],
    },
    {
        value: "T16",
        label: " جسم اجنبي في الأذن - Foreign body in ear",
        clinics: ["ENT", "Emergency General"],
    },
    {
        value: "M00",
        label: "التهاب المفاصل الانتاني - Pyogenic arthritis",
        clinics: ["InternalMedicine", "General", "Orthopedic"],
    },
    {
        value: "M05",
        label: " التهاب المفاصل الرثواني ايجابي المصل - Seropositive rheumatoid arthritis ",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Orthopedic",
            "InternalMedicine",
            "Emergency Pediatric",
        ],
    },
    {
        value: "M10",
        label: "النقرس - Gout",
        clinics: ["InternalMedicine", "General", "Orthopedic", "InternalMedicine"],
    },
    {
        value: "M15.9",
        label: "التهاب مفاصل تنكسي - Generalized osteoarthritis",
        clinics: ["InternalMedicine", "General", "Orthopedic"],
    },
    {
        value: "Q65",
        label: "تشوهات الورك الخلقية - Congenital deformities of hip",
        clinics: ["Orthopedic", "Plastic Surgery"],
    },
    {
        value: "Q66",
        label: "تشوهات القدم الخلقية - Congenital deformities of feet",
        clinics: ["Orthopedic", "Plastic Surgery"],
    },
    {
        value: "Q68",
        label: "تشوهات خلقية أخرى في الجهاز الهيكلي  - Other congenital musculoskeletal deformities",
        clinics: ["Orthopedic"],
    },
    {
        value: "M86",
        label: "ذات العظم والنقي - Osteomyelitis",
        clinics: ["Orthopedic"],
    },
    {
        value: "M51",
        label: "ديسك قطني - thoracic, thoracolumbar and lumbosacral disc disorders",
        clinics: ["Orthopedic"],
    },
    {
        value: "M50",
        label: "ديسك رقبي - Cervical disc disorder",
        clinics: ["Orthopedic", "Neurology"],
    },
    {
        value: "M84.0",
        label: " سوء اندمال الكسر - Malunion of fracture",
        clinics: ["Orthopedic"],
    },
    {
        value: "M84.1",
        label: "عدم اندمال الكسر - Nonunion of fracture",
        clinics: ["Orthopedic"],
    },
    {
        value: "M81.9",
        label: " هشاشة العظام - Osteoporosis ",
        clinics: ["InternalMedicine", "General", "Orthopedic"],
    },
    {
        value: "M83.9",
        label: "تلين عظام عند البالغين  - Adult osteomalacia",
        clinics: ["InternalMedicine", "Orthopedic", "Endocrinology"],
    },
    {
        value: "M83.0",
        label: "تلين عظام نفاسي  - Puerperal osteomalacia",
        clinics: ["Orthopedic"],
    },
    {
        value: "M83.1",
        label: " تلين العظام في الشيخوخة  - Senile Osteomalacia",
        clinics: ["InternalMedicine", "General", "Orthopedic"],
    },
    {
        value: "M87",
        label: " تنخر العظم - Osteonecrosis",
        clinics: ["Orthopedic"],
    },
    {
        value: "M46.1",
        label: " الْتِهابُ المَفْصِلِ العَجُزِيِّ الحَرْقَفِيّ - Sacroiliitis",
        clinics: ["Orthopedic"],
    },
    {
        value: "M61",
        label: "تكلس العضلات - Calcification and ossification of muscle",
        clinics: ["Orthopedic"],
    },
    {
        value: "E14.5",
        label: "تقرح القدم السكري - diabetes mellitus With peripheral circulatory complications",
        clinics: ["InternalMedicine", "General", "GeneralSurgery", "Orthopedic"],
    },
    {
        value: "M77.9",
        label: " التهاب الأوتار - Enthesopathy,Tendonitis",
        clinics: ["Orthopedic"],
    },
    {
        value: "Q65.2",
        label: "خلع الورك الولادي - Congenital dislocation of hip",
        clinics: ["Orthopedic", "Pediatric", "Emergency Pediatric"],
    },
    {
        value: "A87",
        label: "التهاب سحايا فيروسي  - Viral meningitis",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "InternalMedicine",
            "Emergency Pediatric",
        ],
    },
    {
        value: "G00.9",
        label: "التهاب سحايا جرثومي  - Bacterial meningitis",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Emergency Pediatric",
        ],
    },
    {
        value: "G61.0",
        label: " اشتباه متلازمة غيلان باريه  - Guillain-Barré syndrome",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "InternalMedicine",
            "Emergency Pediatric",
        ],
    },
    {
        value: "A80",
        label: " اشتباه شلل أطفال  - Acute poliomyelitis",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Emergency Pediatric",
        ],
    },
    {
        value: "G06.0",
        label: "خراج داخل الجمجمة - Intracranial abscess and granuloma",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Neurosurgery",
            "Emergency Pediatric",
        ],
    },
    {
        value: "G06.2",
        label: "خراج فوق او تحت الجافية  - Extradural and subdural abscess",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Neurosurgery",
            "Emergency Pediatric",
        ],
    },
    {
        value: "G20",
        label: "داء باركنسون - Parkinson disease",
        clinics: ["InternalMedicine", "General", "InternalMedicine"],
    },
    {
        value: "G30",
        label: "الزهايمر - Alzheimer disease",
        clinics: ["InternalMedicine", "General", "InternalMedicine"],
    },
    {
        value: "G35",
        label: " التصلب اللويحي المتعدد - Multiple sclerosis",
        clinics: ["InternalMedicine", "Neurosurgery", "InternalMedicine"],
    },
    {
        value: "G40",
        label: "الصرع - Epilepsy",
        clinics: ["InternalMedicine", "General", "InternalMedicine", "Neurology"],
    },
    {
        value: "R56.0",
        label: "اختلاج حروري - Febrile Convulsion",
        clinics: ["General", "Pediatric", "Emergency Pediatric"],
    },
    {
        value: "G44.2",
        label: " الصداع التوتري - Tension-type headache",
        clinics: ["InternalMedicine", "General", "Neurosurgery"],
    },
    {
        value: "G43",
        label: "الشقيقة - Migraine",
        clinics: ["InternalMedicine", "General", "InternalMedicine"],
    },
    {
        value: "G44.0",
        label: "الصداع العنقودي - Cluster headache",
        clinics: ["InternalMedicine", "General"],
    },
    {
        value: "I60",
        label: " نزف تحت العنكبوت - Subarachnoid haemorrhage",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Neurosurgery",
            "InternalMedicine",
            "Emergency Pediatric",
        ],
    },
    {
        value: "S06.4",
        label: "نزف رضي  فوق الجافية   - Extradural haemorrhage (traumatic)",
        clinics: ["Neurosurgery"],
    },
    {
        value: "I62.1",
        label: "نزف غير رضي فوق الجافية  - Nontraumatic extradural haemorrhage",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Neurosurgery",
            "InternalMedicine",
            "Emergency Pediatric",
        ],
    },
    {
        value: "I62.0",
        label: " نزف تحت الجافية  - Subdural haemorrhage (acute)(nontraumatic)",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Neurosurgery",
            "InternalMedicine",
            "Emergency Pediatric",
        ],
    },
    {
        value: "I63",
        label: " احتشاء دماغي - Cerebral infarction",
        clinics: ["InternalMedicine", "General", "InternalMedicine", "Neurology"],
    },
    {
        value: "I67.1",
        label: "أم دم دماغية - Cerebral aneurysm",
        clinics: ["Neurosurgery", "InternalMedicine"],
    },
    {
        value: "G50.0",
        label: "التهاب العصب مثلث التوائم - Trigeminal neuralgia",
        clinics: [
            "InternalMedicine",
            "General",
            "Neurosurgery",
            "InternalMedicine",
        ],
    },
    {
        value: "G51.0",
        label: " شلل بل - Bell palsy",
        clinics: [
            "InternalMedicine",
            "General",
            "Neurosurgery",
            "InternalMedicine",
        ],
    },
    {
        value: "G54.0",
        label: " اضطرابات الضفيرة العضدية - Brachial plexus disorders",
        clinics: ["Neurosurgery", "InternalMedicine", "Orthopedic"],
    },
    {
        value: "G54.1",
        label: "اضطرابات الضفيرة القطنية العجزية - Lumbosacral plexus disorders",
        clinics: ["Neurosurgery"],
    },
    {
        value: "G56.0",
        label: "متلازمة النفق الرسغي - Carpal tunnel syndrome",
        clinics: ["Orthopedic", "InternalMedicine", "Neurosurgery"],
    },
    {
        value: "G70.0",
        label: " الوهن العضلي الوخيم - Myasthenia gravis",
        clinics: ["InternalMedicine", "InternalMedicine"],
    },
    {
        value: "G80",
        label: " الشلل الدماغي - Cerebral palsy",
        clinics: [
            "InternalMedicine",
            "Neurosurgery",
            "Pediatric",
            "Emergency Pediatric",
        ],
    },
    { value: "G81", label: "شلل نصفي - Hemiplegia", clinics: ["Neurosurgery"] },
    {
        value: "G91",
        label: "استسقاء دماغي - Hydrocephalus",
        clinics: [
            "Pediatric",
            "Neurosurgery",
            "InternalMedicine",
            "Emergency Pediatric",
        ],
    },
    {
        value: "G96.0",
        label: "تسرب السائل الدماغي الشوكي - Cerebrospinal fluid leak",
        clinics: ["Neurosurgery", "InternalMedicine"],
    },
    {
        value: "B00",
        label: " الحلأ البسيط - Herpes Simplex",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "InternalMedicine",
            "Emergency Pediatric",
        ],
    },
    {
        value: "B02",
        label: " داء المنطقة (الحلأ النطاقي) - Zoster [herpes zoster]",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "InternalMedicine",
            "Emergency Pediatric",
        ],
    },
    {
        value: "B01",
        label: "الحماق (جدري الماء) - Varicella [chickenpox]",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Emergency Pediatric",
            "Dermotology",
        ],
    },
    {
        value: "B05",
        label: "الحصبة - Measles",
        clinics: ["General", "Pediatric", "Emergency Pediatric", "Dermotology"],
    },
    {
        value: "B06",
        label: " الحصبة الالمانية - Rubella [German measles]",
        clinics: ["General", "Pediatric", "Emergency Pediatric", "Dermotology"],
    },
    {
        value: "L01",
        label: "القوباء - Impetigo",
        clinics: ["General", "Pediatric", "Emergency Pediatric"],
    },
    {
        value: "B55",
        label: "الليشمانيا - Leishmaniasis",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Emergency Pediatric",
            "Dermotology",
        ],
    },
    {
        value: "B55A",
        label: "متابعة الليشمانيا - Leishmaniasis",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Emergency Pediatric",
        ],
    },
    {
        value: "B86",
        label: "الجرب - Scabies",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Gynecology",
            "InternalMedicine",
            "Emergency Pediatric",
            "Emergency Gynecology",
            "Dermotology",
        ],
    },
    {
        value: "B85.2",
        label: "قمل - Lice",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Emergency Pediatric",
            "Dermotology",
        ],
    },
    {
        value: "B07",
        label: "الثآليل - Warts",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "GeneralSurgery",
            "Emergency Pediatric",
        ],
    },
    {
        value: "L00",
        label: " مُتَلاَزِمَةُ الجِلْدِ المَسْموط بالعنقوديات - Staphylococcal scalded skin syndrome ",
        clinics: ["General", "Pediatric", "Emergency Pediatric"],
    },
    {
        value: "L02",
        label: "خراج جلدي-دمل  - Cutaneous abscess, furuncle and carbuncle",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Gynecology",
            "GeneralSurgery",
            "Orthopedic",
            "Emergency Pediatric",
            "Dermotology",
        ],
    },
    {
        value: "L03",
        label: "التهاب النسيج الخلوي - Cellulitis",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Gynecology",
            "GeneralSurgery",
            "Orthopedic",
            "Emergency Pediatric",
        ],
    },
    {
        value: "B35.0",
        label: " سعفة الرأس و اللحية - Tinea barbae and tinea capitis",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Emergency Pediatric",
            "Dermotology",
        ],
    },
    {
        value: "B35.6",
        label: "السغفة الإربية و سعفة الساق - Tinea inguinalis [Tinea cruris]",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Emergency Pediatric",
        ],
    },
    {
        value: "B35.4",
        label: "سعفة الجسد  - Tinea corporis",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Emergency Pediatric",
        ],
    },
    {
        value: "B35.3",
        label: "سعفة القدم أو داء الأفوات  - Tinea pedis",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Emergency Pediatric",
        ],
    },
    {
        value: "B36.0",
        label: "النخالية المبرقشة - Pityriasis versicolor",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Emergency Pediatric",
        ],
    },
    {
        value: "L22",
        label: "التهاب الجلد الحفاضي - Diaper dermatitis",
        clinics: ["General", "Pediatric", "Emergency Pediatric"],
    },
    {
        value: "L25",
        label: "التهاب جلد تماسي- اكزيما - Unspecified contact dermatitis",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Emergency Pediatric",
        ],
    },
    {
        value: "L20",
        label: "التهاب جلد تأتبي  - Atopic dermatitis ",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "InternalMedicine",
            "Emergency Pediatric",
            "Dermotology",
        ],
    },
    {
        value: "L43.9",
        label: " الحزاز المسطح - Lichen planus",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Emergency Pediatric",
        ],
    },
    {
        value: "L50",
        label: "الشرى - Urticaria",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Emergency Pediatric",
            "Emergency Medicine",
            "Dermotology",
        ],
    },
    {
        value: "L55",
        label: "حروق الشمس - Sunburn",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Emergency Pediatric",
            "Dermotology",
        ],
    },
    {
        value: "L60",
        label: " اضطرابات الأظافر - Nail disorders",
        clinics: ["InternalMedicine", "General"],
    },
    {
        value: "L63",
        label: "حاصّة بقعية - ثعلبة  - Alopecia areata",
        clinics: ["InternalMedicine", "General"],
    },
    {
        value: "L70",
        label: "حب الشباب - Acne",
        clinics: ["InternalMedicine", "General"],
    },
    {
        value: "L80",
        label: "البهاق - Vitiligo",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Emergency Pediatric",
        ],
    },
    {
        value: "L81.1",
        label: "كلف - Chloasma",
        clinics: ["InternalMedicine", "General"],
    },
    {
        value: "L10",
        label: "الفقاع - Pemphigus",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Emergency Pediatric",
        ],
    },
    {
        value: "L40",
        label: "الصداف - Psoriasis",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Emergency Pediatric",
        ],
    },
    {
        value: "S00",
        label: " إصابة سطحية للرأس - Superficial injury of head",
        clinics: [
            "PediatricSurgery",
            "GeneralSurgery",
            "Neurosurgery",
            "Emergency General",
            "Plastic Surgery",
        ],
    },
    {
        value: "S01.0",
        label: " جرح مفتوح في فروة الرأس - Open wound of scalp",
        clinics: [
            "PediatricSurgery",
            "GeneralSurgery",
            "Neurosurgery",
            "Neurosurgery",
        ],
    },
    {
        value: "S01.9",
        label: " جرح مفتوح في الرأس ( دون تحديد المنطقة ) - Open wound of head, part unspecified",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "T30.1",
        label: "حرق من الدرجة الأولى - Burn of first degree, body region unspecified",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "T30.2",
        label: "حرق من الدرجة الثانية - Burn of second degree, body region unspecified",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "T30.3",
        label: "حرق من الدرجة الثالثة - Burn of third degree, body region unspecified",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "Y36.7",
        label: "اصابة حرب-كيميائي - War operations involving chemical weapons and other forms of unconventional warfare",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "W34",
        label: " طلق ناري - Discharge from other and unspecified firearms",
        clinics: ["PediatricSurgery", "GeneralSurgery", "GeneralSurgery"],
    },
    {
        value: "S02.9",
        label: "كسر في الجمجمة - Fracture of skull and facial bones, part unspecified",
        clinics: ["Neurosurgery"],
    },
    {
        value: "S02.2",
        label: " كسر في عظم الأنف - Fracture of nasal bone",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "S02.6",
        label: "  كسر في الفك السفلي - Fracture of mandible",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "S05.1",
        label: " كدمة  أنسجة مقلة العين و محيطها  - Contusion of eyeball and orbital tissues",
        clinics: ["PediatricSurgery", "GeneralSurgery", "Ophthalmology"],
    },
    {
        value: "S07.0",
        label: "تهشم بالوجه - Crushing injury of face",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "S07.1",
        label: " تهشم بالجمجمة - Crushing injury of skull",
        clinics: ["Neurosurgery", "Neurosurgery"],
    },
    {
        value: "S12",
        label: " كسر في الرقبة - Fracture of neck",
        clinics: ["Neurosurgery", "Orthopedic", "Emergency General"],
    },
    {
        value: "S14",
        label: " إصابة الأعصاب والحبل الشوكي على مستوى الرقبة - Injury of nerves and spinal cord at neck level ",
        clinics: ["Neurosurgery", "Neurosurgery"],
    },
    {
        value: "S20.2",
        label: " رض على الصدر - Contusion of thorax",
        clinics: ["PediatricSurgery", "GeneralSurgery", "Cardiothoracic"],
    },
    {
        value: "S22.3",
        label: " كسر أضلاع  - Fracture of ribs",
        clinics: ["PediatricSurgery", "GeneralSurgery", "Cardiothoracic"],
    },
    {
        value: "S30.0",
        label: "كدمة في أسفل الظهر والحوض - Contusion of lower back and pelvis",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "S30.1",
        label: " كدمة في جدار البطن - Contusion of abdominal wall",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "S30.2",
        label: " كدمة الأعضاء التناسلية الخارجية - Contusion of external genital organs",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "S31.0",
        label: "جرح مفتوح من أسفل الظهر والحوض - Open wound of lower back and pelvis",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "S31.1",
        label: " جرح مفتوح من جدار البطن - Open wound of abdominal wall",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "S31.5",
        label: "جرح مفتوح للأعضاء التناسلية الخارجية - Open wound of external genital organs",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "S32.0",
        label: "كسر في فقرات أسفل الظهر - Fracture of lumbar vertebras",
        clinics: ["Neurosurgery"],
    },
    {
        value: "S32.8",
        label: "  كسر في الحوض - Fracture of other and unspecified parts of lumbar spine and pelvis",
        clinics: ["GeneralSurgery", "Orthopedic"],
    },
    {
        value: "S32.7",
        label: "كسور متعددة في العمود الفقري أسفل الظهر والحوض - Multiple fractures of lumbar spine and pelvis",
        clinics: ["Orthopedic", "Neurosurgery"],
    },
    {
        value: "S36.7",
        label: " إصابة عدة أجهزة داخل البطن - Injury of multiple intra-abdominal organs",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "S37.7",
        label: "إصابة عدة أجهزة داخل الحوض - Injury of multiple pelvic organs",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "S38.0",
        label: " إصابة هرسية في الأعضاء التناسلية الخارجية - Crushing injury of external genital organs",
        clinics: [],
    },
    {
        value: "S40.7",
        label: "إصابات سطحية متعددة من الكتف والعضد - Multiple superficial injuries of shoulder and upper arm",
        clinics: ["GeneralSurgery", "Orthopedic"],
    },
    {
        value: "S41.0",
        label: "جرح مفتوح من الكتف - Open wound of shoulder",
        clinics: ["GeneralSurgery", "Orthopedic"],
    },
    {
        value: "S41.1",
        label: "جرح مفتوح من العضد - Open wound of upper arm",
        clinics: ["GeneralSurgery", "Orthopedic"],
    },
    {
        value: "S41.7",
        label: " جروح متعددة مفتوحة من الكتف والعضد - Multiple open wounds of shoulder and upper arm",
        clinics: ["GeneralSurgery", "Orthopedic"],
    },
    {
        value: "S42.0",
        label: "كسر في الترقوة - Fracture of clavicle",
        clinics: ["Orthopedic"],
    },
    {
        value: "S42.1",
        label: "كسر لوح الكتف - Fracture of scapula",
        clinics: ["Orthopedic"],
    },
    {
        value: "S42.2",
        label: " كسر  في القسم العلوي من العضد  - Fracture of upper end of humerus",
        clinics: ["Orthopedic"],
    },
    {
        value: "S42.3",
        label: "كسر في جسم العضد  - Fracture of shaft of humerus",
        clinics: ["Orthopedic"],
    },
    {
        value: "S42.4",
        label: " كسر  في القسم السفلي من العضد  - Fracture of lower end of humerus",
        clinics: ["Orthopedic"],
    },
    {
        value: "S42.7",
        label: "كسور متعددة من الترقوة، لوح الكتف والعضد - Multiple fractures of clavicle, scapula and humerus",
        clinics: ["Orthopedic"],
    },
    {
        value: "S43.0",
        label: "خلع مفصل الكتف - Dislocation of shoulder joint",
        clinics: ["Orthopedic", "Emergency General"],
    },
    {
        value: "S43.1",
        label: "خلع المفصل الأخرمي الترقوي - Dislocation of acromioclavicular joint",
        clinics: ["Orthopedic", "Emergency General"],
    },
    {
        value: "S48.9",
        label: " بتر رضي في العضد - Traumatic amputation of shoulder and upper arm, level unspecified",
        clinics: ["Orthopedic"],
    },
    {
        value: "S50",
        label: "إصابة سطحية في الساعد - Superficial injury of forearm",
        clinics: [
            "GeneralSurgery",
            "Orthopedic",
            "Emergency General",
            "Plastic Surgery",
        ],
    },
    {
        value: "S51",
        label: " جرح مفتوح في الساعد - Open wound of forearm",
        clinics: ["Orthopedic", "Emergency General", "Plastic Surgery"],
    },
    {
        value: "S52",
        label: "كسر في الساعد - Fracture of forearm",
        clinics: ["Orthopedic", "Emergency General"],
    },
    {
        value: "S57",
        label: "إصابة هرسية في الساعد - Crushing injury of forearm",
        clinics: ["Orthopedic"],
    },
    {
        value: "S58",
        label: " بتر رضي في الساعد  - Traumatic amputation of forearm",
        clinics: ["Orthopedic"],
    },
    {
        value: "S60",
        label: "إصابة سطحية في الرسغ واليد - Superficial injury of wrist and hand ",
        clinics: [
            "GeneralSurgery",
            "Orthopedic",
            "Emergency General",
            "Plastic Surgery",
        ],
    },
    {
        value: "S61",
        label: " جرح مفتوح في الرسغ واليد - Open wound of wrist and hand",
        clinics: ["Orthopedic", "Emergency General", "Plastic Surgery"],
    },
    {
        value: "S62",
        label: "كسر معصم (كل  الأنواع) - Fracture at wrist and hand level",
        clinics: ["Orthopedic", "Emergency General"],
    },
    {
        value: "S62.3",
        label: " كسر في عظام مشط اليد - Fracture of metacarpal bones",
        clinics: ["Orthopedic"],
    },
    {
        value: "S62.5",
        label: "كسر في الابهام  - Fracture of thumb ",
        clinics: ["Orthopedic"],
    },
    {
        value: "S62.6",
        label: " كسر في الأصابع ( عدا الابهام ) - Fracture of fingers",
        clinics: ["Orthopedic"],
    },
    {
        value: "S63.1",
        label: "خلع في الأصابع  - Dislocation of finger",
        clinics: ["Orthopedic", "Emergency General"],
    },
    {
        value: "S68.0",
        label: "بتر رضي للابهام جزئي او كامل  - Traumatic amputation of thumb (complete)(partial)",
        clinics: ["Orthopedic"],
    },
    {
        value: "S68.1",
        label: "بتر رضي لاصبع ( غير الابهام ) جزئي او كامل  - Traumatic amputation of other single finger (complete)(partial)",
        clinics: ["Orthopedic"],
    },
    {
        value: "S70.1",
        label: " كدمة في الفخذ - Contusion of thigh",
        clinics: ["GeneralSurgery", "Orthopedic"],
    },
    {
        value: "S71.1",
        label: " جرح مفتوح من الفخذ - Open wound of thigh",
        clinics: ["GeneralSurgery", "Orthopedic"],
    },
    {
        value: "S72.0",
        label: " كسر عنق عظم الفخذ - Fracture of neck of femur",
        clinics: ["Orthopedic"],
    },
    {
        value: "S72",
        label: " كسر عظم الفخذ - Fracture of  femur",
        clinics: ["Orthopedic", "Emergency General"],
    },
    {
        value: "S77.1",
        label: "اصابة هرسية  في الفخذ - Crushing injury of thigh",
        clinics: ["Orthopedic"],
    },
    {
        value: "S78.1",
        label: "بتر رضي على مستوى بين الورك والركبة - Traumatic amputation at level between hip and knee",
        clinics: ["Orthopedic"],
    },
    {
        value: "S80.0",
        label: "كدمة الركبة - Contusion of knee",
        clinics: ["GeneralSurgery", "Orthopedic"],
    },
    {
        value: "S80.1",
        label: "كدمة في الساق - Contusion of other and unspecified parts of lower leg",
        clinics: ["GeneralSurgery", "Orthopedic"],
    },
    {
        value: "S80.0",
        label: "جرح مفتوح من الركبة - Open wound of knee",
        clinics: ["GeneralSurgery", "Orthopedic"],
    },
    {
        value: "S81.9",
        label: "جرح مفتوح من الساق - Open wound of lower leg, part unspecified",
        clinics: ["GeneralSurgery", "Orthopedic"],
    },
    {
        value: "S82.0",
        label: "كسر داغصة - Patella Fracture",
        clinics: ["Orthopedic"],
    },
    {
        value: "S83",
        label: "أذية غضاريف و-أو أربطة الركبة - Dislocation, sprain and strain of joints and ligaments of knee",
        clinics: ["Orthopedic", "Emergency General"],
    },
    {
        value: "S82.9",
        label: "كسر في الساق - Fracture of lower leg, part unspecified",
        clinics: ["Orthopedic"],
    },
    {
        value: "S82.5",
        label: "كسر في الكاحل الأنسي  - Fracture of  medial malleolus",
        clinics: ["Orthopedic"],
    },
    {
        value: "S82.6",
        label: "كسر في الكاحل الوحشي  - Fracture of  lateral malleolus",
        clinics: ["Orthopedic"],
    },
    {
        value: "S87",
        label: "اصابة هرسية  في الساق - Crushing injury of lower leg",
        clinics: ["Orthopedic"],
    },
    {
        value: "S92.9",
        label: " كسر في القدم(عظام القدم و-أو الأمشاط و-أو أصابع)  - Fracture of foot",
        clinics: ["Orthopedic"],
    },
    {
        value: "S91",
        label: " جرح مفتوح في القدم والكاحل  - Open wound of ankle and foot",
        clinics: [
            "GeneralSurgery",
            "Orthopedic",
            "Emergency General",
            "Plastic Surgery",
        ],
    },
    {
        value: "S97",
        label: " اصابة هرسية في القدم - Crushing injury of ankle and foot",
        clinics: ["Orthopedic"],
    },
    {
        value: "S88.1",
        label: "بتر رضي  على مستوى بين الركبة والكاحل - Traumatic amputation at level between knee and ankle",
        clinics: ["Orthopedic"],
    },
    {
        value: "S98.4",
        label: "بتر  رضي في القدم - Traumatic amputation of foot, level unspecified",
        clinics: ["Orthopedic"],
    },
    {
        value: "S90.3",
        label: " كدمة في القدم - Contusion of other and unspecified parts of foot",
        clinics: ["GeneralSurgery", "Orthopedic"],
    },
    {
        value: "S93.4",
        label: "  التواء الكاحل - Sprain of ankle",
        clinics: ["Orthopedic"],
    },
    {
        value: "S93.2",
        label: "تمزق الأربطة في الكاحل - Rupture of ligaments at ankle and foot level",
        clinics: ["Orthopedic"],
    },
    {
        value: "T00",
        label: " إصابات سطحية تشمل مناطق الجسم متعددة - Superficial injuries involving multiple body regions",
        clinics: [
            "PediatricSurgery",
            "PediatricSurgery",
            "GeneralSurgery",
            "GeneralSurgery",
        ],
    },
    {
        value: "T01",
        label: "جروح مفتوحة تشمل مناطق الجسم متعددة - Open wounds involving multiple body regions",
        clinics: [
            "PediatricSurgery",
            "PediatricSurgery",
            "GeneralSurgery",
            "GeneralSurgery",
        ],
    },
    {
        value: "T02",
        label: "كسور متعددة تشمل مناطق الجسم - Fractures involving multiple body regions",
        clinics: ["Orthopedic"],
    },
    {
        value: "F03",
        label: "الخرف - Dementia",
        clinics: ["InternalMedicine", "General"],
    },
    {
        value: "F05.9",
        label: "الهذيان - Delirium",
        clinics: ["InternalMedicine", "General", "MentalHealth"],
    },
    {
        value: "R44.3",
        label: " أهلاسات عضوية - hallucinations",
        clinics: ["InternalMedicine", "General", "MentalHealth"],
    },
    {
        value: "F20",
        label: "الفصام - Schizophrenia",
        clinics: ["InternalMedicine", "General", "MentalHealth"],
    },
    {
        value: "F32",
        label: "اكتئاب - Depressive episode",
        clinics: ["InternalMedicine", "General", "MentalHealth"],
    },
    {
        value: "F43.1",
        label: " اضطراب الكرب التالي للصدمة - Post-traumatic stress disorder",
        clinics: ["InternalMedicine", "General", "MentalHealth"],
    },
    {
        value: "F41",
        label: " اضطرابات القلق  - Anxiety disorders",
        clinics: ["InternalMedicine", "General", "MentalHealth"],
    },
    {
        value: "K01",
        label: "الأسنان المنحشرة  - Embedded and impacted teeth",
        clinics: ["Dental"],
    },
    { value: "K02", label: "تسوس الأسنان  - Dental caries", clinics: ["Dental"] },
    {
        value: "K05.0",
        label: "التهاب اللثة الحاد - Acute gingivitis",
        clinics: ["Dental"],
    },
    {
        value: "K05.1",
        label: "التهاب اللثة المزمن - Chronic  gingivitis",
        clinics: ["Dental"],
    },
    {
        value: "K08",
        label: "اضطرابات أخرى في الاسنان والنسج الداعمة لها  - Other disorders of teeth and supporting structures",
        clinics: ["Dental"],
    },
    {
        value: "K08.1",
        label: "فقدان سن رضي او بالقلع او بسبب مرض في اللثة  - Loss of teeth due to accident, extraction or local periodontal disease",
        clinics: ["Dental"],
    },
    {
        value: "K08.3",
        label: "بقايا جذور الأسنان - Retained dental root",
        clinics: ["Dental"],
    },
    {
        value: "K09",
        label: "كيسات الفم  - Cysts of oral region, not elsewhere classified",
        clinics: ["Dental"],
    },
    {
        value: "B26",
        label: "النكاف - Mumps",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "ENT",
            "Emergency Pediatric",
        ],
    },
    {
        value: "A05.1",
        label: " الداء الوشيقي - Botulism",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Emergency Pediatric",
        ],
    },
    {
        value: "A41.9",
        label: "  تجرثم الدم - Sepsis",
        clinics: ["InternalMedicine", "Pediatric", "Emergency Pediatric"],
    },
    {
        value: "R57.2",
        label: "صدمة  انتانية - Septic shock",
        clinics: [
            "InternalMedicine",
            "Pediatric",
            "InternalMedicine",
            "Emergency Pediatric",
        ],
    },
    {
        value: "D50",
        label: "فقر الدم بعوز الحديد - Iron deficiency anaemia",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Emergency Pediatric",
        ],
    },
    {
        value: "D51",
        label: "فقر دم بعوز فيتامين ب 12 - Vitamin B12 deficiency anaemia",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Emergency Pediatric",
        ],
    },
    {
        value: "D52.9",
        label: "فقر دم بعوز الفوليك  - Folate deficiency anaemia, unspecified",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Emergency Pediatric",
            "Hematology",
        ],
    },
    {
        value: "D56",
        label: "التلاسيميا - Thalassaemia",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Emergency Pediatric",
        ],
    },
    {
        value: "D57",
        label: "داء الخلايا المنجلية - Sickle-cell disorders",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Emergency Pediatric",
        ],
    },
    {
        value: "D65",
        label: "تخثر داخل الأوعية المنتشر - Disseminated intravascular coagulation DIC",
        clinics: ["InternalMedicine", "GeneralSurgery"],
    },
    {
        value: "D66",
        label: "عوز العامل الثامن الوارثي - Hereditary factor VIII deficiency",
        clinics: [
            "InternalMedicine",
            "Pediatric",
            "InternalMedicine",
            "Emergency Pediatric",
            "Hematology",
        ],
    },
    {
        value: "D69.3",
        label: "فرفرية نقص الصفيحات الأساسية  - Idiopathic thrombocytopenic purpura",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "GeneralSurgery",
            "InternalMedicine",
            "Emergency Pediatric",
        ],
    },
    {
        value: "M31.1",
        label: "فرفرية نقص الصفيحات الخثارية  - Thrombotic thrombocytopenic purpura",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "GeneralSurgery",
            "InternalMedicine",
            "Emergency Pediatric",
        ],
    },
    {
        value: "P59.9",
        label: "يرقان ولادي - Neonatal jaundice, unspecified",
        clinics: ["General", "Pediatric", "Emergency Pediatric"],
    },
    {
        value: "E56.1",
        label: "الداء النزفي الباكر - Deficiency of vitamin K",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "B58",
        label: "داء المقوسات التوكسوبلاسما - Toxoplasmosis",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Gynecology",
            "Emergency Pediatric",
            "Emergency Gynecology",
        ],
    },
    {
        value: "E85.0",
        label: " حمى البحر المتوسط - Familial Mediterranean fever",
        clinics: [
            "PediatricSurgery",
            "InternalMedicine",
            "General",
            "Pediatric",
            "GeneralSurgery",
            "GeneralSurgery",
            "InternalMedicine",
            "Emergency Pediatric",
        ],
    },
    {
        value: "T67.0",
        label: " ضربة الشمس - Heatstroke and sunstroke",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Emergency Pediatric",
        ],
    },
    {
        value: "M32",
        label: "الذئبة الحمامية الجهازية - Systemic lupus erythematosus",
        clinics: ["InternalMedicine", "General", "InternalMedicine", "Dermotology"],
    },
    {
        value: "T75.3",
        label: " دوار الحركة - Motion sickness",
        clinics: ["InternalMedicine", "General"],
    },
    {
        value: "T78.2",
        label: "صدمة تأقية  - Anaphylactic shock, unspecified",
        clinics: ["InternalMedicine", "InternalMedicine", "Emergency Medicine"],
    },
    {
        value: "R57.1",
        label: " صدمة نقص حجم - Hypovolaemic shock",
        clinics: [
            "InternalMedicine",
            "GeneralSurgery",
            "InternalMedicine",
            "Emergency Medicine",
        ],
    },
    {
        value: "R59",
        label: "  ضخامة عقد لمفاوية - Enlarged lymph nodes",
        clinics: [],
    },
    {
        value: "T63.2",
        label: " لدغة العقرب - Scorpion bite",
        clinics: ["InternalMedicine"],
    },
    {
        value: "T63.4",
        label: "لدغة حشرة - Insect bite",
        clinics: ["InternalMedicine"],
    },
    {
        value: "T63.0",
        label: "لدغة أفعى - Snake bite",
        clinics: ["InternalMedicine"],
    },
    {
        value: "B67",
        label: "داء الكييسات المائية - Hydatidosis",
        clinics: ["InternalMedicine", "GeneralSurgery"],
    },
    {
        value: "A82",
        label: " داء الكلب - Rabies",
        clinics: [
            "InternalMedicine",
            "Pediatric",
            "InternalMedicine",
            "Emergency Pediatric",
        ],
    },
    {
        value: "T96",
        label: "التسمم بالأدوية او المواد الكيميائية  - Sequelae of poisoning by drugs, medicaments and biological substances",
        clinics: ["InternalMedicine", "GeneralSurgery"],
    },
    {
        value: "T75.4",
        label: "آثار التيار الكهربائي - Effects of electric current",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    { value: "Z41.2", label: "ختان - Circumcision", clinics: ["Urology"] },
    {
        value: "R63.0",
        label: "قهم (فقدان الشهية) - Anorexia",
        clinics: ["InternalMedicine", "General", "MentalHealth"],
    },
    {
        value: "R63.4",
        label: " فقدان غير طبيعي للوزن  - Abnormal weight loss",
        clinics: ["InternalMedicine", "General"],
    },
    {
        value: "R63.5",
        label: "زيادة غير طبيعية في الوزن  - Abnormal weight gain",
        clinics: ["InternalMedicine", "General"],
    },
    {
        value: "H52",
        label: "أسواء الانكسار والمطابقة  - Disorders of refraction and accommodation",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H18.6",
        label: "قرنية مخروطية - keratoconus",
        clinics: ["Ophthalmology", "Ophthalmology"],
    },
    {
        value: "S09.5",
        label: " جرح في العين - injury in the eye",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H33",
        label: "انفصال شبكية - Retinal detachment",
        clinics: ["Ophthalmology", "Ophthalmology"],
    },
    {
        value: "H16.0",
        label: "قرحة قرنية - Corneal ulcer",
        clinics: ["Ophthalmology", "Ophthalmology"],
    },
    {
        value: "H36.0*",
        label: "اعتلال الشبكية السكري - Diabetic retinopathy",
        clinics: ["Ophthalmology"],
    },
    {
        value: "Z34",
        label: "مراقبة حمل - Supervision of normal pregnancy",
        clinics: ["General", "Gynecology", "Gynecology", "Emergency Gynecology"],
    },
    {
        value: "Z39",
        label: " مراقبة بعد الولادة - Postpartum care and examination",
        clinics: ["General", "Gynecology", "Gynecology", "Emergency Gynecology"],
    },
    {
        value: "N70",
        label: "التهاب قناة فالوب او المبيض   - Salpingitis and oophoritis",
        clinics: ["General", "Gynecology", "Gynecology", "Emergency Gynecology"],
    },
    {
        value: "N71",
        label: "التهاب الرحم  - Inflammatory disease of uterus, except cervix",
        clinics: ["General", "Gynecology", "Gynecology", "Emergency Gynecology"],
    },
    {
        value: "N72",
        label: "التهاب عنق الرحم  - Inflammatory disease of cervix",
        clinics: ["General", "Gynecology", "Gynecology", "Emergency Gynecology"],
    },
    {
        value: "N73",
        label: "الداء الحوضي الالتهابي  - Other female pelvic inflammatory diseases",
        clinics: ["General", "Gynecology", "Gynecology", "Emergency Gynecology"],
    },
    {
        value: "N75",
        label: "أمراض غدة بارتولان  - Diseases of Bartholin gland",
        clinics: ["General", "Gynecology", "Gynecology", "Emergency Gynecology"],
    },
    {
        value: "N76",
        label: "التهاب المهبل والفرج - Other inflammation of vagina and vulva",
        clinics: ["General", "Gynecology", "Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O20.0",
        label: "تهديد اسقاط - Threatened abortion",
        clinics: ["Gynecology", "Gynecology", "Emergency Gynecology"],
    },
    {
        value: "P95",
        label: "موت محصول حملي - Fetal death of unspecified cause",
        clinics: ["Gynecology", "Gynecology", "Emergency Gynecology"],
    },
    {
        value: "N97",
        label: "عقم أنثوي  - Female infertility",
        clinics: ["General", "Gynecology", "Gynecology", "Emergency Gynecology"],
    },
    {
        value: "Z30",
        label: "تنظيم اسرة  - Contraceptive management",
        clinics: ["General", "Gynecology", "Emergency Gynecology"],
    },
    {
        value: "N83.2",
        label: "كيسة مبيض - Other and unspecified ovarian cysts",
        clinics: [
            "General",
            "Gynecology",
            "GeneralSurgery",
            "Emergency Gynecology",
        ],
    },
    {
        value: "N80",
        label: "بطانة رحمية هاجرة  - Endometriosis",
        clinics: ["General", "Gynecology", "Emergency Gynecology"],
    },
    {
        value: "N81",
        label: "هبوط الاعضاء التناسلية او الرحم - Female genital prolapse",
        clinics: ["Gynecology", "Gynecology", "Emergency Gynecology"],
    },
    {
        value: "N82",
        label: "ناسور في القناة التناسلية  - Fistulae involving female genital tract",
        clinics: ["Gynecology", "Gynecology", "Emergency Gynecology"],
    },
    {
        value: "N84",
        label: "بوليب في القناة التناسلية  - Polyp of female genital tract",
        clinics: ["Gynecology", "Gynecology", "Emergency Gynecology"],
    },
    {
        value: "N95",
        label: "اضطرابات الاياس وما حول الاياس  - Menopausal and other perimenopausal disorders",
        clinics: ["General", "Gynecology", "Gynecology", "Emergency Gynecology"],
    },
    {
        value: "N88",
        label: "امراض عنق الرحم غير الالتهابية  - noninflammatory disorders of cervix",
        clinics: ["General", "Gynecology", "Gynecology", "Emergency Gynecology"],
    },
    {
        value: "N89",
        label: "امراض المهبل غير الالتهابية  - noninflammatory disorders of vagina",
        clinics: ["General", "Gynecology", "Gynecology", "Emergency Gynecology"],
    },
    {
        value: "N91",
        label: "ندرة او انقطاع الطمث  - Absent, scanty and rare menstruation",
        clinics: ["General", "Gynecology", "Gynecology", "Emergency Gynecology"],
    },
    {
        value: "N92",
        label: "غزارة او عدم انتظام الطمث  - Excessive, frequent and irregular menstruation",
        clinics: ["General", "Gynecology", "Gynecology", "Emergency Gynecology"],
    },
    {
        value: "C53",
        label: "سرطان العنق  - Malignant neoplasm of cervix uteri",
        clinics: ["General", "Gynecology", "Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O01",
        label: "رحى عذارية ( حمل عنقودي ) - Hydatidiform mole",
        clinics: ["General", "Gynecology", "Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O00",
        label: "حمل هاجر  - Ectopic pregnancy",
        clinics: [
            "General",
            "Gynecology",
            "GeneralSurgery",
            "Gynecology",
            "Emergency Gynecology",
        ],
    },
    {
        value: "G58.9",
        label: "انضغاط عصب  - Mononeuropathy, unspecified",
        clinics: ["Neurosurgery", "Orthopedic"],
    },
    {
        value: "T14.4",
        label: " انقطاع عصب - Interruption of nerve",
        clinics: ["Neurosurgery", "Orthopedic"],
    },
    {
        value: "G93.4",
        label: "اعتلال دماغي  -  encephalopathy",
        clinics: ["InternalMedicine", "Neurosurgery", "InternalMedicine"],
    },
    {
        value: "K60.5",
        label: "ناسور شرجي مستقيمي  - Anorectal fistula",
        clinics: ["PediatricSurgery", "GeneralSurgery", "GeneralSurgery"],
    },
    {
        value: "L05",
        label: "ناسور عصعصي  - Pilonidal cyst",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "K61.0",
        label: "خراج حول الشرج - Perianal abscess",
        clinics: [
            "PediatricSurgery",
            "General",
            "GeneralSurgery",
            "GeneralSurgery",
        ],
    },
    {
        value: "D17.9",
        label: "كتلة دهنية - Lipoma ",
        clinics: ["General", "GeneralSurgery"],
    },
    {
        value: "K80",
        label: "حصاة مرارة - Cholelithiasis",
        clinics: ["General", "GeneralSurgery"],
    },
    {
        value: "Z48.0",
        label: "نزع تثبيت خارجي - Attention to surgical dressings and sutures",
        clinics: ["General"],
    },
    {
        value: "Z47.0",
        label: "نزع تثبيت خارجي - Follow-up care involving removal of fracture plate and other internal fixation device",
        clinics: [],
    },
    {
        value: "Z47.8",
        label: "نزع تثبيت خارجي - The removal of external fixation",
        clinics: ["Orthopedic"],
    },
    {
        value: "Z48.9",
        label: "متابعة ورعاية بعد العمل الجراحي  - Surgical follow-up care, unspecified",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "K60.2",
        label: "شق شرجي - Anal fissure",
        clinics: [
            "PediatricSurgery",
            "General",
            "GeneralSurgery",
            "GeneralSurgery",
        ],
    },
    {
        value: "IDA-1",
        label: "فتح بطن اسعافي - Laparotomy ambulatory",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "IDA-2",
        label: "فتح بطن استقصائي - investigative Laparotomy",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "IDA-3",
        label: "تفجير صدر - chest drainage",
        clinics: ["GeneralSurgery", "Cardiothoracic"],
    },
    {
        value: "N43.3",
        label: "قيلة مائية - Hydrocele",
        clinics: ["GeneralSurgery", "Urology"],
    },
    {
        value: "M65.3",
        label: "اصبع مقداحية  - Trigger finger",
        clinics: ["Orthopedic"],
    },
    {
        value: "Y84.9",
        label: "خزعة  - Medical procedure, biopsy",
        clinics: [
            "InternalMedicine",
            "Gynecology",
            "GeneralSurgery",
            "ENT",
            "Urology",
            "Cardiothoracic",
        ],
    },
    {
        value: "Y84.9",
        label: "خزعة  - Medical procedure, laparotomy",
        clinics: [
            "InternalMedicine",
            "Gynecology",
            "GeneralSurgery",
            "ENT",
            "Urology",
            "Cardiothoracic",
        ],
    },
    {
        value: "Y84.9",
        label: "خزعة  - Medical procedure, curretage",
        clinics: [
            "InternalMedicine",
            "Gynecology",
            "GeneralSurgery",
            "ENT",
            "Urology",
            "Cardiothoracic",
        ],
    },
    {
        value: "Y84.6",
        label: "خزعة  - Urinary catheterization",
        clinics: [],
    },
    {
        value: "Y84.0",
        label: "خزعة  - Cardiac catheterization",
        clinics: [],
    },
    {
        value: "Y84.8",
        label: "خزعة  - Other medical procedures, IV line ",
        clinics: [],
    },
    {
        value: "K35",
        label: "التهاب زائدة دودية  - Acute appendicitis",
        clinics: ["InternalMedicine", "GeneralSurgery"],
    },
    {
        value: "B19",
        label: "التهاب كبد فيروسي  - Unspecified viral hepatitis",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "Y58.0",
        label: "لقاح السل  - BCG vaccine",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "Y58.4",
        label: "لقاح الكزاز  - Tetanus vaccine",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "Y59",
        label: "لقاحات أخرى  - Other and unspecified vaccines and biological substances",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "IDA-100",
        label: "لاشيئ - nothing",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Gynecology",
            "GeneralSurgery",
            "Orthopedic",
            "Ophthalmology",
            "ENT",
            "Neurosurgery",
            "VascularSurgery",
            "Urology",
            "Cardiothoracic",
            "MentalHealth",
            "Dental",
            "Radiology",
            "Emergency Pediatric",
        ],
    },
    {
        value: "H81.1",
        label: "دوار دهليزي - Benign Paroxysmal Vertigo",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Gynecology",
            "GeneralSurgery",
            "Orthopedic",
            "Emergency Gynecology",
        ],
    },
    {
        value: "G40",
        label: "الصرع - Epilepsy",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Gynecology",
            "GeneralSurgery",
            "Orthopedic",
            "Emergency Gynecology",
            "Neurology",
        ],
    },
    {
        value: "H01.0",
        label: "التهاب الاذن - Blepharitis",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Gynecology",
            "GeneralSurgery",
            "Orthopedic",
            "Emergency Gynecology",
        ],
    },
    {
        value: "H10",
        label: "التهاب الاجفان - Conjunctivitis",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Gynecology",
            "GeneralSurgery",
            "Orthopedic",
            "Emergency Gynecology",
        ],
    },
    {
        value: "H04.9",
        label: " انسداد مجرى الدمع - Disorder of Lacrimal System, Unspecified",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Gynecology",
            "GeneralSurgery",
            "Orthopedic",
            "Emergency Gynecology",
        ],
    },
    {
        value: "P59.9",
        label: " اليرقان الفيزيولوجي - Neonatal Jaundice, Unspecified",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Gynecology",
            "GeneralSurgery",
            "Orthopedic",
            "Emergency Gynecology",
        ],
    },
    {
        value: "F32",
        label: "اكتئاب - Depressive episode",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Gynecology",
            "GeneralSurgery",
            "Orthopedic",
            "Emergency Gynecology",
        ],
    },
    {
        value: "F41.9",
        label: "اضطراب القلق - Anxiety disorder, unspecified",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Gynecology",
            "GeneralSurgery",
            "Orthopedic",
            "Emergency Gynecology",
        ],
    },
    {
        value: "F20",
        label: "فضام - Schizophrenia",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Gynecology",
            "GeneralSurgery",
            "Orthopedic",
            "Emergency Gynecology",
        ],
    },
    {
        value: "F31",
        label: "ثنائي قطب - Bipolar affective disorder",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Gynecology",
            "GeneralSurgery",
            "Orthopedic",
            "Emergency Gynecology",
        ],
    },
    {
        value: "F43.1",
        label: "متلازمة مابعد الصدمة - Post-traumatic stress disorder PTSD",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Gynecology",
            "GeneralSurgery",
            "Orthopedic",
            "Emergency Gynecology",
        ],
    },
    {
        value: "F84.0",
        label: "اضطراب التوحد - Childhood autism",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Gynecology",
            "GeneralSurgery",
            "Orthopedic",
            "Pediatric",
            "Emergency Pediatric",
            "Emergency Gynecology",
        ],
    },
    {
        value: "R32",
        label: "تبول ليلي لاارادي - Unspecified urinary incontinence",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Gynecology",
            "GeneralSurgery",
            "Orthopedic",
            "Urology",
            "Emergency Gynecology",
        ],
    },
    {
        value: "E11",
        label: "السكر غير المعتمد على الأنسولين - Type 2 diabetes mellitus",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Gynecology",
            "GeneralSurgery",
            "Orthopedic",
            "Emergency Gynecology",
        ],
    },
    {
        value: "J21",
        label: "التهاب القصبات الحاد - 2- Acute bronchiolitis",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Gynecology",
            "GeneralSurgery",
            "Orthopedic",
            "Emergency Gynecology",
        ],
    },
    {
        value: "L30.3",
        label: "التهاب الجلد الجرثومي - Infective dermatitis",
        clinics: [
            "InternalMedicine",
            "General",
            "Pediatric",
            "Gynecology",
            "GeneralSurgery",
            "Orthopedic",
            "Emergency Gynecology",
        ],
    },
    {
        value: "K00.0",
        label: "انعدام الأسنان      ANODONTIA",
        clinics: ["Dental"],
    },
    {
        value: "K00.1",
        label: "الأسنان الفائقة     SUPERNUMERARY TEETH",
        clinics: ["Dental"],
    },
    {
        value: "K02.3",
        label: "القبض على تسوس الأسنان      ARRESTED DENTAL CARIES",
        clinics: ["Dental"],
    },
    {
        value: "K02.5",
        label: "تسوس الأسنان على سطح الحفرة والشق   DENTAL CARIES ON PIT AND FISSURE SURFACE",
        clinics: ["Dental"],
    },
    {
        value: "K02.51",
        label: "تسوس الأسنان على الحفرة وشق السطح يقتصر على المينا DENTAL CARIES ON PIT AND FISSURE SURFACE LIMITED TO ENAMEL",
        clinics: ["Dental"],
    },
    {
        value: "K02.52",
        label: "تسوس الأسنان على الحفرة والتشقق السطحي للضغط على الأسنان   DENTAL CARIES ON PIT AND FISSURE SURFACE PENETRATING INTO DENTIN",
        clinics: ["Dental"],
    },
    {
        value: "K02.53",
        label: "تسوس الأسنان على الحفرة وتسقيف سطح الفتحة في اللب  DENTAL CARIES ON PIT AND FISSURE SURFACE PENETRATING INTO PULP",
        clinics: ["Dental"],
    },
    {
        value: "K02.6",
        label: "تسوس الأسنان على سطح ناعم   DENTAL CARIES ON SMOOTH SURFACE",
        clinics: ["Dental"],
    },
    {
        value: "K02.61",
        label: "تسوس الأسنان على سطح ناعم يقتصر على المينا DENTAL CARIES ON SMOOTH SURFACE LIMITED TO ENAMEL",
        clinics: ["Dental"],
    },
    {
        value: "K02.7",
        label: "تسوس جذر الأسنان    DENTAL ROOT CARIES",
        clinics: ["Dental"],
    },
    {
        value: "K02.9",
        label: "تسوس الأسنان غير محدد       UNSPECIFIED DENTAL CARIES",
        clinics: ["Dental", "Emergency General"],
    },
    {
        value: "K03.0",
        label: "الانفعال المفرط للاسنان     EXCESSIVE ATTRITION OF TEETH",
        clinics: ["Dental"],
    },
    {
        value: "K03.1",
        label: "تآكل الأسنان        ABRASION OF TEETH",
        clinics: ["Dental"],
    },
    {
        value: "K03.2",
        label: "تآكل الأسنان        EROSION OF TEETH",
        clinics: ["Dental"],
    },
    {
        value: "K03.4",
        label: "فرط تلون الملاط     HYPERCEMENTOSIS",
        clinics: ["Dental"],
    },
    {
        value: "K03.7",
        label: "التغييرات اللونية الإيجابية لأنسجة الأسنان الثابتة  POSTERUPTIVE COLOR CHANGES OF DENTAL HARD TISSUES",
        clinics: ["Dental"],
    },
    {
        value: "K03.8",
        label: "أمراض أخرى محددة من أنسجة الأسنان الصعبة    OTHER SPECIFIED DISEASES OF HARD TISSUES OF TEETH",
        clinics: ["Dental"],
    },
    {
        value: "K04.3",
        label: "تشكيل الأنسجة الصلبة غير الطبيعية في اللب   ABNORMAL HARD TISSUE FORMATION IN PULP",
        clinics: ["Dental"],
    },
    {
        value: "K08.0",
        label: "إكراه الأسنان بسبب الأسباب الجهازية EXFOLIATION OF TEETH DUE TO SYSTEMIC CAUSES",
        clinics: ["Dental"],
    },
    {
        value: "K08.3",
        label: "جذر الأسنان المحتجزة        RETAINED DENTAL ROOT",
        clinics: ["Dental"],
    },
    {
        value: "K08.50",
        label: "استعادة غير مرضية من الأسنان ، غير محدد    UNSATISFACTORY RESTORATION OF TOOTH, UNSPECIFIED",
        clinics: ["Dental"],
    },
    {
        value: "K08.51",
        label: "فتح هوامش استعادة الأسنان  OPEN RESTORATION MARGINS OF TOOTH",
        clinics: ["Dental"],
    },
    {
        value: "K08.53",
        label: "مواد طب الأسنان المعطوبة   FRACTURED DENTAL RESTORATIVE MATERIAL",
        clinics: ["Dental"],
    },
    {
        value: "K08.53",
        label: "مواد طب الأسنان المعطوبة   FRACTURED DENTAL RESTORATIVE MATERIAL",
        clinics: ["Dental"],
    },
    {
        value: "K08.55",
        label: "التحسس إلى مواد طب الأسنان القائمة ALLERGY TO EXISTING DENTAL RESTORATIVE MATERIAL",
        clinics: ["Dental"],
    },
    {
        value: "M26.32",
        label: "المباعدة المفرطة للأسنان المنهكة بالكامل   EXCESSIVE SPACING OF FULLY ERUPTED TEETH",
        clinics: ["Dental"],
    },
    {
        value: "M26.62",
        label: "التهاب المفاصل الصدغي المشترك      ARTHRALGIA OF TEMPOROMANDIBULAR JOINT",
        clinics: ["Dental"],
    },
    {
        value: "IDA-101",
        label: "اجراءات تمريضية- nursing procedures",
        clinics: ["nursing"],
    },
    {
        value: "N74",
        label: "الاضطرابات الالتهابية في الحوض لدى النساء لأمراض مصنفة في مكان آخر - Female pelvic inflammatory disorders in diseases classified elsewhere",
        clinics: ["Gynecology"],
    },
    {
        value: "N77",
        label: "تقرحات و التهاب الفرج المهبلي في الأمراض المصنفة في مكان آخر - Vulvovaginal ulceration and inflammation in diseases classified elsewhere",
        clinics: ["Gynecology"],
    },
    {
        value: "B24",
        label: " مرض فيروس نقص المناعة البشرية غير محدد - Unspecified human immunodeficiency virus [HIV] disease ",
        clinics: ["Gynecology", "InternalMedicine"],
    },
    {
        value: "A56",
        label: " أمراض المتدثرات (الكلاميديا) المنقولة جنسيا - Other sexually transmitted chlamydial diseases  ",
        clinics: ["Gynecology", "InternalMedicine"],
    },
    {
        value: "B08.1",
        label: " المليساء المعدية- Molluscum contagiosum ",
        clinics: ["Gynecology", "Gynecology", "Emergency Gynecology"],
    },
    {
        value: "A54",
        label: " انتان بالمكورات البنية ، السيلان - Gonococcal infection, Gonorrhea ",
        clinics: [
            "Gynecology",
            "Gynecology",
            "InternalMedicine",
            "Emergency Gynecology",
        ],
    },
    {
        value: "A53",
        label: " الزهري - Other and unspecified syphilis ",
        clinics: ["Gynecology"],
    },
    {
        value: "A59",
        label: " داء المشعرات - Trichomoniasis ",
        clinics: ["Gynecology", "Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O23",
        label: " انتان المجاري البولية والتناسلية أثناء الحمل - Infections of genitourinary tract in pregnancy ",
        clinics: ["Gynecology", "Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O86.2",
        label: " انتان المجاري البولية التالي للولادة - Urinary tract infection following delivery ",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "N94.6",
        label: " عسر الطمث - Dysmenorrhoea, unspecified ",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "N93",
        label: " النزف المهبلي الشاذ - Other abnormal uterine and vaginal bleeding ",
        clinics: ["Gynecology", "Gynecology", "Emergency Gynecology"],
    },
    {
        value: "E34.3",
        label: "قصر القامة - Dwarfism, Short stature, not elsewhere classified",
        clinics: ["Pediatric", "Emergency Pediatric", "Endocrinology"],
    },
    {
        value: "R62.8",
        label: "فشل نمو - Failure to thrive, Other lack of expected normal physiological development",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "P59.0",
        label: "اليرقان(المتلازمة الزيانية)   المرتبط بالولادة المبكرة - Neonatal jaundice associated with preterm delivery",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "P59.9",
        label: "اليرقان(المتلازمة الزيانية) غير محدد - Neonatal jaundice, unspecified",
        clinics: ["Pediatric"],
    },
    {
        value: "P59.3",
        label: "اليرقان(المتلازمة الزيانية) من مثبط حليب الثدي - Neonatal jaundice from breast milk inhibitor",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "I42",
        label: "اعتلال العضلة القلبية - Cardiomyopathy",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "I40",
        label: "التهاب العضلة القلبية - Acute myocarditis",
        clinics: [
            "Pediatric",
            "Cardiothoracic",
            "Pediatric",
            "Emergency Pediatric",
        ],
    },
    {
        value: "R10.4",
        label: "مغص الرضع - infantile colic, Other and unspecified abdominal pain",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "G80",
        label: "ضمور دماغي(شلل دماغي) - Cerebral palsy",
        clinics: ["Pediatric"],
    },
    {
        value: "R04.0",
        label: "رعاف متكرر (اضطرابات عوامل التخثر) - Epistaxis",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "D69.9",
        label: "الإضطرابات النزفية - Haemorrhagic condition, unspecified",
        clinics: ["Pediatric", "Emergency Pediatric", "Hematology"],
    },
    {
        value: "R50.9",
        label: "الترفع الحروري - Fever, unspecified",
        clinics: [
            "Pediatric",
            "InternalMedicine",
            "Emergency Pediatric",
            "Emergency General",
        ],
    },
    {
        value: "R10.4",
        label: "الألم البطني الوظيفي - Other and unspecified abdominal pain",
        clinics: ["Pediatric"],
    },
    {
        value: "G40.9",
        label: "اختلاج صرعي(صرع) - Epileptic convulsion, Epilepsy, unspecified",
        clinics: ["Pediatric", "Neurosurgery", "Emergency Pediatric"],
    },
    {
        value: "R56.0",
        label: "اختلاج حروري - Febrile convulsions",
        clinics: ["Pediatric"],
    },
    {
        value: "H66.9",
        label: "التهاب اذن وسطى - Otitis media, unspecified",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "R59.9",
        label: "التهاب عقد لمفاوية - Enlarged lymph nodes, unspecified",
        clinics: ["Pediatric", "Emergency Pediatric", "ThoracicSurgery"],
    },
    {
        value: "G40.4",
        label: "تشنجات طفلية - Infantile spasms, Other generalized epilepsy and epileptic syndromes",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "Q65.2",
        label: "خلع الورك الولادي - Congenital dislocation of hip, unspecified",
        clinics: ["Pediatric"],
    },
    {
        value: "E34.3",
        label: "تأخر النمو البنيوي - constitutional growth delay, Short stature, not elsewhere classified",
        clinics: ["Pediatric"],
    },
    { value: "K40", label: "فتق إربي - Inguinal hernia", clinics: ["Pediatric"] },
    {
        value: "R16.0",
        label: "الضخامة الكبدية - Hepatomegaly, not elsewhere classified",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "Q44.7",
        label: "الضخامة الكبدية وتشوهات أخرى في الكبد - Hepatomegaly, Other congenital malformations of liver",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "I50.9",
        label: "قصور القلب الحاد - Heart failure, unspecified",
        clinics: [
            "Pediatric",
            "Cardiothoracic",
            "Pediatric",
            "Emergency Pediatric",
        ],
    },
    {
        value: "F70",
        label: "تخلف عقلي - Mild mental retardation",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "F71",
        label: "تخلف عقلي - Moderate mental retardation",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "F72",
        label: "تخلف عقلي - Severe mental retardation",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "P83.5",
        label: "قيلة مائية",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "N43",
        label: "قيلة مائية",
        clinics: [
            "PediatricSurgery",
            "Pediatric",
            "Urology",
            "Emergency Pediatric",
        ],
    },
    {
        value: "E26.8",
        label: "تناذر بارتر",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "R55",
        label: "نوب غشي/نوب فقدان وعي",
        clinics: [
            "Pediatric",
            "Cardiothoracic",
            "Pediatric",
            "Emergency Pediatric",
            "Emergency General",
        ],
    },
    {
        value: "P20.9",
        label: "نقص أكسجة حول الولادة",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "P21",
        label: "نقص أكسجة حول الولادة",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "M67.4",
        label: "كيسات مفصلية - Ganglion cyst",
        clinics: ["Orthopedic"],
    },
    {
        value: "M71.2",
        label: "كيسة بيكر - Synovial cyst of popliteal space [Baker]",
        clinics: ["Orthopedic"],
    },
    {
        value: "G58.9",
        label: "انضغاط أعصاب محيطية - Nerve compression syndrome, Mononeuropathy, unspecified",
        clinics: ["Orthopedic"],
    },
    {
        value: "T14.4",
        label: "أذية أعصاب محيطية - Injury of nerve(s) of unspecified body region",
        clinics: ["Orthopedic"],
    },
    {
        value: "I77.8",
        label: "قرحة وعائية - Other specified disorders of arteries and arterioles",
        clinics: ["GeneralSurgery", "VascularSurgery"],
    },
    {
        value: "I87.2",
        label: "قرحة وعائية - Venous ulcer, Venous insufficiency (chronic)(peripheral)",
        clinics: ["GeneralSurgery", "VascularSurgery", "GeneralSurgery"],
    },
    {
        value: "D18.0",
        label: "ورم وعائي - Haemangioma, any site",
        clinics: [
            "PediatricSurgery",
            "GeneralSurgery",
            "VascularSurgery",
            "GeneralSurgery",
            "Plastic Surgery",
        ],
    },
    {
        value: "N60",
        label: "افات الثدي - Benign mammary dysplasia, fibrocystic mastopathy",
        clinics: ["GeneralSurgery", "GeneralSurgery"],
    },
    {
        value: "N64.9",
        label: "افات الثدي - Disorder of breast, unspecified",
        clinics: ["PediatricSurgery", "GeneralSurgery", "GeneralSurgery"],
    },
    {
        value: "N60.0",
        label: "افات الثدي - Solitary cyst of breast",
        clinics: ["GeneralSurgery", "GeneralSurgery"],
    },
    {
        value: "N64.8",
        label: "افات الثدي -  Other specified disorders of breast, Galactocele",
        clinics: ["GeneralSurgery", "GeneralSurgery"],
    },
    {
        value: "N60.1",
        label: "افات الثدي - Diffuse cystic mastopathy",
        clinics: ["GeneralSurgery", "GeneralSurgery"],
    },
    {
        value: "N64.5",
        label: "افات الثدي - Other signs and symptoms in breast, Nipple discharge, Retraction of nipple",
        clinics: ["GeneralSurgery", "GeneralSurgery"],
    },
    {
        value: "N60.2",
        label: "افات الثدي - Fibroadenosis of breast",
        clinics: ["GeneralSurgery", "GeneralSurgery"],
    },
    {
        value: "N60.3",
        label: "افات الثدي - Fibrosclerosis of breast",
        clinics: ["GeneralSurgery", "GeneralSurgery"],
    },
    {
        value: "N60.4",
        label: "افات الثدي - Mammary duct ectasia",
        clinics: ["GeneralSurgery", "GeneralSurgery"],
    },
    {
        value: "N60.8",
        label: "افات الثدي - Other benign mammary dysplasias",
        clinics: ["GeneralSurgery", "GeneralSurgery"],
    },
    {
        value: "N60.9",
        label: "افات الثدي - Benign mammary dysplasia, unspecified",
        clinics: ["GeneralSurgery", "GeneralSurgery"],
    },
    {
        value: "N61",
        label: "افات الثدي - Inflammatory disorders of breast, Abscess, Mastitis, neonatal infective mastitis",
        clinics: ["GeneralSurgery", "GeneralSurgery"],
    },
    {
        value: "N64.4",
        label: "افات الثدي - Mastodynia",
        clinics: ["GeneralSurgery", "GeneralSurgery"],
    },
    {
        value: "N62",
        label: "افات الثدي - Hypertrophy of breast, Gynaecomastia, ",
        clinics: ["PediatricSurgery", "GeneralSurgery", "GeneralSurgery"],
    },
    {
        value: "N64.3",
        label: "افات الثدي - Galactorrhoea not associated with childbirth",
        clinics: ["GeneralSurgery", "GeneralSurgery"],
    },
    {
        value: "N63",
        label: "افات الثدي - Unspecified lump in breast, Nodule",
        clinics: ["PediatricSurgery", "GeneralSurgery", "GeneralSurgery"],
    },
    {
        value: "N64.0",
        label: "افات الثدي - Fissure and fistula of nipple",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "N64.1",
        label: "افات الثدي - Fat necrosis of breast",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "N64.2",
        label: "افات الثدي - Atrophy of breast",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "I73.9",
        label: "نقص تروية طرف - Peripheral vascular disease, unspecified. Intermittent claudication, Spasm of artery",
        clinics: [
            "PediatricSurgery",
            "GeneralSurgery",
            "VascularSurgery",
            "GeneralSurgery",
        ],
    },
    {
        value: "L89",
        label: "قرحة إضطجاعية - Decubitus ulcer and pressure area",
        clinics: ["PediatricSurgery", "GeneralSurgery", "Plastic Surgery"],
    },
    {
        value: "Z48",
        label: "متابعة بعد عمل جراحي - Other surgical follow-up care",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "K43.2",
        label: "فتق اندحاقي - Incisional hernia without obstruction or gangrene",
        clinics: ["PediatricSurgery", "GeneralSurgery", "GeneralSurgery"],
    },
    {
        value: "K43.0",
        label: "فتق اندحاقي - Incisional hernia with obstruction, without gangrene",
        clinics: ["PediatricSurgery", "GeneralSurgery", "GeneralSurgery"],
    },
    {
        value: "K43.1",
        label: "فتق اندحاقي - Incisional hernia with gangrene",
        clinics: ["PediatricSurgery", "GeneralSurgery", "GeneralSurgery"],
    },
    {
        value: "K42",
        label: "فتق سرة - Umbilical hernia",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "K41",
        label: "فتق فخذي - Femoral hernia",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "J00-J06",
        label: "انتان طرق تنفسية علوي- Acute upper respiratory infections",
        clinics: ["InternalMedicine"],
    },
    {
        value: "J00",
        label: "انتان طرق تنفسية علوي- Acute nasopharyngitis [common cold]",
        clinics: ["InternalMedicine", "InternalMedicine"],
    },
    {
        value: "J01",
        label: "التهاب جيوب أنفية حاد- Acute sinusitis",
        clinics: ["InternalMedicine"],
    },
    {
        value: "J02",
        label: "التهاب بلعوم حاد- Acute pharyngitis",
        clinics: ["InternalMedicine"],
    },
    {
        value: "J03",
        label: "التهاب لوزات حاد- Acute tonsillitis",
        clinics: ["InternalMedicine"],
    },
    {
        value: "J04",
        label: "التهاب الحنجرة والتهاب القصبة الهوائية الحاد- Acute laryngitis and tracheitis",
        clinics: ["InternalMedicine", "InternalMedicine"],
    },
    {
        value: "J05",
        label: "التهاب الحنجرة الانسدادي الحاد [الخناق] والتهاب لسان المزمار- Acute obstructive laryngitis [croup] and epiglottitis",
        clinics: ["InternalMedicine", "InternalMedicine"],
    },
    {
        value: "J06",
        label: "التهابات الجهاز التنفسي العلوي الحادة في مواقع متعددة وغير محددة- Acute upper respiratory infections of multiple and unspecified sites",
        clinics: ["InternalMedicine", "InternalMedicine"],
    },
    {
        value: "K29.7",
        label: "التهاب المعدة- Gastritis, unspecified",
        clinics: ["InternalMedicine", "InternalMedicine"],
    },
    {
        value: "A09.9",
        label: "التهاب المعدة و الأمعاء الحاد- Gastroenteritis and colitis of unspecified origin",
        clinics: ["InternalMedicine"],
    },
    {
        value: "M25.5",
        label: "الألم المفصلي- Pain in joint",
        clinics: ["InternalMedicine", "InternalMedicine"],
    },
    {
        value: "M13.0",
        label: "التهاب مفاصل متعدد- Polyarthritis, unspecified",
        clinics: ["InternalMedicine", "InternalMedicine"],
    },
    {
        value: "R07.4",
        label: "الألم الصدري من منشأ غير حدد- Chest pain, unspecified",
        clinics: ["InternalMedicine", "InternalMedicine"],
    },
    {
        value: "R07.3",
        label: "الألم الصدري الجداري- Other chest pain, Anterior chest-wall pain",
        clinics: ["InternalMedicine", "InternalMedicine"],
    },
    {
        value: "O13",
        label: "ارتفاع الضغط الحملي- Gestational [pregnancy-induced] hypertension without significant proteinuria",
        clinics: [
            "InternalMedicine",
            "Gynecology",
            "InternalMedicine",
            "Emergency Gynecology",
        ],
    },
    {
        value: "O14",
        label: "- Gestational [pregnancy-induced] hypertension with significant proteinuria",
        clinics: ["InternalMedicine", "InternalMedicine"],
    },
    {
        value: "I15",
        label: "ارتفاع الضغط الثانوي- Secondary hypertension",
        clinics: [
            "InternalMedicine",
            "InternalMedicine",
            "Nephrology",
            "Emergency General",
        ],
    },
    {
        value: "I10",
        label: "ارتفاع الضغط الأولي الأساسي- Essential (primary) hypertension",
        clinics: ["InternalMedicine", "Nephrology", "Emergency General"],
    },
    {
        value: "M35.2",
        label: "داء بهجت- Behçet disease",
        clinics: ["InternalMedicine", "InternalMedicine", "Dermotology"],
    },
    {
        value: "E11",
        label: "داء السكري الغير معتمد على الانسولين- Non-insulin-dependent diabetes mellitus",
        clinics: ["InternalMedicine"],
    },
    {
        value: "N39.4",
        label: "سلس بول متواصل - Other specified urinary incontinence",
        clinics: ["PediatricSurgery", "PediatricSurgery", "Urology"],
    },
    {
        value: "F98.0",
        label: "سلس بول مبدئي - Nonorganic enuresis, Urinary Incontinence, Enuresis (primary)(secondary) of nonorganic origin, Functional enuresis, Psychogenic enuresis",
        clinics: ["Urology"],
    },
    {
        value: "F98.0",
        label: "سلس بول كابوسي - Psychogenic enuresis,  Urinary Incontinence",
        clinics: ["Urology"],
    },
    {
        value: "R32",
        label: "سلس بول ليلي - Unspecified urinary incontinence, Bedwetting",
        clinics: ["Urology"],
    },
    {
        value: "N39.4",
        label: "سلس بول ليلي نهاري - Other specified urinary incontinence",
        clinics: ["Urology"],
    },
    {
        value: "N39.4",
        label: "سلس بول فرط الافاضة - Other specified urinary incontinence",
        clinics: ["Urology"],
    },
    {
        value: "N39.3",
        label: "سلس بول جهدي - Stress incontinence",
        clinics: ["Urology"],
    },
    {
        value: "D30.0",
        label: "أورام(سرطان) في الكلية - Benign neoplasm of the Kidney",
        clinics: ["PediatricSurgery", "Urology"],
    },
    {
        value: "C64",
        label: "أورام(سرطان) في الكلية - Malignant neoplasm of kidney, except renal pelvis",
        clinics: ["PediatricSurgery", "Urology"],
    },
    {
        value: "C65",
        label: "أورام(سرطان) في الكلية - Malignant neoplasm of renal pelvis",
        clinics: ["PediatricSurgery", "Urology"],
    },
    {
        value: "D30.2",
        label: "أورام(سرطان) في الحالب - Benign neoplasm of the Ureter",
        clinics: ["PediatricSurgery", "Urology"],
    },
    {
        value: "C66",
        label: "أورام(سرطان) في الحالب - Malignant neoplasm of ureter",
        clinics: ["PediatricSurgery", "Urology"],
    },
    {
        value: "D30.3",
        label: "أورام(سرطان) في المثانة - Benign neoplasm of the Bladder",
        clinics: ["PediatricSurgery", "Urology"],
    },
    {
        value: "C67",
        label: "أورام(سرطان) في المثانة - Malignant neoplasm of bladder",
        clinics: ["PediatricSurgery", "Urology"],
    },
    {
        value: "D29.2",
        label: "أورام(سرطان) في الخصية - Benign neoplasm of the Testis",
        clinics: ["PediatricSurgery", "Urology"],
    },
    {
        value: "C62",
        label: "أورام(سرطان) في الخصية - Malignant neoplasm of testis",
        clinics: ["PediatricSurgery", "Urology"],
    },
    {
        value: "Y89.1",
        label: "عقابيل اصابة حربية - Sequelae of war operations",
        clinics: ["PediatricSurgery", "Urology"],
    },
    {
        value: "T98.3",
        label: "عقابيل عمل جراحي - Sequelae of complications of surgical and medical care, not elsewhere classified",
        clinics: ["PediatricSurgery", "Urology"],
    },
    {
        value: "D29.1",
        label: "أورام(سرطان) في البروستات - Benign neoplasm of the Prostate, Benign hyperplasia of prostate, enlargement of prostate",
        clinics: ["Urology"],
    },
    {
        value: "C61",
        label: "أورام(سرطان) في البروستات - Malignant neoplasm of prostate",
        clinics: ["PediatricSurgery", "Urology", "Hematology"],
    },
    { value: "N46", label: "عقم بدني - Male infertility", clinics: ["Urology"] },
    { value: "N46", label: "عقم ثانوي - Male infertility", clinics: ["Urology"] },
    {
        value: "Z48.8",
        label: "متابعة عمل جراحي - Other specified surgical follow-up care",
        clinics: ["PediatricSurgery", "Urology"],
    },
    {
        value: "N31.9",
        label: "مثانة عصبية - Neuromuscular dysfunction of bladder, unspecified",
        clinics: ["PediatricSurgery", "Urology"],
    },
    {
        value: "N45",
        label: "التهاب خصية وبربخ - Orchitis and epididymitis",
        clinics: ["PediatricSurgery", "PediatricSurgery", "Urology"],
    },
    {
        value: "N32.3",
        label: "رتوج مثانة - Diverticulum of bladder",
        clinics: ["Urology"],
    },
    {
        value: "IDA-102",
        label: "اجراءات اشعة- Radiology procedures",
        clinics: ["Radiology"],
    },
    {
        value: "K55.1",
        label: "Chronic vascular disorders of intestine, Chronic ischaemic, Mesenteric (atherosclerosis, vascular insufficiency) - اضطرابت الأوعية الدموية المزمنة في الأمعاء، الاقفار المزمن، (التصلب الوعائي، عدم الكفاية الوعائية) المساريقية",
        clinics: ["VascularSurgery", "GeneralSurgery"],
    },
    {
        value: "I70",
        label: "Atherosclerosis - تصلب الشرايين",
        clinics: ["VascularSurgery"],
    },
    {
        value: "I71",
        label: "Aortic aneurysm and dissection - أم الدم الأبهرية و تسلخها",
        clinics: ["VascularSurgery"],
    },
    {
        value: "I72",
        label: "Other aneurysm and dissection - أمهات الدم الأخرى و تسلخها",
        clinics: ["VascularSurgery"],
    },
    {
        value: "I73.0",
        label: "Raynaud syndrome - متلازمة رينود",
        clinics: ["VascularSurgery", "Dermotology"],
    },
    {
        value: "I73.1",
        label: "Thromboangiitis obliterans [Buerger] - الالتهاب الوعائي الخثاري (برغر)",
        clinics: ["VascularSurgery"],
    },
    {
        value: "I73.8",
        label: "Other specified peripheral vascular diseases - أمراض الأوعية الدموية الطرفية المحددة الأخرى",
        clinics: ["VascularSurgery"],
    },
    {
        value: "I74.8",
        label: "Arterial embolism and thrombosis - الصمات الشريانية و الخثار الشرياني في شرايين أخرى",
        clinics: ["VascularSurgery"],
    },
    {
        value: "I77.0",
        label: "Arteriovenous fistula, acquired - ناسور شرياني وريدي، مكتسب",
        clinics: ["VascularSurgery", "ENT"],
    },
    {
        value: "I77.1",
        label: "Stricture of artery - تضيق شرياني",
        clinics: ["VascularSurgery"],
    },
    {
        value: "I77.2",
        label: "Rupture of artery - تمزق شرياني",
        clinics: ["VascularSurgery"],
    },
    {
        value: "I77.5",
        label: "Necrosis of artery - تنخر الشريان",
        clinics: ["VascularSurgery"],
    },
    {
        value: "I77.6",
        label: "Arteritis, unspecified - التهاب الشرايين، غير محدد",
        clinics: ["VascularSurgery"],
    },
    {
        value: "I78.0",
        label: "Naevus, non-neoplastic - الوحمة، غير التنشؤية",
        clinics: ["VascularSurgery"],
    },
    {
        value: "I78.8",
        label: "Other diseases of capillaries - أمراض الشعيرات الدموية الأخرى",
        clinics: ["VascularSurgery"],
    },
    {
        value: "I80",
        label: "Phlebitis and thrombophlebitis, Deep vein thrombosis - التهابات الأوردة و التهابات الأوردة الخثارية، خثار الوريد العميق",
        clinics: ["VascularSurgery"],
    },
    {
        value: "I81",
        label: "Portal vein thrombosis - خثار الوريد الباب",
        clinics: ["VascularSurgery"],
    },
    {
        value: "I82.0",
        label: "Budd-Chiari syndrome - متلازمة بود كياري",
        clinics: ["VascularSurgery"],
    },
    {
        value: "I82.1",
        label: "Thrombophlebitis migrans - التهاب الوريد الخثاري المتنقل",
        clinics: ["VascularSurgery"],
    },
    {
        value: "I82.2",
        label: "Embolism and thrombosis of vena cava - صمة و خثار في الوريد الأجوف",
        clinics: ["VascularSurgery"],
    },
    {
        value: "I82.3",
        label: "Embolism and thrombosis of renal vein - صمة و خثار في الوريد الكلوي",
        clinics: ["VascularSurgery"],
    },
    {
        value: "I82.8",
        label: "Embolism and thrombosis of other specified veins - صمة و خثار في وريد محدد آخر",
        clinics: ["VascularSurgery"],
    },
    {
        value: "I86",
        label: "Varicose veins of other sites - دوالي وريدية في أماكن أخرى",
        clinics: ["VascularSurgery"],
    },
    {
        value: "I87",
        label: "Other disorders of veins - اضطرابات وريدية أخرى",
        clinics: ["VascularSurgery"],
    },
    {
        value: "I88",
        label: "Nonspecific lymphadenitis - التهاب الغدد اللمفاوية غير النوعي",
        clinics: ["VascularSurgery"],
    },
    {
        value: "I89.0",
        label: "Lymphoedema - الوذمة اللمفاوية",
        clinics: ["VascularSurgery"],
    },
    {
        value: "I89.1",
        label: "Lymphangitis - التهاب الأوعية اللمفاوية",
        clinics: ["VascularSurgery"],
    },
    {
        value: "I74.2",
        label: "Embolism and thrombosis of arteries of upper extremities - صمة و خثار في شرايين الأطراف العلوية",
        clinics: ["VascularSurgery"],
    },
    {
        value: "I74.3",
        label: "Embolism and thrombosis of arteries of lower extremities - صمة و خثار في شرايين الأطراف السفلية",
        clinics: ["VascularSurgery"],
    },
    {
        value: "S15.0",
        label: "Injury of carotid artery - أذية الشريان السباتي",
        clinics: ["VascularSurgery"],
    },
    {
        value: "S15.1",
        label: "Injury of vertebral artery - أذية الشريان الفقاري",
        clinics: ["VascularSurgery"],
    },
    {
        value: "S15.2",
        label: "Injury of external jugular vein - أذية الوريد الوداجي الظاهر",
        clinics: ["VascularSurgery"],
    },
    {
        value: "S15.3",
        label: "Injury of internal jugular vein - أذية الوريد الوداجي الباطن",
        clinics: ["VascularSurgery"],
    },
    {
        value: "S15.7",
        label: "Injury of multiple blood vessels at neck level - أذية وعائية متعددة في مستوى العنق",
        clinics: ["VascularSurgery"],
    },
    {
        value: "D35.5",
        label: "Benign neoplasm of Carotid body - ورم حميد في الجسم السباتي",
        clinics: ["VascularSurgery"],
    },
    {
        value: "C75.4",
        label: "Malignant neoplasm of Carotid body - ورم خبيث في الجسم السباتي",
        clinics: ["VascularSurgery"],
    },
    {
        value: "D18.1",
        label: "Lymphangioma, any site - ورم وعائي لمفاوي، أي موقع",
        clinics: ["VascularSurgery"],
    },
    {
        value: "I47.1",
        label: "Supraventricular tachycardia, atrial, atrioventricular [AV], junctional, nodal - تسرع قلب فوق بطيني، أذيني، أذيني بطيني، وصلي، عقدي",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I47.2",
        label: "Ventricular tachycardia - تسرع بطيني",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I47.9",
        label: "Paroxysmal tachycardia, unspecified, Bouveret-Hoffmann syndrome - تسرع القلب الانتيابي، غير محدد، متلازمة بوفيريه-هوفمان",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I47.0",
        label: "Re-entry ventricular arrhythmia - لانظمية عود الدخول البطيني",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "R00.2",
        label: "Palpitations - الخفقان",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I49.0",
        label: "Ventricular fibrillation and flutter - الرجفان والرفرفة البطينية",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I49.1",
        label: "Atrial premature depolarization - نزع الاستقطاب الأذيني الباكر",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I49.2",
        label: "Junctional premature depolarization - نزع الاستقطاب الوصلي الباكر",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I49.3",
        label: "Ventricular premature depolarization - نزع الاستقطاب البطيني الباكر",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I49.5",
        label: "Sick sinus syndrome - متلازمة العقدة الجيبية المريضة",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I48.0",
        label: "Paroxysmal atrial fibrillation - رجفان أذيني انتيابي",
        clinics: ["Cardiothoracic", "Emergency General"],
    },
    {
        value: "I48.1",
        label: "Persistent atrial fibrillation - رجفان أذيني معند",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I48.2",
        label: "Chronic atrial fibrillation - رجفان أذيني مزمن",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I48.3",
        label: "Typical atrial flutter, Type I atrial flutter - رفرفة أذينية تقليدية، رفرفة أذينية نمط أول",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I48.4",
        label: "Atypical atrial flutter, Type II atrial flutter - رفرفة أذينية غير نمطية، رفرفة أذينية نمط ثاني",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I48.9",
        label: "Atrial fibrillation and atrial flutter, unspecified - رفرفة و رجفان أذيني، غير محدد",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I50.0",
        label: "Congestive heart failure, Congestive heart disease, Right ventricular failure (secondary to left heart failure) - قصور قلب احتقاني، الداء القلبي الاحتقاني، قصور بطين أيمن (ثانوي لقصور قلب أيسر)",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I50.1",
        label: "Left ventricular failure, Cardiac asthma, Left heart failure, Oedema of lung or Pulmonary oedema (with mention of heart disease or heart failure) - قصور بطين أيسر، ربو قلبي، قصور قلب أيسر، وذمة رئوية (بوجود داء قلبي أو قصور قلب)",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I34.0",
        label: "Nonrheumatic Mitral (valve) insufficiency (incompetence, regurgitation) - عدم كفاية الصمام التاجي اللارثوية (عجز، قلس)",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I34.1",
        label: "Nonrheumatic Mitral (valve) prolapse, Floppy mitral valve syndrome - تدلي الصمام التاجي اللارثوي، متلازمة الصمام التاجي المرن",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I34.2",
        label: "Nonrheumatic mitral (valve) stenosis - تضيق الصمام التاجي اللارثوي",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I34.8",
        label: "Other nonrheumatic mitral valve disorders - اضطرابات الصمام التاجي اللارثوية الأخرى",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I34.9",
        label: "Nonrheumatic mitral valve disorder, unspecified - اضطرابات الصمام التاجي اللارثوية، غير محدد",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I35.0",
        label: "Nonrheumatic Aortic (valve) stenosis - تضيق الصمام الأبهري اللارثوي",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I35.1",
        label: "Nonrheumatic Aortic (valve) insufficiency (incompetence, regurgitation)  - عدم كفاية الصمام الأبهري اللارثوية (عجز، قلس)",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I35.2",
        label: "Nonrheumatic Aortic (valve) stenosis with insufficiency - تضيق الصمام الأبهري اللارثوي مع عدم كفاية",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I35.8",
        label: "Other Nonrheumatic aortic valve disorders - اضطرابات الصمام الأبهري اللارثوية الأخرى",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I35.9",
        label: "Nonrheumatic Aortic valve disorder, unspecified - اضطرابات الصمام الأبهري اللارثوية، غير محدد",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I21.0",
        label: "Acute transmural myocardial infarction of anterior wall (anterior, anteroapical, anterolateral, anteroseptal) - احتشاء عضلة قلبية حاد عابر للجدار الأمامي (أمامي، أمامي قمي، أمامي جانبي، أمامي حاجزي)",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I21.1",
        label: "Acute transmural myocardial infarction of inferior wall (diaphragmatic wall, inferior, inferolateral, inferoposterior) - احتشاء عضلة قلبية حاد عابر للجدار الأمامي (الجدار الحجابي، السفلي، السفلي الجانبي)",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I21.2",
        label: "Acute transmural myocardial infarction of other sites (apical-lateral, basal-lateral, high lateral, lateral, posterior (true), posterobasal, posterolateral, posteroseptal, septal) - احتشاء عضلة قلبية حاد عابر للجدار في أماكن أخرى (قمي جانبي، قاعدي جانبي، جانبي عالي، جانبي، خلفي (حقيقي)، خلفي قاعدي، خلفي جانبي، خلفي حاجزي، حاجزي)",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I21.3",
        label: "Acute transmural myocardial infarction of unspecified site - احتشاء عضلة قلبية عابر للجدار في مكان غير محدد",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I21.4",
        label: "Acute subendocardial myocardial infarction (Myocardial infarction with non-ST elevation, Nontransmural myocardial infarction) - احتشاء عضلة قلبية تحت الشغاف الحاد (احتشاء عضلة قلبية مع عدم ارتفاع قطعة ST، احتشاء عضلة قلبية غير عابر للجدار)",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I21.9",
        label: "Acute myocardial infarction, unspecified - احتشاء عضلة قلبية حاد، غير محدد",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I22.0",
        label: "Subsequent myocardial infarction of anterior wall (anterior, anteroapical, anterolateral, anteroseptal) - عقابيل احتشاء عضلة قلبية في الجدار الأمامي (أمامي، أمامي قمي، أمامي جانبي، أمامي حاجزي)",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I22.1",
        label: "Subsequent myocardial infarction of anterior wall (diaphragmatic wall, inferior, inferolateral, inferoposterior) - عقابيل احتشاء عضلة قلبية في الجدار الأمامي (الجدار الحجابي، السفلي، السفلي الجانبي، السفلي الخلفي)",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I22.8",
        label: "Subsequent myocardial infarction of other sites (apical-lateral, basal-lateral, high lateral, lateral, posterior (true), posterobasal, posterolateral, posteroseptal, septal) - عقابيل احتشاء العضلة القلبية في أماكن أخرى (قمي جانبي، قاعدي جانبي، جانبي عالي، جانبي، خلفي (حقيقي)، خلفي قاعدي، خلفي جانبي، خلفي حاجزي، حاجزي)",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I22.9",
        label: "Subsequent myocardial infarction of unspecified site - عقابيل احتشاء العضلة القلبية في مكان غير محدد",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I23.0",
        label: "Haemopericardium as current complication following acute myocardial infarction - تدمي التامور كاختلاط حالي لاحتشاء العضلة القلبية الحاد",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I23.1",
        label: "Atrial septal defect as current complication following acute myocardial infarction - عيب الحاجز الأذيني كمضاعفات حالية بعد احتشاء عضلة القلب الحاد",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I23.2",
        label: "Ventricular septal defect as current complication following acute myocardial infarction - عيب الحاجز البطيني كمضاعفات حالية بعد احتشاء عضلة القلب الحاد",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I23.3",
        label: "Rupture of cardiac wall without haemopericardium as current complication following acute myocardial infarction - تمزق الجدار القلبي بدون تدمي التامور كمضاعفات حالية بعد احتشاء عضلة القلب الحاد",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I23.4",
        label: "Rupture of chordae tendineae as current complication following acute myocardial infarction - تمزق الحبال الوترية القلبية كمضاعفات حالية بعد احتشاء عضلة القلب الحاد",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I23.5",
        label: "Rupture of papillary muscle as current complication following acute myocardial infarction - تمزق العضلات الحليمية كمضاعفات حالية بعد احتشاء عضلة القلب الحاد",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I23.6",
        label: "Thrombosis of atrium, auricular appendage, and ventricle as current complications following acute myocardial infarction - خثار الأذينة واللاحقة الأذينية والبطين كمضاعفات حالية بعد احتشاء عضلة القلب الحاد",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I23.8",
        label: "Other current complications following acute myocardial infarction - المضاعفات الحالية الأخرى بعد احتشاء عضلة القلب الحاد",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I20.0",
        label: "Unstable angina (crescendo, de novo effort, worsening effort, Intermediate coronary syndrome, Preinfarction syndrome) - خناق الصدر غير المستقر (المتعاظم، المتجدد على الجهد، الذي يسوء على الجهد، متلازمة الشريان التاجي المتقطعة، متلازمة ما قبل الاحتشاء)",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I20.1",
        label: "Angina pectoris with documented spasm (angiospastic, Prinzmetal, spasm-induced, variant) - خناق الصدر أو الذبحة الصدرية مع تشنج موثق (التشنج الوعائي ، برينزميتال ، المحرض بالمقبضات، المتنوع)",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I20.8",
        label: "Other forms of angina pectoris, (Angina of effort, Stenocardia) - أشكال أخرى لخناق الصدر أو الذبحة الصدرية (الخناق الجهدي، ترقق القلب)",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I20.9",
        label: "Angina pectoris, unspecified (Anginal syndrome, Ischaemic chest pain) - خناق الصدر أو الذبحة الصدرية، غير محدد (المتلازمة الخناقية، الألم الصدري الافقاري)",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I25.0",
        label: "Atherosclerotic cardiovascular disease, so described - أمراض القلب و الأوعية الدموية الشرياينية العصيدية",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I25.1",
        label: "Atherosclerotic heart disease, Coronary (artery): (atheroma, atherosclerosis, disease, sclerosis) - أمراض القلب الشريانية العصيدية، الشريان التاجي: (العصيدة، التضيق العصيدي، التصلبية)",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I25.2",
        label: "Old myocardial infarction - إحتشاء عضلة قلبية قديم",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I25.3",
        label: "Aneurysm of heart - أم دم قلبية",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I25.4",
        label: "Coronary artery aneurysm - أم دم الشريان التاجي",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I25.5",
        label: "Ischaemic cardiomyopathy - اعتلال العضلة القلبية الاقفاري",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I25.6",
        label: "Silent myocardial ischaemia - افقار العضلة القلبية الصامت",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I25.8",
        label: "Other forms of chronic ischaemic heart disease - أشكال أخرى من أمراض القلب الإقفارية المزمنة",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I25.9",
        label: "Chronic ischaemic heart disease, unspecified - أمراض القلب الإقفارية المزمنة ، غير محدد",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I27.0",
        label: "Primary pulmonary hypertension - فرط التوتر الرئوي الأولي",
        clinics: ["Cardiothoracic", "Pulmonology"],
    },
    {
        value: "I27.8",
        label: "Other specified pulmonary heart diseases, Eisenmenger (complex, syndrome) - أمراض قلبية رئوية أخرى محددة، معقد أو متلازمة إيزنمنجر",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I05",
        label: "Rheumatic mitral valve diseases - أمراض الصمام التاجي الرثوية",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I06",
        label: "Rheumatic aortic valve diseases - أمراض الصمام الأبهري الرثوية",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I07",
        label: "Rheumatic tricuspid valve diseases - أمراض الصمام مثلث الشرف الرثوية",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I08",
        label: "Multiple valve diseases - أمراض صمامات متعددة",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I09.0",
        label: "Rheumatic myocarditis - التهاب العضلة القلبية الرثوي",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I31.0",
        label: "Chronic adhesive pericarditis - التهاب التامور اللاصق المزمن",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I31.1",
        label: "Chronic constrictive pericarditis - التهاب التامور المضيق",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I31.2",
        label: "Haemopericardium - تدمي التامور",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I31.3",
        label: "Pericardial effusion (noninflammatory) - انصباب التامور (غير الالتهابي)",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I33.0",
        label: "Acute and subacute infective endocarditis - التهاب الشغاف الحاد و تحت الحاد",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I36",
        label: "Nonrheumatic tricuspid valve disorders - اضطرابات الصمام مثلث الشرف غير الرثوية",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I38",
        label: "Endocarditis, valve unspecified - التهاب الشغاف، غير محدد",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I42.0",
        label: "Dilated cardiomyopathy, Congestive cardiomyopathy - اعتلال العضلة القلبية التوسعي، اعتلال العضلة القلبية الاحتقاني",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I42.1",
        label: "Obstructive hypertrophic cardiomyopathy, Hypertrophic subaortic stenosis - اعتلال العضلة القلبية الضخامي الانسدادي، تضيق تحت الأبهري الضخامي",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I42.9",
        label: "Cardiomyopathy, unspecified - اعتلال العضلة القلبية، غير محدد",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I44",
        label: "Atrioventricular and left bundle-branch block - الحصار الأذيني البطيني و حصار الغصن الأيسر",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I45",
        label: "Other conduction disorders, Right fascicular block - اضطرابات التوصيل الأخرى، حصار الغصن الأيمن",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "I46",
        label: "Cardiac arrest - توقف القلب",
        clinics: ["Cardiothoracic", "InternalMedicine"],
    },
    {
        value: "I09.8",
        label: "Rheumatic pulmonary valve diseases - أمراض الصمام الرئوي الرثوية",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "J85.0",
        label: "Gangrene and necrosis of lung - غانغرين و تنخر في الرئة",
        clinics: ["Cardiothoracic", "InternalMedicine"],
    },
    {
        value: "J85.1",
        label: "Abscess of lung with pneumonia - خراج في الرئة مع ذات رئة",
        clinics: [
            "Cardiothoracic",
            "InternalMedicine",
            "ThoracicSurgery",
            "Pulmonology",
        ],
    },
    {
        value: "J85.2",
        label: "Abscess of lung without pneumonia - خراج في الرئة بدون ذات رئة",
        clinics: ["Cardiothoracic", "InternalMedicine", "ThoracicSurgery"],
    },
    {
        value: "J85.3",
        label: "Abscess of mediastinum - خراج في المنصف",
        clinics: ["Cardiothoracic", "InternalMedicine"],
    },
    {
        value: "J86.0",
        label: "Pyothorax with fistula, abscess of (pleura, thorax), empyema, pyopneumothorax - تقيح صدر مع ناسور، خراج جنبة أو صدر، دبيلة، التهاب الصدر القيحي الهوائي",
        clinics: ["Cardiothoracic", "InternalMedicine", "ThoracicSurgery"],
    },
    {
        value: "J86.9",
        label: "Pyothorax without fistula, abscess of (pleura, thorax), empyema, pyopneumothorax - تقيح صدر بدون ناسور، خراج جنبة أو صدر، دبيلة، التهاب الصدر القيحي الهوائي",
        clinics: ["Cardiothoracic", "InternalMedicine", "ThoracicSurgery"],
    },
    {
        value: "J93.0",
        label: "Spontaneous tension pneumothorax - ريح صدرية عفوية ضاغطة",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "J93.9",
        label: "Pneumothorax, unspecified - ريح صدرية غير محددة",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "S27.00",
        label: "Traumatic pneumothorax without open wound into thoracic cavity - استرواح الصدر الرضي بدون جرح مفتوح في التجويف الصدري",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "S27.01",
        label: "Traumatic pneumothorax with open wound into thoracic cavity - استرواح الصدر الرضي مع جرح مفتوح في التجويف الصدري",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "S27.10",
        label: "Traumatic haemothorax without open wound into thoracic cavity - إنصباب جنب مدمى (تدمي الجنب) بدون جرح مفتوح في التجويف الصدري",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "S27.11",
        label: "Traumatic haemothorax with open wound into thoracic cavity - إنصباب جنب مدمى (تدمي الجنب) مع جرح مفتوح في التجويف الصدري",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "S27.20",
        label: "Traumatic haemopneumothorax without open wound into thoracic cavity - استرواح جنب هوائي مدمى بدون جرح مفتوح في التجويف الصدري",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "S27.21",
        label: "Traumatic haemopneumothorax with open wound into thoracic cavity - استرواح جنب هوائي مدمى مع جرح مفتوح في التجويف الصدري",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "S27.30",
        label: "Other injuries of lung without open wound into thoracic cavity - أذية أخرى للرئة بدون جرح مفتوح في التجويف الصدري",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "S27.31",
        label: "Other injuries of lung with open wound into thoracic cavity - أذية أخرى للرئة مع جرح مفتوح في التجويف الصدري",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "S27.40",
        label: "Injury of bronchus without open wound into thoracic cavity - أذية القصبة الهوائية بدون جرح مفتوح في التجويف الصدري",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "S27.41",
        label: "Injury of bronchus with open wound into thoracic cavity - أذية القصبة الهوائية مع جرح مفتوح في التجويف الصدري",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "S27.50",
        label: "Injury of thoracic trachea without open wound into thoracic cavity - أذية الرغامى بدون جرح مفتوح في التجويف الصدري",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "S27.51",
        label: "Injury of thoracic trachea with open wound into thoracic cavity - أذية الرغامى مع جرح مفتوح في التجويف الصدري",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "S27.60",
        label: "Injury of pleura without open wound into thoracic cavity - أذيات جنبة بدون جرح مفتوح في التجويف الصدري",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "S27.61",
        label: "Injury of pleura with open wound into thoracic cavity - أذيات جنبة مع جرح مفتوح في التجويف الصدري",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "S27.70",
        label: "Multiple injuries of intrathoracic organs without open wound into thoracic cavity - أذيات متعددة في الأعضاء الصدرية بدون جرح مفتوح في التجويف الصدري",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "S27.71",
        label: "Multiple injuries of intrathoracic organs with open wound into thoracic cavity - أذيات متعددة في الأعضاء الصدرية مع جرح مفتوح في التجويف الصدري",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "S27.80",
        label: "Injury of other specified intrathoracic organs (Diaphragm, Lymphatic thoracic duct, Oesophagus (thoracic part), Thymus gland) without open wound into thoracic cavity - أذية أعضاء أخرى محددة داخل الصدر (الحجاب الحاجز ، القناة الصدرية اللمفاوية ، المريء (الجزء الصدري) ، الغدة الصعترية) بدون جرح مفتوح في التجويف الصدري",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "S27.81",
        label: "Injury of other specified intrathoracic organs (Diaphragm, Lymphatic thoracic duct, Oesophagus (thoracic part), Thymus gland) with open wound into thoracic cavity - أذية أعضاء أخرى محددة داخل الصدر (الحجاب الحاجز ، القناة الصدرية اللمفاوية ، المريء (الجزء الصدري) ، الغدة الصعترية) مع جرح مفتوح في التجويف الصدري",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "S26.00",
        label: "Injury of heart with haemopericardium without open wound into thoracic cavity - أذية في القلب مع تدمي التامور بدون جرح مفتوح في تجويف الصدر",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "S26.01",
        label: "Injury of heart with haemopericardium with open wound into thoracic cavity - أذية في القلب مع تدمي التامور مع جرح مفتوح في تجويف الصدر",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "S26.80",
        label: "Other injuries of heart without open wound into thoracic cavity - أذيات أخرى في القلب بدون جرح مفتوح في التجويف الصدري",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "S26.81",
        label: "Other injuries of heart with open wound into thoracic cavity - أذيات أخرى في القلب مع جرح مفتوح في التجويف الصدري",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "S25",
        label: "Injury of blood vessels of thorax - أذية الأوعية الدموية في الصدر",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "S21",
        label: "Open wound of thorax - جرح مفتوح في الصدر",
        clinics: ["Cardiothoracic", "Emergency General", "Plastic Surgery"],
    },
    {
        value: "S20",
        label: "Superficial injury of thorax - أذية سطحية في الصدر",
        clinics: ["Cardiothoracic", "Emergency General", "Plastic Surgery"],
    },
    {
        value: "J94.0",
        label: "Chylous effusion - إنصباب كيلوسي",
        clinics: ["Cardiothoracic", "InternalMedicine", ""],
    },
    {
        value: "K22.3",
        label: "Perforation of oesophagus, Rupture of oesophagus - انثقاب المري، تمزق المريء",
        clinics: [
            "PediatricSurgery",
            "Cardiothoracic",
            "GeneralSurgery",
            "ThoracicSurgery",
        ],
    },
    {
        value: "K22.4",
        label: "Dyskinesia of oesophagus, Corkscrew oesophagus, Diffuse oesophageal spasm, Spasm of oesophagus - عسر حركية المريء، مريء كسارة البندق، تشنج المريء المنتشر، تشنج المريء",
        clinics: [
            "PediatricSurgery",
            "Cardiothoracic",
            "GeneralSurgery",
            "InternalMedicine",
        ],
    },
    {
        value: "K22.5",
        label: "Diverticulum of oesophagus, acquired - رتج مريء، مكتسب",
        clinics: [
            "PediatricSurgery",
            "Cardiothoracic",
            "GeneralSurgery",
            "ThoracicSurgery",
        ],
    },
    {
        value: "Q39.4",
        label: "Congenital Oesophageal web - وترة المريء الخلقية",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "Q39.0",
        label: "Congenital Atresia of oesophagus without fistula - رتق المريء الخلقي بدون ناسور",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "Q39.1",
        label: "Congenital Atresia of oesophagus with tracheo-oesophageal fistula - رتق المريء الخلقي مع ناسور رغامي مريئي",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "K22.7",
        label: "Barrett oesophagus - مريء باريت",
        clinics: [
            "PediatricSurgery",
            "Cardiothoracic",
            "GeneralSurgery",
            "InternalMedicine",
        ],
    },
    {
        value: "B67.1",
        label: "Echinococcus granulosus infection of lung, hydatid cyst - داء المشوكات الحبيبية في الرئة، كيسات رئوية",
        clinics: ["Cardiothoracic"],
    },
    {
        value: "C20",
        label: "Malignant neoplasm of rectum - ورم خبيث في المستقيم",
        clinics: ["PediatricSurgery", "GeneralSurgery", "Hematology"],
    },
    {
        value: "C25.0",
        label: "Malignant neoplasm of head of pancreas - ورم خبيث في رأس البنكرياس",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "C25.1",
        label: "Malignant neoplasm of body of pancreas - ورم خبيث في جسم البنكرياس",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "C25.2",
        label: "Malignant neoplasm of tail of pancreas - ورم خبيث في ذيل البنكرياس",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "C25.4",
        label: "Malignant neoplasm of Islets of Langerhans of pancreas - ورم خبيث في البنكرياس (أخرى)",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "D12.1",
        label: "Benign neoplasm of appendix - ورم حميد في الزائدة الدودية",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "D12.2",
        label: "Benign neoplasm of ascending colon - ورم حميد في الكولون الصاعد",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "D12.3",
        label: "Benign neoplasm of transverse colon - ورم حميد في الكولون المعترض",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "D12.4",
        label: "Benign neoplasm of descending colon - ورم حميد في الكولون النازل",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "D12.5",
        label: "Benign neoplasm of sigmoid colon - ورم حميد في الكولون السيني",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "D12.6",
        label: "Benign neoplasm of colon, unspecified - ورم حميد في الكولون (غير محدد)",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "D12.7",
        label: "Benign neoplasm of rectosigmoid junction - ورم حميد في الوصل المستقيمي السيني",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "D12.8",
        label: "Benign neoplasm of rectum - ورم حميد في المستقيم",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "D12.9",
        label: "Benign neoplasm of anus and anal canal - ورم حميد في الشرج والقناة الشرجية",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "D13.0",
        label: "Benign neoplasm of Oesophagus - ورم حميد في المريء",
        clinics: ["PediatricSurgery", "GeneralSurgery", "ThoracicSurgery"],
    },
    {
        value: "D13.1",
        label: "Benign neoplasm of Stomach - ورم حميد في المعدة",
        clinics: [
            "PediatricSurgery",
            "PediatricSurgery",
            "GeneralSurgery",
            "InternalMedicine",
        ],
    },
    {
        value: "D13.2",
        label: "Benign neoplasm of Duodenum - ورم حميد في العفج",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "D13.3",
        label: "Benign neoplasm of Other and unspecified parts of small intestine - ورم حميد في الأمعاء الدقيقة",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "D13.4",
        label: "Benign neoplasm of Liver, Intrahepatic bile ducts - ورم حميد في الكبد، الأقنية الصفراوية داخل الكبدية",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "D13.5",
        label: "Benign neoplasm of Extrahepatic bile ducts - ورم حميد في الأقنية الصفراوية خاريج الكبدية",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "D13.6",
        label: "Benign neoplasm of Pancreas - ورم حميد في البنكرياس",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "D13.7",
        label: "Benign neoplasm of Islet cell tumour, Islets of Langerhans of Pancreas - ورم حميد في جزر لانغرهانس في البنكرياس",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "C15",
        label: "Malignant neoplasm of oesophagus - ورم خبيث في المريء",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "C16",
        label: "Malignant neoplasm of stomach - ورم خبيث في المعدة",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "C17",
        label: "Malignant neoplasm of small intestine - ورم خبيث في الأمعاء الدقيقة",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "C18.0",
        label: "Malignant neoplasm of Caecum - ورم خبيث في الأعور",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "C18.1",
        label: "Malignant neoplasm of Appendix - ورم خبيث في الزائدة الدودية",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "C18.2",
        label: "Malignant neoplasm of Ascending colon - ورم خبيث في الكولون الصاعد",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "C18.3",
        label: "Malignant neoplasm of Hepatic flexure - ورم خبيث في الزاوية الكبدية",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "C18.4",
        label: "Malignant neoplasm of Transverse colon - ورم خبيث في الكولون المعترض",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "C18.5",
        label: "Malignant neoplasm of Splenic flexure - ورم خبيث في الزاومية الطحالية",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "C18.6",
        label: "Malignant neoplasm of Descending colon - ورم خبيث في الكولون النازل",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "C18.7",
        label: "Malignant neoplasm of Sigmoid colon - ورم خبيث في الكولون السيني",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "C19",
        label: "Malignant neoplasm of rectosigmoid junction - ورم خبيث في الوصل السيني المستقيمي",
        clinics: ["GeneralSurgery", "Hematology"],
    },
    {
        value: "C21",
        label: "Malignant neoplasm of anus and anal canal (exclusion of Malignant melanoma) - ورم خبيث في الشرج والقناة الشرجية (باستثناء ورم الخلايا الصباغية)",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "E66.9",
        label: "Obesity, unspecified - بدانة",
        clinics: ["PediatricSurgery", "GeneralSurgery", "Endocrinology"],
    },
    {
        value: "k35.8",
        label: "Unspecified acute appendicitis - التهاب زائدة دودية حاد (غير محدد)",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "k91.3",
        label: "Postoperative intestinal obstruction - إنسداد أمعاء تالي لعمل جراحي",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "k21.0",
        label: "Gastro-esophageal reflux disease with esophagitis - القلس المعدي المريئي مع إلتهاب المري",
        clinics: ["GeneralSurgery", "ThoracicSurgery", "Pulmonology"],
    },
    {
        value: "K21.9",
        label: "Gastro-oesophageal reflux disease without oesophagitis - القلس المعدي المريئي بدون إلتهاب المري",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "K56.2",
        label: "Volvulus - داء الرتوج",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "K56.3",
        label: "Gallstone ileus - علوص المرارة",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "K56.5",
        label: "Intestinal adhesions [bands] with obstruction, Peritoneal adhesions [bands] with intestinal obstruction - التصاقات معوية (رباط) مع إنسداد، التصاقات بريتوانية (رباط) مع إنسداد معوي",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "K56.6",
        label: "Other and unspecified intestinal obstruction - إنسداد معوي غير محدد (أخرى)",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "K56.7",
        label: "Ileus, unspecified - علوص (غير محدد)",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "K60.3",
        label: "Anal fistula - ناسور شرجي",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "K60.4",
        label: "Rectal fistula - ناسور مستقيمي",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "K61.1",
        label: "Rectal abscess - خراج مستقيمي",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "K61.2",
        label: "Anorectal abscess - خراج شرجي مستقيمي",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "K61.3",
        label: "Ischiorectal abscess - خراج إسكي مستقيمي",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "K61.4",
        label: "Intrasphincteric abscess - خراج داخل المعصرة",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "K62.2",
        label: "Anal prolapse - هبوط شرج",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "K62.3",
        label: "Rectal prolapse - هبوط مستقيم",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "K62.5",
        label: "Hemorrhage of anus and rectum - نزف من الشرج أو المستقيم",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "K63.1",
        label: "Perforation of intestine (nontraumatic) - إنثقاب أمعاء غير رضي",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "K26.0",
        label: "Duodenal ulcer, Acute with haemorrhage - قرحة عفجية، حادة مع نزف",
        clinics: ["PediatricSurgery", "GeneralSurgery", "InternalMedicine"],
    },
    {
        value: "K26.1",
        label: "Duodenal ulcer, Acute with perforation - قرحة عفجية، حادة مع انثقاب",
        clinics: ["PediatricSurgery", "GeneralSurgery", "InternalMedicine"],
    },
    {
        value: "K26.2",
        label: "Duodenal ulcer, Acute with both haemorrhage and perforation - قرحة عفجية، حادة مع نزف و انثقاب",
        clinics: ["GeneralSurgery", "InternalMedicine"],
    },
    {
        value: "K26.3",
        label: "Duodenal ulcer, Acute without haemorrhage or perforation - قرحة عفجية، حادة بدون نزف أو انثقاب",
        clinics: ["GeneralSurgery", "InternalMedicine"],
    },
    {
        value: "K26.4",
        label: "Duodenal ulcer, Chronic or unspecified with haemorrhage - قرحة عفجية، مزمنة أو غير محددة مع نزف",
        clinics: ["GeneralSurgery", "InternalMedicine"],
    },
    {
        value: "K26.5",
        label: "Duodenal ulcer, Chronic or unspecified with perforation - قرحة عفجية، مزمنة أو غير محددة مع انثقاب",
        clinics: ["GeneralSurgery", "InternalMedicine"],
    },
    {
        value: "K26.6",
        label: "Duodenal ulcer, Chronic or unspecified with both haemorrhage and perforation - قرحة عفجية، مزمنة أو غير محددة مع نزف و انثقاب",
        clinics: ["GeneralSurgery", "InternalMedicine"],
    },
    {
        value: "K26.7",
        label: "Duodenal ulcer, Chronic without haemorrhage or perforation - قرحة عفجية، مزمنة بدون نزف أو انثقاب",
        clinics: ["GeneralSurgery", "InternalMedicine"],
    },
    {
        value: "K63.2",
        label: "Fistula of intestine - ناسور معوي",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "K63.5",
        label: "Polyp of colon - بوليب كولون",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "I84.0",
        label: "Internal thrombosed haemorrhoids - بواسير داخلية متخثرة",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "I84.1",
        label: "Internal haemorrhoids with other complications (bleeding, prolapsed, strangulated, ulcerated) - بواسير داخلية مع اختلاطات أخرى (نزف، تدلي، اختناق، تقرح)",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "I84.2",
        label: "Internal haemorrhoids without complication - بواسير داخلية دون اختلاطات",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "I84.3",
        label: "External thrombosed haemorrhoids, Perianal haematoma (nontraumatic), Perianal thrombosis - بواسير خارجية متخثرة، ورم دموي حول الشرج (غير رضي)، تخثر حول الشرج",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "I84.4",
        label: "External haemorrhoids with other complications (bleeding, prolapsed, strangulated, ulcerated) - بواسير خارجية مع اختلاطات أخرى (نزف، تدلي، اختناق، تقرح)",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "I84.5",
        label: "External haemorrhoids without complication - بواسير خارجية دون اختلاطات",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "I84.6",
        label: "Residual hemorrhoidal skin tags - زائدة جلدية باسورية متبقية",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "K51.0",
        label: "Ulcerative (chronic) pancolitis - التهاب الكولون الشامل التقرحي (المزمن)",
        clinics: ["PediatricSurgery", "GeneralSurgery", "InternalMedicine"],
    },
    {
        value: "K51.2",
        label: "Ulcerative (chronic) proctitis - التهاب المستقيم التقرحي (المزمن)",
        clinics: ["PediatricSurgery", "GeneralSurgery", "InternalMedicine"],
    },
    {
        value: "K51.3",
        label: "Ulcerative (chronic) rectosigmoiditis - التهاب المستقيم والسين التقرحي (المزمن)",
        clinics: ["PediatricSurgery", "GeneralSurgery", "InternalMedicine"],
    },
    {
        value: "K51.5",
        label: "Left sided colitis - التهاب الكولون الأيسر",
        clinics: ["PediatricSurgery", "GeneralSurgery", "InternalMedicine"],
    },
    {
        value: "K40.0",
        label: "Bilateral inguinal hernia, with obstruction, without gangrene - فتق إربي مزدوج، مع إنسداد بدون غانغرين",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "K40.1",
        label: "Bilateral inguinal hernia, with gangrene - فتق إربي مزدوج، مع غانغرين",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "K40.2",
        label: "Bilateral inguinal hernia, without obstruction or gangrene - فتق إربي مزدوج، بدون انسداد أو غانغرين",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "K40.3",
        label: "Unilateral or unspecified inguinal hernia, with obstruction, without gangrene - فتق إربي أحادي الجانب، مع إنسداد بدون غانغرين",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "K40.4",
        label: "Unilateral or unspecified inguinal hernia, with gangrene - فتق إربي أحادي الجانب، مع غانغرين",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "K40.9",
        label: "Unilateral or unspecified inguinal hernia, without obstruction or gangrene - فتق إربي أحادي الجانب، بدون انسداد أو غانغرين",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "K42.0",
        label: "Umbilical hernia with obstruction, without gangrene - فتق سري مع إنسداد، بدون غانغرين",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "K42.1",
        label: "Umbilical hernia with gangrene - فتق سري مع غانغرين",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "K42.9",
        label: "Umbilical hernia without obstruction or gangrene - فتق سري بدون انسداد أو غانغرين",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "K41.0",
        label: "Bilateral femoral hernia, with obstruction, without gangrene - فتق فخذي مزدوج، مع إنسداد بدون غانغرين",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "K41.1",
        label: "Bilateral femoral hernia, with gangrene - فتق فخذي مزدوج، مع غانغرين",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "K41.2",
        label: "Bilateral femoral hernia, without obstruction or gangrene - فتق فخذي مزدوج، بدون انسداد أو غانغرين",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "K41.3",
        label: "Unilateral or unspecified femoral hernia, with obstruction, without gangrene - فتق فخذي أحادي الجانب، مع إنسداد بدون غانغرين",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "K41.4",
        label: "Unilateral or unspecified femoral hernia, with gangrene - فتق فخذي أحادي الجانب، مع غانغرين",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "K41.9",
        label: "Unilateral or unspecified femoral hernia, without obstruction or gangrene - فتق فخذي أحادي الجانب، بدون انسداد أو غانغرين",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "K44.0",
        label: "Diaphragmatic hernia with obstruction, without gangrene - فتق حجابي مع إنسداد، بدون غانغرين",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "k44.1",
        label: "Diaphragmatic hernia with gangrene - فتق حجابي مع غانغرين",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "K44.9",
        label: "Diaphragmatic hernia without obstruction or gangrene - فتق حجابي بدون انسداد أو غانغرين",
        clinics: ["PediatricSurgery", "GeneralSurgery", "ThoracicSurgery"],
    },
    {
        value: "K80.0",
        label: "Calculus of gallbladder with acute cholecystitis without obstruction - تحصي مرارة مع التهاب مرارة حاد بدون انسداد",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "K80.2",
        label: "Calculus of gallbladder without cholecystitis - تحصي مرارة بدون التهاب مرارة",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "K80.3",
        label: "Calculus of bile duct with cholangitis - تحصي أقنية صفراوية مع التهاب طرق صفراوية",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "K80.4",
        label: "Calculus of bile duct with cholecystitis - تحصي أقنية صفراوية مع التهاب مرارة",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "K80.5",
        label: "Calculus of bile duct without cholangitis or cholecystitis - تحصي أقنية صفراوية بدون التهاب مرارة أو التهاب طرق صفراوية",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "L05.0",
        label: "Pilonidal cyst with abscess (fistula, sinus) - كيش شعري مع خراج (ناسور، جيب)",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "L05.9",
        label: "Pilonidal cyst without abscess (fistula, sinus) - كيش شعري بدون خراج (ناسور، جيب)",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "Q79.0",
        label: "Congenital diaphragmatic hernia - فتق حجابي خلقي",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "R10.0",
        label: "Acute abdomen - حالة بطن حادة",
        clinics: ["PediatricSurgery", "GeneralSurgery", "Emergency General"],
    },
    {
        value: "R10.3",
        label: "Pain localized to other parts of lower abdomen - ألم موضعي في أجزاء أخرى من أسفل البطن",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "Z80",
        label: "Family history of malignant neoplasm - قصة عائلية لأورام خبيثة",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "S27.0",
        label: "Traumatic pneumothorax - ريح صدرية رضية",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "J94.2",
        label: "Haemothorax  - انصباب جنب مدمى",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "S27.1",
        label: "Traumatic haemothorax - انصباب جنب مدمى رضي",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "K11.5",
        label: "Sialolithiasis - حصيات الغدة اللعابية",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "K11.6",
        label: "Mucocele of salivary gland - قيلة مخاطية في الغدة اللعابية",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "K12.2",
        label: "Cellulitis and abscess of mouth - خراج و التهاب النسيج الخلوي في الفم",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "I71.0",
        label: " تسلّخ الأبهر - Dissection of aorta - تسلخ الأبهر",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "S00",
        label: "Superficial injury of head - إصابة سطحية في الرأس",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "S01.0",
        label: "Open wound of scalp - جرح مفتوح في فروة الرأس",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "Y36.7",
        label: "War operations involving chemical weapons and other forms of unconventional warfare - إصابة حربية تشمل أسلحة كيميائية و أنماط أخرى من الأسلحة غير التقليدية",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "S05.1",
        label: "Contusion of eyeball and orbital tissues - كدمة  أنسجة مقلة العين و محيطها ",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "S07.0",
        label: "Crushing injury of face - تهشم في الوجه",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "S20.2",
        label: "Contusion of thorax - كدمة على الصدر",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "S22.3",
        label: " Fracture of ribs - كسر أضلاع",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "S30.0",
        label: "Contusion of lower back and pelvis - كدمة في أسفل الظهر والحوض",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "S30.1",
        label: "Contusion of abdominal wall - كدمة في جدار البطن",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "S30.2",
        label: "Contusion of external genital organs - كدمة على الأعضاء التناسلية الخارجية",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "S31.0",
        label: "Open wound of lower back and pelvis - جرح مفتوح في أسفل الظهر والحوض",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "S31.1",
        label: "Open wound of abdominal wall - جرح مفتوح في جدار البطن",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "S31.5",
        label: "Open wound of external genital organs - جرح مفتوح في الأعضاء التناسلية الخارجية",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "S36.7",
        label: "Injury of multiple intra-abdominal organs - إصابة عدة أجهزة في داخل البطن",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "S37.7",
        label: "Injury of multiple pelvic organs - إصابة عدة أجهزة في داخل الحوض",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "T00",
        label: "Superficial injuries involving multiple body regions -  إصابات سطحية تشمل مناطق متعددة من الجسم",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "T01",
        label: "Open wounds involving multiple body regions - جروح مفتوحة تشمل مناطق متعددة من الجسم",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "T75.4",
        label: "Effects of electric current - تأثير التيار الكهربائي",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "N43.3",
        label: "Hydrocele - قيلة مائية",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "I77.8",
        label: "Other specified disorders of arteries and arterioles (Erosion, Ulcer) - قرحة وعائية، تآكل وعائي (شرياني، شريني)",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "L89",
        label: "Decubitus ulcer and pressure area - قرحة اضطجاعية، قرحة الفراش",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "Z48",
        label: "Other surgical follow-up care - متابعة بعد عمل جراحي",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "Q83.1",
        label: "Accessory breast - الثدي الإضافي",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "Q83.2",
        label: "Absent nipple - غياب الحلمة",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "Q83.3",
        label: "Accessory nippleو Supernumerary nipple - الحلمة الإضافية، الحلمة الزائدة",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "D05.0",
        label: "Lobular carcinoma in situ - سرطان فصيصي لابد في الثدي",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "D05.1",
        label: "Intraductal carcinoma in situ - سرطان داخل قنوي لابد في الثدي",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "D05.9",
        label: "Carcinoma in situ of breast, unspecified - سرطانة لابدة في الثدي، غير محدد",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "D24",
        label: "Benign neoplasm of breast - ورم حميد في الثدي",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "C50",
        label: "Malignant neoplasm of breast - ورم خبيث في الثدي",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "D73.0",
        label: "Hyposplenism - قصور الطحال",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "D73.1",
        label: "Hypersplenism - فرط الطحالية",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "D73.4",
        label: "Cyst of spleen - كيسة طحالية",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "D73.5",
        label: "Infarction of spleen - احتشاء الطحال",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "E03.3",
        label: "Postinfectious hypothyroidism - قصور الدرق التالي للانتان",
        clinics: ["GeneralSurgery", "InternalMedicine"],
    },
    {
        value: "E04.0",
        label: "Nontoxic diffuse goitre, diffuse (colloid), simple - سلعة درقية منتشرة غير سامة، غرواني، بسيطة",
        clinics: ["GeneralSurgery", "InternalMedicine"],
    },
    {
        value: "E04.1",
        label: "Nontoxic single thyroid nodule - عقدة درقية وحيدة غير سامة",
        clinics: ["GeneralSurgery", "InternalMedicine", "Endocrinology"],
    },
    {
        value: "E04.2",
        label: "Nontoxic multinodular goiter - سلعة متعدد العقد",
        clinics: [
            "PediatricSurgery",
            "Endocrinology",
            "GeneralSurgery",
            "InternalMedicine",
        ],
    },
    {
        value: "E05.2",
        label: "Thyrotoxicosis [hyperthyroidism] with toxic multinodular goitre - إنسمام درقي (فرط نشاط الدرق) مع سلعة درقية متعددة العقد سمية",
        clinics: ["GeneralSurgery", "InternalMedicine"],
    },
    {
        value: "E05.5",
        label: "Thyroid crisis or storm - أزمة أو عاصفة درقية",
        clinics: ["PediatricSurgery", "GeneralSurgery", "InternalMedicine"],
    },
    {
        value: "E06.1",
        label: "Subacute thyroiditis - التهاب درق تحت حاد",
        clinics: ["PediatricSurgery", "GeneralSurgery", "InternalMedicine"],
    },
    {
        value: "E06.2",
        label: "Chronic thyroiditis with transient thyrotoxicosis - التهاب درق مزمن مع انسمام درقي عابر",
        clinics: ["GeneralSurgery", "InternalMedicine"],
    },
    {
        value: "E06.4",
        label: "Drug-induced thyroiditis - التهاب الدرق الدوائي",
        clinics: ["PediatricSurgery", "GeneralSurgery", "InternalMedicine"],
    },
    {
        value: "E07.0",
        label: "Hypersecretion of calcitonin, C-cell hyperplasia of thyroid, Hypersecretion of thyrocalcitonin - فرط إفراز الكالسيتونين ، تضخم خلايا C في الغدة الدرقية ، فرط إفراز الكالسيتونين الدرقي",
        clinics: ["GeneralSurgery", "InternalMedicine"],
    },
    {
        value: "O90.5",
        label: "Postpartum thyroiditis - التهاب الدرق التالي للولادة",
        clinics: [
            "PediatricSurgery",
            "GeneralSurgery",
            "Gynecology",
            "InternalMedicine",
            "Emergency Gynecology",
        ],
    },
    {
        value: "E21.0",
        label: "Primary hyperparathyroidism - فرط نشاط جارات الدرق الأولي",
        clinics: [
            "GeneralSurgery",
            "Pediatric",
            "InternalMedicine",
            "Emergency Pediatric",
        ],
    },
    {
        value: "E21.1",
        label: "Secondary hyperparathyroidism - فرط نشاط جارات الدرق الثانوي",
        clinics: [
            "GeneralSurgery",
            "Pediatric",
            "InternalMedicine",
            "Emergency Pediatric",
        ],
    },
    {
        value: "D34",
        label: "Benign neoplasm of thyroid gland - ورم حميد في الغدة الدرقية",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "C73",
        label: "Malignant neoplasm of thyroid gland (Thyroid cancer) - ورم خبيث في الغدة الدرقة",
        clinics: [
            "PediatricSurgery",
            "GeneralSurgery",
            "Endocrinology",
            "Hematology",
            "ThoracicSurgery",
            "Pulmonology",
        ],
    },
    {
        value: "C75.0",
        label: "Malignant neoplasm of Parathyroid gland - ورم خبيث في الغدد جارات الدرق",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "D35.1",
        label: "Benign neoplasm of Parathyroid gland - ورم حميد في الغدد جارات الدرق",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "C22.0",
        label: "Liver cell carcinoma, Hepatocellular carcinoma, Hepatoma - سرطان الخلايا الكبدية ،  سرطان الكبد",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "C22.2",
        label: "Hepatoblastoma - ورم أرومي كبدي",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "C22.1",
        label: "Intrahepatic bile duct carcinoma, Cholangiocarcinoma - سرطان القناة الصفراوية داخل الكبد",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "C23",
        label: "Malignant neoplasm of gallbladder - ورم خبيث في المرارة",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "C24.0",
        label: "Malignant neoplasm of Extrahepatic bile duct, Biliary duct or passage, Common bile duct, Cystic duct, Hepatic duct - ورم خبيث في (القناة الصفراوية خارج الكبد ، القناة المرارية أو القناة الصفراوية ، القناة الصفراوية المشتركة ، القناة الكيسية ، القناة الكبدية)",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "C24.1",
        label: "Malignant neoplasm of Ampulla of Vater - ورم خبيث في أمبولة (مجل) فاتر",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "S36.00",
        label: "Injury of spleen without open wound into cavity - إصابة طحالية بدون جرح مفتوح في تجويف البطن",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "S36.01",
        label: "Injury of spleen with open wound into cavity - إصابة طحالية مع جرح مفتوح في تجويف البطن",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "S36.10",
        label: "Injury of liver or gallbladder (Bile duct) without open wound into cavity - إصابة كبدية أو المرارة (القناة الصفراوية) بدون جرح مفتوح في تجويف البطن",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "S36.11",
        label: "Injury of liver or gallbladder (Bile duct) with open wound into cavity - إصابة كبدية أو المرارة (القناة الصفراوية) مع جرح مفتوح في تجويف البطن",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "S36.20",
        label: "Injury of pancreas without open wound into cavity - إصابة بنكرياسية بدون جرح مفتوح في تجويف البطن",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "S36.21",
        label: "Injury of pancreas with open wound into cavity - إصابة بنكرياسية مع جرح مفتوح في تجويف البطن",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "S36.30",
        label: "Injury of stomach without open wound into cavity - إصابة معدية بدون جرح مفتوح في تجويف البطن",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "S36.31",
        label: "Injury of stomach with open wound into cavity - إصابة معدية مع جرح مفتوح في تجويف البطن",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "S36.40",
        label: "Injury of small intestine without open wound into cavity - إصابة معوية بدون جرح مفتوح في تجويف البطن",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "S36.41",
        label: "Injury of small intestine with open wound into cavity - إصابة معوية مع جرح مفتوح في تجويف البطن",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "S36.50",
        label: "Injury of colon without open wound into cavity - إصابة كولونية بدون جرح مفتوح في تجويف البطن",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "S36.51",
        label: "Injury of colon with open wound into cavity - إصابة كولونية مع جرح مفتوح في تجويف البطن",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "S36.60",
        label: "Injury of rectum without open wound into cavity - إصابة مستقيمية بدون جرح مفتوح في تجويف البطن",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "S36.61",
        label: "Injury of rectum with open wound into cavity - إصابة مستقيمية مع جرح مفتوح في تجويف البطن",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "S36.70",
        label: "Injury of multiple intra-abdominal organs without open wound into cavity - إصابة أعضاء متعددة داخل البطن بدون جرح مفتوح في تجويف البطن",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "S36.71",
        label: "Injury of multiple intra-abdominal organs with open wound into cavity - إصابة أعضاء متعددة داخل البطن مع جرح مفتوح في تجويف البطن",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "S39.0",
        label: "Injury of muscle and tendon of abdomen, lower back and pelvis - إصابة عضلات و أوتار في البطن، أسفل الظهر والحوض",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "S39.6",
        label: "Injury of intra-abdominal organ(s) with pelvic organ(s) - إصابة عضو أو أعضاء في البطن مع عضو أو أعضاء في الحوض",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "K86.2",
        label: "Cyst of pancreas - كيسة بنكرياسية",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "K86.3",
        label: "Pseudocyst of pancreas - كيسة بنكرياسية كاذبة",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "E84",
        label: "Cystic fibrosis - التليف الكيسي في البنكرياس",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "D17",
        label: "Benign lipomatous neoplasm - أورام دهنية حميدة",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "A18.3",
        label: "Tuberculosis of intestines, peritoneum and mesenteric glands - سل الأمعاء، البريتوان، العقد المساريقية",
        clinics: ["PediatricSurgery", "GeneralSurgery", "InternalMedicine"],
    },
    {
        value: "K25.0",
        label: "Gastric ulcer, Acute with haemorrhage - قرحة عفجية، حادة مع نزف",
        clinics: ["PediatricSurgery", "GeneralSurgery", "InternalMedicine"],
    },
    {
        value: "K25.1",
        label: "Gastric ulcer, Acute with perforation - قرحة عفجية، حادة مع انثقاب",
        clinics: ["PediatricSurgery", "GeneralSurgery", "InternalMedicine"],
    },
    {
        value: "K25.2",
        label: "Gastric ulcer, Acute with both haemorrhage and perforation - قرحة عفجية، حادة مع نزف و انثقاب",
        clinics: ["GeneralSurgery", "InternalMedicine"],
    },
    {
        value: "K25.3",
        label: "Gastric ulcer, Acute without haemorrhage or perforation - قرحة عفجية، حادة بدون نزف أو انثقاب",
        clinics: ["GeneralSurgery", "InternalMedicine"],
    },
    {
        value: "K25.4",
        label: "Gastric ulcer, Chronic or unspecified with haemorrhage - قرحة عفجية، مزمنة أو غير محددة مع نزف",
        clinics: ["GeneralSurgery", "InternalMedicine"],
    },
    {
        value: "K25.5",
        label: "Gastric ulcer, Chronic or unspecified with perforation - قرحة عفجية، مزمنة أو غير محددة مع انثقاب",
        clinics: ["GeneralSurgery", "InternalMedicine"],
    },
    {
        value: "K25.6",
        label: "Gastric ulcer, Chronic or unspecified with both haemorrhage and perforation - قرحة عفجية، مزمنة أو غير محددة مع نزف و انثقاب",
        clinics: ["GeneralSurgery", "InternalMedicine"],
    },
    {
        value: "K25.7",
        label: "Gastric ulcer, Chronic without haemorrhage or perforation - قرحة عفجية، مزمنة بدون نزف أو انثقاب",
        clinics: ["GeneralSurgery", "InternalMedicine"],
    },
    {
        value: "R12",
        label: "Heartburn - حرقة الفؤاد، اللذع",
        clinics: ["PediatricSurgery", "GeneralSurgery", "InternalMedicine"],
    },
    {
        value: "K31.0",
        label: "Acute dilatation of stomach - توسع المعدة الحاد",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "K31.1",
        label: "Adult hypertrophic pyloric stenosis - تضيق البواب الضخامي عند البالغين",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "Q40.0",
        label: "Congenital hypertrophic pyloric stenosis - تضيق البواب الضخامي الخلقي",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "K31.3",
        label: "Pylorospasm - تشنج البواب",
        clinics: ["PediatricSurgery", "GeneralSurgery", "InternalMedicine"],
    },
    {
        value: "K31.4",
        label: "Gastric diverticulum - رتوج المعدة",
        clinics: ["PediatricSurgery", "GeneralSurgery", "InternalMedicine"],
    },
    {
        value: "K31.6",
        label: "Fistula of stomach and duodenum - ناسور معوي عفجي",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "K31.7",
        label: "Polyp of stomach and duodenum - بوليب معدي، عفجي",
        clinics: ["PediatricSurgery", "GeneralSurgery", "InternalMedicine"],
    },
    {
        value: "K52.9",
        label: "Noninfective gastroenteritis and colitis unspecified (Diarrhoea, Enteritis, Ileitis, Jejunitis, Sigmoiditis) - التهاب المعدة و الكولون غير الانتاني (إسهال، التهاب أمعاء، التهاب الصائم، التهاب الدقاق، التهاب السين) غير محدد",
        clinics: ["PediatricSurgery", "GeneralSurgery", "InternalMedicine"],
    },
    {
        value: "A03",
        label: "Shigellosis - داء الشيغلات",
        clinics: ["PediatricSurgery", "GeneralSurgery", "InternalMedicine"],
    },
    {
        value: "A04",
        label: "Other bacterial intestinal infections - إنتانات معوية جرثومية أخرى",
        clinics: ["PediatricSurgery", "GeneralSurgery", "InternalMedicine"],
    },
    {
        value: "A09.0",
        label: "Other and unspecified gastroenteritis and colitis of infectious origin - انتانات معوية معدية كولونية أخرى غير محددة",
        clinics: ["PediatricSurgery", "GeneralSurgery", "InternalMedicine"],
    },
    {
        value: "K59.1",
        label: "Functional diarrhoea - الاسهال الوظيفي",
        clinics: ["PediatricSurgery", "GeneralSurgery", "InternalMedicine"],
    },
    {
        value: "K59.3",
        label: "Megacolon, not elsewhere classified - تضخم الكولون، غير مصنف في مكان آخر",
        clinics: ["PediatricSurgery", "GeneralSurgery", "InternalMedicine"],
    },
    {
        value: "Q43.1",
        label: "Hirschsprung disease - داء هيرشبرونغ",
        clinics: [
            "PediatricSurgery",
            "GeneralSurgery",
            "Pediatric",
            "Emergency Pediatric",
        ],
    },
    {
        value: "Q43.0",
        label: "Meckel diverticulum - رتج ميكل",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "K55.0",
        label: "Acute vascular disorders of intestine, intestinal infarction, small intestine ischaemia, Mesenteric (embolism, infarction, thrombosis), Subacute ischaemic colitis - اضطرابات الأوعية الدموية الحادة في الأمعاء، الاحتشاء المعوي، إقفار الأمعاء الدقيقة، (صمة، إحتشاء، خثار) الأوعية المساريقية، التهاب الكولون الاقفاري تحت الحاد",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "K56.0",
        label: "Paralytic ileus - العلوص الشللي",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "K57",
        label: "Diverticular disease of intestine - داء الرتوج المعوي",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "E86",
        label: "Volume depletion, Dehydration, Hypovolaemia - نقص الحجم، التجفاف",
        clinics: [
            "PediatricSurgery",
            "GeneralSurgery",
            "InternalMedicine",
            "Emergency Medicine",
        ],
    },
    {
        value: "K66.0",
        label: "Peritoneal adhesions - التصاقات بريتوانية",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "K66.1",
        label: "Haemoperitoneum - تدمي البريتوان",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "S36.8",
        label: "traumatic haemoperitoneum - تدمي البريتوان الرضي",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "K75.0",
        label: "Abscess of liver - خراج الكبد",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "K75.1",
        label: "Phlebitis of portal vein - خثار الوريد الباب",
        clinics: ["PediatricSurgery", "GeneralSurgery", "InternalMedicine"],
    },
    {
        value: "K83.1",
        label: "Obstruction of bile duct - انسداد القناة الصفراوية",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "K83.2",
        label: "Perforation of bile duct, Rupture of bile duct - انثقاب القناة الصفراوية، تمزق القناة الصفراوية",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "K83.3",
        label: "Fistula of bile duct, Choledochoduodenal fistula - ناسور القناة الصفراوية، ناسور صفراوي عفجي",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "K83.4",
        label: "Spasm of sphincter of Oddi - تشنج مصرة أودي",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "K83.5",
        label: "Biliary cyst - كيسة صفراوية",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "K90.2",
        label: "Blind loop syndrome - تناذر العروة العمياء",
        clinics: ["PediatricSurgery", "GeneralSurgery", "InternalMedicine"],
    },
    {
        value: "K91.1",
        label: "(dumping, postgastrectomy, postvagotomy) Syndrome - تناذر (الاغراق، ما بعد قعطع المبهم، ما بعد قطع المعدة)",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "K91.4",
        label: "Colostomy and enterostomy malfunction - سوء وظيفة الفغر الكولوني و المعوي",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "K92.0",
        label: "Haematemesis - الاقياء الدموي",
        clinics: ["PediatricSurgery", "GeneralSurgery", "InternalMedicine"],
    },
    {
        value: "K92.1",
        label: "Melaena - التغوط الأسود",
        clinics: [
            "PediatricSurgery",
            "GeneralSurgery",
            "InternalMedicine",
            "Emergency General",
        ],
    },
    {
        value: "K92.2",
        label: "Gastrointestinal haemorrhage, unspecified - النزف المعدي المعوي، غير محدد",
        clinics: ["PediatricSurgery", "GeneralSurgery", "InternalMedicine"],
    },
    {
        value: "T79.4",
        label: "Traumatic shock - الصدمة الرضية",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "T79.0",
        label: "Air embolism (traumatic) - الصمة الهوائية الرضية",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "T79.1",
        label: "Fat embolism (traumatic) - الصمة الدهنية الرضية",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "M72.6",
        label: "Necrotizing fasciitis - التهاب اللفافة المنخر",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "N49.8",
        label: "Inflammatory disorders of other specified male genital organs, Inflammation of multiple sites in male genital organs, Fournier",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "N76.8",
        label: "Other specified inflammation of vagina and vulva, Fournier",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "S10",
        label: "Superficial injury of neck - أذية سطحية في العنق",
        clinics: ["GeneralSurgery", "Emergency General", "Plastic Surgery"],
    },
    {
        value: "S11.0",
        label: "Open wound involving larynx and cervical trachea - جرح مفتوح في العنق يتضمن الحنجرة و الرغامى الرقبية",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "S11.1",
        label: "Open wound involving thyroid gland - جرح مفتوح في العنق يتضمن الغدة الدرقية",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "S11.2",
        label: "Open wound involving pharynx and cervical oesophagus - جرح مفتوح في العنق يتضمن البلعوم و المريء الرقبي",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "S11.7",
        label: "Multiple open wounds of neck - جروح متعددة في العنق",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "S11.8",
        label: "Open wound of other parts of neck - جرح مفتوح في العنق لأعضاء أخرى",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "S18",
        label: "Traumatic amputation at neck level, Decapitation - بتر رضي في مستوى الرقبة ، قطع الرأس",
        clinics: ["GeneralSurgery", "Neurosurgery"],
    },
    {
        value: "S16",
        label: "Injury of muscle and tendon at neck level - أذية العضلات و الأوتار في مستوى العنق",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "S19.7",
        label: "Multiple injuries of neck - أذيات متعددة في العنق",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "C48",
        label: "Malignant neoplasm of retroperitoneum and peritoneum - الأورام الخبيثة في الحيز خلف البريتوان و البريتوان",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "D35.0",
        label: "Benign neoplasm of Adrenal gland - ورم حميد في الغدة الكظرية",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "C74",
        label: "Malignant neoplasm of adrenal gland - ورم خبيث في الغدة الكظرية",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "D20.0",
        label: "Benign neoplasm of soft tissue of Retroperitoneum - ورم حميد في الأنسجة الرخوة للحيز خلف البريتوان",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "D20.1",
        label: "Benign neoplasm of soft tissue of Peritoneum - ورم حميد في الأنسجة الرخوة للبريتوان",
        clinics: ["GeneralSurgery"],
    },
    {
        value: "D22",
        label: "Melanocytic naevi - الوحمة الصباغية",
        clinics: ["PediatricSurgery", "GeneralSurgery"],
    },
    {
        value: "D23",
        label: "Other benign neoplasms of skin - أورام الجلد الحميد الأخرى",
        clinics: ["PediatricSurgery", "GeneralSurgery", "Plastic Surgery"],
    },
    {
        value: "P22",
        label: "Respiratory distress of newborn - العسرة التنفسية عند الوليد",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "P23",
        label: "Congenital pneumonia - ذات الرئة الخلقية",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "P24",
        label: "Neonatal aspiration syndromes - المتلازمة الاستنشاقية عند الوليد",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "P28.5",
        label: "Respiratory failure of newborn - قصور تنفسي عند الوليد",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "P28.4",
        label: "Other apnoea of newborn - توقفات النفس الأخرى عند الوليد",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "P28.3",
        label: "Primary sleep apnoea of newborn, Sleep apnoea of newborn - توقف التنفس الأولي أثناء النوم عند الوليد ، توقف التنفس أثناء النوم عند الوليد",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "P28.2",
        label: "Cyanotic attacks of newborn - نوبة زراق عند الوليد",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "P28.0",
        label: "Primary atelectasis of newborn - انخماص بدئي عند الوليد",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "P28.1",
        label: "Other and unspecified atelectasis of newborn - انخماص غير محدد عند الوليد",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "Q03",
        label: "Congenital hydrocephalus - موه الرأس الخلقي",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "Q61.4",
        label: "Renal dysplasia - عسر تصنع الكلية",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "Q61.5",
        label: "Medullary cystic kidney - الداء اللبي الكيسي في الكلية",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "P53",
        label: "Haemorrhagic disease of fetus and newborn - الداء النزفي عند الجنين و الوليد",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "Y58.1",
        label: "Typhoid and paratyphoid vaccine - لقاح الحمى التيفية و نظيرة التيفية",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "Y58.9",
        label: "Other and unspecified bacterial vaccines - لقاحات جرثومية أخرى غير محددة",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "Y59.0",
        label: "Viral vaccines - لقاحات فيروسية",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "Y59.9",
        label: "Vaccine or biological substance, unspecified - لقاح أو مادة بيولوجية ، غير محدد",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "A37.1",
        label: "Whooping cough due to Bordetella parapertussis - السعال الديكي بسبب البورديتلا نظيرة الشاهوقية",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "A38",
        label: "Scarlet fever - الحمى القرمزية",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "J69",
        label: "Pneumonitis due to solids and liquids - ذات الرئة الناجمة عن السوائل و المواد الصلبة",
        clinics: ["Pediatric", "InternalMedicine", "Emergency Pediatric"],
    },
    {
        value: "Q20",
        label: "Congenital malformations of cardiac chambers and connections, Common arterial trunk, Persistent truncus arteriosus, Double inlet ventricle, Common ventricle - التشوهات الخلقية للحجرات القلبية والوصلات ، الجذع الشرياني المشترك ، الجذع الشرياني المستمر ، البطين المزدوج المخرج، البطين المشترك",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "Q21.2",
        label: "Atrioventricular septal defect - العيب الحاجزي الأذيني البطيني",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "Q21.4",
        label: "Aortopulmonary septal defect - عيب الحاجز الأبهري الرئوي",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "Q21.8",
        label: "Eisenmenger defect, Pentalogy of Fallot - خلل إيزنمنجر، خماسي فالوت",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "Q22",
        label: "Congenital malformations of pulmonary and tricuspid valves - عيوب الصمام الرئوي و مثلث الشرف الخلقية",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "Q23",
        label: "Congenital malformations of aortic and mitral valves - عيوب الصمام الأبهري و التاجي الخلقية",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "Q24.0",
        label: "Dextrocardia - القلب اليميني، إنقلاب الأحشاء",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "Q89.3",
        label: "Situs inversus, Dextrocardia with situs inversus, Transposition of viscera (abdominal, thoracic) - القلب العكسي، انقلاب الأحشاء مع القلب العكسي، تبدل مواضع الأحشاء أو انقلاب الأحشاء (البطنية، الصدرية)",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "E23.0",
        label: "Hypopituitarism - قصور نخامي",
        clinics: [
            "Pediatric",
            "Gynecology",
            "Emergency Pediatric",
            "Emergency Gynecology",
            "Endocrinology",
        ],
    },
    {
        value: "E22.0",
        label: "Acromegaly and pituitary gigantism - ضخامة نهايات",
        clinics: ["Pediatric", "Emergency Pediatric", "Endocrinology"],
    },
    {
        value: "E25",
        label: "Adrenogenital disorders - اضطرابات الغدة الكظرية",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "E73",
        label: "Lactose intolerance - عدم تحمل اللاكتوز",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "E88.9",
        label: "Metabolic disorder, unspecified - الاضطرابات الاستقلابية، غير محددة",
        clinics: ["Pediatric", "Orthopedic", "Emergency Pediatric"],
    },
    {
        value: "K90.9",
        label: "Intestinal malabsorption, unspecified - سوء الامتصاص المعوي، غير محدد",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "N18",
        label: "Chronic renal failure - القصور الكلوي المزمن",
        clinics: [
            "Pediatric",
            "InternalMedicine",
            "Emergency Pediatric",
            "Nephrology",
        ],
    },
    {
        value: "E40",
        label: "Kwashiorkor - داء كواشيركور",
        clinics: ["Pediatric", "InternalMedicine", "Emergency Pediatric"],
    },
    {
        value: "E41",
        label: "Nutritional marasmus - السغل التغذوي",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "E42",
        label: "Marasmic kwashiorkor - سغل كواشيركور",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "E44.0",
        label: "Moderate protein-energy malnutrition - سوء التغذية البروتينية المعتدل",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "E44.1",
        label: "Mild protein-energy malnutrition - سوء التغذية البروتينية البسيط",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "E51",
        label: "Thiamine deficiency - عوز الثيامين",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "G00",
        label: "Bacterial meningitis - التهاب السحايا الجرثومي بغير المكورات السحائية",
        clinics: ["Pediatric", "InternalMedicine", "Emergency Pediatric"],
    },
    {
        value: "G04",
        label: "Encephalitis, myelitis and encephalomyelitis - التهاب الدماغ، التهاب النخاع الشوكي، التهاب الدماغ و النخاع الشوكي",
        clinics: ["Pediatric", "InternalMedicine", "Emergency Pediatric"],
    },
    {
        value: "G06",
        label: "Intracranial and intraspinal abscess and granuloma - الخراج داخل الجمجمة وداخل النخاع والورم الحبيبي",
        clinics: ["Pediatric", "InternalMedicine", "Emergency Pediatric"],
    },
    {
        value: "B55.9",
        label: "Leishmaniasis, unspecified - الليشمانيا (متابعة)",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "B26",
        label: "Mumps - النكاف",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "D52",
        label: "Folate deficiency anaemia - فقر الدم بعوز حمض الفوليك",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "D53",
        label: "Nutritional anaemias - فقر الدم التغذوي",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "D55.0",
        label: "Anaemia due to glucose-6-phosphate dehydrogenase [G6PD] deficiency (Favism, G6PD deficiency anaemia) - فقر الدم بعوز إنزيم G6PD، داء الفوال",
        clinics: ["Pediatric", "Emergency Pediatric", "Hematology"],
    },
    {
        value: "A50",
        label: "Congenital syphilis - الزهري الخلقي",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "B85.0",
        label: "Pediculosis due to Pediculus humanus capitis, Head-louse infestation - القمال الناجم عن القمل البشري، قمل الرأس",
        clinics: [
            "Pediatric",
            "Gynecology",
            "InternalMedicine",
            "Emergency Pediatric",
            "Emergency Gynecology",
        ],
    },
    {
        value: "B85.1",
        label: "Pediculosis due to Pediculus humanus corporis, Body-louse infestation - القمال الناجم عن القمل البشري، قمل الجسم و العانة",
        clinics: [
            "Pediatric",
            "Gynecology",
            "InternalMedicine",
            "Emergency Pediatric",
            "Emergency Gynecology",
        ],
    },
    {
        value: "B18",
        label: "Chronic viral hepatitis - التهابات الكبد الفيروسية المزمنة",
        clinics: ["Pediatric", "InternalMedicine", "Emergency Pediatric"],
    },
    {
        value: "B54",
        label: "Unspecified malaria - ملاريا غير محددة",
        clinics: ["Pediatric", "InternalMedicine", "Emergency Pediatric"],
    },
    {
        value: "Q75.0",
        label: "Craniosynostosis - التحام الدروز الباكر",
        clinics: ["Pediatric", "Neurosurgery", "Emergency Pediatric"],
    },
    {
        value: "A39",
        label: "Meningococcal infection - الانتان بالمكورات السحائية",
        clinics: ["Pediatric", "InternalMedicine", "Emergency Pediatric"],
    },
    {
        value: "B34.2",
        label: "Coronavirus infection, unspecified site - الإصابة بالفيروسات التاجية ، موقع غير محدد",
        clinics: ["Pediatric", "InternalMedicine", "Emergency Pediatric"],
    },
    {
        value: "A02",
        label: "Other salmonella infections - إنتانات السالامونيلا الأخرى",
        clinics: ["Pediatric", "InternalMedicine", "Emergency Pediatric"],
    },
    {
        value: "A36",
        label: "Diphtheria - الخناق",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "A33",
        label: "Tetanus neonatorum - كزاز الوليد",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "A35",
        label: "Other tetanus - الكزاز",
        clinics: [
            "Pediatric",
            "Gynecology",
            "Emergency Pediatric",
            "Emergency Gynecology",
        ],
    },
    {
        value: "B03",
        label: "Smallpox - الجدري",
        clinics: ["Pediatric", "Emergency Pediatric"],
    },
    {
        value: "U04",
        label: "Severe acute respiratory syndrome [SARS] - المتلازمة التنفسية الحادة الوخيمة (سارس)",
        clinics: ["Pediatric", "InternalMedicine", "Emergency Pediatric"],
    },
    {
        value: "O02.1",
        label: "Missed abortion - إجهاض فائت",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O03",
        label: "Spontaneous abortion - إجهاض عفوي",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O04",
        label: "Medical abortion, termination of pregnancy (legal, therapeutic), therapeutic abortion - إجهاض طبي، إنهاء الحمل (قانوني، علاجي)، إجهاض علاجي",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O07",
        label: "Failed attempted abortion - محاولة إجهاض (فاشلة)",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O08.1",
        label: "Delayed or excessive haemorrhage following abortion and ectopic and molar pregnancy - نزف متأخر أو  مفرط تالي للإجهاض و الحمل العنقودي و الحمل هاجر",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O08.2",
        label: "Embolism following abortion and ectopic and molar pregnancy - صمة تالية للإجهاض و الحمل العنقودي و الحمل الهاجر",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O08.3",
        label: "Shock following abortion and ectopic and molar pregnancy - صدمة تالية للإجهاض و الحمل العنقودي و الحمل الهاجر",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O08.4",
        label: "Renal failure following abortion and ectopic and molar pregnancy - قصور كلوي تالي للإجهاض و الحمل العنقودي و الحمل الهاجر",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O08.6",
        label: "Damage to pelvic organs and tissues following abortion and ectopic and molar pregnancy - أذية للأعضاء و الأنسجة الحوضية تالية للإجهاض و الحمل العنقودي و الحمل الهاجر",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O10",
        label: "Pre-existing hypertension complicating pregnancy, childbirth and the puerperium - ارتفاع توتر شرياني موجود مسبقاً يؤثر على الحمل، ولادة الجنين و النفاس",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O11",
        label: "Pre-existing hypertensive disorder with superimposed proteinuria - ارتفاع توتر شرياني موجود مسبقاً مع بيلة بروتينية متراكبة",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O12",
        label: "Gestational [pregnancy-induced] oedema and proteinuria without hypertension - وذمة حملية (محرض بالحمل) و بيلة بروتينية بدون ارتفاع توتر شرياني",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O14.0",
        label: "Gestational [pregnancy-induced] hypertension with significant proteinuria, Moderate pre-eclampsia - ارتفاع توتر شرياني حملي (محرض بالحمل) مع بيلة بروتينية مهمة، ما قبل إرجاج متوسط",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O14.1",
        label: "Gestational [pregnancy-induced] hypertension with significant proteinuria, Severe pre-eclampsia - ارتفاع توتر شرياني حملي (محرض بالحمل) مع بيلة بروتينية مهمة، ما قبل إرجاج شديد",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O14.2",
        label: "Gestational [pregnancy-induced] hypertension with significant proteinuria, HELLP syndrome - ارتفاع توتر شرياني حملي (محرض بالحمل) مع بيلة بروتينية مهمة، متلازمة HELLP",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O15.0",
        label: "Eclampsia in pregnancy - إرجاج في الحمل",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O15.1",
        label: "Eclampsia in labour - إرجاج أثناء الوضع",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O15.2",
        label: "Eclampsia in the puerperium - إرجاج في مرحلة النفاس",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O20.9",
        label: "Haemorrhage in early pregnancy, unspecified - نزف في بداية الحمل، غير محدد",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O21.0",
        label: "Mild hyperemesis gravidarum - إقياء حملي طفيف",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O21.1",
        label: "Hyperemesis gravidarum with metabolic disturbance - إقياء حملي مع اضطراب استقلابي",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O21.2",
        label: "Late vomiting of pregnancy, Excessive vomiting starting after 22 completed weeks of gestation - إقياء متأخر حملي، إقياء مفرط يبدأ بعد الأسبوع 22 من الحمل",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O22.0",
        label: "Varicose veins of lower extremity in pregnancy - دوالي وريدية في الأطراف السفلية في الحمل",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O22.1",
        label: "Genital varices in pregnancy - دوالي أعضاء تناسلية في الحمل",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O22.2",
        label: "Superficial thrombophlebitis in pregnancy - التهاب وريد خثاري سطحي في الحمل",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O22.3",
        label: "Deep phlebothrombosis in pregnancy, Deep-vein thrombosis, antepartum - التهاب وريد خثاري عميق في الحمل، خثار وريد عميق، ما قبل الولادة",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O22.4",
        label: "Haemorrhoids in pregnancy - بواسير أثناء الحمل",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O24",
        label: "Diabetes mellitus in pregnancy - الداء السكري أثناء الحمل",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O25",
        label: "Malnutrition in pregnancy - سوء التغذية أثناء الحمل",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O26.0",
        label: "Excessive weight gain in pregnancy - زيادة وزن مفرطة أثناء الحمل",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O26.1",
        label: "Low weight gain in pregnancy - نقص وزن أثناء الحمل",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O26.3",
        label: "Retained intrauterine contraceptive device in pregnancy - جهاز منع الحمل محتجز في الرحم أثناء الحمل",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O26.4",
        label: "Herpes gestationis - الحلأ الحملي",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O26.5",
        label: "Maternal hypotension syndrome, Supine hypotensive syndrome - متلازمة انخفاض الضغط الأمومي، متلازمة هبوط الضغط الانتصابي",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O26.6",
        label: "Liver disorders in pregnancy, childbirth and the puerperium, Cholestasis (intrahepatic) in pregnancy, Obstetric cholestasis - اضطرابات الكبد أثناء الحمل، ولادة الطفل و النفاس، ركود صفراوي (داخل كبدي) في الحمل، ركود صفراوي حملي",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O28",
        label: "Abnormal findings on antenatal screening of mother - موجودات غير طبيعية على فحص الأم قبل الولادة",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O30",
        label: "Multiple gestation - حمل متعدد",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O32",
        label: "Maternal care for known or suspected malpresentation of fetus - رعاية الأم لسوء مجيء جنيني مشتبه أو معروف",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O33",
        label: "Maternal care for known or suspected disproportion - رعاية الأم لحالة عدم تناسب مشتبه أو معروف",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O34",
        label: "Maternal care for known or suspected abnormality of pelvic organs - رعاية الأم لشذوذ في أعضاء الحوض معروف أو مشتبه به",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O35",
        label: "Maternal care for known or suspected fetal abnormality and damage - رعاية الأم بسبب تشوه و أذية الجنين المعروفين أو المشتبه بهم",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O36",
        label: "Maternal care for other known or suspected fetal problems - رعاية الأم لمشاكل الجنين الأخرى المعروفة أو المشتبه بها",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O40",
        label: "Polyhydramnios - موه السلى",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O41.0",
        label: "Oligohydramnios - شح السائل الأمنيوسي",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O41.1",
        label: "Infection of amniotic sac and membranes - انتان الكيس السلوي والأغشية الجنينية",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O42",
        label: "Premature rupture of membranes - تمزق الأغشية الباكر",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O43.0",
        label: "Placental transfusion syndromes - متلازمة نقل الدم المشيمي",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O43.1",
        label: "Malformation of placenta - تشوه المشيمة",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O43.2",
        label: "Morbidly adherent placenta - المشيمة الملتصقة",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O44.0",
        label: "Placenta praevia specified as without haemorrhage - انزياح المشيمة بدون نزف",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O44.1",
        label: "Placenta praevia with haemorrhage - انزياح المشيمة مع نزف",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O45",
        label: "Premature separation of placenta [abruptio placentae] - انفصال المشيمة الباكر",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O46.9",
        label: "Antepartum haemorrhage, unspecified - نزيف قبل الولادة ، غير محدد",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O47",
        label: "False labour - مخاض كاذب",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O48",
        label: "Prolonged pregnancy - حمل مديد",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O60.0",
        label: "Preterm labour without delivery (induced, spontaneous) - المخاض الباكر بدون ولادة (المحرض، العفوي)",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O60.1",
        label: "Preterm spontaneous labour with preterm delivery, Preterm labour with delivery, Preterm spontaneous labour with preterm delivery by caesarean section - المخاض العفوي الباكر مع الولادة قبل الباكرة ، المخاض الباكر مع الولادة ، المخاض العفوي الباكر مع الولادة المبكرة من خلال ولادة قيصرية",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O60.2",
        label: "Preterm spontaneous labour with term delivery, Preterm spontaneous labour with term delivery by caesarean section - المخاض العفوي الباكر مع ولادة تامة، المخاض العفوي الباكر مع ولادة تامة من خلال ولادة قيصرية",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O60.3",
        label: "Preterm delivery without spontaneous labour, caesarean section, without spontaneous labour, induction - المخاض الباكر بدون مخاض عفوي، عملية قيصرية، بدون مخاض عفوي، تحريض",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O63",
        label: "Long labour - مخاض مديد",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O62.0",
        label: "Primary inadequate contractions, Failure of cervical dilatation, Primary hypotonic uterine dysfunction, Uterine inertia during latent phase of labour - تقلصات رحمية بدئية غير كافية ، فشل توسع عنق الرحم، خلل وظيفي بدئي ناقص التوتر في الرحم، العطالة الرحمية أثناء المرحلة المتأخرة من المخاض",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O62.1",
        label: "Secondary uterine inertia, Arrested active phase of labour, Secondary hypotonic uterine dysfunction - عطالة رحمية ثانوية، توقف المرحلة النشطة للمخاض، خلل وظيفي ثانوي ناقص التوتر في الرحم",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O62.2",
        label: "Other uterine inertia, Atony of uterus - عطالة رحمية أخرى، وهن الرحم أو الرحم الرخو",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O62.3",
        label: "Precipitate labour - مخاض سريع",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O62.4",
        label: "Hypertonic, incoordinate, and prolonged uterine contractions, Contraction ring dystocia, Dyscoordinate labour, Hour-glass contraction of uterus, Hypertonic uterine , dysfunction, Incoordinate uterine action, Tetanic contractions, Uterine dystocia - تقلصات الرحم المفرطة وغير المتسقة و المطولة، عسر الولادة الناجم عن حلقة التقلص،مخاض غير متسق، تقلصات الساعة الرملية، الرحم المفرط التقلص، خلل وظيفي ، عمل الرحم غير المنتظم ، تقلصات تكززية ، عسر الولادة الرحمي",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O64",
        label: "Obstructed labour due to malposition and malpresentation of fetus - مخاض متعثر نتيجة سوء موقع الجنين و سوء المجيء",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O65",
        label: "Obstructed labour due to maternal pelvic abnormality - مخاض متعثر نتيجة شذوذ حوضي أمومي",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O66",
        label: "Other obstructed labour - مخاض متعثر، أخرى",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O67",
        label: "Labour and delivery complicated by intrapartum haemorrhage - تعقد المخاض والولادة بسبب النزيف أثناء الولادة",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O68",
        label: "Labour and delivery complicated by fetal stress [distress] - تعقد المخاض والولادة بسبب إجهاد الجنين [ضيق]",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O69",
        label: "Labour and delivery complicated by umbilical cord complications - تعقد المخاض والولادة بسبب مضاعفات الحبل السري",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O70",
        label: "Perineal laceration during delivery - التمزق العجاني أثناء الولادة",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O71",
        label: "Other obstetric trauma - رضوض نسائية أخرى",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O72",
        label: "Postpartum haemorrhage - نزف ما بعد الولادة",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O73",
        label: "Retained placenta and membranes, without haemorrhage - احتباس المشيمة و الأغشية بدون نزف",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O75",
        label: "Other complications of labour and delivery - اختلاطات أخرى للمخاض أو الولادة",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O80",
        label: "Single spontaneous delivery - ولادة عفوية وحيدة",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O81",
        label: "Single delivery by forceps and vacuum extractor - ولادة وحيدة بالملقط و المحجم",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O82",
        label: "Single delivery by caesarean section - ولادة وحيدة بالعملية القيصرية",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O83",
        label: "Other assisted single delivery - ولادة وحيدة مساعدة أخرى",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O84",
        label: "Multiple delivery - ولادة متعددة",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O85",
        label: "Puerperal sepsis (endometritis, fever, peritonitis, sepsis) - الإنتان النفاسي (التهاب بطانة الرحم ، الحمى ، التهاب الصفاق ، الإنتان)",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O86",
        label: "Other puerperal infections - انتانات نفاسية أخرى",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O87.0",
        label: "Superficial thrombophlebitis in the puerperium - التهاب الوريد الخثاري السطحي في النفاس",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O87.1",
        label: "Deep phlebothrombosis in the puerperium - الخثار الوريدي العميق في النفاس",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O87.2",
        label: "Haemorrhoids in the puerperium - البواسير في النفاس",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O88.0",
        label: "Obstetric air embolism - صمة هوائية  توليدية",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O88.1",
        label: "Amniotic fluid embolism - صمة سائل أمنيوسي",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O88.2",
        label: "Obstetric blood-clot embolism (Obstetric, Puerperal) - صمة دموية (التوليد ، النفاس)",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O88.3",
        label: "Obstetric pyaemic and septic embolism - الانصمام الخثاري و الانتاني التوليدي",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O90.0",
        label: "Disruption of caesarean section wound - تفزر جرح عملية قيصرية",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O90.1",
        label: "Disruption of perineal obstetric wound - تفزر جرح عجاني توليدي",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O90.2",
        label: "Haematoma of obstetric wound - ورم دموري في الجرح التوليدي",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O90.4",
        label: "Postpartum acute renal failure, Hepatorenal syndrome following labour and delivery - الفشل الكلوي الحاد بعد الولادة ، المتلازمة الكبدية الكلوية بعد المخاض والولادة",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O90.9",
        label: "Complication of the puerperium, unspecified - اختلاطات النفاس، غير محددة",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O91",
        label: "Infections of breast associated with childbirth - انتانات الصدر المرافقة للولادة",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O92",
        label: "Other disorders of breast and lactation associated with childbirth - اضطرابات الثدي و الارضاع الأخرى المرافقة للولادة",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O94",
        label: "Sequelae of complication of pregnancy, childbirth and the puerperium - عقابيل مضاعفات الحمل والولادة والنفاس",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O96",
        label: "Death from any obstetric cause occurring more than 42 days but less than one year after delivery - الوفاة لأي سبب ولادي تحدث بعد أكثر من 42 يومًا ولكن أقل من عام واحد من الولادة",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O97",
        label: "Death from sequelae of obstetric causes - وفاة بسبب عقابيل الأسباب التوليدية",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O98",
        label: "Maternal infectious and parasitic diseases classifiable elsewhere but complicating pregnancy, childbirth and the puerperium - الأمراض الانتانية والطفيلية للأمهات المصنفة في مكان آخر ولكنها تعقد الحمل والولادة والنفاس",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O99.0",
        label: "Anaemia complicating pregnancy, childbirth and the puerperium - فقر الدم الذي يعقد الحمل والولادة والنفاس",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "N83",
        label: "Noninflammatory disorders of ovary, fallopian tube and broad ligament - اضطرابات غير التهابية في المبيض، قناة فالوب، و الرباط العريض",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "N85",
        label: "Other noninflammatory disorders of uterus, except cervix - اضطرابات أخرى غير التهابية في الرحم ، باستثناء عنق الرحم",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "N86",
        label: "Erosion and ectropion of cervix uteri - تآكل و الشتر الخارجي لعنق الرحم",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "N87",
        label: "Dysplasia of cervix uteri - خلل التنسج في عنق الرحم",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "N90",
        label: "Other noninflammatory disorders of vulva and perineum - اضطرابات غير التهابية أخرى في الفرج و العجان",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "N94",
        label: "Pain and other conditions associated with female genital organs and menstrual cycle - ألم و حالات أخرى مرتبطة بالأعضاء التناسلية الأنثوية والدورة الشهرية",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "N96",
        label: "Habitual aborter - الإجهاض المعتاد",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "N74.0",
        label: "Tuberculous infection of cervix uteri - سل عنق الرحم",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "N74.1",
        label: "Female tuberculous pelvic inflammatory disease - مرض التهاب الحوض السلي الأنثوي",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "N74.2",
        label: "Female syphilitic pelvic inflammatory disease - مرض التهاب الحوض الزهري عند النساء",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "N74.3",
        label: "Female gonococcal pelvic inflammatory disease - مرض التهاب الحوض بالمكورات البنية عند النساء",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "N74.4",
        label: "Female chlamydial pelvic inflammatory disease - مرض التهاب الحوض بالمتدثرات عند الإناث",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "Z30.0",
        label: "General counselling and advice on contraception - الاستشارة والنصيحة العامة حول منع الحمل",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "Z30.1",
        label: "Insertion of (intrauterine) contraceptive device - إدخال وسيلة منع حمل (داخل الرحم)",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "Z30.2",
        label: "Sterilization - التعقيم",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "Z30.3",
        label: "Menstrual extraction - الاسقاط الطمثي",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "Z30.4",
        label: "Surveillance of contraceptive drugs - مراقبة أدوية موانع الحمل",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "Z30.5",
        label: "Surveillance of (intrauterine) contraceptive device - مراقبة وسيلة منع الحمل (داخل الرحم)",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "Z30.8",
        label: "Other contraceptive management - وسائل منع الحمل الأخرى",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "C51",
        label: "Malignant neoplasm of vulva - ورم خبيث في الفرج",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "C52",
        label: "Malignant neoplasm of vagina - ورم خبيث في المهبل",
        clinics: ["Gynecology", "Emergency Gynecology", "Hematology"],
    },
    {
        value: "C54",
        label: "Malignant neoplasm of corpus uteri - ورم خبيث في جسم الرحم",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "C55",
        label: "Malignant neoplasm of uterus, part unspecified - ورم خبيث في جزء غير محدد من الرحم",
        clinics: ["Gynecology", "Emergency Gynecology", "Hematology"],
    },
    {
        value: "C56",
        label: "Malignant neoplasm of ovary - ورم خبيث في المبيض",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "C57",
        label: "Malignant neoplasm of other and unspecified female genital organs - ورم خبيث في أعضاء تناسلية أخرى و غير محددة عند الإناث",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "C58",
        label: "Malignant neoplasm of placenta - ورم خبيث في الفرج",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "B37",
        label: "Candidiasis - داء المبيضات",
        clinics: ["Gynecology", "InternalMedicine", "Emergency Gynecology"],
    },
    {
        value: "D25",
        label: "Leiomyoma of uterus, fibromyoma of uterus - الورم العضلي الأملس في الرحم، الورم الليفي العضلي في الرحم",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "D26",
        label: "Other benign neoplasms of uterus - أورام الرحم الحميدة الأخرى",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "D27",
        label: "Benign neoplasm of ovary - أورام المبيض الحميدة",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "D28",
        label: "Benign neoplasm of other and unspecified female genital organs - أورام الأعضاء التناسلية الأنثوية الحميدة الأخرى غير المحددة",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O05",
        label: "Other abortion - إجهاض، أخرى",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "O06",
        label: "Unspecified abortion - إجهاض، غير محدد",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "Y05",
        label: "Sexual assault by bodily force, rape, sodomy - الاعتداء الجنسي عن طريق القوة الجسدية، الاغتصاب واللواط",
        clinics: [
            "PediatricSurgery",
            "Gynecology",
            "Urology",
            "Emergency Gynecology",
        ],
    },
    {
        value: "Z04.4",
        label: "Examination and observation following alleged rape and seduction - الفحص والمراقبة بعد الاغتصاب والإغراء المزعوم",
        clinics: ["Gynecology", "Urology", "Emergency Gynecology"],
    },
    {
        value: "A34",
        label: "Obstetrical tetanus - الكزاز الأمومي",
        clinics: ["Gynecology", "Emergency Gynecology"],
    },
    {
        value: "I61.3",
        label: "Intracerebral haemorrhage in brain stem - نزف في جذع الدماغ",
        clinics: ["InternalMedicine"],
    },
    {
        value: "I61.4",
        label: "Intracerebral haemorrhage in cerebellum - نزف مخيخي",
        clinics: ["InternalMedicine"],
    },
    {
        value: "I61.5",
        label: "Intracerebral haemorrhage, intraventricular - نزف داخل البطينات الدماغية",
        clinics: ["InternalMedicine"],
    },
    {
        value: "I61.9",
        label: "Intracerebral haemorrhage, unspecified - نزف داخل االدماغ، غير محدد",
        clinics: ["InternalMedicine", "Neurosurgery"],
    },
    {
        value: "I64",
        label: "Stroke, not specified as haemorrhage or infarction - السكتة الدماغية غير محددة على أنها نزيف أو احتشاء",
        clinics: ["InternalMedicine"],
    },
    {
        value: "I60.8",
        label: "Other subarachnoid haemorrhage, Meningeal haemorrhage, Rupture of cerebral arteriovenous malformation - نزوف أخرى تحت العنكبوتية ، نزيف سحائي ، تمزق تشوه شرياني وريدي دماغي",
        clinics: ["InternalMedicine"],
    },
    {
        value: "I60.9",
        label: "Subarachnoid haemorrhage, unspecified, Ruptured (congenital) cerebral aneurysm - نزيف تحت العنكبوتية ، غير محدد ، تمزق أم دم دماغية (الخلقية)",
        clinics: ["InternalMedicine"],
    },
    {
        value: "I67.4",
        label: "Hypertensive encephalopathy - اعتلال الدماغ بفرط ضغط الدم",
        clinics: ["InternalMedicine"],
    },
    {
        value: "G12.2",
        label: "Amyotrophic Lateral sclerosis - التصلب الجانبي الضموري",
        clinics: ["InternalMedicine"],
    },
    {
        value: "G56.1",
        label: "Other lesions of median nerve, Mononeuropathy - آفات أخرى للعصب الناصف، اعتلال عصب وحيد",
        clinics: ["InternalMedicine", "Orthopedic"],
    },
    {
        value: "G56.2",
        label: "Lesion of ulnar nerve, Tardy ulnar nerve palsy, Mononeuropathy - آفات العصب الزندي، شلل العصب الزندي لتاردي، اعتلال عصب وحيد",
        clinics: ["InternalMedicine", "Orthopedic"],
    },
    {
        value: "G56.3",
        label: "Lesion of radial nerve, Mononeuropathy - آفات العصب الكعبري، اعتلال عصب وحيد",
        clinics: ["InternalMedicine", "Orthopedic"],
    },
    {
        value: "G56.9",
        label: "Mononeuropathy of upper limb, unspecified - اعتلال عصب وحيد في الطرف العلوي، غير محدد",
        clinics: ["InternalMedicine", "Orthopedic"],
    },
    {
        value: "M54.3",
        label: "Sciatica - ألم العصب الوركي، عرق النسا",
        clinics: ["InternalMedicine", "Neurosurgery", "Orthopedic"],
    },
    {
        value: "G57.9",
        label: "Mononeuropathy of lower limb, unspecified - اعتلال عصب وحيد في الطرف السفلي، غير محدد",
        clinics: ["InternalMedicine", "Orthopedic"],
    },
    {
        value: "J09",
        label: "Influenza due to certain identified influenza virus, Influenza A/H1N1, Influenza A/H5N1 - الانفلونزا الناجمة عن نوع محدد من فيروسات الانفلونزا، إنفلونزا A/ H1N1، إنفلونزا A/ H5N1",
        clinics: ["InternalMedicine"],
    },
    {
        value: "J10",
        label: "Influenza due to other identified influenza virus - الانفلونزا الناجمة عن نوع آخر من فيروسات الانفلونزا",
        clinics: ["InternalMedicine"],
    },
    {
        value: "A49.2",
        label: "Haemophilus influenzae infection, unspecified site - الانتان بالمستدمية النزلية، غير محدد المكان",
        clinics: ["InternalMedicine"],
    },
    {
        value: "J12",
        label: "Viral pneumonia due to viruses other than influenza viruses - ذات الرئة الفيروسية الناجمة عن فيروسات غير فيروس الانفلونزا",
        clinics: ["InternalMedicine"],
    },
    {
        value: "J13",
        label: "Pneumonia due to Streptococcus pneumoniae - ذات الرئة الناجمة عن المكورات العقدية الرئوية",
        clinics: ["InternalMedicine"],
    },
    {
        value: "J16.0",
        label: "Chlamydial pneumonia - ذات الرئة بالمتدثرات",
        clinics: ["InternalMedicine"],
    },
    {
        value: "J15",
        label: "Bacterial pneumonia due to bacteria other than S. pneumoniae and H. influenzae and Chlamydia - ذات الرئة الجرثومية الناجمة عن جراثيم غير المكورات العقدية الرئوية أو المستدمية النزلية أو المتدثرات",
        clinics: ["InternalMedicine"],
    },
    {
        value: "J18",
        label: "Pneumonia, organism unspecified - ذات الرئة، غير محددة",
        clinics: ["InternalMedicine"],
    },
    {
        value: "J20",
        label: "Acute bronchitis - التهاب القصبات الحاد",
        clinics: ["InternalMedicine"],
    },
    {
        value: "J22",
        label: "Unspecified acute lower respiratory infection - انتان تنفسي سفلي حاد غير محدد",
        clinics: ["InternalMedicine"],
    },
    {
        value: "J41",
        label: "Simple and mucopurulent chronic bronchitis - التهاب القصبات المزمن البسيط و  المخاطي القيحي",
        clinics: ["InternalMedicine"],
    },
    {
        value: "J44.9",
        label: "Chronic obstructive pulmonary disease, unspecified - أمراض الرئة الانسدادية المزمنة، غير محددة",
        clinics: ["InternalMedicine", "ThoracicSurgery", "Pulmonology"],
    },
    {
        value: "J46",
        label: "Status asthmaticus, Acute severe asthma - الحالة الربوية، الربو الحاد الشديد",
        clinics: ["InternalMedicine"],
    },
    {
        value: "J68",
        label: "Respiratory conditions due to inhalation of chemicals, gases, fumes and vapours - اضطراب تنفسي ناجم عن استنشاق الكيماويات، الغازات، الأبخرة، الدخان",
        clinics: ["InternalMedicine"],
    },
    {
        value: "A15",
        label: "Respiratory tuberculosis, bacteriologically and histologically confirmed - السل الرئوي، مثبت جرثومياً و نسيجياً",
        clinics: ["InternalMedicine"],
    },
    {
        value: "A16",
        label: "Respiratory tuberculosis, not confirmed bacteriologically or histologically - السل الرئوي، غير مثبت جرثومياً أو نسيجياً",
        clinics: ["InternalMedicine"],
    },
    {
        value: "A19",
        label: "Miliary tuberculosis - السل الدخني",
        clinics: ["InternalMedicine"],
    },
    {
        value: "A18.2",
        label: "Tuberculous peripheral lymphadenopathy - اعتلال العقد اللمفية المحيطية السلي",
        clinics: ["InternalMedicine"],
    },
    {
        value: "J84.1",
        label: "Diffuse pulmonary fibrosis, Idiopathic pulmonary fibrosis - تليف الرئة المنتشر، التليف الرئوي مجهول السبب",
        clinics: ["InternalMedicine"],
    },
    {
        value: "J96.0",
        label: "Acute respiratory failure - قصور تنفسي حاد",
        clinics: ["InternalMedicine"],
    },
    {
        value: "J96.1",
        label: "Chronic respiratory failure - قصور تنفسي مزمن",
        clinics: ["InternalMedicine"],
    },
    {
        value: "R09.1",
        label: "Pleurisy - التهاب الجنبة",
        clinics: ["InternalMedicine"],
    },
    {
        value: "R09.2",
        label: "Respiratory arrest, Cardiorespiratory failure - توقف تنفس، قصور قلبي تنفسي",
        clinics: ["InternalMedicine"],
    },
    {
        value: "J80",
        label: "Adult respiratory distress syndrome (ARDS) - متلازمة الشدة التنفسية عند البالغين",
        clinics: ["InternalMedicine", "ThoracicSurgery", "Pulmonology"],
    },
    {
        value: "G47.3",
        label: "Sleep apnoea - انقطاع التنفس أثناء النوم",
        clinics: ["InternalMedicine"],
    },
    {
        value: "E66.2",
        label: "Extreme obesity with alveolar hypoventilation, Pickwickian syndrome - البدانة المفرطة مع نقص التهوية السنخي ، متلازمة بيكويك",
        clinics: ["InternalMedicine"],
    },
    {
        value: "J98.1",
        label: "Pulmonary collapse, Atelectasis, Collapse of lung - الانهيار الرئوي ، انخماص الرئة ، انهيار الرئة",
        clinics: ["InternalMedicine"],
    },
    {
        value: "K29.0",
        label: "Acute haemorrhagic gastritis - التهاب المعدة النزفي الحاد",
        clinics: ["InternalMedicine"],
    },
    {
        value: "K29.1",
        label: "Other acute gastritis - التهابات المعدة الحادة الأخرى",
        clinics: ["InternalMedicine"],
    },
    {
        value: "K29.2",
        label: "Alcoholic gastritis - التهاب المعدة الكحولي",
        clinics: ["InternalMedicine"],
    },
    {
        value: "K29.3",
        label: "Chronic superficial gastritis - التهاب المعدة السطحي المزمن",
        clinics: ["InternalMedicine"],
    },
    {
        value: "K29.4",
        label: "Chronic atrophic gastritis - التهاب المعدة الضموري المزمن",
        clinics: ["InternalMedicine"],
    },
    {
        value: "K29.8",
        label: "Duodenitis - التهابات العفج",
        clinics: ["InternalMedicine"],
    },
    {
        value: "K29.9",
        label: "Gastroduodenitis, unspecified - التهاب المعدة و العفج، غير محدد",
        clinics: ["InternalMedicine"],
    },
    {
        value: "A05",
        label: "Other bacterial foodborne intoxications - التسمم الغذائي الجرثومي",
        clinics: ["InternalMedicine"],
    },
    {
        value: "A08",
        label: "Viral and other specified intestinal infections - انتانات الأمعاء الفيروسية و المحددة الأخرى",
        clinics: ["InternalMedicine"],
    },
    {
        value: "E83.1",
        label: "Haemochromatosis - داء الصباغ الدموي",
        clinics: ["InternalMedicine"],
    },
    {
        value: "G93.7",
        label: "Reye syndrome - متلازمة راي",
        clinics: ["InternalMedicine"],
    },
    {
        value: "E83.0",
        label: "Wilson disease - داء ويلسون",
        clinics: ["InternalMedicine"],
    },
    {
        value: "K70",
        label: "Alcoholic liver disease (fatty liver, hepatitis, fibrosis and sclerosis, cirrhosis, hepatic failure) - أمراض الكبد الكحولية (تشحم الكبد، التهاب الكبد، تليف و تصلب الكبد، تشمع الكبد، قصور الكبد)",
        clinics: ["InternalMedicine"],
    },
    {
        value: "K72",
        label: 'Hepatic failure (coma, encephalopathy, hepatitis (acute, fulminant, malignant), Acute and subacute, Chronic) - القصور الكبدي (الغيبوبة، الاعتلال الدماغي، التهاب الكبد "الحاد، الصاعق، الخبيث"، الحاد و تحت الحاد، المزمن)',
        clinics: ["InternalMedicine"],
    },
    {
        value: "K75.4",
        label: "Autoimmune hepatitis - التهاب الكبد المناعي الذاتي",
        clinics: ["InternalMedicine"],
    },
    {
        value: "K76.0",
        label: "Fatty (change of) liver - تشحم الكبد",
        clinics: ["InternalMedicine"],
    },
    {
        value: "K76.3",
        label: "Infarction of liver - احتشاء الكبد",
        clinics: ["InternalMedicine"],
    },
    {
        value: "K76.6",
        label: "Portal hypertension - ارتفاع التوتر البابي",
        clinics: ["InternalMedicine"],
    },
    {
        value: "K76.7",
        label: "Hepatorenal syndrome - المتلازمة الكبدية الكلوية",
        clinics: ["InternalMedicine"],
    },
    {
        value: "I85.0",
        label: "Oesophageal varices with bleeding - دوالي المري مع نزف",
        clinics: ["InternalMedicine"],
    },
    {
        value: "I85.9",
        label: "Oesophageal varices without bleeding - دوالي المري بدون نزف",
        clinics: ["InternalMedicine"],
    },
    {
        value: "I86.4",
        label: "Gastric varices - دوالي المعدة",
        clinics: ["InternalMedicine"],
    },
    {
        value: "K90.3",
        label: "Pancreatic steatorrhoea - الاسهال الدهني البنكرياسي",
        clinics: ["InternalMedicine"],
    },
    {
        value: "G41",
        label: "Status epilepticus - الحالة الصرعية",
        clinics: ["InternalMedicine"],
    },
    {
        value: "R51",
        label: "Headache - صداع",
        clinics: ["InternalMedicine", "Emergency General"],
    },
    {
        value: "G44",
        label: "Other headache syndromes (Cluster, Vascular, Tension, Chronic post-traumatic) - متلازمات الصداع الأخرى (العنقودي، الوعائي، التوتر، المزمن ما بعد الرض)",
        clinics: ["InternalMedicine"],
    },
    {
        value: "G45.9",
        label: "Transient cerebral ischaemic attack, unspecified - نوبة نقص تروية دماغية عابرة ، غير محددة",
        clinics: ["InternalMedicine", "Emergency Medicine"],
    },
    {
        value: "G71.0",
        label: "Muscular dystrophy - الحثل العضلي",
        clinics: ["InternalMedicine", "Neurology"],
    },
    {
        value: "G81.0",
        label: "Flaccid hemiplegia - شلل شقي رخو",
        clinics: ["InternalMedicine"],
    },
    {
        value: "G81.1",
        label: "Spastic hemiplegia - شلل شقي تشنجي",
        clinics: ["InternalMedicine"],
    },
    {
        value: "G82.0",
        label: "Flaccid paraplegia - شلل نصفي رخو",
        clinics: ["InternalMedicine"],
    },
    {
        value: "G82.1",
        label: "Spastic paraplegia - شلل نصفي تشنجي",
        clinics: ["InternalMedicine"],
    },
    {
        value: "G82.3",
        label: "Flaccid tetraplegia, Quadriplegia  - شلل رباعي رخو",
        clinics: ["InternalMedicine"],
    },
    {
        value: "G82.4",
        label: "Spastic tetraplegia, Quadriplegia - شلل رباعي تشنجي",
        clinics: ["InternalMedicine"],
    },
    {
        value: "G93.0",
        label: "Cerebral cysts (Arachnoid cyst, Porencephalic cyst, acquired) - كيسة دماغية (كيسة عنكبوتية، كيسة مسامية، مكتسبة)",
        clinics: ["InternalMedicine"],
    },
    {
        value: "G93.2",
        label: "Benign intracranial hypertension - ارتفاع التوتر الحميد داخل القحف",
        clinics: ["InternalMedicine"],
    },
    {
        value: "G93.5",
        label: "Compression of brain - انضغاط الدماغ",
        clinics: ["InternalMedicine"],
    },
    {
        value: "G93.6",
        label: "Cerebral oedema - وذمة دماغية",
        clinics: ["InternalMedicine"],
    },
    {
        value: "G95.2",
        label: "Cord compression, unspecified - انضغاط الحبل الشوكي، غير محدد",
        clinics: ["InternalMedicine"],
    },
    {
        value: "M08",
        label: "Juvenile arthritis - التهاب المفاصل الشبابي",
        clinics: ["InternalMedicine"],
    },
    {
        value: "M15",
        label: "Polyarthrosis - اعتلال المفاصل المتعدد",
        clinics: ["InternalMedicine", "Orthopedic"],
    },
    {
        value: "M30",
        label: "Polyarteritis nodosa - التهاب الشرايين العقدي",
        clinics: ["InternalMedicine"],
    },
    {
        value: "L93.0",
        label: "Discoid lupus erythematosus - الذئبة الحمامية القرصية",
        clinics: ["InternalMedicine"],
    },
    {
        value: "M34",
        label: "Systemic sclerosis - التصلب الجهازي",
        clinics: ["InternalMedicine"],
    },
    {
        value: "L94.0",
        label: "Localized scleroderma [morphea] - تصلب الجلد الموضعي",
        clinics: ["InternalMedicine"],
    },
    {
        value: "L94.1",
        label: "Linear scleroderma - تصلب الجلد الخطي",
        clinics: ["InternalMedicine"],
    },
    {
        value: "M45",
        label: "Ankylosing spondylitis - التهاب الفقار اللاصق",
        clinics: ["InternalMedicine", "Orthopedic"],
    },
    {
        value: "M83",
        label: "Adult osteomalacia - تلين العظام عند البالغين",
        clinics: ["InternalMedicine", "Orthopedic"],
    },
    {
        value: "I02",
        label: "Rheumatic chorea - داء الرقص",
        clinics: ["InternalMedicine"],
    },
    {
        value: "D68.0",
        label: "Von Willebrand disease - داء فون ويلبراند",
        clinics: ["InternalMedicine", "Hematology"],
    },
    {
        value: "D69.0",
        label: "Allergic purpura, Henoch(-Schönlein) - الفرفرية التحسسية، هينوخ شونلاين",
        clinics: ["InternalMedicine", "Hematology", "Dermotology"],
    },
    {
        value: "D45",
        label: "Polycythaemia vera - كثرة الحمر الحقيقية",
        clinics: ["InternalMedicine"],
    },
    {
        value: "D44.8",
        label: "Multiple Endocrine Neoplasia  Syndrome (MEN) - متلازمة ورم الغدد الصماء المتعددة",
        clinics: ["InternalMedicine"],
    },
    {
        value: "E85",
        label: "Amyloidosis - الداء النشواني",
        clinics: ["InternalMedicine"],
    },
    {
        value: "L23",
        label: "Allergic contact dermatitis - التهاب الجلد التحسسي التماسي",
        clinics: ["InternalMedicine"],
    },
    {
        value: "R57.0",
        label: "Cardiogenic shock - الصدمة القلبية",
        clinics: ["InternalMedicine"],
    },
    {
        value: "R96",
        label: "Other sudden death, cause unknown - موت مفاجىء، السبب غير معروف",
        clinics: ["InternalMedicine"],
    },
    {
        value: "A51",
        label: "Early syphilis - الزهري المبكر الأولي، الثانوي أقل من عامين",
        clinics: ["InternalMedicine"],
    },
    {
        value: "A52",
        label: "Late syphilis - الزهري المتأخر أكثر من عامين أو غير معلوم المدة",
        clinics: ["InternalMedicine"],
    },
    {
        value: "A53.0",
        label: "Latent syphilis, unspecified as early or late - الزهري الكامن، غير محدد كمبكر أو متأخر",
        clinics: ["InternalMedicine"],
    },
    {
        value: "M79.7",
        label: "Fibromyalgia, Fibromyositis, Fibrositis, Myofibrositis - الألم العضلي الليفي",
        clinics: ["InternalMedicine", "Orthopedic"],
    },
    {
        value: "B20",
        label: "Human immunodeficiency virus [HIV] disease resulting in infectious and parasitic diseases - مرض فيروس نقص المناعة البشرية [الإيدز] المؤدي إلى الأمراض المعدية والطفيلية",
        clinics: ["InternalMedicine"],
    },
    {
        value: "B21",
        label: "Human immunodeficiency virus [HIV] disease resulting in malignant neoplasms - مرض فيروس نقص المناعة البشرية [الإيدز] المؤدي إلى تنشؤات خبيثة",
        clinics: ["InternalMedicine"],
    },
    {
        value: "B22",
        label: "Human immunodeficiency virus [HIV] disease resulting in other specified diseases - مرض فيروس نقص المناعة البشرية [الإيدز] المؤدي إلى أمراض أخرى محددة",
        clinics: ["InternalMedicine"],
    },
    {
        value: "B23",
        label: "Human immunodeficiency virus [HIV] disease resulting in other conditions - مرض فيروس نقص المناعة البشرية [الإيدز] المؤدي إلى حالات أخرى",
        clinics: ["InternalMedicine"],
    },
    {
        value: "L03.0",
        label: "Cellulitis and acute lymphangitis of finger and toe - الداحس الظفري",
        clinics: ["InternalMedicine"],
    },
    {
        value: "R11.10",
        label: "Vomiting, unspecified - إقياء غير محدد",
        clinics: ["InternalMedicine"],
    },
    {
        value: "R10.9",
        label: "Unspecified abdominal pain - ألم بطني ",
        clinics: ["InternalMedicine", "Emergency General"],
    },
    {
        value: "R19.7",
        label: "Diarrhea, unspecified - إسهال",
        clinics: ["InternalMedicine", "Emergency General"],
    },
    {
        value: "N50.82",
        label: "Scrotal pain - ألم الصفن (الخصية)",
        clinics: ["InternalMedicine"],
    },
    {
        value: "E55.0",
        label: "Rickets, active - كساح (نقص فيتامين د)",
        clinics: ["InternalMedicine", "Orthopedic"],
    },
    {
        value: "R62.50",
        label: "Unspecified lack of expected normal physiological development in childhood - نقص التطور الروحي الحركي",
        clinics: ["InternalMedicine"],
    },
    {
        value: "R62.0",
        label: "Delayed milestone in childhood - تأخر المشي",
        clinics: ["InternalMedicine"],
    },
    {
        value: "R62.52",
        label: "Short stature (child) - قصر قامة عند الأطفال",
        clinics: ["InternalMedicine", "Endocrinology"],
    },
    {
        value: "A01.00",
        label: "Typhoid fever, unspecified - التيفوئيد",
        clinics: ["InternalMedicine"],
    },
    {
        value: "L04.9",
        label: "Acute lymphadenitis, unspecified - التهاب العقد اللمفاوية",
        clinics: ["InternalMedicine"],
    },
    {
        value: "H66.90",
        label: "Otitis media, unspecified, unspecified ear - التهاب الأذن الوسطى",
        clinics: ["InternalMedicine", "Emergency General"],
    },
    {
        value: "H10.0",
        label: "Mucopurulent conjunctivitis - إلتهاب الملتحمة القيحي",
        clinics: ["InternalMedicine"],
    },
    {
        value: "H10.10",
        label: "Acute atopic conjunctivitis, unspecified eye - إلتهاب الملتحمة التحسسي",
        clinics: ["InternalMedicine"],
    },
    {
        value: "T30.0",
        label: "Burn of unspecified body region, unspecified degree - الحروق",
        clinics: ["InternalMedicine", "Dermotology"],
    },
    {
        value: "J40",
        label: "Bronchitis, not specified as acute or chronic - التهاب القصبات الشعري",
        clinics: ["InternalMedicine"],
    },
    {
        value: "L70.9",
        label: "Acne, unspecified - حب الشباب",
        clinics: ["InternalMedicine"],
    },
    {
        value: "L73.9",
        label: "Follicular disorder, unspecified - التهاب الجريبات الشعري",
        clinics: ["InternalMedicine"],
    },
    {
        value: "L40.9",
        label: "Psoriasis, unspecified - الصدف",
        clinics: ["InternalMedicine"],
    },
    {
        value: "L50.9",
        label: "Urticaria, unspecified - الشرى",
        clinics: ["InternalMedicine"],
    },
    {
        value: "B37.0",
        label: "Candidal stomatitis - إلتهاب الفم بالكانديدا",
        clinics: ["InternalMedicine", "Dermotology"],
    },
    {
        value: "S00.0",
        label: "Superficial injury of scalp - أذية سطحية للفروة",
        clinics: ["Neurosurgery"],
    },
    {
        value: "S00.7",
        label: "Multiple superficial injuries of head - أذيات سطحية متعددة في الرأس",
        clinics: ["Neurosurgery"],
    },
    {
        value: "S01.7",
        label: "Multiple open wounds of head - جروح مفتوحة متعددة في الرأس",
        clinics: ["Neurosurgery"],
    },
    {
        value: "S02.00",
        label: "Closed Fracture of vault of skull, Frontal bone, Parietal bone - كسر مغلق في سقف الجمجمة، العظم الجبهي، العظم الجداري",
        clinics: ["Neurosurgery"],
    },
    {
        value: "S02.01",
        label: "Open Fracture of vault of skull, Frontal bone, Parietal bone - كسر مفتوح في سقف الجمجمة، العظم الجبهي، العظم الجداري",
        clinics: ["Neurosurgery"],
    },
    {
        value: "S02.10",
        label: "Closed Fracture of base of skull, Fossa (anterior, middle, posterior) Occiput, Orbital roof, Sinus (ethmoid, frontal), Sphenoid, Temporal bone - كسر مغلق في قاعدة الجمجمة ، الحفرة (الأمامية ، الوسطى ، الخلفية) القذالي ، سقف الحجاج ، الجيوب الأنفية (الغربالي ، أمامي) ، الوتدي ، العظم الصدغي",
        clinics: ["Neurosurgery"],
    },
    {
        value: "S02.11",
        label: "Open Fracture of base of skull, Fossa (anterior, middle, posterior) Occiput, Orbital roof, Sinus (ethmoid, frontal), Sphenoid, Temporal bone - كسر مفتوح في قاعدة الجمجمة ، الحفرة (الأمامية ، الوسطى ، الخلفية) القذالي ، سقف الحجاج ، الجيوب الأنفية (الغربالي ، أمامي) ، الوتدي ، العظم الصدغي",
        clinics: ["Neurosurgery"],
    },
    {
        value: "S02.70",
        label: "Closed Multiple fractures involving skull and facial bones - كسور متعددة مغلقة تشمل عظام الجمجمة والوجه",
        clinics: ["Neurosurgery"],
    },
    {
        value: "S02.71",
        label: "Open Multiple fractures involving skull and facial bones - سور متعددة مفتوحة تشمل عظام الجمجمة والوجه",
        clinics: ["Neurosurgery"],
    },
    {
        value: "S06.00",
        label: "Concussion without open intracranial wound - ارتجاج دماغي بدون جرح مفتوح داخل القحف",
        clinics: ["Neurosurgery"],
    },
    {
        value: "S06.01",
        label: "Concussion with open intracranial wound - ارتجاج دماغي مع جرح مفتوح داخل القحف",
        clinics: ["Neurosurgery"],
    },
    {
        value: "S06.10",
        label: "Traumatic cerebral oedema without open intracranial wound - وذمة دماغية رضية بدون جرح مفتوح داخل القحف",
        clinics: ["Neurosurgery"],
    },
    {
        value: "S06.11",
        label: "Traumatic cerebral oedema with open intracranial wound - وذمة دماغية رضية مع جرح مفتوح داخل القحف",
        clinics: ["Neurosurgery"],
    },
    {
        value: "S06.20",
        label: "Diffuse brain injury, Cerebral (contusion, laceration), Traumatic compression of brain without open intracranial wound - أذية دماغية منتشرة، دماغية (كدمة، تمزق)، انضغاط دماغ رضي بدون جرح مفتوح داخل القحف",
        clinics: ["Neurosurgery"],
    },
    {
        value: "S06.21",
        label: "Diffuse brain injury, Cerebral (contusion, laceration), Traumatic compression of brain with open intracranial wound - أذية دماغية منتشرة، دماغية (كدمة، تمزق)، انضغاط دماغ رضي مع جرح مفتوح داخل القحف",
        clinics: ["Neurosurgery"],
    },
    {
        value: "S06.30",
        label: "Focal brain injury, cerebral (contusion, laceration), traumatic intracerebral haemorrhage without open intracranial wound - إصابة بؤرية في الدماغ ، دماغي (كدمة ، جرح) ، نزيف رضي داخل الدماغ بدون جرح مفتوح داخل القحف",
        clinics: ["Neurosurgery"],
    },
    {
        value: "S06.31",
        label: "Focal brain injury, cerebral (contusion, laceration), traumatic intracerebral haemorrhage with open intracranial wound - إصابة بؤرية في الدماغ ، دماغي (كدمة ، جرح) ، نزيف رضي داخل الدماغ مع جرح مفتوح داخل القحف",
        clinics: ["Neurosurgery"],
    },
    {
        value: "S06.40",
        label: "Epidural haemorrhage, Extradural haemorrhage without open intracranial wound - نزف فوق الجافية ، نزف خارج الجافية بدون جرح مفتوح داخل القحف",
        clinics: ["Neurosurgery"],
    },
    {
        value: "S06.41",
        label: "Epidural haemorrhage, Extradural haemorrhage with open intracranial wound - نزف فوق الجافية ، نزف خارج الجافية مع جرح مفتوح داخل القحف",
        clinics: ["Neurosurgery"],
    },
    {
        value: "S06.50",
        label: "Traumatic subdural haemorrhage without open intracranial wound - نزف رضي تحت الجافية بدون جرح مفتوح داخل القحف",
        clinics: ["Neurosurgery"],
    },
    {
        value: "S06.51",
        label: "Traumatic subarachnoid haemorrhage with open intracranial wound - نزف رضي تحت الجافية مع جرح مفتوح داخل القحف",
        clinics: ["Neurosurgery"],
    },
    {
        value: "S06.60",
        label: "Traumatic subarachnoid haemorrhage without open intracranial wound - نزف رضي تحت العنكبوتي بدون جرح مفتوح داخل القحف",
        clinics: ["Neurosurgery"],
    },
    {
        value: "S06.61",
        label: "Traumatic subarachnoid haemorrhage with open intracranial wound - نزف رضي تحت العنكبوتي مع جرح مفتوح داخل القحف",
        clinics: ["Neurosurgery"],
    },
    {
        value: "S06.80",
        label: "Other intracranial injuries, Traumatic haemorrhage (cerebellar, intracranial) without open intracranial wound - إصابات أخرى داخل القحف ، نزيف رضي (مخيخي ، داخل القحف) بدون جرح مفتوح داخل القحف",
        clinics: ["Neurosurgery"],
    },
    {
        value: "S06.81",
        label: "Other intracranial injuries, Traumatic haemorrhage (cerebellar, intracranial) with open intracranial wound - إصابات أخرى داخل القحف ، نزيف رضي (مخيخي ، داخل القحف) مع جرح مفتوح داخل القحف",
        clinics: ["Neurosurgery"],
    },
    {
        value: "S06.90",
        label: "Intracranial injury unspecified, Brain injury without open intracranial wound - أذية داخل القحف غير محددة، أذية دماغية بدون جرح مفتوح داخل القحف",
        clinics: ["Neurosurgery"],
    },
    {
        value: "S06.91",
        label: "Intracranial injury unspecified, Brain injury with open intracranial wound - أذية داخل القحف غير محددة، أذية دماغية مع جرح مفتوح داخل القحف",
        clinics: ["Neurosurgery"],
    },
    {
        value: "S08.0",
        label: "Avulsion of scalp - إنقلاع الفروة",
        clinics: ["Neurosurgery"],
    },
    {
        value: "S09.7",
        label: "Multiple injuries of head - أذيات متعددة في الرأس",
        clinics: ["Neurosurgery"],
    },
    {
        value: "S12.00",
        label: "Closed Fracture of first cervical vertebra, Atlas - كسر مغلق في الفقرة الرقبية الأولى، الأطلس",
        clinics: ["Neurosurgery"],
    },
    {
        value: "S12.01",
        label: "Open Fracture of first cervical vertebra, Atlas - كسر مفتوح في الفقرة الرقبية الأولى، الأطلس",
        clinics: ["Neurosurgery"],
    },
    {
        value: "S12.10",
        label: "Closed Fracture of second cervical vertebra, Axis - كسر مغلق في الفقرة الرقبية الثانية، المحور",
        clinics: ["Neurosurgery"],
    },
    {
        value: "S12.11",
        label: "Open Fracture of second cervical vertebra, Axis - كسر مفتوح في الفقرة الرقبية الثانية، المحور",
        clinics: ["Neurosurgery"],
    },
    {
        value: "S12.20",
        label: "Closed Fracture of other specified cervical vertebra - كسر مغلق في الفقرات الرقبية الأخرى",
        clinics: ["Neurosurgery"],
    },
    {
        value: "S12.21",
        label: "Open Fracture of other specified cervical vertebra - كسر مفتوح في الفقرات الرقبية الأخرى",
        clinics: ["Neurosurgery"],
    },
    {
        value: "S12.70",
        label: "Closed Fracture of Multiple fractures of cervical spine - كسور مغلقة متعددة في العمود الرقبي",
        clinics: ["Neurosurgery"],
    },
    {
        value: "S12.71",
        label: "Open Fracture of Multiple fractures of cervical spine - كسور مفتوحة متعددة في العمود الرقبي",
        clinics: ["Neurosurgery"],
    },
    {
        value: "S13.0",
        label: "Traumatic rupture of cervical intervertebral disc - تمزق رضي في القرص بين الفقرات الرقبية",
        clinics: ["Neurosurgery"],
    },
    {
        value: "S13.1",
        label: "Traumatic Dislocation of cervical vertebra, Cervical spine - خلع رضي في الفقرات الرقبي، العمود الرقبي",
        clinics: ["Neurosurgery", "Emergency General"],
    },
    {
        value: "S13.3",
        label: "Multiple dislocations of neck - خلوع متعددة في العنق",
        clinics: ["Neurosurgery", "Emergency General"],
    },
    {
        value: "S13.4",
        label: "Sprain and strain of cervical spine, Anterior longitudinal (ligament), cervical, Atlanto-axial (joints), Atlanto-occipital (joints), Whiplash injury - إلتواء و وثي في العمود الرقبي، الرباط الطولاني الأمامي، المفصل الفقهي المحوري، المفصل الفهقي القذالي، إصابة سوطية",
        clinics: ["Neurosurgery"],
    },
    {
        value: "M50.0",
        label: "Cervical disc disorder with myelopathy - اضطراب في الأقراص بين الفقرات الرقبية مع اعتلال نخاعي",
        clinics: ["Neurosurgery"],
    },
    {
        value: "M50.1",
        label: "Cervical disc disorder with radiculopathy - اضطراب في الأقراص بين الفقرات الرقبية مع اعتلال جذور",
        clinics: ["Neurosurgery"],
    },
    {
        value: "M50.2",
        label: "Other cervical disc displacement - انزياح الأقراص بين الفقرات الرقبية",
        clinics: ["Neurosurgery", "Orthopedic"],
    },
    {
        value: "M50.3",
        label: "Other cervical disc degeneration - تنكسات القرص بين الفقرات الرقبية الأخرى",
        clinics: ["Neurosurgery"],
    },
    {
        value: "M51.0",
        label: "Lumbar and other intervertebral disc disorders with myelopathy - اضطرابات الأقراص بين الفقرات القطنية و الأخرى مع اعتلال نخاعي",
        clinics: ["Neurosurgery"],
    },
    {
        value: "M51.1",
        label: "Lumbar and other intervertebral disc disorders with radiculopathy, Sciatica due to intervertebral disc disorder - اضطرابات الأقراص بين الفقرات القطنية و الأخرى مع اعتلال جذور، ألم العصب الوركي الناجم عن اضطرابات الأقراص بين الفقرات",
        clinics: ["Neurosurgery"],
    },
    {
        value: "M51.2",
        label: "Other specified intervertebral disc displacement - إنزياح القرص بين الفقرات الأخرى",
        clinics: ["Neurosurgery", "Orthopedic"],
    },
    {
        value: "M51.3",
        label: "Other specified intervertebral disc degeneration - تنكس القرص بين الفقرات الأخرى",
        clinics: ["Neurosurgery"],
    },
    {
        value: "M53.2",
        label: "Spinal instabilities - عدم ثباتية العمود الشوكي",
        clinics: ["Neurosurgery"],
    },
    {
        value: "M54.4",
        label: "Lumbago with sciatica - الألم القطني مع ألم العصب الوركي",
        clinics: ["Neurosurgery"],
    },
    {
        value: "M54.5",
        label: "Low back pain - ألم أسفل الظهر",
        clinics: ["Neurosurgery", "Orthopedic", "Emergency General"],
    },
    {
        value: "M54.6",
        label: "Pain in thoracic spine - ألم العمود الفقري الصدري",
        clinics: ["Neurosurgery"],
    },
    {
        value: "C70",
        label: "Malignant neoplasm of meninges - الأورام الخبيثة في السحايا",
        clinics: ["Neurosurgery"],
    },
    {
        value: "C71",
        label: "Malignant neoplasm of brain - الأورام الخبيثة في الدماغ",
        clinics: ["Neurosurgery"],
    },
    {
        value: "C69.6",
        label: "Malignant neoplasm of Retrobulbar tissue - الأورام الخبيثة في الحيز خلف المقلة",
        clinics: ["Neurosurgery"],
    },
    {
        value: "C72",
        label: "Malignant neoplasm of spinal cord, cranial nerves and other parts of central nervous system - الأورام الخبيثة في الحبل الشوكي، الأعصاب القحفية، والأجزاء الأخرى من الجهاز العصبي المركزي",
        clinics: ["Neurosurgery"],
    },
    {
        value: "C47",
        label: "Malignant neoplasm of peripheral nerves and autonomic nervous system - الأورام الخبيثة في الأعصاب المحيطية و الجهاز العصبي الذاتي",
        clinics: ["Neurosurgery"],
    },
    {
        value: "D32",
        label: "Benign neoplasm of meninges - الأورام الحميدة في السحايا",
        clinics: ["Neurosurgery"],
    },
    {
        value: "D33",
        label: "Benign neoplasm of brain and other parts of central nervous system - الأورام الخبيثة في الدماغ و الأعضاء الأخرى من الجهاز العصبي المركزي",
        clinics: ["Neurosurgery"],
    },
    {
        value: "M51.26",
        label: "Other intervertebral disc displacement, lumbar region - دسك قطني",
        clinics: ["Neurosurgery", "Neurology"],
    },
    {
        value: "M50.20",
        label: "Other cervical disc displacement, unspecified cervical region - دسك رقبي",
        clinics: ["Neurosurgery"],
    },
    {
        value: "M13.80",
        label: "Other specified arthritis, unspecified site - التهاب مفاصل نقرية",
        clinics: ["Neurosurgery"],
    },
    {
        value: "M99.1",
        label: "Subluxation complex (vertebral) - انزلاق فقرات",
        clinics: ["Neurosurgery"],
    },
    {
        value: "G56.20",
        label: "Lesion of ulnar nerve, unspecified upper limb - تناذر النفق المرفقي",
        clinics: ["Neurosurgery"],
    },
    {
        value: "F99",
        label: "Mental disorder, not otherwise specified - اضطرابات نفسية",
        clinics: ["Neurosurgery"],
    },
    {
        value: "G95.89",
        label: "Other specified diseases of spinal cord - تناذر الحبل المشدود(أو أمراض اخرى للنخاع الشوكي)",
        clinics: ["Neurosurgery"],
    },
    {
        value: "Q05.9",
        label: "Spina bifida, unspecified - شوك مشقوف (قيلة سحائية)",
        clinics: ["Neurosurgery"],
    },
    {
        value: "G91.9",
        label: "Hydrocephalus, unspecified - استسقاء دماغي",
        clinics: ["Neurosurgery"],
    },
    {
        value: "C71.9",
        label: "Malignant neoplasm of brain, unspecified - ورم دماغي",
        clinics: ["Neurosurgery", "Hematology"],
    },
    {
        value: "H00.0",
        label: "Hordeolum and other deep inflammation of eyelid - الشعيرة و التهابات الأجفان العميقة الأخرى",
        clinics: ["Ophthalmology"],
    },
    { value: "H00.1", label: "Chalazion - البردة", clinics: ["Ophthalmology"] },
    {
        value: "H01.9",
        label: "Inflammation of eyelid, unspecified - التهابات الأجفان، غير محدد",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H02.3",
        label: "Blepharochalasis - ارتخاء الجفن",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H02.6",
        label: "Xanthelasma of eyelid - اللويحة الصفراء في الأجفان",
        clinics: ["Ophthalmology"],
    },
    { value: "H04.2", label: "Epiphora - الدماع", clinics: ["Ophthalmology"] },
    {
        value: "H04.3",
        label: "Acute and unspecified inflammation of lacrimal passages - التهاب الطرق الدمعية الحاد، غير محدد",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H04.4",
        label: "Chronic inflammation of lacrimal passages - التهاب الطرق الدمعية المزمن",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H05.2",
        label: "Exophthalmic conditions - حالات الجحوظ",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H05.3",
        label: "Deformity of orbit - تشوهات الحجاج",
        clinics: ["Ophthalmology"],
    },
    { value: "H05.4", label: "Enophthalmos - الخوص", clinics: ["Ophthalmology"] },
    { value: "H11.0", label: "Pterygium - ظفرة", clinics: ["Ophthalmology"] },
    {
        value: "H15.1",
        label: "Episcleritis - التهاب ظاهر الصلبة",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H16.2",
        label: "Keratoconjunctivitis - التهاب القرنية و الملتحمة",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H16.3",
        label: "Interstitial and deep keratitis - التهاب القرنية العميق و الخلالي",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H16.9",
        label: "Keratitis, unspecified - التهاب القرنية، غير محدد",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H17",
        label: "Corneal scars and opacities - التندب و تعتيم القرنية",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H18.0",
        label: "Corneal pigmentations and deposits - تصبغات و الرواسب القرنية",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H18.4",
        label: "Corneal degeneration - تنكس القرنية",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H18.5",
        label: "Hereditary corneal dystrophies - ضمور القرنية الوراثي",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H20.0",
        label: "Acute and subacute iridocyclitis - التهاب القزحية الحاد و تحت الحاد",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H20.1",
        label: "Chronic iridocyclitis - التهاب القزحية المزمن",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H20.2",
        label: "Lens-induced iridocyclitis - التهاب القزحية الناجم عن زرع العدسة",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H21",
        label: "Other disorders of iris and ciliary body - الاضطرابات الأخرى في القزحية و الجسم الهدبي",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H25",
        label: "Senile cataract - الساد الشيخي",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H26",
        label: "Other cataract - الساد، أخرى",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H27.0",
        label: "Aphakia - انعدام العدسة",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H27.1",
        label: "Dislocation of lens - خلع العدسة",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H30",
        label: "Chorioretinal inflammation - التهاب المشيمية",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H31",
        label: "Other disorders of choroid - اضطرابات المشيمية الأخرى",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H34",
        label: "Retinal vascular occlusions - انسداد الأوعية الشبكية",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H35.2",
        label: "Other proliferative retinopathy - اعتلالات الشبكية التكاثرية الأخرى",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H35.3",
        label: "Degeneration of macula and posterior pole - تنكس اللطخة الصفراء و القطب الخلفي",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H35.4",
        label: "Peripheral retinal degeneration - تنكس الشبكية المحيطي",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H35.5",
        label: "Hereditary retinal dystrophy - حثل الشبكية الوراثي",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H35.6",
        label: "Retinal haemorrhage - نزف الشبكية",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H35.7",
        label: "Separation of retinal layers - انفصال طبقات الشبكية",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H35.9",
        label: "Retinal disorder, unspecified - اضطرابات الشبكية، غير محددة",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H43.0",
        label: "Vitreous prolapse - تدلي زجاجي",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H43.2",
        label: "Crystalline deposits in vitreous body - ترسبات كريسالية في الجسم الزجاجي",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H43.9",
        label: "Disorder of vitreous body, unspecified - اضطرابات الجسم الزجاجي، غير محددة",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H44.2",
        label: "Degenerative myopia - الحسر التنكسي",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H44.4",
        label: "Hypotony of eye - نقص التوتر داخل العين",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H44.5",
        label: "Degenerated conditions of globe - حالات تنكسية في المقلة",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H44.9",
        label: "Disorder of globe, unspecified - اضطرابات المقلة، غير محددة",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H46",
        label: "Optic neuritis - التهاب العصب البصري",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H47",
        label: "Other disorders of optic [2nd] nerve and visual pathways - اضطرابات العصب البصري الأخرى و المسارات البصرية",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H49",
        label: "Paralytic strabismus - حول شللي",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H50",
        label: "Other strabismus - حول، أخرى",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H52.2",
        label: "Astigmatism - اللابؤرية",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H52.3",
        label: "Anisometropia and aniseikonia - تفاوت و أسواء الانكسار",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H52.4",
        label: "Presbyopia - القصو (ضعف البصر) الشيخي",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H53.0",
        label: "Amblyopia ex anopsia - الغمش المهمل",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H36.0",
        label: "Diabetic retinopathy - اعتلال الشبكية السكري",
        clinics: ["Ophthalmology"],
    },
    { value: "H53.2", label: "Diplopia - الشفع", clinics: ["Ophthalmology"] },
    {
        value: "H53.5",
        label: "Colour vision deficiencies - قصور رؤية الألوان",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H54",
        label: "Visual impairment including blindness (binocular or monocular) - ضعف البصر بما في ذلك العمى (أحادي أو ثنائي)",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H55",
        label: "Nystagmus and other irregular eye movements - الرأرأة و حركات العين غير المنتظمة",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H57.1",
        label: "Ocular pain - ألم العين",
        clinics: ["Ophthalmology"],
    },
    {
        value: "S05",
        label: "Injury of eye and orbit - أذية العين و الحجاج",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H34.839",
        label: "Tributary (branch) retinal vein occlusion, unspecified eye - إنسداد وريد في الشبكية",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H34.819",
        label: "Central retinal vein occlusion, unspecified eye - إنسداد شريان في الشبكية",
        clinics: ["Ophthalmology"],
    },
    {
        value: "T15",
        label: "Foreign body on external eye - رمد ربيعي",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H35.30",
        label: "Unspecified macular degeneration - إلتهاب ملتحمة فيروسي(أو إلتهابات أخرى)",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H10.44",
        label: "Vernal conjunctivitis - إلتهاب ملتحمة جرثومي",
        clinics: ["Ophthalmology"],
    },
    {
        value: "B30.9",
        label: "Viral conjunctivitis, unspecified - نزف تحت الملتحمة",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H10.89",
        label: "Other conjunctivitis - ديرموئيد",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H11.30",
        label: "Conjunctival hemorrhage, unspecified eye - متلازمة العين الجافة للغدة الدمعية غير المحددة",
        clinics: ["Ophthalmology"],
    },
    {
        value: "D31.90",
        label: "Benign neoplasm of unspecified part of unspecified eye - إعتلال عصب بصري",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H04.129",
        label: "Dry eye syndrome of unspecified lacrimal gland - وذمة عصب بصري",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H46.00",
        label: "Optic papillitis, unspecified eye - إنسداد مجرى الدمع",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H47.1",
        label: "Papilledema - ضمور الشبكية الصبغي",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H04.559",
        label: "Acquired stenosis of unspecified nasolacrimal duct - حول أنسي",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H35.52",
        label: "Pigmentary retinal dystrophy - حول وحشي",
        clinics: ["Ophthalmology"],
    },
    { value: "H50.0", label: "Esotropia - حول شللي", clinics: ["Ophthalmology"] },
    {
        value: "H50.1",
        label: "Exotropia - الحول الوحشي ",
        clinics: ["Ophthalmology"],
    },
    {
        value: "H49.9",
        label: "Unspecified paralytic strabismus - الحول الشللي غير المحدد",
        clinics: ["Ophthalmology"],
    },
    {
        value: "N32.3",
        label: "Diverticulum of bladder - رتوج مثانة",
        clinics: ["PediatricSurgery", "Urology"],
    },
    {
        value: "S37.00",
        label: "Injury of kidney without open wound into cavity - إصابة كلية بدون جرح مفتوح في التجويف البطني",
        clinics: ["PediatricSurgery", "Urology"],
    },
    {
        value: "S37.01",
        label: "Injury of kidney with open wound into cavity - إصابة كلية مع جرح مفتوح في التجويف البطني",
        clinics: ["PediatricSurgery", "Urology"],
    },
    {
        value: "S37.10",
        label: "Injury of ureter without open wound into cavity - إصابة حالب بدون جرح مفتوح في التجويف البطني",
        clinics: ["PediatricSurgery", "Urology"],
    },
    {
        value: "S37.11",
        label: "Injury of ureter with open wound into cavity - إصابة حالب مع جرح مفتوح في التجويف البطني",
        clinics: ["PediatricSurgery", "Urology"],
    },
    {
        value: "S37.20",
        label: "Injury of bladder without open wound into cavity - إصابة مثانة بدون جرح مفتوح في التجويف البطني",
        clinics: ["PediatricSurgery", "Urology"],
    },
    {
        value: "S37.21",
        label: "Injury of bladder with open wound into cavity - إصابة مثانة مع جرح مفتوح في التجويف البطني",
        clinics: ["PediatricSurgery", "Urology"],
    },
    {
        value: "S37.30",
        label: "Injury of urethra without open wound into cavity - إصابة إحليل بدون جرح مفتوح في التجويف البطني",
        clinics: ["PediatricSurgery", "Urology"],
    },
    {
        value: "S37.31",
        label: "Injury of urethra with open wound into cavity - إصابة إحليل مع جرح مفتوح في التجويف البطني",
        clinics: ["PediatricSurgery", "Urology"],
    },
    {
        value: "S37.70",
        label: "Injury of multiple pelvic organs without open wound into cavity - إصابة عدة أعضاء حوضية بدون جرح مفتوح في التجويف البطني",
        clinics: ["PediatricSurgery", "Urology"],
    },
    {
        value: "S37.71",
        label: "Injury of multiple pelvic organs with open wound into cavity - إصابة أعضاء حوضية مع جرح مفتوح في التجويف البطني",
        clinics: ["PediatricSurgery", "Urology"],
    },
    {
        value: "S37.80",
        label: "Injury of other pelvic organs (Adrenal gland, Prostate, Seminal vesicle, Vas deferens) without open wound into cavity - إصابة أعضاء حوضية أخرى (الغدة الكظرية، الأوعية المنوية، الأسهر) بدون جرح مفتوح في التجويف البطني",
        clinics: ["PediatricSurgery", "Urology"],
    },
    {
        value: "S37.81",
        label: "Injury of other pelvic organs (Adrenal gland, Prostate, Seminal vesicle, Vas deferens) with open wound into cavity - إصابة أعضاء حوضية أخرى (الغدة الكظرية، الأوعية المنوية، الأسهر) مع جرح مفتوح في التجويف البطني",
        clinics: ["PediatricSurgery", "Urology"],
    },
    {
        value: "A18.1",
        label: "Tuberculosis of genitourinary system - سل الجهاز البولي",
        clinics: ["PediatricSurgery", "Urology"],
    },
    {
        value: "N41.2",
        label: "Abscess of prostate - خراج البروستات",
        clinics: ["Urology"],
    },
    {
        value: "N44",
        label: "Torsion of testis - انفتال الخصية",
        clinics: ["PediatricSurgery", "Urology"],
    },
    {
        value: "D29",
        label: "Benign neoplasm of male genital organs - أورام الأعضاء التناسلية الذكرية الحميدة",
        clinics: ["PediatricSurgery", "Urology"],
    },
    {
        value: "C64.9",
        label: "Malignant neoplasm of unspecified kidney, except renal pelvis - ورم كلية",
        clinics: ["PediatricSurgery", "Urology", "Hematology"],
    },
    {
        value: "N45.4",
        label: "Abscess of epididymis or testis - خراج البربخ أو الخصية",
        clinics: ["PediatricSurgery", "Urology"],
    },
    {
        value: "N13.0",
        label: "Hydronephrosis with ureteropelvic junction obstruction - تضيق وصل حويضي حالبي",
        clinics: ["PediatricSurgery", "Urology"],
    },
    {
        value: "N13.7",
        label: "Vesicoureteral-reflux - جذر مثاني حالبي",
        clinics: ["PediatricSurgery", "Urology"],
    },
    {
        value: "A54.01",
        label: "Gonococcal cystitis and urethritis, unspecified - التهاب إحليل سيلاني",
        clinics: ["PediatricSurgery", "Urology"],
    },
    {
        value: "N35.9",
        label: "Urethral stricture, unspecified - تضيق إحليل",
        clinics: ["PediatricSurgery", "Urology"],
    },
    {
        value: "Q64.70",
        label: "Unspecified congenital malformation of bladder and urethra - تشوهات إحليل ومثانة",
        clinics: ["PediatricSurgery", "Urology"],
    },
    {
        value: "T02.2",
        label: "Fractures involving multiple regions of one upper limb - كسور في مناطق متعددة في أحد الأطراف العلوية",
        clinics: ["Orthopedic"],
    },
    {
        value: "T02.3",
        label: "Fractures involving multiple regions of one lower limb - كسور في مناطق متعددة في أحد الأطراف السفلية",
        clinics: ["Orthopedic"],
    },
    {
        value: "T02.4",
        label: "Fractures involving multiple regions of both upper limbs - كسور في مناطق متعددة في كلا الطرفين العلويين",
        clinics: ["Orthopedic"],
    },
    {
        value: "T02.5",
        label: "Fractures involving multiple regions of both lower limbs - كسور في مناطق متعددة في كلا الطرفين السفليين",
        clinics: ["Orthopedic"],
    },
    {
        value: "T02.6",
        label: "Fractures involving multiple regions of upper limb(s) with lower limb(s) - كسور في مناطق متعددة في طرف أو كلا الطرفين العلويين مع طرف أو كلا الطرفين السفليين",
        clinics: ["Orthopedic"],
    },
    {
        value: "S32.10",
        label: "Closed Fracture of sacrum - كسور العجز المغلقة",
        clinics: ["Orthopedic"],
    },
    {
        value: "S32.11",
        label: "Open Fracture of sacrum - كسور العجز المفتوحة",
        clinics: ["Orthopedic"],
    },
    {
        value: "S32.20",
        label: "Closed Fracture of coccyx - كسور العصعص المغلقة",
        clinics: ["Orthopedic"],
    },
    {
        value: "S32.21",
        label: "Open Fracture of coccyx - كسور العصعص المفتوحة",
        clinics: ["Orthopedic"],
    },
    {
        value: "S32.30",
        label: "Closed Fracture of ilium - كسور الحرقفة المغلقة",
        clinics: ["Orthopedic"],
    },
    {
        value: "S32.31",
        label: "Open Fracture of ilium - كسور الحرقفة المفتوحة",
        clinics: ["Orthopedic"],
    },
    {
        value: "S32.40",
        label: "Closed Fracture of acetabulum - كسور الجوف الحقي المغلقة",
        clinics: ["Orthopedic"],
    },
    {
        value: "S32.41",
        label: "Open Fracture of acetabulum - كسور الجوف الحقي المفتوحة",
        clinics: ["Orthopedic"],
    },
    {
        value: "S32.50",
        label: "Closed Fracture of pubis - كسور العانة المغلقة",
        clinics: ["Orthopedic"],
    },
    {
        value: "S32.51",
        label: "Open Fracture of pubis - كسور العانة المفتوحة",
        clinics: ["Orthopedic"],
    },
    {
        value: "S32.00",
        label: "Closed Fracture of lumbar vertebra - كسور الفقرات القطنية المغلقة",
        clinics: ["Orthopedic"],
    },
    {
        value: "S32.01",
        label: "Open Fracture of lumbar vertebra - كسور الفقرات القطنية المفتوحة",
        clinics: ["Orthopedic"],
    },
    {
        value: "S72.00",
        label: "Closed Fracture of neck of femur - كسر مغلق في عنق الفخذ",
        clinics: ["Orthopedic"],
    },
    {
        value: "S72.01",
        label: "Open Fracture of neck of femur - كسر مفتوح في عنق الفخذ",
        clinics: ["Orthopedic"],
    },
    {
        value: "S72.10",
        label: "Closed Pertrochanteric fracture, Intertrochanteric fracture, Trochanteric fracture - كسر مغلق عبر المدورين، بين المدورين، المدورين",
        clinics: ["Orthopedic"],
    },
    {
        value: "S72.11",
        label: "Open Pertrochanteric fracture, Intertrochanteric fracture, Trochanteric fracture - كسر مفتوح عبر المدورين، بين المدورين، المدورين",
        clinics: ["Orthopedic"],
    },
    {
        value: "S72.20",
        label: "Closed Subtrochanteric fracture - كسر مغلق تحت المدورين",
        clinics: ["Orthopedic"],
    },
    {
        value: "S72.21",
        label: "Open Subtrochanteric fracture - كسر مفتوح تحت المدورين",
        clinics: ["Orthopedic"],
    },
    {
        value: "S72.30",
        label: "Closed Fracture of shaft of femur - كسر مغلق في جسم الفخذ",
        clinics: ["Orthopedic"],
    },
    {
        value: "S72.31",
        label: "Open Fracture of shaft of femur - كسر مفتوح في جسم الفخذ",
        clinics: ["Orthopedic"],
    },
    {
        value: "S72.40",
        label: "Closed Fracture of lower end of femur - كسر مغلق في النهاية السفلية للفخذ",
        clinics: ["Orthopedic"],
    },
    {
        value: "S72.41",
        label: "Open Fracture of lower end of femur - كسر مفتوح في النهاية السفلية للفخذ",
        clinics: ["Orthopedic"],
    },
    {
        value: "S72.70",
        label: "Closed Multiple fractures of femur - كسور متعددة مغلقة في الفخذ",
        clinics: ["Orthopedic"],
    },
    {
        value: "S72.71",
        label: "Open Multiple fractures of femur - كسور متعددة مفتوحة في الفخذ",
        clinics: ["Orthopedic"],
    },
    {
        value: "S73.0",
        label: "Dislocation of hip - خلع الورك",
        clinics: ["Orthopedic", "Emergency General"],
    },
    {
        value: "S73.1",
        label: "Sprain and strain of hip - التواء و وثي الورك",
        clinics: ["Orthopedic"],
    },
    {
        value: "S42.00",
        label: "Closed Fracture of clavicle - كسر مغلق في الترقوة",
        clinics: ["Orthopedic"],
    },
    {
        value: "S42.01",
        label: "Open Fracture of clavicle - كسر مفتوح في الترقوة",
        clinics: ["Orthopedic"],
    },
    {
        value: "S42.10",
        label: "Closed Fracture of scapula - كسر مغلق في الكتف",
        clinics: ["Orthopedic"],
    },
    {
        value: "S42.11",
        label: "Open Fracture of scapula - كسر مفتوح في الكتف",
        clinics: ["Orthopedic"],
    },
    {
        value: "S42.20",
        label: "Closed Fracture of upper end of humerus - كسر مغلق في النهاية العلوية للعضد",
        clinics: ["Orthopedic"],
    },
    {
        value: "S42.21",
        label: "Open Fracture of upper end of humerus - كسر مفتوح في النهاية العلوية للعضد",
        clinics: ["Orthopedic"],
    },
    {
        value: "S42.30",
        label: "Closed Fracture of shaft of humerus - كسر مغلق في جسم العضد",
        clinics: ["Orthopedic"],
    },
    {
        value: "S42.31",
        label: "Open Fracture of shaft of humerus - كسر مفتوح في جسم العضد",
        clinics: ["Orthopedic"],
    },
    {
        value: "S42.40",
        label: "Closed Fracture of lower end of humerus - كسر مغلق في النهاية السفلية للعضد",
        clinics: ["Orthopedic"],
    },
    {
        value: "S42.41",
        label: "Open Fracture of lower end of humerus - كسر مفتوح في النهاية السفلية للعضد",
        clinics: ["Orthopedic"],
    },
    {
        value: "S42.70",
        label: "Closed Multiple fractures of clavicle, scapula and humerus - كسور مغلقة متعددة في الترقوة، الكتف والعضد",
        clinics: ["Orthopedic"],
    },
    {
        value: "S42.71",
        label: "Open Multiple fractures of clavicle, scapula and humerus - كسور مفتوحة متعددة في الترقوة، الكتف والعضد",
        clinics: ["Orthopedic"],
    },
    {
        value: "S43.4",
        label: "Sprain and strain of shoulder joint - التواء و وثي في مفصل الكتف",
        clinics: ["Orthopedic"],
    },
    {
        value: "S52.00",
        label: "Closed Fracture of upper end of ulna - كسر مغلق في النهاية العلوية للزند",
        clinics: ["Orthopedic"],
    },
    {
        value: "S52.01",
        label: "Open Fracture of upper end of ulna - كسر مفتوح في النهاية العلوية للزند",
        clinics: ["Orthopedic"],
    },
    {
        value: "S52.10",
        label: "Closed Fracture of upper end of radius - كسر مغلق في النهاية العلوية للكعبرة",
        clinics: ["Orthopedic"],
    },
    {
        value: "S52.11",
        label: "Open Fracture of upper end of radius - كسر مفتوح في النهاية العلوية للكعبرة",
        clinics: ["Orthopedic"],
    },
    {
        value: "S52.20",
        label: "Closed Fracture of shaft of ulna - كسر مغلق في جسم الزند",
        clinics: ["Orthopedic"],
    },
    {
        value: "S52.21",
        label: "Open Fracture of shaft of ulna - كسر مفتوح في جسم الزند",
        clinics: ["Orthopedic"],
    },
    {
        value: "S52.30",
        label: "Closed Fracture of shaft of radius - كسر مغلق في جسم الكعبرة",
        clinics: ["Orthopedic"],
    },
    {
        value: "S52.31",
        label: "Open Fracture of shaft of radius - كسر مفتوح في جسم الكعبرة",
        clinics: ["Orthopedic"],
    },
    {
        value: "S52.40",
        label: "Closed Fracture of shafts of both ulna and radius - كسر مغلق في جسم الزند والكعبرة",
        clinics: ["Orthopedic"],
    },
    {
        value: "S52.41",
        label: "Open Fracture of shafts of both ulna and radius - كسر مفتوح في جسم الزند والكعبرة",
        clinics: ["Orthopedic"],
    },
    {
        value: "S52.50",
        label: "Closed Fracture of lower end of radius - كسر مغلق في النهاية السفلية للكعبرة",
        clinics: ["Orthopedic"],
    },
    {
        value: "S52.51",
        label: "Open Fracture of lower end of radius - كسر مفتوح في النهاية السفلية للكعبرة",
        clinics: ["Orthopedic"],
    },
    {
        value: "S52.60",
        label: "Closed Fracture of lower end of both ulna and radius - كسر مغلق في النهاية السفلية للزند و الكعبرة",
        clinics: ["Orthopedic"],
    },
    {
        value: "S52.61",
        label: "Open Fracture of lower end of both ulna and radius - كسر مفتوح في النهاية السفلية للزند و الكعبرة",
        clinics: ["Orthopedic"],
    },
    {
        value: "S52.70",
        label: "Closed Multiple fractures of forearm - كسور مغلقة متعددة في الذراع",
        clinics: ["Orthopedic"],
    },
    {
        value: "S52.71",
        label: "Open Multiple fractures of forearm - كسور مفتوحة متعددة في الذراع",
        clinics: ["Orthopedic"],
    },
    {
        value: "S53.0",
        label: "Dislocation of radial head - خلع رأس الكعبرة",
        clinics: ["Orthopedic"],
    },
    {
        value: "S53.1",
        label: "Dislocation of elbow, unspecified - خلع المرفق، غير محدد",
        clinics: ["Orthopedic", "Emergency General"],
    },
    {
        value: "S53.4",
        label: "Sprain and strain of elbow - إلتواء و وثي في المرفق",
        clinics: ["Orthopedic"],
    },
    {
        value: "S62.00",
        label: "Closed Fracture of navicular [scaphoid] bone of hand - كسر مغلق في العظم الزورقي لليد",
        clinics: ["Orthopedic"],
    },
    {
        value: "S62.01",
        label: "Open Fracture of navicular [scaphoid] bone of hand - كسر مفتوح في العظم الزورقي لليد",
        clinics: ["Orthopedic"],
    },
    {
        value: "S62.10",
        label: "Closed Fracture of other carpal bone(s) - كسر مغلق في عظام الرسغ الأخرى غير الزورقي",
        clinics: ["Orthopedic"],
    },
    {
        value: "S62.11",
        label: "Open Fracture of other carpal bone(s) - كسر مفتوح في عظام الرسغ الأخرى غير الزورقي",
        clinics: ["Orthopedic"],
    },
    {
        value: "S62.20",
        label: "Closed Fracture of first metacarpal bone - كسر مغلق في عظم المشط الأول في اليد",
        clinics: ["Orthopedic"],
    },
    {
        value: "S62.21",
        label: "Open Fracture of first metacarpal bone - كسر مفتوح في عظم المشط الأول في اليد",
        clinics: ["Orthopedic"],
    },
    {
        value: "S62.30",
        label: "Closed Fracture of other metacarpal bone - كسر مغلق في عظام أمشاط اليد غير المشط الأول",
        clinics: ["Orthopedic"],
    },
    {
        value: "S62.31",
        label: "Open Fracture of other metacarpal bone - كسر مفتوح في عظام أمشاط اليد غير المشط الأول",
        clinics: ["Orthopedic"],
    },
    {
        value: "S62.40",
        label: "Closed Multiple fractures of metacarpal bones - كسور مغلقة متعددة في أمشاط اليد",
        clinics: ["Orthopedic"],
    },
    {
        value: "S62.41",
        label: "Open Multiple fractures of metacarpal bones - كسور مفتوحة متعددة في أمشاط اليد",
        clinics: ["Orthopedic"],
    },
    {
        value: "S62.50",
        label: "Closed Fracture of thumb - كسر مغلق في إبهام اليد",
        clinics: ["Orthopedic"],
    },
    {
        value: "S62.51",
        label: "Open Fracture of thumb - كسر مفتوح في إبهام اليد",
        clinics: ["Orthopedic"],
    },
    {
        value: "S62.60",
        label: "Closed Fracture of other finger - كسر مغلق في الأصابع الأخرى عدا الإبهام",
        clinics: ["Orthopedic"],
    },
    {
        value: "S62.61",
        label: "Open Fracture of other finger - كسر مفتوح في الأصابع الأخرى عدا الإبهام",
        clinics: ["Orthopedic"],
    },
    {
        value: "S62.70",
        label: "Closed Multiple fractures of fingers - كسور مغلقة متعددة في الأصابع",
        clinics: ["Orthopedic"],
    },
    {
        value: "S62.71",
        label: "Open Multiple fractures of fingers - كسور مفتوحة متعددة في الأصابع",
        clinics: ["Orthopedic"],
    },
    {
        value: "S63.0",
        label: "Dislocation of wrist - خلع الرسغ",
        clinics: ["Orthopedic", "Emergency General"],
    },
    {
        value: "S63.2",
        label: "Multiple dislocations of fingers - خلوع متعددة في الأصابع",
        clinics: ["Orthopedic", "Emergency General"],
    },
    {
        value: "S63.5",
        label: "Sprain and strain of wrist - إلتواء و وثي في المعصم",
        clinics: ["Orthopedic"],
    },
    {
        value: "S63.6",
        label: "Sprain and strain of finger(s) - إلتواء و وثي في الأصابع",
        clinics: ["Orthopedic"],
    },
    {
        value: "S82.00",
        label: "Closed Fracture of patella - كسر مغلق في الرضفة",
        clinics: ["Orthopedic"],
    },
    {
        value: "S82.01",
        label: "Open Fracture of patella - كسر مفتوح في الرضفة",
        clinics: ["Orthopedic"],
    },
    {
        value: "S82.10",
        label: "Closed Fracture of upper end of tibia - كسر مغلق في النهاية العلوية للظنبوب",
        clinics: ["Orthopedic"],
    },
    {
        value: "S82.11",
        label: "Open Fracture of upper end of tibia - كسر مفتوح في النهاية العلوية للظنبوب",
        clinics: ["Orthopedic"],
    },
    {
        value: "S82.20",
        label: "Closed Fracture of shaft of tibia - كسر مغلق في جسم الظنبوب",
        clinics: ["Orthopedic"],
    },
    {
        value: "S82.21",
        label: "OpenFracture of shaft of tibia - كسر مفتوح في جسم الظنبوب",
        clinics: ["Orthopedic"],
    },
    {
        value: "S82.30",
        label: "Closed Fracture of lower end of tibia - كسر مغلق في النهاية السفلية للظنبوب",
        clinics: ["Orthopedic"],
    },
    {
        value: "S82.31",
        label: "Open Fracture of lower end of tibia - كسر مفتوح في النهاية السفلية للظنبوب",
        clinics: ["Orthopedic"],
    },
    {
        value: "S82.40",
        label: "Closed Fracture of fibula alone - كسر مغلق في الشظية",
        clinics: ["Orthopedic"],
    },
    {
        value: "S82.41",
        label: "Open Fracture of fibula alone - كسر مفتوح في الشظية",
        clinics: ["Orthopedic"],
    },
    {
        value: "S82.50",
        label: "Closed Fracture of medial malleolus - كسر مغلق في الكعب الأنسي",
        clinics: ["Orthopedic"],
    },
    {
        value: "S82.51",
        label: "Open Fracture of medial malleolus - كسر مفتوح في الكعب الأنسي",
        clinics: ["Orthopedic"],
    },
    {
        value: "S82.60",
        label: "Closed Fracture of lateral malleolus - كسر مغلق في الكعب الوحشي",
        clinics: ["Orthopedic"],
    },
    {
        value: "S82.61",
        label: "Open Fracture of lateral malleolus - كسر مفتوح في الكعب الوحشي",
        clinics: ["Orthopedic"],
    },
    {
        value: "S82.70",
        label: "Closed Multiple fractures of lower leg - كسور مغلقة متعددة في الساق",
        clinics: ["Orthopedic"],
    },
    {
        value: "S82.71",
        label: "Open Multiple fractures of lower leg - كسور مفتوحة متعددة في الساق",
        clinics: ["Orthopedic"],
    },
    {
        value: "S83.0",
        label: "Dislocation of patella - خلع الرضفة",
        clinics: ["Orthopedic"],
    },
    {
        value: "S83.1",
        label: "Dislocation of knee - خلع الركبة",
        clinics: ["Orthopedic", "Emergency General"],
    },
    {
        value: "S83.2",
        label: "Tear of meniscus, current - تمزق في الرباط الهلالي",
        clinics: ["Orthopedic"],
    },
    {
        value: "S83.3",
        label: "Tear of articular cartilage of knee, current - تمزق في الغضروف المفصلي للركبة",
        clinics: ["Orthopedic"],
    },
    {
        value: "S83.4",
        label: "Sprain and strain involving (fibular)(tibial) collateral ligament of knee - إلتواء و وثي في الرباط الجانبي للركبة (شظوي) (ظنبوبي)",
        clinics: ["Orthopedic"],
    },
    {
        value: "S83.5",
        label: "Sprain and strain involving (anterior)(posterior) cruciate ligament of knee - إلتواء و وثي في الرباط المتصالب للركبة (أمامي) (خلفيي)",
        clinics: ["Orthopedic"],
    },
    {
        value: "S92.00",
        label: "Closed Fracture of calcaneus - كسر مغلق في العقب",
        clinics: ["Orthopedic"],
    },
    {
        value: "S92.01",
        label: "Open Fracture of calcaneus - كسر مفتوح في العقب",
        clinics: ["Orthopedic"],
    },
    {
        value: "S92.10",
        label: "Closed Fracture of talus - كسر مغلق في الكاحل",
        clinics: ["Orthopedic"],
    },
    {
        value: "S92.11",
        label: "Open Fracture of talus - كسر مفتوح في الكاحل",
        clinics: ["Orthopedic"],
    },
    {
        value: "S92.20",
        label: "Closed Fracture of other tarsal bone(s) - كسر مغلق في أحد عظام الرصغ",
        clinics: ["Orthopedic"],
    },
    {
        value: "S92.21",
        label: "Open Fracture of other tarsal bone(s) - كسر مفتوح في أحد عظام الرصغ",
        clinics: ["Orthopedic"],
    },
    {
        value: "S92.30",
        label: "Closed Fracture of metatarsal bone - كسر مغلق في مشط القدم",
        clinics: ["Orthopedic"],
    },
    {
        value: "S92.31",
        label: "Open Fracture of metatarsal bone - كسر مفتوح في مشط القدم",
        clinics: ["Orthopedic"],
    },
    {
        value: "S92.40",
        label: "Closed Fracture of great toe - كسر مغلق في إبهام القدم",
        clinics: ["Orthopedic"],
    },
    {
        value: "S92.41",
        label: "Open Fracture of great toe - كسر مفتوح في إبهام القدم",
        clinics: ["Orthopedic"],
    },
    {
        value: "S92.50",
        label: "Closed Fracture of other toe - كسر مغلق في أصابع القدم غير الإبهام",
        clinics: ["Orthopedic"],
    },
    {
        value: "S92.51",
        label: "Open Fracture of other toe - كسر مفتوح في أصابع القدم غير الإبهام",
        clinics: ["Orthopedic"],
    },
    {
        value: "S92.70",
        label: "Closed Multiple fractures of foot - كسور مغلقة متعددة في القدم",
        clinics: ["Orthopedic"],
    },
    {
        value: "S92.71",
        label: "Open Multiple fractures of foot - كسور مفتوحة متعددة في القدم",
        clinics: ["Orthopedic"],
    },
    {
        value: "S93.0",
        label: "Dislocation of ankle joint - خلع مفصل الكاحل",
        clinics: ["Orthopedic", "Emergency General"],
    },
    {
        value: "S93.1",
        label: "Dislocation of toe(s) - خلع في أصابع القدم",
        clinics: ["Orthopedic", "Emergency General"],
    },
    {
        value: "S93.5",
        label: "Sprain and strain of toe(s) - إلتواء و وثي في أصابع القدم",
        clinics: ["Orthopedic", "Emergency General"],
    },
    {
        value: "C41.3",
        label: "Malignant neoplasm of Ribs, sternum and clavicle - ورم خبيث في الأضلاع، القص، الترقوة",
        clinics: ["Orthopedic", "ThoracicSurgery"],
    },
    {
        value: "C41.4",
        label: "Malignant neoplasm of Pelvic bones, sacrum and coccyx - ورم خبيث في عظام الحوض، العجز، العصعص",
        clinics: ["Orthopedic"],
    },
    {
        value: "C40.0",
        label: "Malignant neoplasm of Scapula and long bones of upper limb - ورم خبيث في عظام الكتف والعظام الطويلة للطرف العلوي",
        clinics: ["Orthopedic"],
    },
    {
        value: "C40.1",
        label: "Malignant neoplasm of Short bones of upper limb - ورم خبيث في العظام القصيرة للطرف العلوي",
        clinics: ["Orthopedic"],
    },
    {
        value: "C40.2",
        label: "Malignant neoplasm of Long bones of lower limb - ورم خبيث في العظام الطويلة للطرف السفلي",
        clinics: ["Orthopedic"],
    },
    {
        value: "C40.3",
        label: "Malignant neoplasm of Short bones of lower limb - ورم خبيث في العظام القصيرة للطرف السفلي",
        clinics: ["Orthopedic"],
    },
    {
        value: "D16.0",
        label: "Benign neoplasm of Scapula and long bones of upper limb - ورم حميد في عظام الكتف والعظام الطويلة للطرف العلوي",
        clinics: ["Orthopedic"],
    },
    {
        value: "D16.1",
        label: "Benign neoplasm of Short bones of upper limb - ورم حميد في العظام القصيرة للطرف العلوي",
        clinics: ["Orthopedic"],
    },
    {
        value: "D16.2",
        label: "Benign neoplasm of Long bones of lower limb - ورم حميد في العظام الطويلة للطرف السفلي",
        clinics: ["Orthopedic"],
    },
    {
        value: "D16.3",
        label: "Benign neoplasm of Short bones of lower limb - ورم حميد في العظام القصيرة للطرف السفلي",
        clinics: ["Orthopedic"],
    },
    {
        value: "D16.7",
        label: "Benign neoplasm of Ribs, sternum and clavicle - ورم حميد في الأضلاع، القص، الترقوة",
        clinics: ["Orthopedic"],
    },
    {
        value: "D16.8",
        label: "Benign neoplasm of Pelvic bones, sacrum and coccyx - ورم حميد في عظام الحوض، العجز، العصعص",
        clinics: ["Orthopedic"],
    },
    {
        value: "G54.6",
        label: "Phantom limb syndrome with pain - متلازمة الطرف الشبح مع ألم",
        clinics: ["Orthopedic"],
    },
    {
        value: "G54.7",
        label: "Phantom limb syndrome without pain - متلازمة الطرف الشبح بدون ألم",
        clinics: ["Orthopedic"],
    },
    {
        value: "M02",
        label: "Reactive arthropathies - اعتلال المفاصل الارتكاسي",
        clinics: ["Orthopedic"],
    },
    {
        value: "M16",
        label: "Coxarthrosis [arthrosis of hip] - تنكس أو اعتلال مفصل الورك",
        clinics: ["Orthopedic"],
    },
    {
        value: "M17",
        label: "Gonarthrosis [arthrosis of knee] - تنكس أو اعتلال مفصل الركبة",
        clinics: ["Orthopedic"],
    },
    {
        value: "M18",
        label: "Arthrosis of first carpometacarpal joint - تنكس أو اعتلال المفصل الرسغي السنعي الأول",
        clinics: ["Orthopedic"],
    },
    {
        value: "A18.0",
        label: "Tuberculosis of bones and joints - سل العظام والمفاصل",
        clinics: ["Orthopedic"],
    },
    {
        value: "M86.0",
        label: "Acute haematogenous osteomyelitis - التهاب العظم و النقي الحاد دموي المنشأ",
        clinics: ["Orthopedic"],
    },
    {
        value: "M86.1",
        label: "Other acute osteomyelitis - التهابات العظم و النقي الحاد الأخرى",
        clinics: ["Orthopedic"],
    },
    {
        value: "M86.2",
        label: "Subacute osteomyelitis - التهاب العظم و النقي تحت الحاد",
        clinics: ["Orthopedic"],
    },
    {
        value: "M86.3",
        label: "Chronic multifocal osteomyelitis - التهاب العظم و النقي المزمن متعدد البؤر",
        clinics: ["Orthopedic"],
    },
    {
        value: "M86.5",
        label: "Other chronic haematogenous osteomyelitis - التهابات العظم و النقي المزمنة دموية المنشأ الأخرى",
        clinics: ["Orthopedic"],
    },
    {
        value: "M86.6",
        label: "Other chronic osteomyelitis - التهابات العظم و النقي المزمنة الأخرى",
        clinics: ["Orthopedic"],
    },
    {
        value: "M88",
        label: "Paget disease of bone [osteitis deformans] - داء باجيت في العظم، التهاب العظم المشوه",
        clinics: ["Orthopedic"],
    },
    {
        value: "M89.5",
        label: "Osteolysis - انحلال العظم",
        clinics: ["Orthopedic"],
    },
    { value: "M41", label: "Scoliosis - الجنف", clinics: ["Orthopedic"] },
    {
        value: "M43.0",
        label: "Spondylolysis - انحلال الفقار",
        clinics: ["Orthopedic"],
    },
    {
        value: "M48.0",
        label: "Spinal stenosis, Caudal stenosis - التضيق الشوكي، تضيق ذل الفرس",
        clinics: ["Orthopedic"],
    },
    {
        value: "M24.45",
        label: "recurrent dislocation of the hip - خلع الورك الناكس",
        clinics: ["Orthopedic", "Emergency General"],
    },
    {
        value: "Q74.9",
        label: "congenital deformities of the lower limb ( congenital abnormalities of the lower limb - تشوهات الطرف السفلي الخلقية",
        clinics: ["Orthopedic"],
    },
    {
        value: "M21.549",
        label: "club foot - القدم القفداء",
        clinics: ["Orthopedic"],
    },
    {
        value: "M21.6X9",
        label: "foot deformities  - تشوهات القدم",
        clinics: ["Orthopedic"],
    },
    {
        value: "Q68.1",
        label: "congenital deformities of the hand ( congenital abnormalities the hand - تشوهات اليد الخلقية",
        clinics: ["Orthopedic", "Plastic Surgery"],
    },
    {
        value: "Q89.9",
        label: "other congenital deformities (abnormalities) - التشوهات الولادية الأخرى",
        clinics: ["Orthopedic"],
    },
    {
        value: "Q78.0",
        label: "osteogenesis imperfecta - سوء تكون العظام الولادية",
        clinics: ["Orthopedic"],
    },
    {
        value: "D21.9",
        label: "Benign connective tissue tumor  - أورام النسج الضامة الحميدة",
        clinics: ["Orthopedic"],
    },
    {
        value: "C49.9",
        label: "malignant connective tissue tumor - أورام النسج الضامة الخبيثة",
        clinics: ["Orthopedic"],
    },
    {
        value: "L60.0",
        label: "ingrown nail - الظفر الناشب",
        clinics: ["Orthopedic"],
    },
    { value: "L03.01", label: "felon - داحث", clinics: ["Orthopedic"] },
    { value: "M21.40", label: "flat foot - قدم مسطحة", clinics: ["Orthopedic"] },
    {
        value: "M65.4",
        label: "de quervain disease - ديكروفان",
        clinics: ["Orthopedic"],
    },
    { value: "T14.90", label: "truma - رض", clinics: ["Orthopedic"] },
    {
        value: "S83.106",
        label: "knee dislocation  - خلع ركبة",
        clinics: ["Orthopedic", "Emergency General"],
    },
    {
        value: "L98.9",
        label: "skin disease  - أمراض جلدية",
        clinics: ["Orthopedic"],
    },
    { value: "L02.91", label: "absses - خراج", clinics: ["Orthopedic"] },
    {
        value: "M66.9",
        label: "tendon ruptures  - إنقطاع اوتار (اذية اوتار رضية)",
        clinics: ["Orthopedic"],
    },
    {
        value: "M21.949",
        label: "Unspecified acquired deformity of hand, unspecified hand - تشوهات اليد",
        clinics: ["Orthopedic"],
    },
    {
        value: "M24.419",
        label: " recurrent shoulder dislocation  - خلع الكتف الناكس",
        clinics: ["Orthopedic", "Emergency General"],
    },
    {
        value: "M22.00",
        label: "recurent patella dislocation  - خلع الرضفة الناكس",
        clinics: ["Orthopedic", "Emergency General"],
    },
    {
        value: "M93.1",
        label: "kienbock diseases  - مرض كينبوك",
        clinics: ["Orthopedic"],
    },
    {
        value: "M72.0",
        label: "dupuytren disease  - مرض دوبويترن",
        clinics: ["Orthopedic"],
    },
    {
        value: "M93.0",
        label: "slipped capital femoral epiphysis - انزلاق المشاش الفخذي العلوي ",
        clinics: ["Orthopedic"],
    },
    {
        value: "B07.0",
        label: "Plantar wart - ثآليل أخمصية ",
        clinics: ["Dermotology"],
    },
    {
        value: "B07.8",
        label: "Other viral warts - ثآليل فيروسية أخرى",
        clinics: ["Dermotology"],
    },
    {
        value: "B07.9",
        label: " Viral wart, unspecified  - ثآليل فيروسية غير محددة",
        clinics: ["Dermotology"],
    },
    {
        value: "B35.3",
        label: "Tinea pedis - سعفة القدم",
        clinics: ["Dermotology"],
    },
    {
        value: "B35.4",
        label: "Tinea corporis - سعفة جسد",
        clinics: ["Dermotology"],
    },
    {
        value: "B35.5",
        label: "Tinea imbricata - سعفة امبريكاتا",
        clinics: ["Dermotology"],
    },
    {
        value: "C84.0",
        label: " Mycosis fungoides - فطار فطاراني",
        clinics: ["Dermotology"],
    },
    {
        value: "D03.30",
        label: " Melanoma in situ of unspecified part of face  - ميلانوما في  الموضع على جزء غير محدد في الوجه",
        clinics: ["Dermotology"],
    },
    {
        value: "D03.39",
        label: " Melanoma in situ of other parts of face  - ميلانوما في الموقع على أجزاء أخرى في الوجه",
        clinics: ["Dermotology"],
    },
    {
        value: "D03.51",
        label: " Melanoma in situ of anal skin  - ميلانوما في الموقع على جلد الشرج",
        clinics: ["Dermotology"],
    },
    {
        value: "D03.52",
        label: " Melanoma in situ of breast (skin) (soft tissue)  - ميلانوما في الموقع على الثدي",
        clinics: ["Dermotology"],
    },
    {
        value: "D03.59",
        label: " Melanoma in situ of other part of trunk  - ميلانوما في الموقع على أجزاء أخرى من الجذع",
        clinics: ["Dermotology"],
    },
    {
        value: "C43.30",
        label: "Malignant melanoma of unspecified part of face - ميلانوما خبيثة في مكان غير محدد من الوجه",
        clinics: ["Dermotology"],
    },
    {
        value: "C43.51",
        label: "Malignant melanoma of anal skin - ميلانوما خبيثة عل جلد الشرج",
        clinics: ["Dermotology"],
    },
    {
        value: "C43.52",
        label: "Malignant melanoma of skin of breast - ميلانوما خبيثة عل جلد الثدي",
        clinics: ["Dermotology"],
    },
    {
        value: "C43.59",
        label: "Malignant melanoma of other part of trunk - ميلانوما خبيثة عل أجزاء أخرى من الجذع",
        clinics: ["Dermotology"],
    },
    {
        value: "C44.01",
        label: "Basal cell carcinoma of skin of lip - سرطانة قاعدية الخلايا عل الشفة",
        clinics: ["Dermotology"],
    },
    {
        value: "C44.02",
        label: "Squamous cell carcinoma of skin of lip - سرطانة شائكة الخلايا عل الشفة",
        clinics: ["Dermotology"],
    },
    {
        value: "C44.111",
        label: "Basal cell carcinoma of skin of unspecified eyelid - سرطانة قاعدية الخلايا على الجفن",
        clinics: ["Dermotology"],
    },
    {
        value: "C44.121",
        label: "Squamous cell carcinoma of skin of unspecified eyelid - سرطانة شائكة الخلايا على الجفن",
        clinics: ["Dermotology"],
    },
    {
        value: "C44.211",
        label: "Basal cell carcinoma of skin of unspecified ear - سرطانة قاعدية الخلايا على الأذن",
        clinics: ["Dermotology"],
    },
    {
        value: "C44.291",
        label: "Other specified malignant neoplasm of skin of unspecified ear - أورام خبيثة أخرى محددة على الأذن",
        clinics: ["Dermotology"],
    },
    {
        value: "C44.300",
        label: "Unspecified malignant neoplasm of skin of unspecified part of face - أورام خبيثة أخرى غير محددة على الوجه",
        clinics: ["Dermotology"],
    },
    {
        value: "C44.310",
        label: "Basal cell carcinoma of skin of unspecified parts of face - سرطانة قاعدية الخلايا على الوجه",
        clinics: ["Dermotology"],
    },
    {
        value: "C44.311",
        label: "Basal cell carcinoma of skin of nose - سرطانة قاعدية الخلايا على الأنف",
        clinics: ["Dermotology"],
    },
    {
        value: "C44.321",
        label: "Squamous cell carcinoma of skin of nose - سرطانة شائكة الخلايا على الأنف",
        clinics: ["Dermotology"],
    },
    {
        value: "C44.40",
        label: "Unspecified malignant neoplasm of skin of scalp and neck - سرطانة خبيثة على الفروة او الرقبة",
        clinics: ["Dermotology"],
    },
    {
        value: "C44.41",
        label: "Basal cell carcinoma of skin of scalp and neck - سرطانة قاعدية الخلايا على الفروة او الرقبة",
        clinics: ["Dermotology"],
    },
    {
        value: "C44.500",
        label: "Unspecified malignant neoplasm of anal skin - اورام خبيثة غير محددة على جلد الشرج",
        clinics: ["Dermotology"],
    },
    {
        value: "C44.501",
        label: "Unspecified malignant neoplasm of skin of breast - اورام خبيثة غير محددة على جلد الثدي",
        clinics: ["Dermotology"],
    },
    {
        value: "C44.509",
        label: "Unspecified malignant neoplasm of skin of other part of trunk - اورام خبيثة غير محددة على أجزاء أخرى من الجذع",
        clinics: ["Dermotology"],
    },
    {
        value: "C44.510",
        label: "Basal cell carcinoma of anal skin - سرطانة قاعدية الخلايا على جلد الشرج",
        clinics: ["Dermotology"],
    },
    {
        value: "C44.511",
        label: "Basal cell carcinoma of skin of breast - سرطانة قاعدية الخلايا على جلد الثدي",
        clinics: ["Dermotology"],
    },
    {
        value: "C44.519",
        label: "Basal cell carcinoma of skin of other part of trunk - سرطانة قاعدية الخلايا على أجزاء أخرى من الجذع",
        clinics: ["Dermotology"],
    },
    {
        value: "D18.01",
        label: "Hemangioma of skin and subcutaneous tissue - الأورام الدموية للجلد وللنسيج تحت الجلد",
        clinics: ["Dermotology"],
    },
    {
        value: "D22.5",
        label: "Melanocytic nevi of trunk - وحمة ميلانية على الجذع",
        clinics: ["Dermotology"],
    },
    {
        value: "I83.10",
        label: "Varicose veins - دوالي وريدية",
        clinics: ["Dermotology"],
    },
    {
        value: "I87.2",
        label: "Venous insufficiency (chronic) (peripheral) - قصور وريدي مزمن محيطي",
        clinics: ["Dermotology"],
    },
    {
        value: "L40.0",
        label: "Psoriasis vulgaris - الصداف الشائع",
        clinics: ["Dermotology"],
    },
    {
        value: "L40.1",
        label: "Generalized pustular psoriasis - الصداف البثري المعمم",
        clinics: ["Dermotology"],
    },
    {
        value: "L40.2",
        label: "Acrodermatitis continua - التهاب جلد النهايات المستمر",
        clinics: ["Dermotology"],
    },
    {
        value: "L40.3",
        label: "Pustulosis palmaris et plantaris - البثار الراحي الاخمصي",
        clinics: ["Dermotology"],
    },
    {
        value: "L40.4",
        label: "Guttate psoriasis - الصداف النقطي",
        clinics: ["Dermotology"],
    },
    {
        value: "L40.50",
        label: "Arthropathic psoriasis unspecified - الصداف المفصلي غير المحدد",
        clinics: ["Dermotology"],
    },
    {
        value: "L40.51",
        label: "Distal interphalangeal psoriatic arthropathy - اعتلال المفاصل الصدافي للسلاميات البعيدة",
        clinics: ["Dermotology"],
    },
    {
        value: "L40.53",
        label: "Psoriatic spondylitis - التهاب الفقار الصدفي",
        clinics: ["Dermotology"],
    },
    {
        value: "L40.54",
        label: "Psoriatic juvenile arthropathy - اعتلال المفاصل الصدافي الشبابي",
        clinics: ["Dermotology"],
    },
    {
        value: "L40.8",
        label: "Other psoriasis - أنواع أخرى من الصداف",
        clinics: ["Dermotology"],
    },
    {
        value: "L43.0",
        label: "Hypertrophic lichen planus - الحزاز المسطح الضخامي",
        clinics: ["Dermotology"],
    },
    {
        value: "L43.1",
        label: "Bullous lichen planus - الحزاز المسطح الفقاعي",
        clinics: ["Dermotology"],
    },
    {
        value: "L43.2",
        label: "Lichenoid drug reaction - الاندفاع الدوائي الحزازاني",
        clinics: ["Dermotology"],
    },
    {
        value: "L43.3",
        label: "Subacute (active) lichen planus - الحزاز المسطح تحت الحاد",
        clinics: ["Dermotology"],
    },
    {
        value: "L43.8",
        label: "Other lichen planus - أنواع أخرى من الحزاز المسطح",
        clinics: ["Dermotology"],
    },
    {
        value: "L44.0",
        label: "Pityriasis rubra pilaris - النخالية الشعروية الحمراء",
        clinics: ["Dermotology"],
    },
    {
        value: "L81.0",
        label: "Postinflammatory hyperpigmentation - فرط التصبغ التالي للالتهاب",
        clinics: ["Dermotology"],
    },
    { value: "L81.1", label: "Chloasma - الكلف", clinics: ["Dermotology"] },
    { value: "L81.2", label: "Freckles - النمش", clinics: ["Dermotology"] },
    {
        value: "L81.3",
        label: "Café au lait spots  - بقعة قهوة بالحليب",
        clinics: ["Dermotology"],
    },
    {
        value: "L81.4",
        label: "Other melanin hyperpigmentation - اضطرابات فرط التصبغ الأخرى",
        clinics: ["Dermotology"],
    },
    {
        value: "L81.7",
        label: "Pigmented purpuric dermatosis - الجلاد الحاك المصطبغ",
        clinics: ["Dermotology"],
    },
    {
        value: "L82.0",
        label: "Inflamed seborrheic keratosis - التقران الزهمي الالتهابي",
        clinics: ["Dermotology"],
    },
    {
        value: "L82.1",
        label: "Other seborrheic keratosis - التقرانات الزهمية الأخرى",
        clinics: ["Dermotology"],
    },
    {
        value: "L85.3",
        label: "Xerosis cutis - جفاف الجلد المصطبغ",
        clinics: ["Dermotology"],
    },
    {
        value: "L90.0",
        label: "Lichen sclerosus et atrophicus - الحزاز التصلبي الضموري",
        clinics: ["Dermotology"],
    },
    {
        value: "L90.3",
        label: "Atrophoderma of Pasini and Pierini - ضمور الجلد باسيني وبيريني",
        clinics: ["Dermotology"],
    },
    {
        value: "L90.4",
        label: "Acrodermatitis chronica atrophicans - التهاب جلد النهايات الضموري المزمن",
        clinics: ["Dermotology"],
    },
    {
        value: "L90.5",
        label: "Scar conditions and fibrosis of skin - حالات التندب والتليف في الجلد",
        clinics: ["Dermotology", "Plastic Surgery"],
    },
    {
        value: "L90.6",
        label: "Striae atrophicae - الخطوط الضمورية ( الفزر الجلدية )",
        clinics: ["Dermotology"],
    },
    {
        value: "L90.8",
        label: "Other atrophic disorders of skin - الضمورات الأخرى في الجلد",
        clinics: ["Dermotology"],
    },
    {
        value: "L91.0",
        label: "Hypertrophic scar - الندب الضخامية",
        clinics: ["Dermotology"],
    },
    {
        value: "L91.8",
        label: "Other hypertrophic disorders of the skin - اضطرابات ضخامية أخرى في الجلد",
        clinics: ["Dermotology"],
    },
    {
        value: "L92.0",
        label: "Granuloma annulare - الحبيبوم الحلقي",
        clinics: ["Dermotology"],
    },
    {
        value: "L94.0",
        label: "Localized scleroderma [morphea] - صلابة الجلد الموضعة ( مورفيا )",
        clinics: ["Dermotology"],
    },
    {
        value: "L94.1",
        label: "Linear scleroderma - صلابة الجلد الخطية",
        clinics: ["Dermotology"],
    },
    {
        value: "L94.3",
        label: "Sclerodactyly - صلابة الأصابع",
        clinics: ["Dermotology"],
    },
    {
        value: "L95.1",
        label: "Erythema elevatum diutinum - الحمامى المرتفعة الدائمة",
        clinics: ["Dermotology"],
    },
    {
        value: "L98.2",
        label: "Febrile neutrophilic dermatosis [Sweet] - الجلاد الحموي الحاد ( سويت )",
        clinics: ["Dermotology"],
    },
    {
        value: "L98.419",
        label: "Non-pressure chronic ulcer of buttock - قرحة غير ناتجة عن الضغط على الالية",
        clinics: ["Dermotology"],
    },
    {
        value: "L20.81",
        label: "Atopic neurodermatitis - التهاب الجلد العصبي التأتبي",
        clinics: ["Dermotology"],
    },
    {
        value: "L20.82",
        label: "Flexural eczema - اكزيما الثنايا",
        clinics: ["Dermotology"],
    },
    {
        value: "L20.83",
        label: "Infantile (acute) (chronic) eczema - الأكزيما ( حادة ) ( مزمنة ) لدى الأطفال",
        clinics: ["Dermotology"],
    },
    {
        value: "L20.84",
        label: "Intrinsic (allergic) eczema - الاكزيما الاليرجائية",
        clinics: ["Dermotology"],
    },
    {
        value: "L20.89",
        label: "Other atopic dermatitis - التهاب الجلد الاكزيمائي الأخرى",
        clinics: ["Dermotology"],
    },
    {
        value: "L21.0",
        label: "Seborrhea capitis - اكزيما زهمية على الفروة",
        clinics: ["Dermotology"],
    },
    {
        value: "L21.1",
        label: "Seborrheic infantile dermatitis - الاكزيما الزهمية عند الأطفال",
        clinics: ["Dermotology"],
    },
    {
        value: "L21.8",
        label: "Other seborrheic dermatitis - الأنواع الأخرى من الاكزيما الزهمية",
        clinics: ["Dermotology"],
    },
    {
        value: "L26",
        label: "Exfoliative dermatitis - التهاب الجلد الموسف",
        clinics: ["Dermotology"],
    },
    {
        value: "L27.0",
        label: "Generalized skin eruption due to drugs and medicaments taken internally - طفح جلدي معمم دوائي",
        clinics: ["Dermotology"],
    },
    {
        value: "L27.1",
        label: "Localized skin eruption due to drugs and medicaments taken internally - طفح جلدي موضع دوائي",
        clinics: ["Dermotology"],
    },
    {
        value: "L28.0",
        label: "Lichen simplex chronicus - حزاز بسيط مزمن",
        clinics: ["Dermotology"],
    },
    {
        value: "L28.1",
        label: "Prurigo nodularis - حكاك عقيدي",
        clinics: ["Dermotology"],
    },
    {
        value: "L29.0",
        label: "Pruritus ani - حكة شرجية",
        clinics: ["Dermotology"],
    },
    {
        value: "L29.2",
        label: "Pruritus vulvae - حكة فرجية",
        clinics: ["Dermotology"],
    },
    {
        value: "L29.9",
        label: "Pruritus unspecified - حكة غير محددة",
        clinics: ["Dermotology"],
    },
    {
        value: "L63.0",
        label: "Alopecia (capitis) totalis - الحاصة الكلية",
        clinics: ["Dermotology"],
    },
    {
        value: "L63.1",
        label: "Alopecia universalis - الحاصة الشاملة",
        clinics: ["Dermotology"],
    },
    {
        value: "L64.0",
        label: "Drug-induced androgenic alopecia - الحاصة الاندروجينية المحرضة بالدواء",
        clinics: ["Dermotology"],
    },
    {
        value: "L64.8",
        label: "Other androgenic alopecia - الحاصات الاندروجينية الأخرى",
        clinics: ["Dermotology"],
    },
    {
        value: "L65.0",
        label: "Telogen effluvium - نفحة طور الراحة",
        clinics: ["Dermotology"],
    },
    {
        value: "L65.1",
        label: "Anagen effluvium - نفحة طور النمو",
        clinics: ["Dermotology"],
    },
    {
        value: "L65.2",
        label: "Alopecia mucinosa - الحاصة المخاطية ( موسينية )",
        clinics: ["Dermotology"],
    },
    {
        value: "L65.8",
        label: "Other specified nonscarring hair loss - تساقط الشعر غير المندب (الأخرى)",
        clinics: ["Dermotology"],
    },
    {
        value: "L66.1",
        label: "Lichen planopilaris - الحزاز المسطح الشعري",
        clinics: ["Dermotology"],
    },
    {
        value: "L70.0",
        label: "Acne vulgaris - العد الشائع",
        clinics: ["Dermotology"],
    },
    {
        value: "L70.1",
        label: "Acne conglobata - العد المكبب",
        clinics: ["Dermotology"],
    },
    {
        value: "L70.2",
        label: "Acne varioliformis - العد الجدري",
        clinics: ["Dermotology"],
    },
    {
        value: "L70.3",
        label: "Acne tropica - العد المداري",
        clinics: ["Dermotology"],
    },
    {
        value: "L70.4",
        label: "Infantile acne - عد الرضيع",
        clinics: ["Dermotology"],
    },
    {
        value: "L70.8",
        label: "Other acne - أنواع أخرى من العد",
        clinics: ["Dermotology"],
    },
    {
        value: "L71.0",
        label: "Perioral dermatitis - التهاب ما حول الفم",
        clinics: ["Dermotology"],
    },
    {
        value: "L71.1",
        label: "Rhinophyma - فيمة الانف",
        clinics: ["Dermotology"],
    },
    {
        value: "L71.8",
        label: "Other rosacea - انواع أخرى من الوردية",
        clinics: ["Dermotology"],
    },
    {
        value: "L72.3",
        label: "Sebaceous cyst - كيسة دهنية",
        clinics: ["Dermotology", "Plastic Surgery"],
    },
    {
        value: "L73.0",
        label: "Acne keloid - جدرة ناتجة عن العد",
        clinics: ["Dermotology"],
    },
    {
        value: "L12.0",
        label: "Bullous pemphigoid - الفقاع الفقاعاني",
        clinics: ["Dermotology"],
    },
    {
        value: "L12.1",
        label: "Cicatricial pemphigoid - الفقاع التندبي",
        clinics: ["Dermotology"],
    },
    {
        value: "L12.2",
        label: "Chronic bullous disease of childhood - الداء المزمن الفقاعي عند الأطفال",
        clinics: ["Dermotology"],
    },
    {
        value: "L12.8",
        label: "Other pemphigoid - أنواع أخرى من الفقاعاني",
        clinics: ["Dermotology"],
    },
    {
        value: "L13.0",
        label: "Dermatitis herpetiformis - التهاب الجلد حلئي الشكل",
        clinics: ["Dermotology"],
    },
    {
        value: "L56.4",
        label: "Polymorphous light eruption - اندفاع الضيائي عديد الأشكال",
        clinics: ["Dermotology"],
    },
    {
        value: "L56.8",
        label: "Other specified acute skin changes due to ultraviolet radiation - أنواع أخرى من الاضطرابات الجلدية الحادة بسبب الاشعة فوق البنفسجية",
        clinics: ["Dermotology"],
    },
    {
        value: "L57.0",
        label: "Actinic keratosis - التقران السفعي",
        clinics: ["Dermotology"],
    },
    {
        value: "R21",
        label: "Rash and other nonspecific skin eruption - أنواع أخرى من الاندفاع والطفح الجلدي",
        clinics: ["Dermotology"],
    },
    {
        value: "B34.9",
        label: "Viral infection. unspecified - عدوى فيروسية. غير محدد",
        clinics: ["Emergency General"],
    },
    {
        value: "E86.0",
        label: "Dehydration - تجفيف",
        clinics: ["Emergency General"],
    },
    {
        value: "F41.1",
        label: "Generalized anxiety disorder - اضطراب القلق المعمم",
        clinics: ["Emergency General"],
    },
    {
        value: "F32.9",
        label: "Major depressive disorder. single episode. unspecified - اضطراب اكتئابي حاد. حلقة واحدة. غير محدد",
        clinics: ["Emergency General"],
    },
    {
        value: "F10.10",
        label: "Alcohol abuse. uncomplicated - مدمن كحول. غير معقد",
        clinics: ["Emergency General"],
    },
    {
        value: "F10.120",
        label: "Alcohol abuse with intoxication. uncomplicated - تعاطي الكحول مع التسمم. غير معقد",
        clinics: ["Emergency General"],
    },
    {
        value: "F10.129",
        label: "Alcohol abuse with intoxication. unspecified - تعاطي الكحول مع التسمم. غير محدد",
        clinics: ["Emergency General"],
    },
    {
        value: "G93.3",
        label: "Postviral fatigue syndrome - متلازمة التعب التالي للفيروسات",
        clinics: ["Emergency General"],
    },
    {
        value: "G43.109",
        label: "Migraine with aura. not intractable. without status migrainosus - صداع نصفي مع هالة. لا يستعصي على الحل. بدون حالة الصداع النصفي",
        clinics: ["Emergency General", "Neurology"],
    },
    {
        value: "I48.91",
        label: "Unspecified atrial fibrillation - الرجفان الأذيني غير المحدد",
        clinics: ["Emergency General"],
    },
    {
        value: "J06.9",
        label: "Acute upper respiratory infection. unspecified - عدوى الجهاز التنفسي العلوي الحادة. غير محدد",
        clinics: ["Emergency General"],
    },
    {
        value: "K08.539",
        label: "Fractured dental restorative material. unspecified - مادة ترميمية للأسنان المكسورة. غير محدد",
        clinics: ["Emergency General"],
    },
    {
        value: "K59.00",
        label: "Constipation. unspecified - إمساك. غير محدد",
        clinics: ["Emergency General"],
    },
    {
        value: "L03.119",
        label: "Cellulitis of unspecified part of limb - التهاب النسيج الخلوي في جزء غير محدد من الطرف",
        clinics: ["Emergency General"],
    },
    {
        value: "L03.129",
        label: "Acute lymphangitis of unspecified part of limb - التهاب الأوعية اللمفية الحاد في جزء غير محدد من الأطراف",
        clinics: ["Emergency General"],
    },
    {
        value: "M25.569",
        label: "Pain in unspecified knee - ألم في الركبة غير محدد",
        clinics: ["Emergency General"],
    },
    {
        value: "M53.3",
        label: "Sacrococcygeal disorders. not elsewhere classified - اضطرابات العصعص. لم تصنف في مكان آخر",
        clinics: ["Emergency General"],
    },
    {
        value: "M54.9",
        label: "Dorsalgia. unspecified - الظهر. غير محدد",
        clinics: ["Emergency General"],
    },
    {
        value: "M79.606",
        label: "Pain in leg. unspecified - ألم في الساق. غير محدد",
        clinics: ["Emergency General"],
    },
    {
        value: "M79.609",
        label: "Pain in unspecified limb - ألم في طرف غير محدد",
        clinics: ["Emergency General"],
    },
    {
        value: "M79.629",
        label: "Pain in unspecified upper arm - ألم في أعلى الذراع غير محدد",
        clinics: ["Emergency General"],
    },
    {
        value: "M79.639",
        label: "Pain in unspecified forearm - ألم في الساعد غير محدد",
        clinics: ["Emergency General"],
    },
    {
        value: "M79.643",
        label: "Pain in unspecified hand - ألم في يد غير محددة",
        clinics: ["Emergency General"],
    },
    {
        value: "M79.646",
        label: "Pain in unspecified finger(s) - ألم في إصبع (أصابع) غير محدد",
        clinics: ["Emergency General"],
    },
    {
        value: "M79.659",
        label: "Pain in unspecified thigh - ألم في الفخذ غير محدد",
        clinics: ["Emergency General"],
    },
    {
        value: "M79.669",
        label: "Pain in unspecified lower leg - ألم في أسفل الساق غير محدد",
        clinics: ["Emergency General"],
    },
    {
        value: "M79.673",
        label: "Pain in unspecified foot - ألم في قدم غير محدد",
        clinics: ["Emergency General"],
    },
    {
        value: "M79.676",
        label: "Pain in unspecified toe(s) - ألم في إصبع غير محدد",
        clinics: ["Emergency General"],
    },
    {
        value: "N15.9",
        label: "Renal tubulo-interstitial disease. unspecified - مرض أنبوبي خلالي كلوي. غير محدد",
        clinics: ["Emergency General"],
    },
    {
        value: "N30.00",
        label: "Acute cystitis without hematuria - التهاب المثانة الحاد بدون بيلة دموية",
        clinics: ["Emergency General"],
    },
    {
        value: "N30.90",
        label: "Cystitis. unspecified without hematuria - التهاب المثانة. غير محدد بدون بيلة دموية",
        clinics: ["Emergency General"],
    },
    {
        value: "N30.91",
        label: "Cystitis. unspecified with hematuria - التهاب المثانة. غير محدد مع بيلة دموية",
        clinics: ["Emergency General"],
    },
    { value: "R53.1", label: "Weakness - ضعف", clinics: ["Emergency General"] },
    {
        value: "R56.1",
        label: "Post traumatic seizures - نوبات ما بعد الصدمة",
        clinics: ["Emergency General"],
    },
    {
        value: "R56.9",
        label: "Unspecified convulsions - تشنجات غير محددة",
        clinics: ["Emergency General"],
    },
    {
        value: "R41.0",
        label: "Disorientation. unspecified - الارتباك. غير محدد",
        clinics: ["Emergency General"],
    },
    {
        value: "R41.840",
        label: "Attention and concentration deficit - نقص الانتباه والتركيز",
        clinics: ["Emergency General"],
    },
    {
        value: "R42",
        label: "Dizziness and giddiness - الدوخة والدوار",
        clinics: ["Emergency General"],
    },
    {
        value: "R10.13",
        label: "Epigastric pain - ألم شرسوفي",
        clinics: ["Emergency General"],
    },
    {
        value: "R10.2",
        label: "Pelvic and perineal pain - آلام الحوض والعجان",
        clinics: ["Emergency General"],
    },
    {
        value: "R10.30",
        label: "Lower abdominal pain. unspecified - آلام أسفل البطن. غير محدد",
        clinics: ["Emergency General"],
    },
    {
        value: "R11.11",
        label: "Vomiting without nausea - القيء بدون غثيان",
        clinics: ["Emergency General"],
    },
    {
        value: "R11.2",
        label: "Nausea with vomiting. unspecified - غثيان مع قيء. غير محدد",
        clinics: ["Emergency General"],
    },
    { value: "R05", label: "Cough - سعال", clinics: ["Emergency General"] },
    {
        value: "R06.00",
        label: "Dyspnea. unspecified - ضيق التنفس. غير محدد",
        clinics: ["Emergency General"],
    },
    {
        value: "R06.3",
        label: "Periodic breathing - التنفس الدوري",
        clinics: ["Emergency General", "Neurology"],
    },
    {
        value: "R06.4",
        label: "Hyperventilation - حالة فرط تهوية",
        clinics: ["Emergency General", "Neurology"],
    },
    {
        value: "R06",
        label: "abnormalities of breathing - اضطرابات أي التنفس",
        clinics: ["Emergency General", "Neurology"],
    },
    {
        value: "R07.9",
        label: "Chest pain. unspecified - ألم صدر. غير محدد",
        clinics: ["Emergency General"],
    },
    {
        value: "S93.499S",
        label: "Sprain of other ligament of unspecified ankle. sequela - التواء في أربطة أخرى من الكاحل غير المحدد. تكملة",
        clinics: ["Emergency General"],
    },
    {
        value: "S83.419D",
        label: "Sprain of medial collateral ligament of unspecified knee. subsequent encounter - التواء في الرباط الجانبي الإنسي للركبة غير المحددة. لقاء لاحق",
        clinics: ["Emergency General"],
    },
    {
        value: "S10.83XS",
        label: "Contusion of other specified part of neck. sequela - كدمة في جزء محدد آخر من الرقبة. تكملة",
        clinics: ["Emergency General"],
    },
    {
        value: "S13.4XXS",
        label: "Sprain of ligaments of cervical spine. sequela - التواء في أربطة العمود الفقري العنقي. تكملة",
        clinics: ["Emergency General"],
    },
    {
        value: "S00.03XD",
        label: "Contusion of scalp. subsequent encounter - كدمة في فروة الرأس. لقاء لاحق",
        clinics: ["Emergency General"],
    },
    {
        value: "S00.33XS",
        label: "Contusion of nose. sequela - كدمة في الأنف. تكملة",
        clinics: ["Emergency General"],
    },
    {
        value: "S00.439S",
        label: "Contusion of unspecified ear. sequela - كدمة أذن غير محددة. تكملة",
        clinics: ["Emergency General"],
    },
    {
        value: "S00.531S",
        label: "Contusion of lip. sequela - كدمة في الشفة. تكملة",
        clinics: ["Emergency General"],
    },
    {
        value: "S00.83XS",
        label: "Contusion of other part of head. sequela - كدمة في جزء آخر من الرأس. تكملة",
        clinics: ["Emergency General"],
    },
    {
        value: "S00.93XS",
        label: "Contusion of unspecified part of head. sequela - كدمة في جزء غير محدد من الرأس. تكملة",
        clinics: ["Emergency General"],
    },
    {
        value: "S06.9X9S",
        label: "Unspecified intracranial injury with loss of consciousness of unspecified duration. sequela - إصابة داخل الجمجمة غير محددة مع فقدان الوعي لمدة غير محددة. تكملة",
        clinics: ["Emergency General"],
    },
    {
        value: "S09.90XS",
        label: "Unspecified injury of head. sequela - إصابة غير محددة في الرأس. تكملة",
        clinics: ["Emergency General"],
    },
    {
        value: "S61.009S",
        label: "Unspecified open wound of unspecified thumb without damage to nail. sequela - جرح مفتوح غير محدد في إبهام غير محدد دون تلف الظفر. تكملة",
        clinics: ["Emergency General"],
    },
    {
        value: "S61.019S",
        label: "Laceration without foreign body of unspecified thumb without damage to nail. sequela - تمزق بدون جسم غريب من الإبهام غير المحدد دون الإضرار بالأظافر. تكملة",
        clinics: ["Emergency General"],
    },
    {
        value: "S61.022S",
        label: "Laceration with foreign body of left thumb without damage to nail. sequela - تمزق مع جسم غريب من الإبهام الأيسر دون إتلاف الظفر. تكملة",
        clinics: ["Emergency General"],
    },
    {
        value: "S61.049S",
        label: "Puncture wound with foreign body of unspecified thumb without damage to nail. sequela - ثقب الجرح بجسم غريب من الإبهام غير محدد دون الإضرار بالأظافر. تكملة",
        clinics: ["Emergency General"],
    },
    {
        value: "S61.059S",
        label: "Open bite of unspecified thumb without damage to nail. sequela - فتح عضة إبهام غير محدد دون الإضرار بالأظافر. تكملة",
        clinics: ["Emergency General"],
    },
    {
        value: "S61.109S",
        label: "Unspecified open wound of unspecified thumb with damage to nail. sequela - جرح مفتوح غير محدد لإبهام غير محدد مع تلف في الظفر. تكملة",
        clinics: ["Emergency General"],
    },
    {
        value: "S61.119S",
        label: "Laceration without foreign body of unspecified thumb with damage to nail. sequela - تمزق بدون جسم غريب من إبهام غير محدد مع تلف الظفر. تكملة",
        clinics: ["Emergency General"],
    },
    {
        value: "S61.129S",
        label: "Laceration with foreign body of unspecified thumb with damage to nail. sequela - تمزق مع جسم غريب من إبهام غير محدد مع تلف الأظافر. تكملة",
        clinics: ["Emergency General"],
    },
    {
        value: "S61.139S",
        label: "Puncture wound without foreign body of unspecified thumb with damage to nail. sequela - ثقب الجرح دون جسم غريب من الإبهام غير محدد مع تلف الظفر. تكملة",
        clinics: ["Emergency General"],
    },
    {
        value: "S61.149S",
        label: "Puncture wound with foreign body of unspecified thumb with damage to nail. sequela - جرح ثقب بجسم غريب من الإبهام غير محدد مع تلف الظفر. تكملة",
        clinics: ["Emergency General"],
    },
    {
        value: "S61.159S",
        label: "Open bite of unspecified thumb with damage to nail. sequela - فتح عضة إبهام غير محدد مع تلف الظفر. تكملة",
        clinics: ["Emergency General"],
    },
    {
        value: "S61.209S",
        label: "Unspecified open wound of unspecified finger without damage to nail. sequela - جرح مفتوح غير محدد في إصبع غير محدد دون تلف الظفر. تكملة",
        clinics: ["Emergency General"],
    },
    {
        value: "N28.85",
        label: "Pyeloureteritis cystica - التهاب الحويضة المثانة",
        clinics: ["Nephrology"],
    },
    {
        value: "N28.86",
        label: "Ureteritis cystica - التهاب الحالب المثاني",
        clinics: ["Nephrology"],
    },
    {
        value: "N28.89",
        label: "Other specified disorders of kidney and ureter - اضطرابات أخرى محددة في الكلى والحالب",
        clinics: ["Nephrology"],
    },
    {
        value: "N28.9",
        label: "Disorder of kidney and ureter, unspecified - اضطراب في الكلى والحالب غير محدد",
        clinics: ["Nephrology"],
    },
    {
        value: "N17.0",
        label: "Acute kidney failure with tubular necrosis - الفشل الكلوي الحاد مع نخر أنبوبي",
        clinics: ["Nephrology"],
    },
    {
        value: "N17.1",
        label: "Acute kidney failure with acute cortical necrosis - فشل كلوي حاد مع نخر قشري حاد",
        clinics: ["Nephrology"],
    },
    {
        value: "N17.2",
        label: "Acute kidney failure with medullary necrosis - الفشل الكلوي الحاد مع نخر النخاع",
        clinics: ["Nephrology"],
    },
    {
        value: "N17.8",
        label: "Other acute kidney failure - فشل كلوي حاد آخر",
        clinics: ["Nephrology"],
    },
    {
        value: "N17.9",
        label: "Acute kidney failure, unspecified - فشل كلوي حاد ، غير محدد",
        clinics: ["Nephrology"],
    },
    {
        value: "N18.2",
        label: "Chronic kidney disease, stage 2 (mild) - مرض الكلى المزمن ، المرحلة 2 (خفيف)",
        clinics: ["Nephrology"],
    },
    {
        value: "N18.3",
        label: "Chronic kidney disease, stage 3 (moderate) - مرض الكلى المزمن ، المرحلة 3 (متوسط)",
        clinics: ["Nephrology"],
    },
    {
        value: "N18.4",
        label: "Chronic kidney disease, stage 4 (severe) - مرض الكلى المزمن ، المرحلة 4 (شديد)",
        clinics: ["Nephrology"],
    },
    {
        value: "N18.6",
        label: "End stage renal disease - مرض الكلى في نهاية المرحلة",
        clinics: ["Nephrology"],
    },
    {
        value: "N18.9",
        label: "Chronic kidney disease, unspecified - مرض الكلى المزمن ، غير محدد",
        clinics: ["Nephrology"],
    },
    {
        value: "N00.0",
        label: "Acute nephritic syndrome with minor glomerular abnormality - متلازمة الالتهاب الكلوي الحاد مع شذوذ كبيبي طفيف",
        clinics: ["Nephrology"],
    },
    {
        value: "N00.1",
        label: "Acute nephritic syndrome with focal and segmental glomerular lesions - متلازمة الالتهاب الكلوي الحاد مع آفات كبيبية بؤرية وقطعية",
        clinics: ["Nephrology"],
    },
    {
        value: "N00.2",
        label: "Acute nephritic syndrome with diffuse membranous glomerulonephritis - متلازمة الالتهاب الكلوي الحاد مع التهاب كبيبات الكلى الغشائي المنتشر",
        clinics: ["Nephrology"],
    },
    {
        value: "N00.3",
        label: "Acute nephritic syndrome with diffuse mesangial proliferative glomerulonephritis - متلازمة الالتهاب الكلوي الحاد مع التهاب كبيبات الكلى التكاثري المسراق المنتشر",
        clinics: ["Nephrology"],
    },
    {
        value: "N00.4",
        label: "Acute nephritic syndrome with diffuse endocapillary proliferative glomerulonephritis - متلازمة الالتهاب الكلوي الحادة مع التهاب كبيبات الكلى التكاثري الداخلي",
        clinics: ["Nephrology"],
    },
    {
        value: "N00.5",
        label: "Acute nephritic syndrome with diffuse mesangiocapillary glomerulonephritis - متلازمة الالتهاب الكلوي الحادة المصحوبة بالتهاب كبيبات الكلى الوعائي الوعائي المنتشر",
        clinics: ["Nephrology"],
    },
    {
        value: "N00.6",
        label: "Acute nephritic syndrome with dense deposit disease - متلازمة الالتهاب الكلوي الحاد مع مرض الرواسب الكثيفة",
        clinics: ["Nephrology"],
    },
    {
        value: "N00.7",
        label: "Acute nephritic syndrome with diffuse crescentic glomerulonephritis - متلازمة الالتهاب الكلوي الحاد مع التهاب كبيبات الكلى الهلالي المنتشر",
        clinics: ["Nephrology"],
    },
    {
        value: "N00.8",
        label: "Acute nephritic syndrome with other morphologic changes - متلازمة الالتهاب الكلوي الحاد مع تغيرات مورفولوجية أخرى",
        clinics: ["Nephrology"],
    },
    {
        value: "N00.9",
        label: "Acute nephritic syndrome with unspecified morphologic changes - متلازمة الالتهاب الكلوي الحاد مع تغيرات شكلية غير محددة",
        clinics: ["Nephrology"],
    },
    {
        value: "N03.0",
        label: "Chronic nephritic syndrome with minor glomerular abnormality - متلازمة الالتهاب الكلوي المزمن مع شذوذ كبيبي طفيف",
        clinics: ["Nephrology"],
    },
    {
        value: "N03.1",
        label: "Chronic nephritic syndrome with focal and segmental glomerular lesions - متلازمة الالتهاب الكلوي المزمن مع آفات كبيبية بؤرية وقطعية",
        clinics: ["Nephrology"],
    },
    {
        value: "N03.2",
        label: "Chronic nephritic syndrome with diffuse membranous glomerulonephritis - متلازمة الالتهاب الكلوي المزمن مع التهاب كبيبات الكلى الغشائي المنتشر",
        clinics: ["Nephrology"],
    },
    {
        value: "N03.3",
        label: "Chronic nephritic syndrome with diffuse mesangial proliferative glomerulonephritis - متلازمة الالتهاب الكلوي المزمن مع التهاب كبيبات الكلى التكاثري المسراق المنتشر",
        clinics: ["Nephrology"],
    },
    {
        value: "N03.4",
        label: "Chronic nephritic syndrome with diffuse endocapillary proliferative glomerulonephritis - متلازمة الالتهاب الكلوي المزمن مع التهاب كبيبات الكلى التكاثري الداخلي",
        clinics: ["Nephrology"],
    },
    {
        value: "N03.5",
        label: "Chronic nephritic syndrome with diffuse mesangiocapillary glomerulonephritis - متلازمة الالتهاب الكلوي المزمن المصحوب بالتهاب كبيبات الكلى والشعيرات المتوسطة المنتشر",
        clinics: ["Nephrology"],
    },
    {
        value: "N03.6",
        label: "Chronic nephritic syndrome with dense deposit disease - متلازمة الالتهاب الكلوي المزمن مع مرض الرواسب الكثيفة",
        clinics: ["Nephrology"],
    },
    {
        value: "N03.7",
        label: "Chronic nephritic syndrome with diffuse crescentic glomerulonephritis - متلازمة الالتهاب الكلوي المزمن مع التهاب كبيبات الكلى الهلالي المنتشر",
        clinics: ["Nephrology"],
    },
    {
        value: "N03.8",
        label: "Chronic nephritic syndrome with other morphologic changes - متلازمة الالتهاب الكلوي المزمن مع تغيرات مورفولوجية أخرى",
        clinics: ["Nephrology"],
    },
    {
        value: "N03.9",
        label: "Chronic nephritic syndrome with unspecified morphologic changes - متلازمة الالتهاب الكلوي المزمن مع تغيرات شكلية غير محددة",
        clinics: ["Nephrology"],
    },
    {
        value: "N04.0",
        label: "Nephrotic syndrome with minor glomerular abnormality - المتلازمة الكلوية مع شذوذ كبيبي طفيف",
        clinics: ["Nephrology"],
    },
    {
        value: "N04.1",
        label: "Nephrotic syndrome with focal and segmental glomerular lesions - المتلازمة الكلوية مع الآفات الكبيبية البؤرية والقطعية",
        clinics: ["Nephrology"],
    },
    {
        value: "N04.2",
        label: "Nephrotic syndrome with diffuse membranous glomerulonephritis - متلازمة أمراض الكلى مع التهاب كبيبات الكلى الغشائي المنتشر",
        clinics: ["Nephrology"],
    },
    {
        value: "N04.3",
        label: "Nephrotic syndrome with diffuse mesangial proliferative glomerulonephritis - المتلازمة الكلوية مع التهاب كبيبات الكلى التكاثري المسراق المنتشر",
        clinics: ["Nephrology"],
    },
    {
        value: "N04.4",
        label: "Nephrotic syndrome with diffuse endocapillary proliferative glomerulonephritis - المتلازمة الكلوية مع التهاب كبيبات الكلى التكاثري الداخلي",
        clinics: ["Nephrology"],
    },
    {
        value: "N04.5",
        label: "Nephrotic syndrome with diffuse mesangiocapillary glomerulonephritis - المتلازمة الكلوية المصحوبة بالتهاب كبيبات الكلى الوعائي الوعائي المنتشر",
        clinics: ["Nephrology"],
    },
    {
        value: "N04.6",
        label: "Nephrotic syndrome with dense deposit disease - متلازمة أمراض الكلى مع مرض الرواسب الكثيفة",
        clinics: ["Nephrology"],
    },
    {
        value: "N04.7",
        label: "Nephrotic syndrome with diffuse crescentic glomerulonephritis - متلازمة أمراض الكلى مع التهاب كبيبات الكلى الهلالي المنتشر",
        clinics: ["Nephrology"],
    },
    {
        value: "N04.8",
        label: "Nephrotic syndrome with other morphologic changes - المتلازمة الكلوية مع تغيرات مورفولوجية أخرى",
        clinics: ["Nephrology"],
    },
    {
        value: "N04.9",
        label: "Nephrotic syndrome with unspecified morphologic changes - المتلازمة الكلوية مع تغيرات شكلية غير محددة",
        clinics: ["Nephrology"],
    },
    {
        value: "N05.0",
        label: "Unspecified nephritic syndrome with minor glomerular abnormality - متلازمة الالتهاب الكلوي غير المحددة مع شذوذ كبيبي طفيف",
        clinics: ["Nephrology"],
    },
    {
        value: "N05.1",
        label: "Unspecified nephritic syndrome with focal and segmental glomerular lesions - متلازمة الالتهاب الكلوي غير المحددة المصحوبة بآفات كبيبية بؤرية وقطعية",
        clinics: ["Nephrology"],
    },
    {
        value: "N05.2",
        label: "Unspecified nephritic syndrome with diffuse membranous glomerulonephritis - متلازمة الالتهاب الكلوي غير المحددة المصحوبة بالتهاب كبيبات الكلى الغشائي المنتشر",
        clinics: ["Nephrology"],
    },
    {
        value: "N05.3",
        label: "Unspecified nephritic syndrome with diffuse mesangial proliferative glomerulonephritis - متلازمة الالتهاب الكلوي غير المحددة مع التهاب كبيبات الكلى التكاثري المسراق المنتشر",
        clinics: ["Nephrology"],
    },
    {
        value: "N05.4",
        label: "Unspecified nephritic syndrome with diffuse endocapillary proliferative glomerulonephritis - متلازمة الالتهاب الكلوي غير المحددة مع التهاب كبيبات الكلى التكاثري الداخلي",
        clinics: ["Nephrology"],
    },
    {
        value: "N05.5",
        label: "Unspecified nephritic syndrome with diffuse mesangiocapillary glomerulonephritis - متلازمة الالتهاب الكلوي غير المحددة المصحوبة بالتهاب كبيبات الكلى الوعائي الوعائي المنتشر",
        clinics: ["Nephrology"],
    },
    {
        value: "N05.6",
        label: "Unspecified nephritic syndrome with dense deposit disease - متلازمة الالتهاب الكلوي غير المحددة مع مرض الرواسب الكثيفة",
        clinics: ["Nephrology"],
    },
    {
        value: "N05.7",
        label: "Unspecified nephritic syndrome with diffuse crescentic glomerulonephritis - متلازمة الالتهاب الكلوي غير المحددة المصحوبة بالتهاب كبيبات الكلى الهلالي المنتشر",
        clinics: ["Nephrology"],
    },
    {
        value: "N05.8",
        label: "Unspecified nephritic syndrome with other morphologic changes - متلازمة الالتهاب الكلوي غير المحدد مع تغيرات مورفولوجية أخرى",
        clinics: ["Nephrology"],
    },
    {
        value: "N05.9",
        label: "Unspecified nephritic syndrome with unspecified morphologic changes - متلازمة الالتهاب الكلوي غير المحددة مع تغيرات شكلية غير محددة",
        clinics: ["Nephrology"],
    },
    {
        value: "Q61.1",
        label: "Polycystic kidney, infantile type - الكلى متعددة الكيسات ، من النوع الطفولي",
        clinics: ["Nephrology"],
    },
    {
        value: "Q61.11",
        label: "Cystic dilatation of collecting ducts - التوسيع الكيسي لقنوات التجميع",
        clinics: ["Nephrology"],
    },
    {
        value: "Q61.19",
        label: "Other polycystic kidney, infantile type - الكلى متعددة الكيسات الأخرى ، من النوع الطفولي",
        clinics: ["Nephrology"],
    },
    {
        value: "Q61.2",
        label: "Polycystic kidney, adult type - الكلى متعددة الكيسات ، نوع البالغين",
        clinics: ["Nephrology"],
    },
    {
        value: "Z49.31",
        label: "Encounter for adequacy testing for hemodialysis - لقاء لاختبار كفاية غسيل الكلى",
        clinics: ["Nephrology"],
    },
    {
        value: "C50.911",
        label: "Malignant neoplasm of unspecified site of right female breast - ورم خبيث في موقع غير محدد من الثدي الأيمن للأنثى",
        clinics: [],
    },
    {
        value: "C50.912",
        label: "Malignant neoplasm of unspecified site of left female breast - ورم خبيث من موقع غير محدد لثدي الأنثى الأيسر",
        clinics: [],
    },
    {
        value: "C50.919",
        label: "Malignant neoplasm of unspecified site of unspecified female breast - ورم خبيث من موقع غير محدد لثدي أنثى غير محدد",
        clinics: [],
    },
    {
        value: "D22.9",
        label: "Melanocytic nevi, unspecified - الوحمات الصباغية ، غير محددة",
        clinics: ["Plastic Surgery", "Dermotology"],
    },
    {
        value: "D23.9",
        label: "Other benign neoplasm of skin, unspecified - أورام جلدية حميدة أخرى غير محددة",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "G56.00",
        label: "Carpal tunnel syndrome, unspecified upper limb - متلازمة النفق الرسغي ، طرف علوي غير محدد",
        clinics: [],
    },
    {
        value: "G56.01",
        label: "Carpal tunnel syndrome, right upper limb - متلازمة النفق الرسغي ، الطرف العلوي الأيمن",
        clinics: [],
    },
    {
        value: "G56.02",
        label: "Carpal tunnel syndrome, left upper limb - متلازمة النفق الرسغي ، الطرف العلوي الأيسر",
        clinics: [],
    },
    {
        value: "I83.90",
        label: "Asymptomatic varicose veins of unspecified lower extremity - دوالي بدون أعراض لأطراف سفلية غير محددة",
        clinics: [],
    },
    {
        value: "I83.91",
        label: "Asymptomatic varicose veins of right lower extremity - الدوالي بدون أعراض في الطرف الأيمن السفلي",
        clinics: [],
    },
    {
        value: "I83.92",
        label: "Asymptomatic varicose veins of left lower extremity - الدوالي بدون أعراض في الطرف الأيسر السفلي",
        clinics: [],
    },
    {
        value: "I83.93",
        label: "Asymptomatic varicose veins of bilateral lower extremities - الدوالي بدون أعراض من الأطراف السفلية الثنائية",
        clinics: [],
    },
    {
        value: "L85.9",
        label: "Epidermal thickening, unspecified - سماكة البشرة ، غير محدد",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "L87.9",
        label: "Transepidermal elimination disorder, unspecified - اضطراب القضاء عبر الجلد ، غير محدد",
        clinics: [],
    },
    {
        value: "L89.130",
        label: "Pressure ulcer of right lower back, unstageable - قرحة ضغط أسفل الظهر الأيمن ، غير مستقرة",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "L89.131",
        label: "Pressure ulcer of right lower back, stage 1 - قرحة الضغط أسفل الظهر الأيمن ، المرحلة 1",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "L89.132",
        label: "Pressure ulcer of right lower back, stage 2 - قرحة الضغط أسفل الظهر الأيمن ، المرحلة الثانية",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "L89.133",
        label: "Pressure ulcer of right lower back, stage 3 - قرحة الضغط أسفل الظهر الأيمن ، المرحلة الثالثة",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "L89.134",
        label: "Pressure ulcer of right lower back, stage 4 - قرحة الضغط أسفل الظهر الأيمن ، المرحلة الرابعة",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "L89.139",
        label: "Pressure ulcer of right lower back, unspecified stage - قرحة ضغط أسفل الظهر الأيمن ، مرحلة غير محددة",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "L89.140",
        label: "Pressure ulcer of left lower back, unstageable - قرحة الضغط في أسفل الظهر الأيسر ، غير مستقرة",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "L89.141",
        label: "Pressure ulcer of left lower back, stage 1 - قرحة الضغط في أسفل الظهر الأيسر ، المرحلة 1",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "L89.142",
        label: "Pressure ulcer of left lower back, stage 2 - قرحة الضغط في أسفل الظهر الأيسر ، المرحلة الثانية",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "L89.143",
        label: "Pressure ulcer of left lower back, stage 3 - قرحة الضغط في أسفل الظهر الأيسر ، المرحلة 3",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "L89.144",
        label: "Pressure ulcer of left lower back, stage 4 - قرحة الضغط في أسفل الظهر الأيسر ، المرحلة الرابعة",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "L89.149",
        label: "Pressure ulcer of left lower back, unspecified stage - قرحة الضغط من أسفل الظهر الأيسر ، مرحلة غير محددة",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "L89.150",
        label: "Pressure ulcer of sacral region, unstageable - قرحة الضغط في المنطقة العجزية ، غير قابلة للتثبيت",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "L89.151",
        label: "Pressure ulcer of sacral region, stage 1 - قرحة الضغط في المنطقة العجزية ، المرحلة 1",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "L89.152",
        label: "Pressure ulcer of sacral region, stage 2 - قرحة الضغط في المنطقة العجزية ، المرحلة الثانية",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "L89.153",
        label: "Pressure ulcer of sacral region, stage 3 - قرحة الضغط في المنطقة العجزية ، المرحلة الثالثة",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "L89.154",
        label: "Pressure ulcer of sacral region, stage 4 - قرحة الضغط في المنطقة العجزية ، المرحلة 4",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "L89.159",
        label: "Pressure ulcer of sacral region, unspecified stage - قرحة الضغط في منطقة العجز ، مرحلة غير محددة",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "L89.40",
        label: "Pressure ulcer of contiguous site of back, buttock and hip, unspecified stage - قرحة ضغط في موقع مجاور للظهر والأرداف والورك ، مرحلة غير محددة",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "L89.41",
        label: "Pressure ulcer of contiguous site of back, buttock and hip, stage 1 - قرحة الضغط في الموقع المجاور للظهر والأرداف والورك ، المرحلة 1",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "L89.42",
        label: "Pressure ulcer of contiguous site of back, buttock and hip, stage 2 - قرحة ضغط في الموقع المجاور للظهر والأرداف والورك ، المرحلة الثانية",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "L89.43",
        label: "Pressure ulcer of contiguous site of back, buttock and hip, stage 3 - قرحة ضغط في موقع مجاور للظهر والأرداف والورك ، المرحلة الثالثة",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "L89.44",
        label: "Pressure ulcer of contiguous site of back, buttock and hip, stage 4 - قرحة الضغط في الموقع المجاور للظهر والأرداف والورك ، المرحلة الرابعة",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "L89.45",
        label: "Pressure ulcer of contiguous site of back, buttock and hip, unstageable - قرحة ضغط في موقع مجاور للظهر والأرداف والورك ، غير قابلة للتثبيت",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "L90.9",
        label: "Atrophic disorder of skin, unspecified - اضطراب جلدي ضامر ، غير محدد",
        clinics: [],
    },
    {
        value: "L91.9",
        label: "Hypertrophic disorder of the skin, unspecified - اضطراب الجلد الضخامي ، غير محدد",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "L94.9",
        label: "Localized connective tissue disorder, unspecified - اضطراب النسيج الضام الموضعي ، غير محدد",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "M86.011",
        label: "Acute hematogenous osteomyelitis, right shoulder - التهاب العظم والنقي الدموي الحاد ، الكتف الأيمن",
        clinics: [],
    },
    {
        value: "M86.012",
        label: "Acute hematogenous osteomyelitis, left shoulder - التهاب العظم والنقي الدموي الحاد ، الكتف الأيسر",
        clinics: [],
    },
    {
        value: "M86.019",
        label: "Acute hematogenous osteomyelitis, unspecified shoulder - التهاب العظم والنقي الدموي الحاد ، كتف غير محدد",
        clinics: [],
    },
    {
        value: "M86.111",
        label: "Other acute osteomyelitis, right shoulder - التهاب العظم والنقي الحاد الآخر ، الكتف الأيمن",
        clinics: [],
    },
    {
        value: "M86.112",
        label: "Other acute osteomyelitis, left shoulder - التهاب العظم والنقي الحاد الآخر ، الكتف الأيسر",
        clinics: [],
    },
    {
        value: "M86.119",
        label: "Other acute osteomyelitis, unspecified shoulder - التهاب العظم والنقي الحاد الآخر ، كتف غير محدد",
        clinics: [],
    },
    {
        value: "M86.211",
        label: "Subacute osteomyelitis, right shoulder - التهاب العظم والنقي تحت الحاد ، الكتف الأيمن",
        clinics: [],
    },
    {
        value: "M86.212",
        label: "Subacute osteomyelitis, left shoulder - التهاب العظم والنقي تحت الحاد ، الكتف الأيسر",
        clinics: [],
    },
    {
        value: "M86.219",
        label: "Subacute osteomyelitis, unspecified shoulder - التهاب العظم والنقي تحت الحاد ، كتف غير محدد",
        clinics: [],
    },
    {
        value: "M67.411",
        label: "Ganglion, right shoulder - العقدة ، الكتف الأيمن",
        clinics: [],
    },
    {
        value: "M67.412",
        label: "Ganglion, left shoulder - العقدة ، الكتف الأيسر",
        clinics: [],
    },
    {
        value: "M67.419",
        label: "Ganglion, unspecified shoulder - العقدة ، كتف غير محدد",
        clinics: [],
    },
    {
        value: "M67.421",
        label: "Ganglion, right elbow - العقدة ، الكوع الأيمن",
        clinics: [],
    },
    {
        value: "M67.422",
        label: "Ganglion, left elbow - العقدة ، الكوع الأيسر",
        clinics: [],
    },
    {
        value: "M67.429",
        label: "Ganglion, unspecified elbow - العقدة ، الكوع غير محدد",
        clinics: [],
    },
    {
        value: "M67.431",
        label: "Ganglion, right wrist - العقدة ، الرسغ الأيمن",
        clinics: [],
    },
    {
        value: "M67.432",
        label: "Ganglion, left wrist - العقدة ، الرسغ الأيسر",
        clinics: [],
    },
    {
        value: "M67.439",
        label: "Ganglion, unspecified wrist - العقدة ، معصم غير محدد",
        clinics: [],
    },
    {
        value: "M67.441",
        label: "Ganglion, right hand - العقدة ، اليد اليمنى",
        clinics: [],
    },
    {
        value: "M67.442",
        label: "Ganglion, left hand - العقدة ، اليد اليسرى",
        clinics: [],
    },
    {
        value: "M67.449",
        label: "Ganglion, unspecified hand - العقدة ، يد غير محددة",
        clinics: [],
    },
    {
        value: "M67.461",
        label: "Ganglion, right knee - العقدة ، الركبة اليمنى",
        clinics: [],
    },
    {
        value: "M67.462",
        label: "Ganglion, left knee - العقدة ، الركبة اليسرى",
        clinics: [],
    },
    {
        value: "M67.469",
        label: "Ganglion, unspecified knee - العقدة ، ركبة غير محددة",
        clinics: [],
    },
    {
        value: "M67.471",
        label: "Ganglion, right ankle and foot - العقدة ، الكاحل الأيمن والقدم",
        clinics: [],
    },
    {
        value: "M67.472",
        label: "Ganglion, left ankle and foot - العقدة ، الكاحل الأيسر والقدم",
        clinics: [],
    },
    {
        value: "M67.479",
        label: "Ganglion, unspecified ankle and foot - العقدة ، الكاحل والقدم غير محدد",
        clinics: [],
    },
    {
        value: "M67.48",
        label: "Ganglion, other site - العقدة ، موقع آخر",
        clinics: [],
    },
    {
        value: "M67.49",
        label: "Ganglion, multiple sites - العقدة ، مواقع متعددة",
        clinics: [],
    },
    {
        value: "Q37.9",
        label: "Unspecified cleft palate with unilateral cleft lip - حنك مشقوق غير محدد مع شفة أرنبية أحادية الجانب",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "T87.40",
        label: "Infection of amputation stump, unspecified extremity - إصابة جذع البتر ، أطراف غير محددة",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "T87.41",
        label: "Infection of amputation stump, right upper extremity - إصابة جذع البتر ، الطرف العلوي الأيمن",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "T87.42",
        label: "Infection of amputation stump, left upper extremity - إصابة جذع البتر ، الطرف العلوي الأيسر",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "T87.43",
        label: "Infection of amputation stump, right lower extremity - إصابة جذع البتر ، الطرف الأيمن السفلي",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "T87.44",
        label: "Infection of amputation stump, left lower extremity - إصابة جذع البتر ، الطرف الأيسر السفلي",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "S01.02",
        label: "Laceration with foreign body of scalp - تمزق مع جسم غريب من فروة الرأس",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "S01.04",
        label: "Puncture wound with foreign body of scalp - جرح ثقب بجسم غريب من فروة الرأس",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "S71.001",
        label: "Unspecified open wound, right hip - جرح مفتوح غير محدد ، الورك الأيمن",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "S71.002",
        label: "Unspecified open wound, left hip - جرح مفتوح غير محدد ، الورك الأيسر",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "S71.009",
        label: "Unspecified open wound, unspecified hip - جرح مفتوح غير محدد ، ورك غير محدد",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "S71.011",
        label: "Laceration without foreign body, right hip - تمزق بدون جسم غريب ، الورك الأيمن",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "S71.012",
        label: "Laceration without foreign body, left hip - تمزق بدون جسم غريب ، الورك الأيسر",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "S71.019",
        label: "Laceration without foreign body, unspecified hip - تمزق بدون جسم غريب ، ورك غير محدد",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "S71.031",
        label: "Puncture wound without foreign body, right hip - ثقب الجرح بدون جسم غريب ، الورك الأيمن",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "S71.032",
        label: "Puncture wound without foreign body, left hip - ثقب الجرح بدون جسم غريب ، ترك الورك",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "S71.039",
        label: "Puncture wound without foreign body, unspecified hip - ثقب الجرح دون جسم غريب ، الورك غير محدد",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "S71.051",
        label: "Open bite, right hip - العضة المفتوحة ، الفخذ الأيمن",
        clinics: [],
    },
    {
        value: "S71.052",
        label: "Open bite, left hip - العضة المفتوحة ، الورك الأيسر",
        clinics: [],
    },
    {
        value: "S71.059",
        label: "Open bite, unspecified hip - عضة مفتوحة ، ورك غير محدد",
        clinics: [],
    },
    {
        value: "S71.101",
        label: "Unspecified open wound, right thigh - جرح مفتوح غير محدد ، الفخذ الأيمن",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "S71.102",
        label: "Unspecified open wound, left thigh - جرح مفتوح غير محدد ، الفخذ الأيسر",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "S71.109",
        label: "Unspecified open wound, unspecified thigh - جرح مفتوح غير محدد ، فخذ غير محدد",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "S71.111",
        label: "Laceration without foreign body, right thigh - تمزق بدون جسم غريب ، الفخذ الأيمن",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "S71.112",
        label: "Laceration without foreign body, left thigh - تمزق بدون جسم غريب ، الفخذ الأيسر",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "S71.119",
        label: "Laceration without foreign body, unspecified thigh - تمزق بدون جسم غريب ، فخذ غير محدد",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "S71.131",
        label: "Puncture wound without foreign body, right thigh - ثقب الجرح بدون جسم غريب ، الفخذ الأيمن",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "S71.132",
        label: "Puncture wound without foreign body, left thigh - ثقب الجرح بدون جسم غريب ، الفخذ الأيسر",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "S71.139",
        label: "Puncture wound without foreign body, unspecified thigh - ثقب الجرح دون جسم غريب ، الفخذ غير محدد",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "S71.151",
        label: "Open bite, right thigh - العضة المفتوحة ، الفخذ الأيمن",
        clinics: [],
    },
    {
        value: "S71.152",
        label: "Open bite, left thigh - العضة المفتوحة ، الفخذ الأيسر",
        clinics: [],
    },
    {
        value: "S71.159",
        label: "Open bite, unspecified thigh - عضة مفتوحة ، فخذ غير محدد",
        clinics: [],
    },
    {
        value: "S67.20",
        label: "Crushing injury of unspecified hand - إصابة سحق لليد غير محدد",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "S67.21",
        label: "Crushing injury of right hand - إصابة سحق في اليد اليمنى",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "S67.22",
        label: "Crushing injury of left hand - إصابة سحق في اليد اليسرى",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "T25.321",
        label: "Burn of third degree of right foot - حرق الدرجة الثالثة من القدم اليمنى",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "T25.322",
        label: "Burn of third degree of left foot - حرق الدرجة الثالثة من القدم اليسرى",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "T25.329",
        label: "Burn of third degree of unspecified foot - حروق من الدرجة الثالثة بقدم غير محددة",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "T25.721",
        label: "Corrosion of third degree of right foot - تآكل الدرجة الثالثة للقدم اليمنى",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "T25.722",
        label: "Corrosion of third degree of left foot - تآكل القدم اليسرى من الدرجة الثالثة",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "T25.729",
        label: "Corrosion of third degree of unspecified foot - تآكل القدم غير المحددة من الدرجة الثالثة",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "Z85.820",
        label: "Personal history of malignant melanoma of skin - التاريخ الشخصي للإصابة بسرطان الجلد الخبيث",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "Z98.86",
        label: "Personal history of breast implant removal - التاريخ الشخصي لإزالة حشوة الثدي",
        clinics: [],
    },
    {
        value: "B25.2",
        label: "Cytomegaloviral pancreatitis - التهاب البنكرياس المضخم للخلايا",
        clinics: ["Endocrinology"],
    },
    {
        value: "C56.1",
        label: "Malignant neoplasm of right ovary  - ورم خبيث في المبيض الأيمن",
        clinics: ["Endocrinology"],
    },
    {
        value: "C56.2",
        label: "Malignant neoplasm of left ovary - ورم خبيث من المبيض الأيسر",
        clinics: ["Endocrinology"],
    },
    {
        value: "C56.9",
        label: "Malignant neoplasm of unspecified ovary - ورم خبيث لمبيض غير محدد",
        clinics: ["Endocrinology"],
    },
    {
        value: "E66.09",
        label: "Other obesity due to excess calories - السمنة الأخرى بسبب زيادة السعرات الحرارية",
        clinics: ["Endocrinology"],
    },
    {
        value: "E66.1",
        label: "Drug-induced obesity - السمنة الناجمة عن الأدوية",
        clinics: ["Endocrinology"],
    },
    {
        value: "E66.8",
        label: "Other obesity - سمنة ذات مصادر أخرى",
        clinics: ["Endocrinology"],
    },
    {
        value: "E89.6",
        label: "Postprocedural adrenocortical (-medullary) hypofunction - قصور وظيفة قشر الكظر (النخاعي) بعد العملية الجراحية",
        clinics: ["Endocrinology"],
    },
    {
        value: "E05.00",
        label: "Thyrotoxicosis with diffuse goiter without thyrotoxic crisis or storm (Graves disease) - التسمم الدرقي مع تضخم الغدة الدرقية المنتشر دون أزمة تسمم درقي أو عاصفة , داء غريف",
        clinics: ["Endocrinology"],
    },
    {
        value: "E05.90",
        label: "Thyrotoxicosis unspecified without thyrotoxic crisis or storm (Hyperthyroidism) - فرط النشاط الدرقي",
        clinics: ["Endocrinology"],
    },
    {
        value: "E83.52",
        label: "Hypercalcemia - فرط كالسيوم الدم",
        clinics: ["Endocrinology"],
    },
    {
        value: "E84.9",
        label: "Cystic fibrosis unspecified - التليف الكيسي غير محدد",
        clinics: ["Endocrinology"],
    },
    {
        value: "E10.9",
        label: "Type 1 diabetes mellitus without complications         - داء سكري نمط أول",
        clinics: ["Endocrinology"],
    },
    {
        value: "E11.9",
        label: "Type 2 diabetes mellitus without complications - داء سكري نمط ثاني",
        clinics: ["Endocrinology"],
    },
    {
        value: "E13.9",
        label: "Other specified diabetes mellitus without complications - داء السكري ذو نمط أخر",
        clinics: ["Endocrinology"],
    },
    {
        value: "E21.3",
        label: "Hyperparathyroidism unspecified - فرط نشاط جارات الدرق",
        clinics: ["Endocrinology"],
    },
    {
        value: "E24.0",
        label: "Pituitary-dependent Cushing",
        clinics: ["Endocrinology"],
    },
    { value: "E24.2", label: "Drug-induced Cushing", clinics: ["Endocrinology"] },
    {
        value: "E24.3",
        label: "Ectopic ACTH syndrome - متلازمة ACTH خارج الرحم",
        clinics: ["Endocrinology"],
    },
    {
        value: "E24.4",
        label: "Alcohol-induced pseudo-Cushing",
        clinics: ["Endocrinology"],
    },
    { value: "E24.8", label: "Other Cushing", clinics: ["Endocrinology"] },
    { value: "E24.9", label: "Cushing", clinics: ["Endocrinology"] },
    {
        value: "E27.1",
        label: "Primary adrenocortical insufficiency - قصور كظر أولي",
        clinics: ["Endocrinology"],
    },
    {
        value: "E27.2",
        label: "Addisonian crisis - أزمة أديسون",
        clinics: ["Endocrinology"],
    },
    {
        value: "E27.3",
        label: "Drug-induced adrenocortical insufficiency - قصور كظر ناتج عن الأدوية",
        clinics: ["Endocrinology"],
    },
    {
        value: "E27.40",
        label: "Unspecified adrenocortical insufficiency (andrenal insufficiency) - قصور غير محدد في قشر الكظر",
        clinics: ["Endocrinology"],
    },
    {
        value: "E27.49",
        label: "Other adrenocortical insufficiency - قصور قشرة الكظر ذات منشأ أخر",
        clinics: ["Endocrinology"],
    },
    {
        value: "E28.2",
        label: "Polycystic ovarian syndrome - متلازمة تكيس المبايض",
        clinics: ["Endocrinology"],
    },
    {
        value: "E34.4",
        label: "Constitutional tall stature - طول قامة بنيوي",
        clinics: ["Endocrinology"],
    },
    {
        value: "K85.0",
        label: "Idiopathic acute pancreatitis - التهاب البنكرياس الحاد مجهول السبب",
        clinics: ["Endocrinology"],
    },
    {
        value: "K85.1",
        label: "Biliary acute pancreatitis - التهاب البنكرياس الصفراوي الحاد",
        clinics: ["Endocrinology"],
    },
    {
        value: "K85.2",
        label: "Alcohol induced acute pancreatitis - يسبب الكحول التهاب البنكرياس الحاد",
        clinics: ["Endocrinology"],
    },
    {
        value: "K85.3",
        label: "Drug induced acute pancreatitis - الأدوية التي يسببها التهاب البنكرياس الحاد",
        clinics: ["Endocrinology"],
    },
    {
        value: "K85.8",
        label: "Other acute pancreatitis - التهاب البنكرياس الحاد الآخر",
        clinics: ["Endocrinology"],
    },
    {
        value: "K85.9",
        label: "Acute pancreatitis unspecified - التهاب البنكرياس الحاد غير محدد",
        clinics: ["Endocrinology"],
    },
    {
        value: "M88.0",
        label: "Osteitis deformans of skull - تشوه العظام في الجمجمة",
        clinics: ["Endocrinology"],
    },
    {
        value: "M88.1",
        label: "Osteitis deformans of vertebrae - التهاب العظام تشوه الفقرات",
        clinics: ["Endocrinology"],
    },
    {
        value: "M88.811",
        label: "Osteitis deformans of right shoulder - تشوه التهاب العظم في الكتف الأيمن",
        clinics: ["Endocrinology"],
    },
    {
        value: "M88.812",
        label: "Osteitis deformans of left shoulder - تشوه التهاب العظم في الكتف الأيسر",
        clinics: ["Endocrinology"],
    },
    {
        value: "M88.821",
        label: "Osteitis deformans of right upper arm - تشوه التهاب العظم في العضد الأيمن",
        clinics: ["Endocrinology"],
    },
    {
        value: "M88.822",
        label: "Osteitis deformans of left upper arm - تشوه التهاب العظم في العضد الأيسر",
        clinics: ["Endocrinology"],
    },
    {
        value: "M88.831",
        label: "Osteitis deformans of right forearm - تشوه التهاب العظم في الساعد الأيمن",
        clinics: ["Endocrinology"],
    },
    {
        value: "M88.832",
        label: "Osteitis deformans of left forearm - تشوه التهاب العظم في الساعد الأيسر",
        clinics: ["Endocrinology"],
    },
    {
        value: "M88.841",
        label: "Osteitis deformans of right hand - تشوه العظام في اليد اليمنى",
        clinics: ["Endocrinology"],
    },
    {
        value: "M88.842",
        label: "Osteitis deformans of left hand - تشوه العظام في اليد اليسرى",
        clinics: ["Endocrinology"],
    },
    {
        value: "M88.851",
        label: "Osteitis deformans of right thigh - التهاب عظم الفخذ الأيمن",
        clinics: ["Endocrinology"],
    },
    {
        value: "M88.852",
        label: "Osteitis deformans of left thigh - التهاب عظم الفخذ الأيسر",
        clinics: ["Endocrinology"],
    },
    {
        value: "M88.861",
        label: "Osteitis deformans of right lower leg - تشوه التهاب العظم في أسفل الساق اليمنى",
        clinics: ["Endocrinology"],
    },
    {
        value: "M88.862",
        label: "Osteitis deformans of left lower leg - تشوه التهاب العظم في أسفل الساق اليسرى",
        clinics: ["Endocrinology"],
    },
    {
        value: "M88.871",
        label: "Osteitis deformans of right ankle and foot - التهاب العظم في الكاحل الأيمن والقدم",
        clinics: ["Endocrinology"],
    },
    {
        value: "M88.872",
        label: "Osteitis deformans of left ankle and foot - تشوه العظام في الكاحل والقدم اليسرى",
        clinics: ["Endocrinology"],
    },
    {
        value: "M88.879",
        label: "Osteitis deformans of unspecified ankle and foot - تشوه العظام في الكاحل والقدم غير محدد",
        clinics: ["Endocrinology"],
    },
    {
        value: "M88.89",
        label: "Osteitis deformans of multiple sites - تشوه التهاب العظام في مواقع متعددة",
        clinics: ["Endocrinology"],
    },
    {
        value: "M81.0",
        label: "Age-related osteoporosis without current pathological fracture - هشاشة العظام المرتبطة بالعمر دون حدوث كسر مرضي حالي",
        clinics: ["Endocrinology"],
    },
    {
        value: "Q96.0",
        label: "Karyotype 45 X - النمط النووي 45 X",
        clinics: ["Endocrinology"],
    },
    {
        value: "Q96.1",
        label: "Karyotype 46 X iso (Xq) - النمط النووي 46 X iso (Xq)",
        clinics: ["Endocrinology"],
    },
    {
        value: "Q96.2",
        label: "Karyotype 46 X with abnormal sex chromosome except iso (Xq) - النمط النووي 46 X مع كروموسوم جنسي غير طبيعي باستثناء ISO (Xq)",
        clinics: ["Endocrinology"],
    },
    {
        value: "Q96.3",
        label: "Mosaicism 45 X/46 XX or XY - فسيفساء 45 X / 46 XX أو XY",
        clinics: ["Endocrinology"],
    },
    {
        value: "Q96.4",
        label: "Mosaicism 45 X/other cell line(s) with abnormal sex chromosome - الفسيفساء 45 X / خط (خطوط) خلوية أخرى مع كروموسوم جنسي غير طبيعي",
        clinics: ["Endocrinology"],
    },
    {
        value: "Q96.8",
        label: "Other variants of Turner",
        clinics: ["Endocrinology"],
    },
    { value: "Q96.9", label: "Turner", clinics: ["Endocrinology"] },
    {
        value: "G71.11",
        label: "Myotonic muscular dystrophy - الحثل العضلي العضلي",
        clinics: ["Neurology"],
    },
    {
        value: "G71.12",
        label: "Myotonia congenita - ميوتونيا الخلقي",
        clinics: ["Neurology"],
    },
    {
        value: "G71.13",
        label: "Myotonic chondrodystrophy - الحثل الغضروفي العضلي",
        clinics: ["Neurology"],
    },
    {
        value: "G71.14",
        label: "Drug induced myotonia - المخدرات التي يسببها myotonia",
        clinics: ["Neurology"],
    },
    {
        value: "G71.19",
        label: "Other specified myotonic disorders - الاضطرابات العضلية المحددة الأخرى",
        clinics: ["Neurology"],
    },
    {
        value: "G71.2",
        label: "Congenital myopathies - اعتلال عضلي خلقي",
        clinics: ["Neurology"],
    },
    {
        value: "G71.3",
        label: "Mitochondrial myopathy  not elsewhere classified - اعتلال عضلي الميتوكوندريا غير مصنف في مكان آخر",
        clinics: ["Neurology"],
    },
    {
        value: "G71.8",
        label: "Other primary disorders of muscles - اضطرابات العضلات الأولية الأخرى",
        clinics: ["Neurology"],
    },
    {
        value: "G72.0",
        label: "Drug-induced myopathy - الاعتلال العضلي الناجم عن المخدرات",
        clinics: ["Neurology"],
    },
    {
        value: "G72.1",
        label: "Alcoholic myopathy - اعتلال عضلي كحولي",
        clinics: ["Neurology"],
    },
    {
        value: "G72.2",
        label: "Myopathy due to other toxic agents - اعتلال عضلي بسبب عوامل سامة أخرى",
        clinics: ["Neurology"],
    },
    {
        value: "G72.3",
        label: "Periodic paralysis - الشلل الدوري",
        clinics: ["Neurology"],
    },
    {
        value: "G72.41",
        label: "Inclusion body myositis [IBM] - التهاب عضلات الجسم التضميني [IBM]",
        clinics: ["Neurology"],
    },
    {
        value: "G72.49",
        label: "Other inflammatory and immune myopathies  not elsewhere classified - اعتلالات عضلية التهابية ومناعية أخرى غير مصنفة في مكان آخر",
        clinics: ["Neurology"],
    },
    {
        value: "G72.81",
        label: "Critical illness myopathy - مرض عضلي خطير",
        clinics: ["Neurology"],
    },
    {
        value: "G72.89",
        label: "Other specified myopathies - اعتلالات عضلية أخرى محددة",
        clinics: ["Neurology"],
    },
    {
        value: "G73.7",
        label: "Myopathy in diseases classified elsewhere - اعتلال عضلي في أمراض مصنفة في مكان آخر",
        clinics: ["Neurology"],
    },
    {
        value: "G60.0",
        label: "Hereditary motor and sensory neuropathy - اعتلال الأعصاب الوراثي الحركي والحسي",
        clinics: ["Neurology"],
    },
    { value: "G60.1", label: "Refsum", clinics: ["Neurology"] },
    {
        value: "G60.2",
        label: "Neuropathy in association with hereditary ataxia - الاعتلال العصبي المترافق مع ترنح وراثي",
        clinics: ["Neurology"],
    },
    {
        value: "G60.3",
        label: "Idiopathic progressive neuropathy - الاعتلال العصبي التدريجي مجهول السبب",
        clinics: ["Neurology"],
    },
    {
        value: "G60.8",
        label: "Other hereditary and idiopathic neuropathies - اعتلالات الأعصاب الوراثية ومجهولة السبب الأخرى",
        clinics: ["Neurology"],
    },
    {
        value: "G60.9",
        label: "Hereditary and idiopathic neuropathy  unspecified - اعتلال الأعصاب الوراثي ومجهول السبب غير محدد",
        clinics: ["Neurology"],
    },
    {
        value: "G43.001",
        label: "Migraine without aura  not intractable  with status migrainosus - الصداع النصفي غير المستعصي على الحل مع حالة الصداع النصفي",
        clinics: ["Neurology"],
    },
    {
        value: "G43.009",
        label: "Migraine without aura  not intractable  without status migrainosus - الصداع النصفي بدون هالة لا يستعصي على الحل بدون حالة الصداع النصفي",
        clinics: ["Neurology"],
    },
    {
        value: "G43.011",
        label: "Migraine without aura  intractable  with status migrainosus - الصداع النصفي بدون هالة مستعصية مع حالة الصداع النصفي",
        clinics: ["Neurology"],
    },
    {
        value: "G43.019",
        label: "Migraine without aura  intractable  without status migrainosus - الصداع النصفي بدون هالة مستعصية بدون حالة الصداع النصفي",
        clinics: ["Neurology"],
    },
    {
        value: "G43.101",
        label: "Migraine with aura  not intractable  with status migrainosus - الصداع النصفي المصحوب بأورة لا يستعصي على الحل مع حالة الصداع النصفي",
        clinics: ["Neurology"],
    },
    {
        value: "G43.111",
        label: "Migraine with aura  intractable  with status migrainosus - الصداع النصفي مع الهالة المستعصية مع حالة الصداع النصفي",
        clinics: ["Neurology"],
    },
    {
        value: "G43.119",
        label: "Migraine with aura  intractable  without status migrainosus - الصداع النصفي مع الهالة المستعصية بدون حالة الصداع النصفي",
        clinics: ["Neurology"],
    },
    {
        value: "G43.401",
        label: "Hemiplegic migraine  not intractable  with status migrainosus - الصداع النصفي المفلوج لا يستعصي على الحل مع حالة الصداع النصفي",
        clinics: ["Neurology"],
    },
    {
        value: "G43.409",
        label: "Hemiplegic migraine  not intractable  without status migrainosus - الصداع النصفي المفلوج لا يستعصي على الحل بدون حالة الصداع النصفي",
        clinics: ["Neurology"],
    },
    {
        value: "G43.411",
        label: "Hemiplegic migraine  intractable  with status migrainosus - الصداع النصفي المفلوج مستعصي على الحل مع حالة الصداع النصفي",
        clinics: ["Neurology"],
    },
    {
        value: "G43.419",
        label: "Hemiplegic migraine  intractable  without status migrainosus - الصداع النصفي المفلوج مستعصية على الحل بدون حالة الصداع النصفي",
        clinics: ["Neurology"],
    },
    {
        value: "G43.501",
        label: "Persistent migraine aura without cerebral infarction  not intractable  with status migrainosus - هالة الصداع النصفي المستمرة بدون احتشاء دماغي ليست مستعصية على الحل مع حالة الصداع النصفي",
        clinics: ["Neurology"],
    },
    {
        value: "G43.509",
        label: "Persistent migraine aura without cerebral infarction  not intractable  without status migrainosus - هالة الصداع النصفي المستمرة بدون احتشاء دماغي ليست مستعصية على الحل بدون حالة الصداع النصفي",
        clinics: ["Neurology"],
    },
    {
        value: "G43.511",
        label: "Persistent migraine aura without cerebral infarction  intractable  with status migrainosus - هالة الصداع النصفي المستمرة دون احتشاء دماغي مستعصية مع حالة الصداع النصفي",
        clinics: ["Neurology"],
    },
    {
        value: "G43.519",
        label: "Persistent migraine aura without cerebral infarction  intractable  without status migrainosus - هالة الصداع النصفي المستمرة بدون احتشاء دماغي مستعصية بدون حالة الصداع النصفي",
        clinics: ["Neurology"],
    },
    {
        value: "G43.601",
        label: "Persistent migraine aura with cerebral infarction  not intractable  with status migrainosus - هالة الصداع النصفي المستمرة مع احتشاء دماغي ليست مستعصية على الحل مع حالة الصداع النصفي",
        clinics: ["Neurology"],
    },
    {
        value: "G43.609",
        label: "Persistent migraine aura with cerebral infarction  not intractable  without status migrainosus - هالة الصداع النصفي المستمرة مع احتشاء دماغي غير مستعصية بدون حالة الصداع النصفي",
        clinics: ["Neurology"],
    },
    {
        value: "G43.611",
        label: "Persistent migraine aura with cerebral infarction  intractable  with status migrainosus - هالة الصداع النصفي المستمرة مع احتشاء دماغي مستعصية مع حالة الصداع النصفي",
        clinics: ["Neurology"],
    },
    {
        value: "G43.619",
        label: "Persistent migraine aura with cerebral infarction  intractable  without status migrainosus - هالة الصداع النصفي المستمرة مع احتشاء دماغي مستعصية بدون حالة الصداع النصفي",
        clinics: ["Neurology"],
    },
    {
        value: "G43.701",
        label: "Chronic migraine without aura  not intractable  with status migrainosus - الصداع النصفي المزمن بدون هالة لا يستعصي على الحل مع حالة الصداع النصفي",
        clinics: ["Neurology"],
    },
    {
        value: "G43.709",
        label: "Chronic migraine without aura  not intractable  without status migrainosus - الصداع النصفي المزمن بدون هالة لا يستعصي على الحل بدون حالة الصداع النصفي",
        clinics: ["Neurology"],
    },
    {
        value: "G43.711",
        label: "Chronic migraine without aura  intractable  with status migrainosus - الصداع النصفي المزمن بدون هالة مستعصية مع حالة الصداع النصفي",
        clinics: ["Neurology"],
    },
    {
        value: "G43.719",
        label: "Chronic migraine without aura  intractable  without status migrainosus - الصداع النصفي المزمن بدون هالة مستعصية بدون حالة الصداع النصفي",
        clinics: ["Neurology"],
    },
    {
        value: "G43.801",
        label: "Other migraine  not intractable  with status migrainosus - الصداع النصفي الآخر لا يستعصي على الحل مع حالة الصداع النصفي",
        clinics: ["Neurology"],
    },
    {
        value: "G43.809",
        label: "Other migraine  not intractable  without status migrainosus - الصداع النصفي الآخر لا يستعصي على الحل بدون حالة الصداع النصفي",
        clinics: ["Neurology"],
    },
    {
        value: "G43.811",
        label: "Other migraine  intractable  with status migrainosus - الصداع النصفي الأخرى المستعصية مع حالة الصداع النصفي",
        clinics: ["Neurology"],
    },
    {
        value: "G43.819",
        label: "Other migraine  intractable  without status migrainosus - الصداع النصفي الأخرى المستعصية بدون حالة الصداع النصفي",
        clinics: ["Neurology"],
    },
    {
        value: "G43.821",
        label: "Menstrual migraine  not intractable  with status migrainosus - الصداع النصفي أثناء الدورة الشهرية لا يستعصي على الحل مع حالة الصداع النصفي",
        clinics: ["Neurology"],
    },
    {
        value: "G43.829",
        label: "Menstrual migraine  not intractable  without status migrainosus - الصداع النصفي أثناء الدورة الشهرية لا يستعصي على الحل بدون حالة الصداع النصفي",
        clinics: ["Neurology"],
    },
    {
        value: "G43.831",
        label: "Menstrual migraine  intractable  with status migrainosus - الصداع النصفي أثناء الدورة الشهرية مستعصية على الحل مع حالة الصداع النصفي",
        clinics: ["Neurology"],
    },
    {
        value: "G43.839",
        label: "Menstrual migraine  intractable  without status migrainosus - الصداع النصفي الحيضي مستعصي على الحل دون حالة الصداع النصفي",
        clinics: ["Neurology"],
    },
    {
        value: "G43.901",
        label: "Migraine  unspecified  not intractable  with status migrainosus - الصداع النصفي غير محدد لا يستعصي على الحل مع حالة الصداع النصفي",
        clinics: ["Neurology"],
    },
    {
        value: "G43.909",
        label: "Migraine  unspecified  not intractable  without status migrainosus - الصداع النصفي غير محدد لا يستعصي على الحل بدون حالة الصداع النصفي",
        clinics: ["Neurology"],
    },
    {
        value: "G43.911",
        label: "Migraine  unspecified  intractable  with status migrainosus - الصداع النصفي المستعصية غير المحددة مع حالة الصداع النصفي",
        clinics: ["Neurology"],
    },
    {
        value: "G43.919",
        label: "Migraine  unspecified  intractable  without status migrainosus - الصداع النصفي مستعصية غير محددة بدون حالة الصداع النصفي",
        clinics: ["Neurology"],
    },
    {
        value: "G43.A1",
        label: "Cyclical vomiting  intractable - القيء الدوري المستعصية",
        clinics: ["Neurology"],
    },
    {
        value: "G43.B0",
        label: "Ophthalmoplegic migraine  not intractable - الصداع النصفي المرتبط بشلل العين لا يستعصي على الحل",
        clinics: ["Neurology"],
    },
    {
        value: "G43.B1",
        label: "Ophthalmoplegic migraine  intractable - مستعصية على علاج الصداع النصفي المرتبط بشلل العين",
        clinics: ["Neurology"],
    },
    {
        value: "G43.C0",
        label: "Periodic headache syndromes in child or adult  not intractable - متلازمات الصداع الدوري عند الأطفال أو البالغين ليست مستعصية على الحل",
        clinics: ["Neurology"],
    },
    {
        value: "G43.C1",
        label: "Periodic headache syndromes in child or adult  intractable - متلازمات الصداع الدوري عند الأطفال أو الكبار مستعصية على الحل",
        clinics: ["Neurology"],
    },
    {
        value: "G43.D0",
        label: "Abdominal migraine  not intractable - الصداع النصفي البطني لا يستعصي على الحل",
        clinics: ["Neurology"],
    },
    {
        value: "G43.D1",
        label: "Abdominal migraine  intractable - الصداع النصفي البطني مستعصية على الحل",
        clinics: ["Neurology"],
    },
    {
        value: "G47.30",
        label: "Sleep apnea  unspecified - توقف التنفس أثناء النوم غير محدد",
        clinics: ["Neurology"],
    },
    {
        value: "G47.31",
        label: "Primary central sleep apnea - انقطاع النفس النومي المركزي الأساسي",
        clinics: ["Neurology"],
    },
    {
        value: "G47.32",
        label: "High altitude periodic breathing - التنفس الدوري على ارتفاعات عالية",
        clinics: ["Neurology"],
    },
    {
        value: "G47.33",
        label: "Obstructive sleep apnea (adult) (pediatric) - انقطاع النفس الانسدادي النومي (للبالغين) (طب الأطفال)",
        clinics: ["Neurology", "Pulmonology"],
    },
    {
        value: "G47.34",
        label: "Idiopathic sleep related nonobstructive alveolar hypoventilation - النوم مجهول السبب المرتبط بنقص التهوية السنخي غير الانسدادي",
        clinics: ["Neurology"],
    },
    {
        value: "G47.35",
        label: "Congenital central alveolar hypoventilation syndrome - متلازمة نقص التهوية السنخية المركزية الخلقية",
        clinics: ["Neurology"],
    },
    {
        value: "G47.36",
        label: "Sleep related hypoventilation in conditions classified elsewhere - نقص التهوية المرتبط بالنوم في حالات مصنفة في مكان آخر",
        clinics: ["Neurology"],
    },
    {
        value: "G47.37",
        label: "Central sleep apnea in conditions classified elsewhere - انقطاع النفس النومي المركزي في حالات مصنفة في مكان آخر",
        clinics: ["Neurology"],
    },
    {
        value: "G47.39",
        label: "Other sleep apnea - توقف التنفس أثناء النوم",
        clinics: ["Neurology"],
    },
    { value: "G30.0", label: "Alzheimer", clinics: ["Neurology"] },
    { value: "G30.1", label: "Alzheimer", clinics: ["Neurology"] },
    { value: "G30.8", label: "Other Alzheimer", clinics: ["Neurology"] },
    { value: "G25.0", label: "Essential tremor - رعاش", clinics: ["Neurology"] },
    {
        value: "G25.1",
        label: "Drug-induced tremor - الرعاش الناجم عن المخدرات",
        clinics: ["Neurology"],
    },
    {
        value: "G25.2",
        label: "Other specified forms of tremor - أشكال أخرى محددة من الرعاش",
        clinics: ["Neurology"],
    },
    {
        value: "H81.11",
        label: "Benign paroxysmal vertigo  right ear - الدوار الانتيابي الحميد في الأذن اليمنى",
        clinics: ["Neurology"],
    },
    {
        value: "H81.12",
        label: "Benign paroxysmal vertigo  left ear - الدوار الانتيابي الحميد في الأذن اليسرى",
        clinics: ["Neurology"],
    },
    {
        value: "H81.13",
        label: "Benign paroxysmal vertigo  bilateral - الدوار الانتيابي الحميد ثنائي",
        clinics: ["Neurology"],
    },
    {
        value: "R19.6",
        label: "Halitosis - رائحة الفم الكريهة",
        clinics: ["Neurology"],
    },
    { value: "R47.02", label: "Dysphasia - خلل الكلام", clinics: ["Neurology"] },
    {
        value: "R47.81",
        label: "Slurred speech - كلام غير واضح",
        clinics: ["Neurology"],
    },
    {
        value: "R47.82",
        label: "Fluency disorder in conditions classified elsewhere - اضطراب الطلاقة في حالات مصنفة في مكان آخر",
        clinics: ["Neurology"],
    },
    {
        value: "R47.89",
        label: "Other speech disturbances - اضطرابات الكلام الأخرى",
        clinics: ["Neurology"],
    },
    {
        value: "R47.9",
        label: "Unspecified speech disturbances - اضطرابات الكلام غير المحددة",
        clinics: ["Neurology"],
    },
    {
        value: "R48.0",
        label: "Dyslexia and alexia - عسر القراءة والكسسيا",
        clinics: ["Neurology"],
    },
    { value: "R48.1", label: "Agnosia - Agnosia", clinics: ["Neurology"] },
    { value: "R48.2", label: "Apraxia - اللاأدائية", clinics: ["Neurology"] },
    {
        value: "R48.3",
        label: "Visual agnosia - العمه البصري",
        clinics: ["Neurology"],
    },
    {
        value: "R48.8",
        label: "Other symbolic dysfunctions - اختلالات رمزية أخرى",
        clinics: ["Neurology"],
    },
    {
        value: "R48.9",
        label: "Unspecified symbolic dysfunctions - اختلالات رمزية غير محددة",
        clinics: ["Neurology"],
    },
    { value: "R49.0", label: "Dysphonia - خلل النطق", clinics: ["Neurology"] },
    { value: "R49.1", label: "Aphonia - أفونيا", clinics: ["Neurology"] },
    {
        value: "R49.21",
        label: "Hypernasality - فرط التنفس",
        clinics: ["Neurology"],
    },
    {
        value: "R49.22",
        label: "Hyponasality - نقص الصوت",
        clinics: ["Neurology"],
    },
    {
        value: "R49.8",
        label: "Other voice and resonance disorders - اضطرابات الصوت والرنين الأخرى",
        clinics: ["Neurology"],
    },
    {
        value: "R49.9",
        label: "Unspecified voice and resonance disorder - اضطراب الصوت والرنين غير المحدد",
        clinics: ["Neurology"],
    },
    {
        value: "R06.02",
        label: "Shortness of breath - ضيق في التنفس",
        clinics: ["Neurology"],
    },
    { value: "R06.1", label: "Stridor - صرير", clinics: ["Neurology"] },
    { value: "R06.2", label: "Wheezing - صفير", clinics: ["Neurology"] },
    {
        value: "R06.5",
        label: "Mouth breathing - التنفس الفم",
        clinics: ["Neurology"],
    },
    { value: "R06.6", label: "Hiccough - فواق", clinics: ["Neurology"] },
    { value: "R06.7", label: "Sneezing - العطس", clinics: ["Neurology"] },
    {
        value: "R06.81",
        label: "Apnea  not elsewhere classified - انقطاع النفس غير مصنف في مكان آخر",
        clinics: ["Neurology"],
    },
    {
        value: "R06.82",
        label: "Tachypnea  not elsewhere classified - تسرع النفس غير مصنف في مكان آخر",
        clinics: ["Neurology"],
    },
    { value: "R06.83", label: "Snoring - الشخير", clinics: ["Neurology"] },
    {
        value: "T67.1",
        label: "Heat syncope - إغماء الحرارة",
        clinics: ["Neurology"],
    },
    {
        value: "D46.4",
        label: "Refractory anemia - فقر الدم المقاوم",
        clinics: ["Hematology"],
    },
    {
        value: "D46.9",
        label: "Myelodysplastic syndrome - متلازمة خلل التنسج النقوي",
        clinics: ["Hematology"],
    },
    {
        value: "D46.C",
        label: "5q minus syndrome - متلازمة 5q ناقص",
        clinics: ["Hematology"],
    },
    {
        value: "D47.3",
        label: "Essential thrombocythemia - كثرة الصفيحات الأساسية",
        clinics: ["Hematology"],
    },
    {
        value: "D47.9",
        label: "Myeloproliferative disorder - اضطراب التكاثر النقوي",
        clinics: ["Hematology"],
    },
    {
        value: "D56.0",
        label: "Hb-Bart’s disease - مرض Hb-Bart",
        clinics: ["Hematology"],
    },
    {
        value: "D56.3",
        label: "Thalessemia minor - الثلاسيميا الصغرى",
        clinics: ["Hematology"],
    },
    {
        value: "D56.9",
        label: "Thalessemia - الثلاسيميا",
        clinics: ["Hematology"],
    },
    {
        value: "D57.00",
        label: "Sickle-cell crisis - أزمة الخلايا المنجلية",
        clinics: ["Hematology"],
    },
    {
        value: "D57.1",
        label: "Sickle-cell anemia - فقر الدم المنجلي",
        clinics: ["Hematology"],
    },
    {
        value: "D58.2",
        label: "Hemoglobinopathy - اعتلال الهيموغلوبين",
        clinics: ["Hematology"],
    },
    {
        value: "D69.1",
        label: "Platelet dysfunction - ضعف الصفائح الدموية",
        clinics: ["Hematology"],
    },
    { value: "D69.2", label: "Purpura - البرفرية", clinics: ["Hematology"] },
    {
        value: "D69.41",
        label: "Evan’s syndrome - متلازمة إيفان",
        clinics: ["Hematology"],
    },
    {
        value: "D69.59",
        label: "Secondary thrombocytopenia - قلة الصفيحات الثانوية",
        clinics: ["Hematology"],
    },
    {
        value: "D69.6",
        label: "Thrombocytopenia - قلة الصفيحات",
        clinics: ["Hematology"],
    },
    {
        value: "D69.8",
        label: "Vascular fragility - هشاشة الأوعية الدموية",
        clinics: ["Hematology"],
    },
    {
        value: "D68.1",
        label: "Factor XI deficiency - نقص العامل الحادي عشر",
        clinics: ["Hematology"],
    },
    {
        value: "D68.2",
        label: "Factor V deficiency - نقص العامل الخامس",
        clinics: ["Hematology"],
    },
    {
        value: "D68.318",
        label: "Circulating anticoagulants - مضادات التخثر المنتشرة",
        clinics: ["Hematology"],
    },
    {
        value: "D68.59",
        label: "Hypercoagulable state - حالة فرط التخثر",
        clinics: ["Hematology"],
    },
    {
        value: "D68.8",
        label: "Coagulation defects - عيوب التخثر",
        clinics: ["Hematology"],
    },
    {
        value: "D68.9",
        label: "Anticoagulation state - حالة منع تخثر الدم",
        clinics: ["Hematology"],
    },
    {
        value: "D70.3",
        label: "Neutropenia due to infection - قلة العدلات بسبب العدوى",
        clinics: ["Hematology"],
    },
    {
        value: "D70.9",
        label: "Agranulocytosis - ندرة المحببات",
        clinics: ["Hematology"],
    },
    {
        value: "D72.1",
        label: "Eosinophilia - فرط الحمضات",
        clinics: ["Hematology"],
    },
    {
        value: "D72.810",
        label: "Lymphocytopenia - قلة اللمفاويات",
        clinics: ["Hematology"],
    },
    {
        value: "D72.818",
        label: "Monocytopenia - قلة الكريات البيض",
        clinics: ["Hematology"],
    },
    {
        value: "D72.819",
        label: "Decreased white blood cells - قلة خلايا الدم البيضاء",
        clinics: ["Hematology"],
    },
    {
        value: "D72.820",
        label: "Increased white blood cells - زيادة خلايا الدم البيضاء",
        clinics: ["Hematology"],
    },
    {
        value: "D72.821",
        label: "Monocytosis - كثرة الوحيدات",
        clinics: ["Hematology"],
    },
    { value: "D72.825", label: "Bandemia - باندميا", clinics: ["Hematology"] },
    {
        value: "D72.829",
        label: "Leukocytosis - زيادة عدد الكريات البيضاء",
        clinics: ["Hematology"],
    },
    {
        value: "D50.0",
        label: "Chronic blood loss anemia - فقر الدم الناجم عن فقدان الدم المزمن",
        clinics: ["Hematology"],
    },
    {
        value: "D50.9",
        label: "Iron deficient anemia - فقر الدم الناجم عن نقص الحديد",
        clinics: ["Hematology"],
    },
    {
        value: "D51.0",
        label: "Pernicious anemia - فقر الدم الخبيث",
        clinics: ["Hematology"],
    },
    {
        value: "D51.9",
        label: "B12 deficiency anemia - فقر الدم الناجم عن نقص فيتامين ب 12",
        clinics: ["Hematology"],
    },
    {
        value: "D53.0",
        label: "Amino-acid-deficiency anemia - فقر الدم الناجم عن نقص الأحماض الأمينية",
        clinics: ["Hematology"],
    },
    {
        value: "D53.1",
        label: "Megaloblastic anemia - فقر الدم الضخم الأرومات",
        clinics: ["Hematology"],
    },
    {
        value: "D53.9",
        label: "Macrocytic anemia - فقر الدم كبير الخلايا",
        clinics: ["Hematology"],
    },
    {
        value: "D60.9",
        label: "Red cell aplasia - عدم تنسج الخلايا الحمراء",
        clinics: ["Hematology"],
    },
    {
        value: "D61.1",
        label: "Aplastic anemia due to drugs - فقر الدم اللاتنسجي بسبب الأدوية",
        clinics: ["Hematology"],
    },
    {
        value: "D61.2",
        label: "Aplastic anemia due to radiation - فقر الدم اللاتنسجي بسبب الإشعاع",
        clinics: ["Hematology"],
    },
    {
        value: "D61.3",
        label: "Aplastic idiopathic anemia - فقر الدم اللاتنسجي مجهول السبب",
        clinics: ["Hematology"],
    },
    {
        value: "D62",
        label: "Anemia due to acute blood loss - فقر الدم بسبب فقدان الدم الحاد",
        clinics: ["Hematology"],
    },
    {
        value: "D63.0",
        label: "Anemia in neoplastic disease - فقر الدم في أمراض الأورام",
        clinics: ["Hematology"],
    },
    {
        value: "D63.1",
        label: "Anemia in ESRD - فقر الدم في الداء الكلوي بمراحله الأخيرة",
        clinics: ["Hematology"],
    },
    {
        value: "D63.8",
        label: "Anemia in other chronic diseases - فقر الدم في الأمراض المزمنة الأخرى",
        clinics: ["Hematology"],
    },
    { value: "D64.9", label: "Anemia - فقر دم", clinics: ["Hematology"] },
    {
        value: "E83.119",
        label: "Hemochromatosis - داء ترسب الأصبغة الدموية",
        clinics: ["Hematology"],
    },
    {
        value: "C88.0",
        label: "Waldenstrom’s macroglobulinemia - غلوبولين الدم في والدنستروم",
        clinics: ["Hematology"],
    },
    {
        value: "C90.00",
        label: "Multiple myeloma - ورم نقيي متعدد",
        clinics: ["Hematology"],
    },
    {
        value: "D47.2",
        label: "Monoclonal gammopathy - الاعتلال الجامائي وحيد النسيلة",
        clinics: ["Hematology"],
    },
    {
        value: "C91.00",
        label: "Acute lymphocytic leukemia (ALL) - ابيضاض الدم الليمفاوي الحاد (الكل)",
        clinics: ["Hematology"],
    },
    {
        value: "C91.01",
        label: "Acute lymphocytic leukemia remission - مغفرة سرطان الدم الليمفاوي الحاد",
        clinics: ["Hematology"],
    },
    {
        value: "C91.10",
        label: "Chronic lymphocytic leukemia (CLL) - ابيضاض الدم الليمفاوي المزمن (CLL)",
        clinics: ["Hematology"],
    },
    {
        value: "C91.11",
        label: "Chronic lymphocytic leukemia remission - مغفرة سرطان الدم الليمفاوي المزمن",
        clinics: ["Hematology"],
    },
    {
        value: "C92.00",
        label: "Acute myeloid leukemia (AML) - ابيضاض الدم النخاعي الحاد (AML)",
        clinics: ["Hematology"],
    },
    {
        value: "C92.01",
        label: "Acute myeloid leukemia in remission - سرطان الدم النخاعي الحاد في مغفرة",
        clinics: ["Hematology"],
    },
    {
        value: "C92.10",
        label: "Chronic myeloid leukemia (CML) - ابيضاض الدم النخاعي المزمن (CML)",
        clinics: ["Hematology"],
    },
    {
        value: "C92.11",
        label: "Chronic myeloid leukemia remission - مغفرة سرطان الدم النخاعي المزمن",
        clinics: ["Hematology"],
    },
    {
        value: "C92.30",
        label: "Granulocytic sarcoma - ساركوما حبيبات",
        clinics: ["Hematology"],
    },
    {
        value: "C82.90",
        label: "Nodular lymphoma - سرطان الغدد الليمفاوية عقيدية",
        clinics: ["Hematology"],
    },
    {
        value: "C84.10",
        label: "Sezary disease - مرض سيزاري",
        clinics: ["Hematology"],
    },
    {
        value: "C84.40",
        label: "Peripheral T-cell leukemia - ابيضاض الدم المحيطي بالخلايا التائية",
        clinics: ["Hematology"],
    },
    {
        value: "C85.80",
        label: "Cutaneous lymphoma - سرطان الغدد الليمفاوية الجلدي",
        clinics: ["Hematology"],
    },
    {
        value: "C91.40",
        label: "Hairy-cell leukemia - ابيضاض الدم مشعر الخلايا",
        clinics: ["Hematology"],
    },
    {
        value: "C96.2",
        label: "Malignant mastocytosis - كثرة الخلايا البدينة الخبيثة",
        clinics: ["Hematology"],
    },
    {
        value: "C81.70",
        label: "Hodgkin’s paragranuloma - الورم الحبيبي هودجكين",
        clinics: ["Hematology"],
    },
    {
        value: "C81.79",
        label: "Hodgkin’s granuloma - الورم الحبيبي هودجكين",
        clinics: ["Hematology"],
    },
    {
        value: "C81.90",
        label: "Hodgkin’s disease - مرض هودكنز",
        clinics: ["Hematology"],
    },
    {
        value: "C18.9",
        label: "Colon cancer - سرطان القولون",
        clinics: ["Hematology"],
    },
    {
        value: "C33",
        label: "Tracheal cancer - سرطان القصبة الهوائية",
        clinics: ["Hematology", "ThoracicSurgery"],
    },
    {
        value: "C34.90",
        label: "Lung cancer - سرطان الرئة",
        clinics: ["Hematology"],
    },
    {
        value: "C41.9",
        label: "Bone cancer - سرطان العظام",
        clinics: ["Hematology"],
    },
    {
        value: "C46.0",
        label: "Kaposi’s sarcoma, skin - ساركوما كابوسي ، الجلد",
        clinics: ["Hematology"],
    },
    {
        value: "C46.50",
        label: "Kaposi’s sarcoma, lung - ساركوما كابوسي ، الرئة",
        clinics: ["Hematology"],
    },
    {
        value: "C46.9",
        label: "Kaposi’s sarcoma - ساركوما كابوزي",
        clinics: ["Hematology"],
    },
    {
        value: "C53.9",
        label: "Cervical cancer - سرطان عنق الرحم",
        clinics: ["Hematology"],
    },
    {
        value: "C57.00",
        label: "Fallopian cancer - سرطان فالوب",
        clinics: ["Hematology"],
    },
    {
        value: "C62.90",
        label: "Retinoblastoma - الورم الأرومي الشبكي",
        clinics: ["Hematology"],
    },
    {
        value: "C67.9",
        label: "Bladder cancer - سرطان المثانة",
        clinics: ["Hematology"],
    },
    {
        value: "C69.20",
        label: "Testicular cancer - سرطان الخصية",
        clinics: ["Hematology"],
    },
    {
        value: "C71.1",
        label: "Malignant glioma, frontal lobe - الورم الدبقي الخبيث ، الفص الجبهي",
        clinics: ["Hematology"],
    },
    {
        value: "C71.6",
        label: "Medulloblastoma, cerebellum - الورم الأرومي النخاعي ، المخيخ",
        clinics: ["Hematology"],
    },
    {
        value: "C79.51",
        label: "Secondary malignant neoplasm of bone - ورم خبيث ثانوي في العظام",
        clinics: ["Hematology"],
    },
    {
        value: "C79.52",
        label: "Secondary malignant neoplasm of bone marrow - ورم خبيث ثانوي في نخاع العظام",
        clinics: ["Hematology"],
    },
    {
        value: "D32.9",
        label: "Meningioma, cerebral - الورم السحائي الدماغي",
        clinics: ["Hematology"],
    },
    {
        value: "C43.4",
        label: "Melanoma, scalp - سرطان الجلد وفروة الرأس",
        clinics: ["Hematology"],
    },
    {
        value: "C43.9",
        label: "Melanoma, unspecified site - سرطان الجلد ، موقع غير محدد",
        clinics: ["Hematology"],
    },
    {
        value: "C44.211",
        label: "Basal cell carcinoma, ear - سرطان الخلايا القاعدية والأذن",
        clinics: ["Hematology"],
    },
    {
        value: "C44.221",
        label: "Squamous cell carcinoma, ear - سرطان الخلايا الحرشفية ، الأذن",
        clinics: ["Hematology"],
    },
    {
        value: "C44.319",
        label: "Basal cell carcinoma, forehead - سرطان الخلايا القاعدية ، الجبين",
        clinics: ["Hematology"],
    },
    {
        value: "C44.40",
        label: "Skin cancer, neck - سرطان الجلد والعنق",
        clinics: ["Hematology"],
    },
    {
        value: "C44.721",
        label: "Squamous cell carcinoma, hip - سرطان الخلايا الحرشفية ، الورك",
        clinics: ["Hematology"],
    },
    {
        value: "C44.90",
        label: "Skin cancer, unspecified - سرطان الجلد ، غير محدد",
        clinics: ["Hematology"],
    },
    {
        value: "C44.92",
        label: "Squamous cell carcinoma, unspecified - سرطان الخلايا الحرشفية ، غير محدد",
        clinics: ["Hematology", "Plastic Surgery", "Dermotology"],
    },
    {
        value: "CC44.91",
        label: "Basal cell carcinoma, unspecified - سرطان الخلايا القاعدية غير محدد",
        clinics: ["Hematology"],
    },
    {
        value: "Z85.828",
        label: "History of skin cancer - تاريخ من سرطان الجلد",
        clinics: ["Hematology"],
    },
    {
        value: "Z51.0",
        label: "Radiotherapy - العلاج الإشعاعي",
        clinics: ["Hematology"],
    },
    {
        value: "Z51.11",
        label: "Chemotherapy treatment - العلاج الكيميائي",
        clinics: ["Hematology"],
    },
    {
        value: "Z51.12",
        label: "Antineoplastic immunotherapy - العلاج المناعي لمضادات الأورام",
        clinics: ["Hematology"],
    },
    {
        value: "Z51.89",
        label: "Status post chemotherapy - حالة ما بعد العلاج الكيميائي",
        clinics: ["Hematology"],
    },
    {
        value: "Z90.10",
        label: "Status post mastectomy - حالة ما بعد استئصال الثدي",
        clinics: ["Hematology"],
    },
    {
        value: "Z90.49",
        label: "Status post intestine removal - إزالة حالة ما بعد الأمعاء",
        clinics: ["Hematology"],
    },
    {
        value: "Z94.6",
        label: "Status post bone transplant - حالة ما بعد زراعة العظام",
        clinics: ["Hematology"],
    },
    {
        value: "Z94.81",
        label: "Status post bone marrow transplant - حالة ما بعد زرع نخاع العظم",
        clinics: ["Hematology"],
    },
    {
        value: "Z98.89",
        label: "Postsurgical status - حالة ما بعد الجراحة",
        clinics: ["Hematology"],
    },
    {
        value: "R53.81",
        label: "Other malaise - توعك آخر",
        clinics: ["Hematology"],
    },
    {
        value: "R53.83",
        label: "Other fatigue - تعب آخر",
        clinics: ["Hematology"],
    },
    {
        value: "Z12.11",
        label: "Colon cancer screening - فحص سرطان القولون",
        clinics: ["Hematology"],
    },
    {
        value: "Z12.4",
        label: "Cervical cancer screening - فحص سرطان عنق الرحم",
        clinics: ["Hematology"],
    },
    {
        value: "Z12.5",
        label: "Prostate cancer screening - فحص سرطان البروستاتا",
        clinics: ["Hematology", "Urology"],
    },
    {
        value: "C50.011",
        label: "Malignant neoplasm of nipple and areola of right female breast - ورم خبيث في الحلمة وهالة الثدي الأيمن للأنثى",
        clinics: ["Hematology"],
    },
    {
        value: "C50.012",
        label: "Malignant neoplasm of nipple and areola of left female breast - ورم خبيث في الحلمة وهالة الثدي الأيسر للأنثى",
        clinics: ["Hematology"],
    },
    {
        value: "C50.019",
        label: "Malignant neoplasm of nipple and areola of unspecified female breast - ورم خبيث في الحلمة وهالة الثدي الأنثوي غير المحدد",
        clinics: ["Hematology"],
    },
    {
        value: "C50.021",
        label: "Malignant neoplasm of nipple and areola of right male breast - ورم خبيث في الحلمة وهالة الثدي الأيمن للرجل",
        clinics: ["Hematology"],
    },
    {
        value: "C50.022",
        label: "Malignant neoplasm of nipple and areola of leftmale breast - ورم خبيث في الحلمة وهالة الثدي الأيسر",
        clinics: ["Hematology"],
    },
    {
        value: "C50.029",
        label: "Malignant neoplasm of nipple and areola of unspecified male breast - ورم خبيث في الحلمة والهالة من ثدي ذكر غير محدد",
        clinics: ["Hematology"],
    },
    {
        value: "C50.111",
        label: "Malignant neoplasm of central portion of right female breast - ورم خبيث في الجزء المركزي من الثدي الأيمن للإناث",
        clinics: ["Hematology"],
    },
    {
        value: "C50.112",
        label: "Malignant neoplasm of central portion of left female breast - ورم خبيث في الجزء المركزي من ثدي الأنثى الأيسر",
        clinics: ["Hematology"],
    },
    {
        value: "C50.119",
        label: "Malignant neoplasm of central portion of unspecified female breast - ورم خبيث في الجزء المركزي من ثدي أنثى غير محدد",
        clinics: ["Hematology"],
    },
    {
        value: "C50.211",
        label: "Malignant neoplasm of upper-inner quadrant of right female breast - ورم خبيث في الربع العلوي الداخلي من الثدي الأيمن للأنثى",
        clinics: ["Hematology"],
    },
    {
        value: "C50.212",
        label: "Malignant neoplasm of upper-inner quadrant of left female breast - ورم خبيث في الربع العلوي الداخلي لثدي الأنثى الأيسر",
        clinics: ["Hematology"],
    },
    {
        value: "C50.219",
        label: "Malignant neoplasm of upper-inner quadrant of unspecified female breast - ورم خبيث في الربع العلوي الداخلي لثدي أنثى غير محدد",
        clinics: ["Hematology"],
    },
    {
        value: "C50.311",
        label: "Malignant neoplasm of lower-inner quadrant of right female breast - ورم خبيث في الربع الداخلي السفلي من الثدي الأيمن للإناث",
        clinics: ["Hematology"],
    },
    {
        value: "C50.312",
        label: "Malignant neoplasm of lower-inner quadrant of left female breast - ورم خبيث في الربع الداخلي السفلي لثدي الأنثى الأيسر",
        clinics: ["Hematology"],
    },
    {
        value: "C50.319",
        label: "Malignant neoplasm of lower-inner quadrant of unspecified female breast - ورم خبيث في الربع الداخلي السفلي لثدي أنثى غير محدد",
        clinics: ["Hematology"],
    },
    {
        value: "C50.511",
        label: "Malignant neoplasm of lower-outer quadrant of right female breast - ورم خبيث في الربع السفلي الخارجي لثدي الأنثى الأيمن",
        clinics: ["Hematology"],
    },
    {
        value: "C50.512",
        label: "Malignant neoplasm of lower-outer quadrant of left female breast - ورم خبيث في الربع السفلي الخارجي لثدي الأنثى الأيسر",
        clinics: ["Hematology"],
    },
    {
        value: "C50.519",
        label: "Malignant neoplasm of lower-outer quadrant of unspecified female breast - ورم خبيث في الربع السفلي الخارجي لثدي أنثى غير محدد",
        clinics: ["Hematology"],
    },
    {
        value: "C50.611",
        label: "Malignant neoplasm of axillary tail of right female breast - ورم خبيث من الذيل الإبطي لثدي الأنثى الأيمن",
        clinics: ["Hematology"],
    },
    {
        value: "C50.612",
        label: "Malignant neoplasm of axillary tail of left female breast - ورم خبيث من الذيل الإبطي لثدي الأنثى الأيسر",
        clinics: ["Hematology"],
    },
    {
        value: "C50.619",
        label: "Malignant neoplasm of axillary tail of unspecified female breast - ورم خبيث من الذيل الإبطي لثدي أنثى غير محدد",
        clinics: ["Hematology"],
    },
    {
        value: "C50.811",
        label: "Malignant neoplasm of overlapping sites of right female breast - ورم خبيث في مواقع متداخلة من الثدي الأيمن للإناث",
        clinics: ["Hematology"],
    },
    {
        value: "C50.812",
        label: "Malignant neoplasm of overlapping sites of left female breast - ورم خبيث في المواقع المتداخلة لثدي الأنثى الأيسر",
        clinics: ["Hematology"],
    },
    {
        value: "C50.819",
        label: "Malignant neoplasm of overlapping sites of unspecified female breast - ورم خبيث لمواقع متداخلة لثدي أنثى غير محدد",
        clinics: ["Hematology"],
    },
    {
        value: "C50.911",
        label: "Malignant neoplasm of unspecified site of right female breast - ورم خبيث في موقع غير محدد من الثدي الأيمن للأنثى",
        clinics: ["Hematology"],
    },
    {
        value: "C50.912",
        label: "Malignant neoplasm of unspecified site of left female breast - ورم خبيث من موقع غير محدد لثدي الأنثى الأيسر",
        clinics: ["Hematology"],
    },
    {
        value: "C50.919",
        label: "Malignant neoplasm of unspecified site of unspecified female breast - ورم خبيث من موقع غير محدد لثدي أنثى غير محدد",
        clinics: ["Hematology"],
    },
    {
        value: "C50.921",
        label: "Malignant neoplasm of unspecified site of right male breas - ورم خبيث لموقع غير محدد من ذكور الشحوم الأيمن",
        clinics: ["Hematology"],
    },
    {
        value: "C50.922",
        label: "Malignant neoplasm of unspecified site of left male breast - ورم خبيث من موقع غير محدد لثدي الرجل الأيسر",
        clinics: ["Hematology"],
    },
    {
        value: "C50.929",
        label: "Malignant neoplasm of unspecified site of unspecified male breast - ورم خبيث لموقع غير محدد لثدي ذكر غير محدد",
        clinics: ["Hematology"],
    },
    {
        value: "C50411",
        label: "Malignant neoplasm of upper-outer quadrant of right female breast - ورم خبيث في الربع العلوي الخارجي لثدي الأنثى الأيمن",
        clinics: ["Hematology"],
    },
    {
        value: "C50412",
        label: "Malignant neoplasm of upper-outer quadrant of left female breast - ورم خبيث في الربع العلوي الخارجي لثدي الأنثى الأيسر",
        clinics: ["Hematology"],
    },
    {
        value: "C50419",
        label: "Malignant neoplasm of upper-outer quadrant of unspecified female breast - ورم خبيث في الربع العلوي الخارجي لثدي أنثى غير محدد",
        clinics: ["Hematology"],
    },
    {
        value: "Z80.3",
        label: "Family history of malignant neoplasm of breast - تاريخ عائلي للإصابة بورم خبيث في الثدي",
        clinics: ["Hematology"],
    },
    {
        value: "Z85.3",
        label: "Personal history of malignant neoplasm of breast - التاريخ الشخصي للأورام الخبيثة في الثدي",
        clinics: ["Hematology"],
    },
    {
        value: "C56.1",
        label: "Malignant neoplasm of right ovary - ورم خبيث في المبيض الأيمن",
        clinics: ["Hematology"],
    },
    {
        value: "C56.2",
        label: "Malignant neoplasm of left ovary - ورم خبيث من المبيض الأيسر",
        clinics: ["Hematology"],
    },
    {
        value: "C56.9",
        label: "Malignant neoplasm of unspecified ovary - ورم خبيث لمبيض غير محدد",
        clinics: ["Hematology"],
    },
    {
        value: "C79.60",
        label: "Secondary malignant neoplasm of unspecified ovary - ورم خبيث ثانوي لمبيض غير محدد",
        clinics: ["Hematology"],
    },
    {
        value: "C79.61",
        label: "Secondary malignant neoplasm of right ovary - ورم خبيث ثانوي للمبيض الأيمن",
        clinics: ["Hematology"],
    },
    {
        value: "C79.62",
        label: "Secondary malignant neoplasm of left ovary - ورم خبيث ثانوي للمبيض الأيسر",
        clinics: ["Hematology"],
    },
    {
        value: "C79.81",
        label: "Secondary malignant neoplasm of breast - ورم خبيث ثانوي بالثدي",
        clinics: ["Hematology"],
    },
    {
        value: "D05.90",
        label: "Unspecified type of carcinoma in situ of unspecified breast - نوع غير محدد من السرطانات في مكان الثدي غير المحدد",
        clinics: ["Hematology"],
    },
    {
        value: "D05.91",
        label: "Unspecified type of carcinoma in situ of right breast - نوع غير محدد من السرطان في الثدي الأيمن",
        clinics: ["Hematology"],
    },
    {
        value: "D05.92",
        label: "Unspecified type of carcinoma in situ of left breast - نوع غير محدد من السرطان في الثدي الأيسر",
        clinics: ["Hematology"],
    },
    {
        value: "D07.39",
        label: "Carcinoma in situ of other female genital organs - سرطان في الأعضاء التناسلية الأنثوية الأخرى",
        clinics: ["Hematology"],
    },
    {
        value: "Z80.41",
        label: "Family history of malignant neoplasm of ovary - تاريخ عائلي للإصابة بورم خبيث في المبيض",
        clinics: ["Hematology"],
    },
    {
        value: "Z85.43",
        label: "Personal history of malignant neoplasm of ovary - التاريخ الشخصي للأورام الخبيثة في المبيض",
        clinics: ["Hematology"],
    },
    {
        value: "C25.9",
        label: "Malignant neoplasm of pancreas, unspecified - ورم خبيث في البنكرياس غير محدد",
        clinics: ["Hematology"],
    },
    {
        value: "N63",
        label: "Unspecified lump in breast - تورم غير محدد في الثدي",
        clinics: ["Hematology"],
    },
    {
        value: "Z15.01",
        label: "Genetic susceptibility to malignant neoplasm of breast - القابلية الجينية لأورام الثدي الخبيثة",
        clinics: ["Hematology"],
    },
    {
        value: "Z15.02",
        label: "Genetic susceptibility to malignant neoplasm of ovary - القابلية الوراثية لأورام المبيض الخبيثة",
        clinics: ["Hematology"],
    },
    {
        value: "Z31.430",
        label: "Encounter of female for testing for genetic disease carrier status for procreative management - لقاء أنثى لفحص حالة حامل المرض الوراثي للإدارة الإنجابية",
        clinics: ["Hematology"],
    },
    {
        value: "Z31.440",
        label: "Encounter of male for testing for genetic disease carrier status for procreative management - لقاء ذكر لفحص حالة حامل المرض الجيني للإدارة الإنجابية",
        clinics: ["Hematology"],
    },
    {
        value: "Z80.0",
        label: "Family history of malignant neoplasm of digestive organs - تاريخ عائلي للإصابة بأورام خبيثة في الجهاز الهضمي",
        clinics: ["Hematology"],
    },
    {
        value: "Z80.8",
        label: "Family history of malignant neoplasm of other organs or systems - التاريخ العائلي للإصابة بالأورام الخبيثة في الأعضاء أو الأنظمة الأخرى",
        clinics: ["Hematology"],
    },
    {
        value: "Z84.81",
        label: "Family history of carrier of genetic disease - التاريخ العائلي لحاملة المرض الوراثي",
        clinics: ["Hematology"],
    },
    {
        value: "Z85.07",
        label: "Personal history of malignant neoplasm of pancreas - التاريخ الشخصي للأورام الخبيثة في البنكرياس",
        clinics: ["Hematology"],
    },
    {
        value: "T20",
        label: "Burn or corrosion of head, face, and neck - حرق أو تآكل في الرأس والوجه والرقبة",
        clinics: ["Emergency General", "Plastic Surgery", "Pediatric"],
    },
    {
        value: "T21",
        label: "Burn or corrosion of trunk - حرق أو تآكل الجذع",
        clinics: ["Emergency General", "Plastic Surgery", "Pediatric"],
    },
    {
        value: "T22",
        label: "Burn or corrosion of shoulder and upper limb, except wrist and hand - حروق أو تآكل في الكتف والطرف العلوي ، باستثناء الرسغ واليد",
        clinics: ["Emergency General", "Plastic Surgery", "Pediatric"],
    },
    {
        value: "T23",
        label: "Burn or corrosion of wrist and hand - حرق أو تآكل الرسغ واليد",
        clinics: ["Emergency General", "Plastic Surgery", "Pediatric"],
    },
    {
        value: "T24",
        label: "Burn or corrosion of lower limb, except ankle and foot - حرق أو تآكل في الطرف السفلي ، باستثناء الكاحل والقدم",
        clinics: ["Emergency General", "Plastic Surgery", "Pediatric"],
    },
    {
        value: "T25",
        label: "Burn or corrosion of ankle and foot - حروق أو تآكل في الكاحل والقدم",
        clinics: ["Emergency General", "Plastic Surgery", "Pediatric"],
    },
    {
        value: "Q36",
        label: "Cleft lip - شفة أرنبية",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "C44.91",
        label: "Basal cell carcinoma of skin, unspecified - سرطان الخلايا القاعدية للجلد ، غير محدد",
        clinics: ["Plastic Surgery", "Dermotology"],
    },
    {
        value: "S01",
        label: "Open wound of head - فتح جرح في الرأس",
        clinics: ["Emergency General", "Plastic Surgery"],
    },
    {
        value: "S11",
        label: "Open wound of neck - جرح مفتوح في الرقبة",
        clinics: ["Emergency General", "Plastic Surgery"],
    },
    {
        value: "S31",
        label: "Open wound of abdomen, lower back, pelvis and external genitals - جرح مفتوح في البطن وأسفل الظهر والحوض والأعضاء التناسلية الخارجية",
        clinics: ["Emergency General", "Plastic Surgery"],
    },
    {
        value: "S41",
        label: "Open wound of shoulder and upper arm - جرح مفتوح في الكتف والذراع العلوي",
        clinics: ["Emergency General", "Plastic Surgery"],
    },
    {
        value: "S71",
        label: "Open wound of hip and thigh - جرح مفتوح في الورك والفخذ",
        clinics: ["Emergency General", "Plastic Surgery"],
    },
    {
        value: "S81",
        label: "Open wound of knee and lower leg - جرح مفتوح في الركبة وأسفل الساق",
        clinics: ["Emergency General", "Plastic Surgery"],
    },
    {
        value: "S30",
        label: "Superficial injury of abdomen, lower back, pelvis and external genitals - الإصابة السطحية للبطن وأسفل الظهر والحوض والأعضاء التناسلية الخارجية",
        clinics: ["Emergency General", "Plastic Surgery"],
    },
    {
        value: "S40",
        label: "Superficial injury of shoulder and upper arm - إصابة سطحية في الكتف وأعلى الذراع",
        clinics: ["Emergency General", "Plastic Surgery"],
    },
    {
        value: "S70",
        label: "Superficial injury of hip and thigh - إصابة سطحية بالورك والفخذ",
        clinics: ["Emergency General", "Plastic Surgery"],
    },
    {
        value: "S80",
        label: "Superficial injury of knee and lower leg - إصابة سطحية في الركبة وأسفل الساق",
        clinics: ["Emergency General", "Plastic Surgery"],
    },
    {
        value: "S90",
        label: "Superficial injury of ankle and foot - إصابة سطحية في الكاحل والقدم",
        clinics: ["Emergency General", "Plastic Surgery"],
    },
    {
        value: "S02",
        label: "Fracture of skull and facial bones - كسر في الجمجمة وعظام الوجه",
        clinics: ["Emergency General"],
    },
    {
        value: "S22",
        label: "Fracture of rib(s),  sternum and thoracic spine  - كسر في الضلع والقص والعمود الفقري الصدري",
        clinics: ["Emergency General"],
    },
    {
        value: "S32",
        label: "Fracture of lumbar spine and pelvis - كسر في العمود الفقري القطني والحوض",
        clinics: ["Emergency General"],
    },
    {
        value: "S42",
        label: "Fracture of shoulder and upper arm  - كسر في الكتف والذراع",
        clinics: ["Emergency General"],
    },
    {
        value: "S82",
        label: "Fracture of lower leg, including ankle  - كسر في أسفل الساق ، بما في ذلك الكاحل",
        clinics: ["Emergency General"],
    },
    {
        value: "S92",
        label: "Fracture of foot and toe, except ankle  - كسر في القدم وأصابع القدم باستثناء الكاحل",
        clinics: ["Emergency General"],
    },
    {
        value: "T50",
        label: "Poisoning by, adverse effect of and underdosing of diuretics and other and unspecified drugs, medicaments and biological substances - التسمم والتأثير الضار ونقص الجرعات من مدرات البول وغيرها من العقاقير والأدوية والمواد البيولوجية وغير المحددة",
        clinics: ["Emergency General"],
    },
    {
        value: "R99",
        label: "Ill-defined and unknown cause of mortality - سبب غير محدد للوفاة وغير معروف",
        clinics: ["Emergency General"],
    },
    {
        value: "R06",
        label: "Abnormalities of breathing - مشاكل في التنفس",
        clinics: ["Emergency General"],
    },
    {
        value: "C15.9",
        label: "Malignant neoplasm of esophagus, unspecified - ورم خبيث في المريء غير محدد",
        clinics: ["ThoracicSurgery"],
    },
    {
        value: "C16.0",
        label: "Malignant neoplasm of cardia - الأورام الخبيثة في القلب",
        clinics: ["ThoracicSurgery"],
    },
    {
        value: "C34.00",
        label: "Malignant neoplasm of unspecified main bronchus - ورم خبيث من القصبات الهوائية الرئيسية غير المحددة",
        clinics: ["ThoracicSurgery"],
    },
    {
        value: "C34.10",
        label: "Malignant neoplasm of upper lobe, unspecified bronchus or lung - ورم خبيث من الفص العلوي ، القصبات الهوائية أو الرئة غير محدد",
        clinics: ["ThoracicSurgery"],
    },
    {
        value: "C34.11",
        label: "Malignant neoplasm of upper lobe, right bronchus or lung - ورم خبيث في الفص العلوي أو القصبات الهوائية اليمنى أو الرئة",
        clinics: ["ThoracicSurgery", "Pulmonology"],
    },
    {
        value: "C34.12",
        label: "Malignant neoplasm of upper lobe, left bronchus or lung - ورم خبيث في الفص العلوي أو القصبة الهوائية اليسرى أو الرئة",
        clinics: ["ThoracicSurgery", "Pulmonology"],
    },
    {
        value: "C34.2",
        label: "Malignant neoplasm of middle lobe, bronchus or lung - ورم خبيث في الفص الأوسط أو القصبات الهوائية أو الرئة",
        clinics: ["ThoracicSurgery", "Pulmonology"],
    },
    {
        value: "C34.30",
        label: "Malignant neoplasm of lower lobe, unspecified bronchus or lung - ورم خبيث من الفص السفلي ، القصبات الهوائية أو الرئة غير محدد",
        clinics: ["ThoracicSurgery"],
    },
    {
        value: "C34.31",
        label: "Malignant neoplasm of lower lobe, right bronchus or lung - ورم خبيث في الفص السفلي أو القصبات الهوائية اليمنى أو الرئة",
        clinics: ["ThoracicSurgery", "Pulmonology"],
    },
    {
        value: "C34.32",
        label: "Malignant neoplasm of lower lobe, left bronchus or lung - ورم خبيث في الفص السفلي أو القصبة الهوائية اليسرى أو الرئة",
        clinics: ["ThoracicSurgery", "Pulmonology"],
    },
    {
        value: "C45.0",
        label: "Mesothelioma of pleura - ورم الظهارة المتوسطة من غشاء الجنب",
        clinics: ["ThoracicSurgery"],
    },
    {
        value: "C37",
        label: "Malignant neoplasm of thymus - ورم خبيث من الغدة الصعترية",
        clinics: ["ThoracicSurgery"],
    },
    {
        value: "C38.1",
        label: "Malignant neoplasm of anterior mediastinum - ورم خبيث في المنصف الأمامي",
        clinics: ["ThoracicSurgery", "Pulmonology"],
    },
    {
        value: "C38.2",
        label: "Malignant neoplasm of posterior mediastinum - ورم خبيث في المنصف الخلفي",
        clinics: ["ThoracicSurgery", "Pulmonology"],
    },
    {
        value: "C49.3",
        label: "Malignant neoplasm of connective and soft tissue of thorax - ورم خبيث من الأنسجة الضامة والناعمة للصدر",
        clinics: ["ThoracicSurgery"],
    },
    {
        value: "C77.1",
        label: "Secondary and unspecified malignant neoplasm of intrathoracic lymph nodes - ورم خبيث ثانوي وغير محدد من الغدد الليمفاوية داخل الصدر",
        clinics: ["ThoracicSurgery"],
    },
    {
        value: "C78.00",
        label: "Secondary malignant neoplasm of unspecified lung - ورم خبيث ثانوي غير محدد بالرئة",
        clinics: ["ThoracicSurgery", "Pulmonology"],
    },
    {
        value: "C7A.090",
        label: "Malignant carcinoid tumor of the bronchus and lung - ورم سرطاوي خبيث يصيب القصبات الهوائية والرئة",
        clinics: ["ThoracicSurgery", "Pulmonology"],
    },
    {
        value: "D14.2",
        label: "Benign neoplasm of trachea - ورم حميد في القصبة الهوائية",
        clinics: ["ThoracicSurgery", "Pulmonology"],
    },
    {
        value: "E04.9",
        label: "Nontoxic goiter, unspecified - سلعة درقية",
        clinics: ["Endocrinology", "ThoracicSurgery", "Pulmonology"],
    },
    {
        value: "E32.8",
        label: "Other diseases of thymus - أمراض الغدة الصعترية الأخرى",
        clinics: ["ThoracicSurgery", "Pulmonology"],
    },
    {
        value: "G70.00",
        label: "Myasthenia gravis without (acute) exacerbation - الوهن العضلي الوبيل دون تفاقم (حاد)",
        clinics: ["ThoracicSurgery"],
    },
    {
        value: "J38.01",
        label: "Paralysis of vocal cords and larynx, unilateral - شلل الأحبال الصوتية والحنجرة أحادي الجانب",
        clinics: ["ThoracicSurgery"],
    },
    {
        value: "J38.02",
        label: "Paralysis of vocal cords and larynx, bilateral - شلل الاحبال الصوتية والحنجرة ثنائي",
        clinics: ["ThoracicSurgery"],
    },
    {
        value: "J38.6",
        label: "Stenosis of larynx - تضيق الحنجرة",
        clinics: ["ThoracicSurgery"],
    },
    {
        value: "J43.9",
        label: "Emphysema, unspecified - انتفاخ الرئة غير محدد",
        clinics: ["ThoracicSurgery", "Pulmonology"],
    },
    {
        value: "J47.9",
        label: "Bronchiectasis, uncomplicated - توسع القصبات ، غير معقد",
        clinics: ["ThoracicSurgery", "Pulmonology"],
    },
    {
        value: "J92.0",
        label: "Pleural plaque with presence of asbestos - البلاك الجنبي مع وجود الاسبستوس",
        clinics: ["ThoracicSurgery"],
    },
    {
        value: "J91.0",
        label: "Malignant pleural effusion - الانصباب الجنبي الخبيث",
        clinics: ["ThoracicSurgery", "Pulmonology"],
    },
    {
        value: "J95.811",
        label: "Postprocedural pneumothorax - استرواح الصدر بعد الجراحة",
        clinics: ["ThoracicSurgery"],
    },
    {
        value: "J95.812",
        label: "Postprocedural air leak - تسرب الهواء بعد الإجراءات",
        clinics: ["ThoracicSurgery"],
    },
    {
        value: "J93.11",
        label: "Primary spontaneous pneumothorax - استرواح الصدر التلقائي الأولي",
        clinics: ["ThoracicSurgery"],
    },
    {
        value: "J93.12",
        label: "Secondary spontaneous pneumothorax - استرواح الصدر التلقائي الثانوي",
        clinics: ["ThoracicSurgery"],
    },
    {
        value: "J93.81",
        label: "Chronic pneumothorax - استرواح الصدر المزمن",
        clinics: ["ThoracicSurgery"],
    },
    {
        value: "J84.10",
        label: "Pulmonary fibrosis, unspecified - تليف رئوي غير محدد",
        clinics: ["ThoracicSurgery", "Pulmonology"],
    },
    {
        value: "J84.111",
        label: "Idiopathic interstitial pneumonia, not otherwise specified - التهاب رئوي خلالي مجهول السبب ، غير محدد بطريقة أخرى",
        clinics: ["ThoracicSurgery", "Pulmonology"],
    },
    {
        value: "J84.112",
        label: "Idiopathic pulmonary fibrosis - التليف الرئوي مجهول السبب",
        clinics: ["ThoracicSurgery"],
    },
    {
        value: "J96.00",
        label: "Acute respiratory failure, unspecified whether with hypoxia or hypercapnia - فشل تنفسي حاد غير محدد سواء مع نقص الأكسجة أو فرط ثنائي أكسيد الكربون",
        clinics: ["ThoracicSurgery"],
    },
    {
        value: "J95.3",
        label: "Chronic pulmonary insufficiency following surgery - قصور رئوي مزمن بعد الجراحة",
        clinics: ["ThoracicSurgery"],
    },
    {
        value: "J96.90",
        label: "Respiratory failure, unspecified, unspecified whether with hypoxia or hypercapnia - فشل تنفسي ، غير محدد ، غير محدد سواء مع نقص الأكسجة أو فرط ثنائي أكسيد الكربون",
        clinics: ["ThoracicSurgery", "Pulmonology"],
    },
    {
        value: "J96.10",
        label: "Chronic respiratory failure, unspecified whether with hypoxia or hypercapnia - فشل الجهاز التنفسي المزمن ، غير محدد سواء مع نقص الأكسجة أو فرط ثنائي أكسيد الكربون",
        clinics: ["ThoracicSurgery", "Pulmonology"],
    },
    {
        value: "J96.20",
        label: "Acute and chronic respiratory failure, unspecified whether with hypoxia or hypercapnia - قصور تنفسي حاد ومزمن غير محدد سواء مع نقص الأكسجة أو فرط ثنائي أكسيد الكربون",
        clinics: ["ThoracicSurgery", "Pulmonology"],
    },
    {
        value: "J98.4",
        label: "Other disorders of lung - اضطرابات الرئة الأخرى",
        clinics: ["ThoracicSurgery"],
    },
    {
        value: "J95.00",
        label: "Unspecified tracheostomy complication - مضاعفات فغر القصبة الهوائية غير محددة",
        clinics: ["ThoracicSurgery"],
    },
    {
        value: "J95.04",
        label: "Tracheo-esophageal fistula following tracheostomy - الناسور الرغامي المريئي بعد فتح القصبة الهوائية",
        clinics: ["ThoracicSurgery"],
    },
    {
        value: "K22.2",
        label: "Esophageal obstruction - انسداد المري",
        clinics: ["ThoracicSurgery"],
    },
    {
        value: "K22.6",
        label: "Gastro-esophageal laceration-hemorrhage syndrome - متلازمة نزيف المعدة والمري",
        clinics: ["ThoracicSurgery"],
    },
    {
        value: "K22.8",
        label: "Other specified diseases of esophagus - أمراض المري الأخرى المحددة",
        clinics: ["ThoracicSurgery"],
    },
    {
        value: "L74.512",
        label: "Primary focal hyperhidrosis, palms - فرط التعرق البؤري الأساسي ، راحة اليد",
        clinics: ["ThoracicSurgery"],
    },
    {
        value: "Q31.1",
        label: "Congenital subglottic stenosis - تضيق تحت المزمار الخلقي",
        clinics: ["ThoracicSurgery"],
    },
    {
        value: "Q31.5",
        label: "Congenital laryngomalacia - تلين الحنجرة الخلقي",
        clinics: ["ThoracicSurgery"],
    },
    {
        value: "Q32.0",
        label: "Congenital tracheomalacia - تلين الرغامي الخلقي",
        clinics: ["ThoracicSurgery"],
    },
    {
        value: "Q33.2",
        label: "Sequestration of lung - حبس الرئة",
        clinics: ["ThoracicSurgery"],
    },
    {
        value: "Q33.3",
        label: "Agenesis of lung - تكوّن الرئة",
        clinics: ["ThoracicSurgery"],
    },
    {
        value: "Q33.6",
        label: "Congenital hypoplasia and dysplasia of lung - نقص تنسج خلقي وخلل تنسج الرئة",
        clinics: ["ThoracicSurgery"],
    },
    {
        value: "Q67.6",
        label: "Pectus excavatum - صدر مقعر",
        clinics: ["ThoracicSurgery"],
    },
    {
        value: "Q67.7",
        label: "Pectus carinatum - الصدر الجؤجؤي",
        clinics: ["ThoracicSurgery"],
    },
    {
        value: "R59.9",
        label: "Enlarged lymph nodes, unspecified - تضخم الغدد الليمفاوية ، غير محدد",
        clinics: ["ThoracicSurgery"],
    },
    {
        value: "R04.2",
        label: "Hemoptysis - نفث الدم",
        clinics: ["ThoracicSurgery", "Pulmonology", "Emergency General"],
    },
    {
        value: "R22.2",
        label: "Localized swelling, mass and lump, trunk - تورم موضعي ، كتلة وكتل ، جذع",
        clinics: ["ThoracicSurgery", "Pulmonology"],
    },
    {
        value: "R13.10",
        label: "Dysphagia, unspecified - عسر البلع غير محدد",
        clinics: ["ThoracicSurgery"],
    },
    {
        value: "R91.1",
        label: "Solitary pulmonary nodule - العقدة الرئوية الانفرادية",
        clinics: ["ThoracicSurgery", "Pulmonology"],
    },
    {
        value: "Z85.118",
        label: "Personal history of other malignant neoplasm of bronchus and lung - التاريخ الشخصي للأورام الخبيثة الأخرى في القصبات الهوائية والرئة",
        clinics: ["ThoracicSurgery"],
    },
    {
        value: "Z90.2",
        label: "Acquired absence of lung [part of] - الغياب المكتسب للرئة [جزء من]",
        clinics: ["ThoracicSurgery"],
    },
    {
        value: "Z90.02",
        label: "Acquired absence of larynx - اكتسبت غياب الحنجرة",
        clinics: ["ThoracicSurgery"],
    },
    {
        value: "Z90.81",
        label: "Acquired absence of spleen - اكتسبت غياب الطحال",
        clinics: ["ThoracicSurgery"],
    },
    {
        value: "A15.0",
        label: "Tuberculosis of lung - السل الرئوي",
        clinics: ["Pulmonology"],
    },
    {
        value: "A15.4",
        label: "Tuberculosis of intrathoracic lymph nodes - السل من الغدد الليمفاوية داخل الصدر",
        clinics: ["Pulmonology"],
    },
    {
        value: "A15.5",
        label: "Tuberculosis of larynx, trachea and bronchus - السل في الحنجرة والقصبة الهوائية والشعب الهوائية",
        clinics: ["Pulmonology"],
    },
    {
        value: "A15.6",
        label: "Tuberculous pleurisy - ذات الجنب السلي",
        clinics: ["Pulmonology"],
    },
    {
        value: "A15.7",
        label: "Primary respiratory tuberculosis - السل التنفسي الأولي",
        clinics: ["Pulmonology"],
    },
    {
        value: "A15.8",
        label: "Other respiratory tuberculosis - السل التنفسي الأخرى",
        clinics: ["Pulmonology"],
    },
    {
        value: "A15.9",
        label: "Respiratory tuberculosis unspecified - السل التنفسي غير محدد",
        clinics: ["Pulmonology"],
    },
    {
        value: "E84.0",
        label: "Cystic fibrosis with pulmonary manifestations - تليف كيسي مع مظاهر رئوية",
        clinics: ["Pulmonology"],
    },
    {
        value: "J44.1",
        label: "Chronic obstructive pulmonary disease with (acute) exacerbation - مرض الانسداد الرئوي المزمن المصحوب بتفاقم (حاد)",
        clinics: ["Pulmonology"],
    },
    {
        value: "J45.20",
        label: "Mild intermittent asthma, uncomplicated - ربو خفيف متقطع غير معقد",
        clinics: ["Pulmonology"],
    },
    {
        value: "J45.21",
        label: "Mild intermittent asthma with (acute) exacerbation - ربو خفيف متقطع مع تفاقم (حاد)",
        clinics: ["Pulmonology"],
    },
    {
        value: "J45.30",
        label: "Mild persistent asthma, uncomplicated - ربو خفيف ومستمر وغير معقد",
        clinics: ["Pulmonology"],
    },
    {
        value: "J45.31",
        label: "Mild persistent asthma with (acute) exacerbation - ربو خفيف ومستمر مع تفاقم (حاد)",
        clinics: ["Pulmonology"],
    },
    {
        value: "J45.32",
        label: "Mild persistent asthma with status asthmaticus - الربو الخفيف المستمر مع حالة الربو",
        clinics: ["Pulmonology"],
    },
    {
        value: "J45.40",
        label: "Moderate persistent asthma, uncomplicated - ربو مزمن معتدل وغير معقد",
        clinics: ["Pulmonology"],
    },
    {
        value: "J45.41",
        label: "Moderate persistent asthma with (acute) exacerbation - ربو مزمن معتدل مع تفاقم (حاد)",
        clinics: ["Pulmonology"],
    },
    {
        value: "J45.42",
        label: "Moderate persistent asthma with status asthmaticus - ربو مزمن معتدل مع حالة ربوية",
        clinics: ["Pulmonology"],
    },
    {
        value: "J45.50",
        label: "Severe persistent asthma, uncomplicated - ربو مزمن شديد وغير معقد",
        clinics: ["Pulmonology"],
    },
    {
        value: "J45.51",
        label: "Severe persistent asthma with (acute) exacerbation - ربو مزمن شديد مع تفاقم (حاد)",
        clinics: ["Pulmonology"],
    },
    {
        value: "J45.52",
        label: "Severe persistent asthma with status asthmaticus - الربو الشديد المستمر مع حالة الربو",
        clinics: ["Pulmonology"],
    },
    {
        value: "J20.0",
        label: "Acute bronchitis due to Mycoplasma pneumoniae - التهاب الشعب الهوائية الحاد الناجم عن الميكوبلازما الرئوية",
        clinics: ["Pulmonology"],
    },
    {
        value: "J20.1",
        label: "Acute bronchitis due to Hemophilus influenzae - التهاب الشعب الهوائية الحاد الناجم عن الهيموفيلس النفلونزا",
        clinics: ["Pulmonology"],
    },
    {
        value: "J20.2",
        label: "Acute bronchitis due to streptococcus - التهاب الشعب الهوائية الحاد بسبب المكورات العقدية",
        clinics: ["Pulmonology"],
    },
    {
        value: "J20.3",
        label: "Acute bronchitis due to coxsackievirus - التهاب الشعب الهوائية الحاد بسبب فيروس كوكساكي",
        clinics: ["Pulmonology"],
    },
    {
        value: "J20.4",
        label: "Acute bronchitis due to parainfluenza virus - التهاب الشعب الهوائية الحاد بسبب فيروس نظير الانفلونزا",
        clinics: ["Pulmonology"],
    },
    {
        value: "J20.5",
        label: "Acute bronchitis due to respiratory syncytial virus - التهاب الشعب الهوائية الحاد نتيجة لفيروس الجهاز التنفسي المخلوي",
        clinics: ["Pulmonology"],
    },
    {
        value: "J20.6",
        label: "Acute bronchitis due to rhinovirus - التهاب الشعب الهوائية الحاد بسبب فيروس الأنف",
        clinics: ["Pulmonology"],
    },
    {
        value: "J20.7",
        label: "Acute bronchitis due to echovirus - التهاب الشعب الهوائية الحاد بسبب فيروس الصدى",
        clinics: ["Pulmonology"],
    },
    {
        value: "J20.8",
        label: "Acute bronchitis due to other specified organisms - التهاب الشعب الهوائية الحاد بسبب كائنات أخرى محددة",
        clinics: ["Pulmonology"],
    },
    {
        value: "J81.0",
        label: "Acute pulmonary edema - الوذمة الرئوية الحادة",
        clinics: ["Pulmonology"],
    },
    {
        value: "R07.1",
        label: "Chest pain on breathing - ألم في الصدر عند التنفس",
        clinics: ["Pulmonology"],
    },
    {
        value: "R07.81",
        label: "Pleurodynia - التهاب الجنب",
        clinics: ["Pulmonology"],
    },
    {
        value: "R09.02",
        label: "Hypoxemia - نقص تأكسج الدم",
        clinics: ["Pulmonology", "Neurology"],
    },
    {
        value: "R91.8",
        label: "Other nonspecific abnormal finding of lung field - اكتشافات غير طبيعية أخرى غير محددة في مجال الرئة",
        clinics: ["Pulmonology"],
    },
    {
        value: "R94.2",
        label: "Abnormal results of pulmonary function studies - نتائج غير طبيعية لدراسات وظائف الرئة",
        clinics: ["Pulmonology"],
    },
    {
        value: "E06.9",
        label: "Thyroiditis unspecified - التهاب درق",
        clinics: ["Endocrinology"],
    },
    {
        value: "D35.2",
        label: "Benign neoplasm of pituitary gland (Prolactinoma) - برولاكتينوما",
        clinics: ["Endocrinology"],
    },
    {
        value: "D44.4",
        label: "Neoplasm of uncertain behavior of craniopharyngeal duct (craniopharyngioma) - الورم القحفي البلعومي",
        clinics: ["Endocrinology"],
    },
    {
        value: "C74.10",
        label: "Malignant neoplasm of medulla of unspecified adrenal gland (Pheochromocytoma) - فيوكروموسيتوما",
        clinics: ["Endocrinology"],
    },
    {
        value: "L68.0",
        label: "Hirsutism - الشعرانية",
        clinics: ["Endocrinology", "Dermotology"],
    },
    {
        value: "E28.39",
        label: "Other primary ovarian failure (premature ovarian insufficiency) (Female Hypogonadism) - قصور مبيض باكر",
        clinics: ["Endocrinology"],
    },
    {
        value: "E29.1",
        label: "Testicular hypofunction (Male Hypogonadism) - قصور أقناد",
        clinics: ["Endocrinology"],
    },
    {
        value: "N62.0",
        label: "Hypertrophy of breast (Gynecomastia) - التثدي",
        clinics: ["Endocrinology"],
    },
    {
        value: "N97.9",
        label: "Female infertility unspecified - عقم أنثوي",
        clinics: ["Endocrinology"],
    },
    {
        value: "N46.9",
        label: "Male infertility unspecified - عقم ذكوري",
        clinics: ["Endocrinology"],
    },
    {
        value: "M81.6",
        label: "Localized osteoporosis [Lequesne] - تخلخل العظام الموضع",
        clinics: ["Endocrinology"],
    },
    {
        value: "E20.9",
        label: "hypoparathyroidism - قصور نشاط جارات الدرق",
        clinics: ["Endocrinology"],
    },
    {
        value: "E16.2",
        label: "Hypoglycemia unspecified - نقص سكر الدم",
        clinics: ["Endocrinology", "Emergency Medicine"],
    },
    {
        value: "E78.5",
        label: "Hyperlipidemia unspecified - فرط شحوم الدم",
        clinics: ["Endocrinology"],
    },
    {
        value: "R62.52",
        label: "Short stature (child) - قصر قامة عند الأطفال",
        clinics: ["Endocrinology"],
    },
    {
        value: "R62.51",
        label: "Failure to thrive (child) (Growth failure) - فشل نمو ",
        clinics: ["Endocrinology"],
    },
    {
        value: "E30.1",
        label: "Precocious puberty - بلوغ مبكر",
        clinics: ["Endocrinology"],
    },
    {
        value: "E30.0",
        label: "Delayed puberty - بلوغ متأخر",
        clinics: ["Endocrinology", "Gynecology"],
    },
    {
        value: "N91.0",
        label: "Primary amenorrhea - إنقطاع طمث بدئي",
        clinics: ["Endocrinology", "Gynecology"],
    },
    {
        value: "N91.1",
        label: "Secondary amenorrhea - إنقطاع طمث ثانوي",
        clinics: ["Endocrinology", "Gynecology"],
    },
    {
        value: "E26.0",
        label: "Primary hyperaldosteronism - فرط ألدوسترونية بدئي",
        clinics: ["Endocrinology"],
    },
    {
        value: "E83.51",
        label: "Hypocalcemia - نقص كلس الدم",
        clinics: ["Endocrinology"],
    },
    {
        value: "Q52.9",
        label: "Congenital malformation of female genitalia, unspecified - تشوهات خلقية بالاعضاء التناسلية الانثوية",
        clinics: ["PediatricSurgery"],
    },
    {
        value: "Q55.9",
        label: "Congenital malformation of male genital organ, unspecified - تشوهات خلقية بالاعضاء التناسلية الذكرية",
        clinics: ["PediatricSurgery"],
    },
    {
        value: "C43",
        label: "Malignant melanoma of skin - ميلانوما جلد",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "Q67",
        label: "Congenital musculoskeletal deformities of head, face, spine and chest - التشوهات الخلقية في العضلات والعظام في الرأس والوجه والعمود الفقري والصدر",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "Q37",
        label: "Cleft palate with cleft lip - حنك مشقوق مع شفة مشقوقة",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "Q74",
        label: "Other congenital malformations of limb(s) - التشوهات الخلقية للأطراف",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "Q17",
        label: "Other congenital malformations of ear - التشوهات الخلقية في الأذن ",
        clinics: ["Plastic Surgery"],
    },
    {
        value: "I24.9",
        label: "Acute coronary syndrome - متلازمة الشريان التاجي الحادة ",
        clinics: ["Emergency Medicine", "Emergency General"],
    },
    {
        value: "T07",
        label: "Multitrauma - رضوض متعددة",
        clinics: ["Emergency Medicine", "Emergency General"],
    },
    {
        value: "I63.9",
        label: "Cerebral infarction - احتشاء دماغي",
        clinics: ["Emergency Medicine"],
    },
    {
        value: "E13.10",
        label: "Other specified diabetes mellitus with ketoacidosis without coma - ارتفاع سكر الدم والحماض السكري",
        clinics: ["Emergency Medicine", "Emergency General"],
    },
    {
        value: "I16",
        label: "Hypertensive crisis - ارتفاع توتر شرياني اسعافي",
        clinics: ["Emergency Medicine", "Emergency General"],
    },
    {
        value: "R57.8",
        label: "Other shock - صدمة من نوع اخر",
        clinics: ["Emergency Medicine"],
    },
    {
        value: "M50.90",
        label: "Cervical disc - ديسك رقبي ",
        clinics: ["Neurosurgery"],
    },
    {
        value: "M51.36",
        label: "Lumbar disc - ديسك قطني",
        clinics: ["Neurosurgery"],
    },
    {
        value: "M43.06",
        label: "Spondylolysis - انزلاق فقرات",
        clinics: ["Neurosurgery"],
    },
    {
        value: "G62.9",
        label: "Polyneuropathy - اعتلال الأعصاب",
        clinics: ["Neurosurgery", "Neurology"],
    },
    {
        value: "G44.201",
        label: "Tension-type headache - صداع توتري",
        clinics: ["Neurosurgery"],
    },
    {
        value: "G31.9",
        label: "Degenerative disease of nervous system - ضمور دماغي",
        clinics: ["Neurosurgery", "Neurology"],
    },
    { value: "R31", label: "Hematuria - بيلة دموية", clinics: ["Nephrology"] },
    {
        value: "N03",
        label: "Chronic nephritic syndrome - التهاب كلية مزمن",
        clinics: ["Nephrology"],
    },
    {
        value: "N04",
        label: "Nephrotic syndrome - نفروز",
        clinics: ["Nephrology"],
    },
    {
        value: "K73",
        label: "Chronic hepatitis - التهاب كبد",
        clinics: ["Nephrology"],
    },
    {
        value: "N05",
        label: "Unspecified nephritic syndrome - التهاب كلية",
        clinics: ["Nephrology"],
    },
    {
        value: "N07",
        label: "Hereditary nephropathy - اعتلال كلية وراثي",
        clinics: ["Nephrology"],
    },
    {
        value: "N12",
        label: "Tubulo-interstitial nephritis, not specified as acute or chronic - التهاب كلية خلالي حاد أو مزمن",
        clinics: ["Nephrology"],
    },
    {
        value: "N13.9",
        label: "Obstructive and reflux uropathy, unspecified - اعتلال كلية انسداداي",
        clinics: ["Nephrology"],
    },
    {
        value: "N14.2",
        label: "Nephropathy induced by unspecified drug, medicament or biological substance - اعتلال كلية دوائي",
        clinics: ["Nephrology"],
    },
    {
        value: "N20",
        label: "calculus of kidney - حصيات كلية",
        clinics: ["Nephrology"],
    },
    {
        value: "N26.1",
        label: "Atrophy of kidney  - ضمور كلية",
        clinics: ["Nephrology"],
    },
    { value: "N30", label: "Cystitis - التهاب مثانة", clinics: ["Nephrology"] },
    {
        value: "N31",
        label: "Neuromuscular dysfunction of bladder, not elsewhere classified  - اعتلال مثانة عصبي (سلس بولي)",
        clinics: ["Nephrology"],
    },
    {
        value: "N39",
        label: "Other disorders of urinary system  - اضطرابات اخرى بالجهاز البولي",
        clinics: ["Nephrology"],
    },
    { value: "N45.1", label: "Orchitis - التهاب خصية", clinics: ["Nephrology"] },
    {
        value: "N45.2",
        label: "Epididymitis - التهاب بربخ",
        clinics: ["Nephrology"],
    },
    {
        value: "K12.1",
        label: "fungal stomatitis - التهاب الفم الفطري",
        clinics: ["Pediatric"],
    },
    {
        value: "B08.4",
        label: "Viral rash (mouth-foot-hand syndrome) - طفح فيروسي(متلازمه فم قدم  يد)",
        clinics: ["Pediatric"],
    },
    {
        value: "L02.216",
        label: "Cutaneous abscess of umbilicus - إلتهاب السرة",
        clinics: ["Pediatric"],
    },
    {
        value: "P58.0",
        label: "Neonatal jaundice due to bruising - اليرقان الوليدي بسبب الكدمات",
        clinics: ["Pediatric"],
    },
    {
        value: "C44.91",
        label: "Basal cell carcinoma of skin, unspecified - سرطان الخلايا القاعدية للجلد ، غير محدد",
        clinics: ["Plastic Surgery", "Dermotology"],
    },
    {
        value: "C44.92",
        label: "Squamous cell carcinoma of skin, unspecified - سرطان الخلايا الحرشفية للجلد ، غير محدد",
        clinics: ["Plastic Surgery", "Dermotology"],
    },
    {
        value: "L53.9",
        label: "Erythematous condition, unspecified - أحمرية",
        clinics: ["Dermotology"],
    },
    {
        value: "B00.9",
        label: "Herpesviral infection, unspecified - قوباء",
        clinics: ["Dermotology"],
    },
    {
        value: "L21",
        label: "Seborrheic dermatitis - اكزيمة دهنية",
        clinics: ["Dermotology"],
    },
    {
        value: "L24",
        label: "Irritant contact dermatitis - اكزيمة تماس تخريشية",
        clinics: ["Dermotology"],
    },
    {
        value: "Q82.6",
        label: "Congenital sacral dimple - دمامل خلقية",
        clinics: ["Dermotology"],
    },
    {
        value: "L03.90",
        label: "Cellulitis, unspecified - التهاب نسيج خلوي",
        clinics: ["Dermotology"],
    },
    {
        value: "B00.82",
        label: "Herpes simplex myelitis - حلأ بسيط",
        clinics: ["Dermotology"],
    },
    {
        value: "A28.9",
        label: "Zoonotic bacterial disease - داء منطقة جرثومي",
        clinics: ["Dermotology"],
    },
    { value: "Q82.8", label: "darier", clinics: ["Dermotology"] },
    {
        value: "L42",
        label: "Pityriasis rosea - نخالية وردية",
        clinics: ["Dermotology"],
    },
    {
        value: "L41",
        label: "Parapsoriasis - نظير الصداف",
        clinics: ["Dermotology"],
    },
    {
        value: "K14.1",
        label: "Geographic tongue - اللسان الجغرافي",
        clinics: ["Dermotology"],
    },
    {
        value: "L83",
        label: "Acanthosis nigricans - الشواك",
        clinics: ["Dermotology"],
    },
    {
        value: "L92.9",
        label: "Granulomatous disorder of the skin and subcutaneous tissue, unspecified - اضطراب الورم الحبيبي للجلد والأنسجة تحت الجلد ، غير محدد",
        clinics: ["Dermotology"],
    },
    {
        value: "M30.0",
        label: "Polyarteritis nodosa - التهاب الشرايين العديد العقد",
        clinics: ["Dermotology"],
    },
    {
        value: "B34.8",
        label: "Other viral infections of unspecified site - انتانات فيروسية اخرى",
        clinics: ["Dermotology"],
    },
    {
        value: "A63",
        label: "Anogenital (venereal) warts - ثأليل تناسلية",
        clinics: ["Dermotology"],
    },
    {
        value: "B35.1",
        label: "Tinea unguium - فطور أظافر",
        clinics: ["Dermotology"],
    },
    {
        value: "L60.8",
        label: "Other nail disorders - أمراض الأظافر الأخرى",
        clinics: ["Dermotology"],
    },
    {
        value: "B37.9",
        label: "Candidiasis, unspecified - داء مبيضات غير محدد",
        clinics: ["Dermotology"],
    },
    { value: "C81", label: "Hodgkin lymphoma - ليفوم", clinics: ["Dermotology"] },
    {
        value: "L72.0",
        label: "Epidermal cyst - كيسة بشرانية",
        clinics: ["Dermotology"],
    },
    {
        value: "E80",
        label: "Disorders of porphyrin and bilirubin metabolism - اضطرابات استقلاب البورفيرين والبيليروبين",
        clinics: ["Dermotology"],
    },
    {
        value: "L81.9",
        label: "Disorder of pigmentation, unspecified - جفاف جلد مصطبغ",
        clinics: ["Dermotology"],
    },
    {
        value: "L93",
        label: "Lupus erythematosus - ذئبة حمامية",
        clinics: ["Dermotology"],
    },
    {
        value: "L95.9",
        label: "Vasculitis limited to the skin, unspecified - التهاب اوعية جلدي",
        clinics: ["Dermotology"],
    },
    {
        value: "L51.9",
        label: "Erythema multiforme, unspecified - حمام عديدة الأشكال",
        clinics: ["Dermotology"],
    },
    {
        value: "L51.1",
        label: "Stevens-Johnson syndrome - متلازمة ستيفن و جونسون",
        clinics: ["Dermotology"],
    },
    {
        value: "M33",
        label: "Dermatopolymyositis - التهاب جلد وعضلات",
        clinics: ["Dermotology"],
    },
    {
        value: "L43",
        label: "Lichen planus - حزاز مسطح",
        clinics: ["Dermotology"],
    },
    {
        value: "Q85.01",
        label: "Neurofibromatosis, type 1 - داء فون ريكايلانهاوزن",
        clinics: ["Dermotology"],
    },
    {
        value: "Q85.1",
        label: "Tuberous sclerosis - داء تصلب حدبي",
        clinics: ["Dermotology"],
    },
    {
        value: "Q85.8",
        label: "Cowden syndrome - متلازمة كاودن",
        clinics: ["Dermotology"],
    },
    {
        value: "S39.840A",
        label: "fracture of corpus cavernosum penis - كسر قضيب",
        clinics: ["Urology"],
    },
    {
        value: "N49.3",
        label: "Fournier gangrene - خراج فورنييه",
        clinics: ["Urology"],
    },
    {
        value: "N21.1",
        label: "Calculus in urethra - حصاة إحليل",
        clinics: ["Urology"],
    },
    {
        value: "N44.00",
        label: "Torsion of testis, unspecified - انفتال خصية",
        clinics: ["Urology"],
    },
    {
        value: "Z12.6",
        label: "Encounter for screening for malignant neoplasm of bladder - خزعة مثانة",
        clinics: ["Urology"],
    },
    {
        value: "Z12.71",
        label: "Encounter for screening for malignant neoplasm of testis - خزعة خصية",
        clinics: ["Urology"],
    },
    { value: "", label: "", clinics: ["Urology", "ENT", "Emergency General"] },
    {
        value: "Z31.61",
        label: "Procreative counseling and advice using natural family planning. - تنظيم اسرة",
        clinics: ["Gynecology"],
    },
    {
        value: "T83.32",
        label: "Displacement of intrauterine contraceptive device - لولب هاجر",
        clinics: ["Gynecology"],
    },
    {
        value: "Q52.3",
        label: "Imperforate hymen - انسداد غشاء البكارة",
        clinics: ["Gynecology"],
    },
    {
        value: "T17.1XXA",
        label: "Foreign body in nose - جسم أجنبي في الأنف",
        clinics: ["ENT"],
    },
    {
        value: "R22.1",
        label: "Localized swelling, mass and lump, neck - كتلة عنق",
        clinics: ["ENT"],
    },
    { value: "M62.838", label: "Cervical spasm - تشنج رقبي", clinics: ["ENT"] },
    {
        value: "M26.60",
        label: "Temporomandibular joint disorder, unspecified - اضطرابات المفصل الصدغي الفكي",
        clinics: ["ENT"],
    },
    {
        value: "H61.122",
        label: "Hematoma of pinna, left ear - هيماتوما في الصيوان",
        clinics: ["ENT"],
    },
    {
        value: "S00.33",
        label: "Hematoma of nose - ورم دموي في الأنف",
        clinics: ["ENT"],
    },
    {
        value: "H61.113",
        label: "Acquired deformity of pinna, bilateral - شرم أذن",
        clinics: ["ENT"],
    },
    {
        value: "D23.20",
        label: "Other benign neoplasm of skin of unspecified ear and external auricular canal - كيس شحمي خلف الأذن",
        clinics: ["ENT"],
    },
    {
        value: "I61",
        label: "Nontraumatic intracerebral hemorrhage - النزف الدماغي غير الرضحي",
        clinics: ["Neurology"],
    },
    {
        value: "I95",
        label: "hypotention - انخفاض  توتر شرياني  ",
        clinics: ["Emergency General"],
    },
    {
        value: "E13.10",
        label: "ketoacidosis -   بدون غيبوبة الحماض السكري ",
        clinics: ["Emergency General"],
    },
    {
        value: "R57.9",
        label: "Shock, unspecified - الصدمة غير محددة",
        clinics: ["Emergency General"],
    },
    {
        value: "G81.90",
        label: "Recent hmeplegia(stroke) - (خذل شقي حديث (حادث وعائي دماغي",
        clinics: ["Emergency General"],
    },
    {
        value: "R31.9",
        label: "Hematuria - بيلة دموية ",
        clinics: ["Emergency General"],
    },
    {
        value: "K80.66",
        label: "Biliary colic - قولنج مراري",
        clinics: ["Emergency General"],
    },
    {
        value: "T17",
        label: "Foreign body in respiratory tract - جسم اجنبي في الجهاز التنفسي",
        clinics: ["Emergency General"],
    },
    {
        value: "H81",
        label: "Disorders of vestibular function - اضطرابات الوظيفة الدهليزيّة ",
        clinics: ["Emergency General"],
    },
    {
        value: "F43.1",
        label: "Post-traumatic stress disorder (PTSD) - العصاب ",
        clinics: ["Emergency General"],
    },
    {
        value: "H81",
        label: "vertigo unspecified - الدوار ",
        clinics: ["Emergency General"],
    },
    {
        value: "Z18",
        label: "foreign body unspecified - جسم أجنبي ف مكان غير محدد ",
        clinics: ["Emergency General"],
    },
    {
        value: "N64",
        label: "Breast Disor - تشوه ثدي",
        clinics: ["Plastic Surgery"],
    },
];
