"use strict";
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.warehouseUserRoles = exports.warehouseOperationsTypes = exports.warehouseProjectUserRoles = exports.projectUserRoles = exports.approvalsTypes = void 0;
exports.approvalsTypes = [
    "createSR",
    "GIOLogisticsApproval",
    "GIOSenderApproval",
    "SRLogisticsApproval",
    "SROfficeManagerApproval",
    "SRWarehouseSenderApproval",
    "SRWarehouseReceiverApproval",
    "IRDeputyGeneralApproval",
    "IRFinanceProjectManagerApproval",
    "IRProjectManagerApproval",
    "IRSeniorLogisticOfficerApproval",
    "IRGeneralDirectorApproval",
    "CBAOfficeManagerApproval",
    "CBALogisticsApproval",
    "CBAFinanceProjectsManagerApproval",
    "CBADeputyGeneralDirectorApproval",
    "CBAGeneralDirectorApproval",
    "POGeneralDirectorApproval",
    "PODeputyGeneralDirectorApproval",
    "POFinanceProjectsManagerApproval",
    "DNReceivedAndCheckedByApproval",
    "DNApprovedByApproval",
    "SCNReceivedAndCheckedByApproval",
    "SCNApprovedByApproval",
    "PRRequestedByApproval",
    "PRAuthorizedByApproval",
    "approved",
    "",
];
exports.projectUserRoles = __spreadArrays(exports.approvalsTypes, [
    "orderView",
    "orderComplete",
    "orderArchive",
    "orderDiscussionView",
    "ordersTabView",
    "IRView",
    "IRCreate",
    "IRPrint",
    "IRUpdate",
    "IRPublish",
    "IRRevoke",
    "IRCancel",
    "IRUploadSignedPapers",
    "IRViewDiscussion",
    "IRParticipateInDiscussion",
    "IRDownloadSignedCopy",
    "IRsTabView",
    "RFQView",
    "RFQCreate",
    "RFQUpdate",
    "RFQPrint",
    "RFQPublish",
    "RFQRevoke",
    "RFQCancel",
    "RFQUploadSignedPapers",
    "RFQViewDiscussion",
    "RFQParticipateInDiscussion",
    "RFQDownloadSignedCopy",
    "RFQsTabView",
    "CBAView",
    "CBACreate",
    "CBAPrint",
    "CBAUpdate",
    "CBAPublish",
    "CBARevoke",
    "CBACancel",
    "CBAUploadSignedPapers",
    "CBAViewDiscussion",
    "CBAParticipateInDiscussion",
    "CBADownloadSignedCopy",
    "CBATabView",
    "POView",
    "POCreate",
    "POUpdate",
    "POPublish",
    "POPrint",
    "PORevoke",
    "POCancel",
    "POUploadSignedPapers",
    "POViewDiscussion",
    "POParticipateInDiscussion",
    "PODownloadSignedCopy",
    "POTabView",
    "DNView",
    "DNCreate",
    "DNUpdate",
    "DNPrint",
    "DNPublish",
    "DNCancel",
    "DNUploadSignedPapers",
    "DNViewDiscussion",
    "DNParticipateInDiscussion",
    "DNDownloadSignedCopy",
    "DNTabView",
    "SCNView",
    "SCNCreate",
    "SCNUpdate",
    "SCNPrint",
    "SCNPublish",
    "SCNRevoke",
    "SCNCancel",
    "SCNUploadSignedPapers",
    "SCNViewDiscussion",
    "SCNParticipateInDiscussion",
    "SCNDownloadSignedCopy",
    "SCNTabView",
    "PRView",
    "PRCreate",
    "PRUpdate",
    "PRPrint",
    "PRPublish",
    "PRRevoke",
    "PRCancel",
    "PRUploadSignedPapers",
    "PRViewDiscussion",
    "PRParticipateInDiscussion",
    "PRDownloadSignedCopy",
    "PRTabView",
    "SCView",
    "SCCreate",
    "SCUpdate",
    "SCPrint",
    "SCPublish",
    "SCRevoke",
    "SCCancel",
    "SCUploadSignedPapers",
    "SCViewDiscussion",
    "SCParticipateInDiscussion",
    "SCDownloadSignedCopy",
    "SCTabView",
]);
exports.warehouseProjectUserRoles = [
    "viewWarehouseItems",
    "viewWarehouseImports",
    "viewDisposedWarehouseItems",
    "viewConsumedWarehouseItems",
    "viewSentWarehouseItems",
    "viewReceiveWarehouseItems",
    "viewDonateWarehouseItems",
    "viewReceivedDonatedWarehouseItems",
    "viewTransferWarehouseItemsToAnotherProject",
    "viewReceiveWarehouseItemsFromDN",
    "viewExpiredItems",
    // "viewReceivedDonatedWarehouseItemsFromDN",
    // "viewReceiveWarehouseItemsFromSR",
    // "viewGIO",
    // "createGIO",
    // "GIOLogisticsApproval",
    // "GIOSenderApproval",
    // "viewDN",
    // "DNReceivedAndCheckedByApproval",
    // "DNApprovedByApproval",
    // "viewSR",
    // "createSR",
    // "SRLogisticsApproval",
    // "SROfficeManagerApproval",
    // "SRWarehouseSenderApproval",
    // "SRWarehouseReceiverApproval",
    "createWarehouseImports",
    "disposeWarehouseItems",
    "consumeWarehouseItems",
    "sendWarehouseItems",
    "receiveWarehouseItems",
    "donateWarehouseItems",
    "receiveDonatedWarehouseItems",
    "transferWarehouseItemsToAnotherProject",
    "receiveWarehouseItemsFromDN",
    "confirmImportItem",
    "confirmReceiveFromDN",
    "confirmSendItem",
    "confirmTransferItemToAnotherProject",
    "confirmReceiveItem",
    "confirmReceiveDonationItem",
    "confirmSendDonationItem",
    "approveRecieveFromDn",
    "approveReceiveFromDonation",
    "approveReceiveItems",
    "approveSendDonationItem",
    "approveTransferItemToAnotherProject",
    "exportWarehouseItems",
    "exportWarehouseImports",
    "exportDisposedWarehouseItems",
    "exportConsumedWarehouseItems",
    "exportSentWarehouseItems",
    "exportReceiveWarehouseItems",
    "exportDonateWarehouseItems",
    "exportReceivedDonatedWarehouseItems",
    "exportTransferWarehouseItemsToAnotherProject",
    "exportReceiveWarehouseItemsFromDN",
    "exportPrescriptionconsumption",
    "exportPrescriptionconsumptionDetails",
];
exports.warehouseOperationsTypes = [
    "sendWarehouseItems",
    "receiveWarehouseItems",
    "donateWarehouseItems",
    "receiveDonatedWarehouseItems",
    "transferWarehouseItemsToAnotherProject",
    "receiveWarehouseItemsFromDN",
];
exports.warehouseUserRoles = [
    "viewWarehouse",
    "editWarehouse",
    "viewWarehouseDiscussion",
    "participateInWarehouseDiscussion",
    "viewWarehouseItemMovement",
    "manageWarehouseUserRoles",
    "viewPrescriptionconsumption",
    "viewPrescriptionDischarge",
    "viewPrescriptionconsumptionDetails",
    "viewPrescriptionMedicationsConsumption",
    "viewPrescriptionMedicationsConsumptionDetails",
    "viewMedicationDetails",
    "consumeWarehouseHimsPrescriptionPermission",
];
