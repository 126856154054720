"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
function useIntervalDynamic(callback, delay) {
    var savedCallback = react_1.useRef(callback);
    savedCallback.current = callback;
    react_1.useEffect(function () {
        function tick() {
            savedCallback.current();
        }
        var id = setInterval(tick, delay);
        return function () { return clearInterval(id); };
    }, [delay]);
}
exports.default = useIntervalDynamic;
