"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMemoIfNotUndefined = void 0;
var react_1 = require("react");
function useMemoIfNotUndefined(value) {
    var valueRef = react_1.useRef(value);
    if (value !== undefined) {
        valueRef.current = value;
    }
    return valueRef.current;
}
exports.useMemoIfNotUndefined = useMemoIfNotUndefined;
