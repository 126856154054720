"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValueInputCell = void 0;
var ValueInputCell = function (_a) {
    var value = _a.value, _b = _a.colSpan, colSpan = _b === void 0 ? 1 : _b, inputType = _a.inputType, path = _a.path, selectData = _a.selectData, rowSpan = _a.rowSpan, borderWidth = _a.borderWidth, borderColor = _a.borderColor, borderStyle = _a.borderStyle, required = _a.required, triedSubmitting = _a.triedSubmitting, mode = _a.mode;
    return {
        borderWidth: borderWidth !== null && borderWidth !== void 0 ? borderWidth : { top: 1, bottom: 1, left: 1, right: 1 },
        borderColor: borderColor !== null && borderColor !== void 0 ? borderColor : {
            top: "gray",
            bottom: "gray",
            left: "gray",
            right: "gray",
        },
        borderStyle: borderStyle !== null && borderStyle !== void 0 ? borderStyle : {
            top: "solid",
            bottom: "solid",
            left: "solid",
            right: "solid",
        },
        textAlign: "center",
        colSpan: colSpan !== undefined ? colSpan : 1,
        mode: mode,
        options: selectData,
        value: value,
        type: inputType,
        path: path,
        rowSpan: rowSpan,
        required: required,
        triedSubmitting: triedSubmitting,
    };
};
exports.ValueInputCell = ValueInputCell;
