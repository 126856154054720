"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GIOItemsRef = exports.GIOsRef = exports.warehousePrescriptionConsumptionOperationItemsRef = exports.warehousePrescriptionConsumptionOperationsRef = exports.warehouseReceiveDonationOperationsRef = exports.warehouseSendDonationOperationsRef = exports.warehouseConsumeOperationsRef = exports.warehouseDisposeOperationsRef = exports.warehouseTransferToAnotherProjectOperationsRef = exports.warehouseTransferOperationsRef = exports.warehouseCorrectionOperationsRef = exports.warehouseImportOperationsRef = exports.warehouseItemsRef = exports.warehouseRef = exports.DNItemsRef = exports.POItemsRef = exports.SCItemsRef = exports.SCRef = exports.SCNItemsRef = exports.SCNsRef = exports.CBARFQItemsRef = exports.CBAItemsRef = exports.RFQItemsRef = exports.RFQsRef = exports.PRItemsRef = exports.PRsRef = exports.POsRef = exports.DNsRef = exports.IRItemsRef = exports.IRsRef = exports.CBAsRef = exports.approvalsRef = exports.ordersRef = exports.procurementPlansRef = exports.budgetTemplatesRef = exports.budgetSectionsRef = exports.budgetLineItemsRef = exports.budgetLinesRef = exports.usersTokensRef = exports.supplierRatingsRef = exports.donorsRef = exports.usersRef = exports.unitsRef = exports.officesRef = exports.currenciesRef = exports.categoriesRef = exports.suppliersRef = exports.productsRef = exports.projectsRolesRef = exports.projectsRef = void 0;
exports.himsPrescriptionsRef = exports.himsMedicationItemsRef = exports.himsMedicationsRef = exports.himsAdmissionRef = exports.himsPatientRef = exports.himsSessionRef = exports.warehouseRolesRef = exports.rolesRef = exports.warehouseOperationsNotificationsRef = exports.notificationsRef = exports.discussionsRef = exports.userTokensRef = exports.warehouseassignedAssetsRef = exports.warehouseMaintenanceItemsRef = exports.warehouseMedicationsPrescriptionConsumptionOperationItemsRef = exports.warehouseMedicationsPrescriptionConsumptionOperationsRef = exports.SRItemsRef = exports.SRsRef = void 0;
var firebase = __importStar(require("firebase/app"));
require("firebase/firestore");
var firebase_1 = require("portal/config/firebase");
exports.projectsRef = firebase.firestore().collection('projects');
exports.projectsRolesRef = firebase.firestore().collection('userProjectRoles');
exports.productsRef = firebase.firestore().collection('products');
exports.suppliersRef = firebase.firestore().collection('suppliers');
exports.categoriesRef = firebase.firestore().collection('categories');
exports.currenciesRef = firebase.firestore().collection('currencies');
exports.officesRef = firebase.firestore().collection('offices');
exports.unitsRef = firebase.firestore().collection('units');
exports.usersRef = firebase.firestore().collection('users');
exports.donorsRef = firebase.firestore().collection('donors');
exports.supplierRatingsRef = firebase.firestore().collection('supplierRatings');
exports.usersTokensRef = firebase.firestore().collection('usersTokens');
exports.budgetLinesRef = firebase.firestore().collection('budgetLines');
exports.budgetLineItemsRef = firebase.firestore().collection('budgetLineItems');
exports.budgetSectionsRef = firebase.firestore().collection('budgetSections');
exports.budgetTemplatesRef = firebase.firestore().collection('budgetTemplates');
exports.procurementPlansRef = firebase.firestore().collection('procurementPlans');
exports.ordersRef = firebase.firestore().collection('orders');
exports.approvalsRef = firebase.firestore().collection('approvals');
// todo replace with full name
// internalRequests
// requestForQuotations
// comparativeBidAnalyses
// paymentRequests
// deliveryNotes
// serviceCompleteNotes
// purchaseOrders
exports.CBAsRef = firebase.firestore().collection('CBAs');
exports.IRsRef = firebase.firestore().collection('IRs');
exports.IRItemsRef = firebase.firestore().collection('IRItems');
exports.DNsRef = firebase.firestore().collection('DNs');
exports.POsRef = firebase.firestore().collection('POs');
exports.PRsRef = firebase.firestore().collection('PRs');
exports.PRItemsRef = firebase.firestore().collection('PRItems');
exports.RFQsRef = firebase.firestore().collection('RFQs');
exports.RFQItemsRef = firebase.firestore().collection('RFQItems');
exports.CBAItemsRef = firebase.firestore().collection('CBAItems');
exports.CBARFQItemsRef = firebase.firestore().collection('CBARFQItems');
exports.SCNsRef = firebase.firestore().collection('SCNs');
exports.SCNItemsRef = firebase.firestore().collection('SCNItems');
exports.SCRef = firebase.firestore().collection('SCs');
exports.SCItemsRef = firebase.firestore().collection('SCItems');
exports.POItemsRef = firebase.firestore().collection('POItems');
exports.DNItemsRef = firebase.firestore().collection('DNItems');
exports.warehouseRef = firebase.firestore().collection('warehouses');
exports.warehouseItemsRef = firebase.firestore().collection('warehouseItems');
exports.warehouseImportOperationsRef = firebase.firestore().collection('warehouseImportOperations');
exports.warehouseCorrectionOperationsRef = firebase.firestore().collection('warehouseCorrectionOperations');
exports.warehouseTransferOperationsRef = firebase.firestore().collection('warehouseTransferOperations');
exports.warehouseTransferToAnotherProjectOperationsRef = firebase.firestore().collection('warehouseTransferToAnotherProjectOperations');
exports.warehouseDisposeOperationsRef = firebase.firestore().collection('warehouseDisposeOperations');
exports.warehouseConsumeOperationsRef = firebase.firestore().collection('warehouseConsumeOperations');
exports.warehouseSendDonationOperationsRef = firebase.firestore().collection('warehouseSendDonationOperations');
exports.warehouseReceiveDonationOperationsRef = firebase.firestore().collection('warehouseReceiveDonationOperations');
exports.warehousePrescriptionConsumptionOperationsRef = firebase.firestore().collection('warehousePrescriptionConsumptionOperations');
exports.warehousePrescriptionConsumptionOperationItemsRef = firebase
    .firestore()
    .collection('warehousePrescriptionConsumptionOperationItems');
exports.GIOsRef = firebase.firestore().collection('GIOs');
exports.GIOItemsRef = firebase.firestore().collection('GIOItems');
exports.SRsRef = firebase.firestore().collection('SRs');
exports.SRItemsRef = firebase.firestore().collection('SRItems');
exports.warehouseMedicationsPrescriptionConsumptionOperationsRef = firebase
    .firestore()
    .collection('warehouseMedicationsPrescriptionConsumptionOperations');
exports.warehouseMedicationsPrescriptionConsumptionOperationItemsRef = firebase
    .firestore()
    .collection('warehouseMedicationsPrescriptionConsumptionOperationItems');
exports.warehouseMaintenanceItemsRef = firebase.firestore().collection('warehouseMaintenanceItems');
exports.warehouseassignedAssetsRef = firebase.firestore().collection('warehouseAssignedAsstes');
exports.userTokensRef = firebase.firestore().collection('userTokens');
exports.discussionsRef = firebase.firestore().collection('discussions');
exports.notificationsRef = firebase.firestore().collection('notifications');
exports.warehouseOperationsNotificationsRef = firebase.firestore().collection('warehouseOperationsNotifications');
exports.rolesRef = firebase.firestore().collection('roles');
exports.warehouseRolesRef = firebase.firestore().collection('warehouseRoles');
exports.himsSessionRef = firebase_1.himsFirebase.firestore().collection('sessions');
exports.himsPatientRef = firebase_1.himsFirebase.firestore().collection('patients');
exports.himsAdmissionRef = firebase_1.himsFirebase.firestore().collection('admissions');
exports.himsMedicationsRef = firebase_1.himsFirebase.firestore().collectionGroup('medications');
exports.himsMedicationItemsRef = firebase_1.himsFirebase.firestore().collectionGroup('medicationsItems');
exports.himsPrescriptionsRef = firebase_1.himsFirebase.firestore().collectionGroup('prescriptions');
