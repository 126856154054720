"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    common: {
        home: "الصفحة الرئيسية",
        warehousePendingMyApproval: "إشعارات المستودع",
        ordersPendingMyApproval: "إشعارات الطلبات",
        signature: "التوقيع",
        lang: "اللغة",
        languages: "اللغة",
        arabic: "العربية",
        english: "الإنكليزية",
        ida: "Independent Doctors Association",
        ok: "حسنا",
        warning: "تحذير",
        error: "خطأ",
        success: "تمت العملية بنجاح",
        yes: "نعم",
        no: "لا",
        IR: "طلب شراء",
        RFQ: "طلب عرض سعر",
        CBA: "تحليل عروض أسعار",
        PO: "أمر شراء",
        DN: "مذكرة إستلام مواد",
        SCN: "مذكرة إستلام خدمة",
        PR: '"طلب دفعة',
        actions: "أجراءات",
        view: "عرض",
        cancel: "إلغاء",
        delete: "حذف",
        update: "تحديث",
        save: "حفظ",
        send: "إرسال",
        edit: "تعديل",
        sureMsg: "هل أنت متأكد؟",
        status: "الحالة",
        delivery: "تسليم",
        service: "خدمة",
        warehouse: "مستودع",
        afewsecondsago: "بضع ثواني ماضية",
        products: "منتج",
        warehouses: "مستودعات",
        supplier: "مزود",
        news: "الاشعارات",
        tables: "جداول",
        projects: "مشاريع",
        suppliers: "مزودين",
        users: "مستخدمين",
        units: "الوحدات",
        currencies: "العملات",
        offices: "المكاتب",
        categories: "التصنيفات",
        discussion: "نقاش",
        suppliersList: "قائمة الموردين",
        unitsList: "قائمة الوحدات",
        usersList: "قائمة المستخدمين",
        notifications: "إشعارات",
        donors: "الجهات المانحة",
        donorsList: "قائمة الجهات المانحة",
        currency: "العملة",
        activate: "تفعيل",
        deactivate: "إبطال",
        // shortDateTime: '{{تاريخ قصير ، DD / MM HH: mm}}',
        // fullDateTime: '{{date, DD/MM/YYYY HH:mm}}',
        // date: '{{تاريخ طويل ، DD / MM / YYYY HH: mm}}',
        shortDateTime: "{{date, DD/MM HH:mm}}",
        fullDateTime: "{{date, DD/MM/YYYY HH:mm}}",
        date: "{{date , DD/MM/YYYY}}",
        logo: "شعار",
        name: "اسم",
        requireBatchNumber: "يتطلب رقم الدفعة ?",
        office: "منشأة",
        officeType: "نوع المنشأة",
        exportToExcel: "تصدير إلى إكسل",
        comments: "تعليقات",
        typeYourComment: "اكتب تعليقك",
        quantity: "الكمية",
        details: "تفاصيل",
        setDonorLogo: "تعيين شعار الجهة المانحة",
        perPrescriptionQuantity: "لكل كمية وصفة طبية",
        jobTitle: "المسمى الوظيفي",
        unit: "الوحدة",
        category: "الفئة",
        productsList: "قائمة المواد",
        closed: "مغلق",
        running: "فعال",
        planning: "مرحلة التخطيط",
        product: "المادة",
        project: "المشروع",
        notes: "ملاحظات",
        country: "البلد",
        email: "إيميل",
        website: "موقع إلكتروني",
        address: "عنوان",
        warehouseTable: "قائمة المستودع",
        print: "طباعة",
        donor: "الداعم",
    },
    suppliers: {
        code: "Code",
        city: "City",
        physicalAddress: "Physical Address",
        email: "Email",
        contactPerson: "Contact Person",
        registrationDate: "Registeration Date",
        companyNumber: "Company Number",
        website: "Website",
        businessType: "Business Type",
        Discussions: "Discussions",
        addNewSupplier: "Add New Supplier",
        companyName: "Company Name",
        country: "Country",
        mobileNumber: "Mobile Number",
        details: "Details",
        bankInformation: "      Bank Information:",
        searchForSupplierName: "Search for supplier name",
        rate: "Rate",
        iban: "IBAN",
        accountName: "Account Name",
        addSupplier: "Add New Supplier",
        editSupplier: "Edit Supplier",
        viewSupplier: "View Supplier Details",
        ratingAverage: "Average Rating",
        bankName: "Bank Name",
        supplierRating: "Supplier rating",
        rating: "Rating",
        ratedProduct: "Rated product",
        ratedBy: "Rated by",
        ratedAt: "Rated at",
    },
    alerts: {
        success: "Success",
        error: "Error",
        successfullyUpdated: "Successfully updated",
        successfullyUploaded: "Successfully uploaded",
        successfullyAdded: "Successfully added",
        emailCanNotBeEditted: "Email can not be editted",
        ratedSuccessfully: "Rated successfully",
        activatedSuccessfully: "Activated successfully",
        deactivatedSuccessfully: "Deactivated successfully",
        supplierDeactivationWarning: "Are you sure you want to deactivate this supplier?, once you deactivate it you wont be able to create new RFQ from him",
        productDeactivationWarning: "Are you sure you want to deactivate this product?, once you deactivate it you wont be able to include it in new IRs",
        cannotMixProductsAndServices: "Cannot mix products and services in the same IR",
        wrongCurrentPassword: "Current Password Is Wrong",
        passwordUpdated: "Password Successfully Updated",
        notValidEmail: "Invalid Email",
        loginUnsuccess: "Log In Failed",
        loginSuccess: "Successfully Logged In",
        invalidFormMsg: "Form Not Valid!",
        runProjectPopMsg: "Are you sure you want to start the project?",
        finishProjectPopMsg: "Are you sure you want to Close and Finish the project?",
        unitNameAlreadyExists: "Unit name already exists",
        categoryNameAlreadyExists: "Category name already exists",
        officeNameAlreadyExists: "Office name already exists",
        currencyNameAlreadyExists: "Currency name already exists",
        roleAlreadyExistsForThisUser: "Role already exists for this user",
        areYouSureYouWantToApproveThisDocument: "Are you sure you want to approve this document?",
        youCannotAddAnExpiredProduct: "You cannot add an expired product",
        areYouSureYouWantToCancelTheDocument: "Are you sure you want to cancel the document?",
        areYouSureYouWantToPublishTheDocument: "Are you sure you want to publish the document?",
        areYouSureYouWantToRevokeTheApprovalsFromThisDocument: "Are you sure you want to revoke the approvals from this document?",
        permissionAlreadyExists: "Permission already exists",
        dnDateCanNotBeBeforePoDate: "Dn date can not be before po date",
    },
    auth: {
        logOut: "تسجيل الخروج",
        enterConfirmedPassword: "ادخل تأكيد كلمة المرور",
        confirmNewPassword: "تأكيد كلمة مرور الجديدة",
        enterNewPassword: "ادخل الكلمة المرور الجديدة",
        newPassword: "كلمة مرور جديدة",
        enterCurrentPassword: "ادخل كلمة المرور الحالية",
        currentPassword: "كلمة المرور الحالية ",
        login: "تسجيل الدخول",
        forgetPassword: "نسيت كلمة المرور",
        enterPassword: "ادخل كلمة المرور",
        password: "كلمة المرور",
        enterEmail: "ادخل البريد الإلكتروني",
        verify: "تحقق",
        back: "العوده",
        verifyEmail: "تحقق من البريد الالكتروني",
        wrongEmail: "البري الالكتروني غير صحيح",
        checkEmail: "تأكد من البريد الالكتروني",
        resetPassword: "استعادة كلمة المرور",
        changePassword: "تغير كلمة المرور",
        email: "البريد الإلكتروني",
        confirmButton: "تأكيد",
    },
    orders: {
        downloadSignedCopy: "Download Signed Copy",
        revoke: "Revoke",
        publish: "Publish",
        requestedBy: "Requested By",
        requestedAt: "Requested At",
        print: "Print",
        uploadSignedArchive: "Upload Signed Archive",
        startrfq: "Create RFQ",
        addnewPO: "Add New PO",
        addNewInternalDN: "Add New Internal DN",
        setupProjectRole: "Setup Project Role",
        Status: "Status",
        deadLine: "Dead Line",
        viewIR: "View IR",
        addnewPR: "Add New PR",
        viewRFQ: "View RFQ",
        RFQNumber: "RFQ Number",
        ordersTabView: "Orders Tab View",
        approve: "approve",
        startCBA: "Start CBA",
        requiredDateToDelivery: "Required Delivery Date",
        expectedDateToDelivery: "Expected Delivery Date",
        personInCharge: "Person In Charge",
        viewPO: "View PO",
        unitPrice: "Unit Price",
        totalPrice: "Total Price",
        deliveryTerms: "Delivery Terms",
        paymentTerms: "Payment Terms",
        PONumber: "PO Number",
        overallTotal: "Overall Total",
        viewOrder: "View Order",
        completedAt: "Completed At",
        rfqNO: "RFQ No",
        createdAt: "Created At",
        initiatedBy: "Initiated By",
        draft: "Draft",
        pendingApprovals: "Pending Approvals",
        approved: "Approved",
        singedAndArchived: "Singed And Archived",
        completed: "Completed",
        cancelled: "Cancelled",
        officeName: "Office Name",
        cancel: "Cancel",
        complete: "Complete",
        createPOs: "Create POS",
        createSCs: "Create SCs",
        createSCNs: "Create SCNs",
        createDN: "Create DN",
        createPRs: "Create PRs",
        orders: "Orders",
        status: "Status",
        PO: "PO",
        RFQ: "RFQ",
    },
    rfq: {
        rfqNo: "Rfq no",
        rfqDate: "Rfq date",
        projectCode: "Project code",
        budgetLine: "Budget line",
        itemNo: "Item no",
        requestedItemDescription: "Requested item description",
        offeredItem: "Offered item",
        unit: "Unit",
        quantity: "Quantity",
        unitPrice: "Unit price",
        total: "Total",
        taxesKdv: "Taxes kdv",
        shippingCostInsurance: "Shipping cost insurance",
        currency: "Currency",
        overallTotal: "Overall total",
        processedByIda: "Processed by ida",
        processedBySupplier: "Processed by supplier",
        name: "Name",
        date: "Date",
        signature: "Signature",
        deliveryPlaceAndModeOfTransport: "Delivery place and mode of transport",
        deliveryDatePleaseSpecifyDatesIfPartialDelivery: "Delivery date please specify dates if partial delivery",
        termsOfPayment: "Terms of payment",
        packingLabellingAndSpecialInstructions: "Packing labelling and special instructions",
        quotationValidity: "Quotation validity",
        requestForQuotationsRfq: "Request for quotations rfq",
        form: "Form",
        supplierName: "Supplier name",
        supplierAddress: "Supplier address",
        tel: "Tel",
        website: "Website",
        email: "Email",
        contactPersonName: "Contact person name",
        contactPersonMobile: "Contact person mobile",
        approve: "Approve",
    },
    scn: {
        scnNumber: "Scn number",
        projectCode: "Project code",
        scnDate: "Scn date",
        budgetLine: "budgetLine",
        contractNo: "Contract no",
        deliveryLocation: "Delivery location",
        supplierNameAndAddress: "Supplier name and address",
        consigneeName: "Consignee name",
        consigneName: "Consigne name",
        itemNo: "Item no",
        description: "Description",
        unit: "Unit",
        servisOrdered: "Servis ordered",
        servisReceived: "Servis received",
        remarks: "Remarks",
        textAlign: "Text align",
        approve: "Approve",
        receivedAndCheckedBy: "Received and checked by",
        approvedBy: "Approved by",
        date: "Date",
        nameAndSignature: "Name and signature",
        deliveredBy: "Delivered by",
        nameTitleAndSignature: "Name title and signature",
        serviceCompleteNoteScn: "Service complete note scn",
    },
    cba: {
        nameofsupplier: "Nameofsupplier",
        currency: "Currency",
        exchangeRate: "Exchange rate",
        comparativeBidAnalysisCBA: "Comparative bid analysis cba",
        cbaNo: "Cba no",
        cbaDate: "Cba date",
        irNo: "Ir no",
        projectCode: "Project code",
        budgetLine: "Budget line",
        items: "Items",
        selectedSupplierNumber: "Selected supplier number",
        itemNo: "Item no",
        itemDescription: "Item description",
        unit: "Unit",
        quantity: "Quantity",
        offeredItem: "Offered item",
        unitPrice: "Unit price",
        totalPrice: "Total price",
        technicalEvolutionMark: "Technical evolution mark",
        total: "Total",
        kdv: "Kdv",
        shippingCostInsurance: "Shipping cost insurance",
        overallTotal: "Overall total",
        bidDetails: "Bid details",
        termsOfPayment: "Terms of payment",
        deliveryTerms: "Delivery terms",
        preparedByLogistics: "Prepared by logistics",
        approve: "Approve",
        selectedSupplierNo: "Selected supplier no",
        approvedByOfficeManager: "Approved by office manager",
        date: "Date",
        SignName: "Sign/name",
        justificationForSelection: "Justification for selection",
        approvedByFinanceProjectsManager: "Approved by finance projects manager",
        approvedByDeputyGeneralDirector: "Approved by deputy general director",
    },
    ir: {
        internalRequestIr: "Internal request ir",
        no: "No",
        itemDescriptionSpecifications: "Item description specifications",
        unit: "Unit",
        quantity: "Quantity",
        location: "Location",
        estimatedUnitPrice: "Estimated unit price",
        estimatedTotalPrice: "Estimated total price",
        reqruiedDeliveryDate: "Reqruied delivery date",
        projectManager: "Project manager",
        seniorLogisticsOfficer: "Senior logistics officer",
        financeProjectManager: "Finance project manager",
        deputyGeneral: "Deputy general",
        dateApproved: "Date approved",
        signature: "Signature",
        totalEstimatedCostLocalCurrency: "Total estimated cost local currency",
        totalEstimatedCost: "Total estimated cost",
        requestedBy: "Requested by",
        date: "Date",
        office: "Office",
        irNo: "IR No",
        projectCode: "Project code",
        descriptionOfFullIr: "Description of full IR",
        deliveryLocation: "Delivery location",
        exchangeRate1Usd: "Exchange rate for 1 usd",
        approve: "Approve",
    },
    po: {
        indepentDoctorAssociation: "Indepent doctor association",
        poNo: "Po no",
        address: "Address",
        correspondingIrCbaNo: "Corresponding ir cba no",
        projectCode: "Project code",
        requestedBy: "Requested by",
        name: "Name",
        date: "Date",
        signature: "Signature",
        telephone: "Telephone",
        email: "Email",
        vendor: "Vendor",
        telNo: "Tel no",
        contactPerson: "Contact person",
        paymentTerms: "Payment terms",
        invoiceTo: "Invoice to",
        deliveryTerms: "Delivery terms",
        deliverTo: "Deliver to",
        itemNo: "Item no",
        productName: "Product name",
        itemDescription: "Item description",
        brandModelSerialNo: "Brand model serial no",
        unit: "Unit",
        quantity: "Quantity",
        unitPrice: "Unit price",
        subTotal: "Sub total",
        total: "Total",
        kdv: "Kdv",
        shippingCostInsurance: "Shipping cost insurance",
        currency: "Currency",
        overallTotal: "Overall total",
        approvedByFinanceProjectsManager: "Approved by finance projects manager",
        nameAndSignature: "Name and signature",
        approvedByDeputyGeneralDirector: "Approved by deputy general director",
        nameOfSupplierAndStamp: "Name of supplier and stamp",
        purchaseOrderPo: "Purchase order po",
        approve: "Approve",
    },
    dn: {
        deliveryNoteNumber: "Delivery note number",
        projectCode: "Project code",
        deliveryNoteDate: "Delivery note date",
        poNo: "Po no",
        supplierNameAndAddress: "Supplier name and address",
        consigneeName: "Consignee name",
        itemNo: "Item no",
        productName: "Product name",
        description: "Description",
        brandModel: "Brand/Model",
        unit: "Unit",
        quantityOrdered: "Quantity ordered",
        quantityReceived: "Quantity received",
        remarks: "Remarks",
        receivedAndCheckedBy: "Received and checked by",
        approvedBy: "Approved by",
        date: "Date",
        nameAndSignature: "Name and signature",
        deliveredBy: "Delivered by",
        dateNameTitleAndSignature: "Date name title and signature",
        approve: "Approve",
    },
    pr: {
        paymentRequestNo: "Payment request no",
        from: "From",
        date: "Date",
        subject: "Subject",
        totalAmount: "Total amount",
        syr: "Syr",
        usd: "Usd",
        tl: "Tl",
        totalEquivalentAmountInUsd: "Total equivalent amount in usd",
        purposeOfPayment: "Purpose of payment",
        payeePleaseProvideAsMuchDetailsAsPossible: "Payee please provide as much details as possible",
        dueDate: "Due date",
        name: "Name",
        address: "Address",
        invoiceNumber: "Invoice number",
        contractPoNo: "Contract po no",
        paymentModeRequested: "Payment mode requested",
        forBankTransferOnly: "For bank transfer only",
        bankName: "Bank name",
        branch: "Branch",
        accountNo: "Account no",
        swiftNo: "Swift no",
        ibanNo: "Iban no",
        destinationOfFundPleaseProvideAsMuchDetailsAsPossible: "Destination of fund please provide as much details as possible",
        preparedByLogistics: "Prepared by logistics",
        country: "Country",
        office: "Office",
        note: "Note",
        costDistribution: "Cost distribution",
        no: "No",
        paymentDestination: "Payment destination",
        transactionCurrency: "Transaction currency",
        transactionAmount: "Transaction amount",
        exchangeRate: "Exchange rate",
        usdAmountOfTransaction: "Usd amount of transaction",
        approve: "Approve",
        requestedBy: "Requested by",
        reviwedAndCertifiedBy: "Reviwed and certified by",
        authorizedBy: "Authorized by",
        signature: "Signature",
        purchaseOrderPo: "Purchase order po",
        inAccordanceWithTheActivitiesAsDefinedInTheRelatedBudgetOfTheProjectDepartmentMentionedBelowWeHerebyRequestIdaOrganizationFinanceDepartmentToMakeTheFollowingDirectPaymentToThePayeeBelow: "In accordance with the activities as defined in the related budget of the project department mentioned below we hereby request ida organization finance department to make the following direct payment to the payee below",
    },
    products: {
        description: "توصيف المادة",
        unit: "الواحدة",
        category: "التصنيف",
        serialNumbered: "تحتاج الى رقم تسلسلي",
        service: "خدمة",
        productName: "اسم المنتج",
        addNewProduct: "إضافة منتج جديد",
        productType: "نوع المنتج",
        asset: "اصول",
        consumable: "مستهلكات",
        searchForProductName: "بحث عن اسم منتج",
        productAverageCost: "متوسط الكلفة",
        addProduct: "إضافة منتج جديد",
        editProduct: "تعديل منتج",
        viewProduct: "عرض منتج",
        productsList: "قائمة المنتجات",
        productCategory: "تصنيف المنتج",
        productBudgetLine: "بند ميزانية المنتج",
    },
    projects: {
        startingDate: "Starting Date",
        endingDate: "End Date",
        planning: "Planning",
        running: "Running",
        closed: "Closed",
        projectBudget: "Project Budget",
        endingDateCannotBeBeforeStartingDate: "Ending date cannot be before starting date",
        donor: "Project Donor",
        projectName: "Project Name",
        projectCode: "Project Code",
        projectOrders: "Project Orders",
        addProject: "Add New Project",
        viewProject: "View Project",
        projectRoles: "Project Roles",
        create: "Create new Project",
        projectsList: "Projects List",
        enidingDateCannotBeBeforeStartingDate: "Eniding date cannot be before starting date",
        createProject: "Create Project",
        createSucessMsg: "Added New Project",
        editProject: "Edit Project",
        userName: "User Name",
        userRoles: "User Roles",
        minTotal: "Min Total",
        maxTotal: "Max Total",
        irNo: "IR No",
        description: "Description",
        requestedBy: "Requested By",
        requestedAt: "Requested At",
        runProject: "Start Project",
        finishProject: "Finish Project",
        deleteConfirmMsg: "Are you sure delete this Item?",
        thresholdForRequiringCBAFifthApproval: "Threshold for requiring CBA fifth approval",
        PSCValue: "PSC Value",
        setupProjectRole: "Setup project role",
        createIR: "Create IR",
        code: "Code",
    },
    warehouse: {
        storePoint: "نقطة توزيع",
        assets: "الأصول",
        discussions: "المناقشات",
        prescriptionDischargeOperations: "وصفات التخريج",
        medications: "اللزوم",
        prescriptionMedicationsConsumptionOperations: "تفاصيل اللزوم",
        prescriptionMedicationsConsumptionOperationDetails: "مواد اللزوم",
        previousStocks: "الرصيد السابق",
        expiredAfter: "ينتهي بعد",
        consumeManyItems: "صرف مواد",
        consumePrescription: "صرف وصفة طبية",
        addNewItem: "إضافة عنصر جديد",
        warehouse: "المستودع",
        disposeItem: "إتلاف عنصر",
        consumeItem: "صرف عنصر",
        receiveDonationItem: "إستلام عنصر متبرع",
        sendDonationItem: "ارسال عنصر متبرع",
        transferItems: "نقل مواد",
        transferItemsToAnotherProject: "نقل مواد لمشروع أخر",
        movement: "حركة",
        items: "مواد",
        importOperations: " عمليات الاستيراد",
        prescriptionConsumptionOperations: "عمليات استهلاك الوصفات الطبية",
        prescriptionConsumptionOperationDetails: "تفاصيل عمليات استهلاك الوصفات الطبية",
        disposeOperations: "عمليات الإتلاف",
        consumeOperations: "عمليات الإستهلاك",
        sentItems: "إرسال مواد",
        sendDonationOperations: "عمليات إرسال التبرعات",
        receiveDonationOperations: "عمليات إستلام التبرعات",
        receiveItems: "المواد المستلمة",
        warehouseItems: "مواد المستودع",
        batchNumberMovements: "الحركات حسب رقم الدفعة",
        receivedFromDN: "المستلم من مذكرة إستلام مواد",
        serialNumbersLengthDoesntMatchAssetNumbersLength: "طول الرقم التسلسلي لا يطابق طول رقم الأصل",
        thisStockNumberBelongToAnotherProject: "هذا الرقم المخزني ينتمي لمشروع أخر",
        thisStockNumberBelongToAnotherItem: "هذا الرقم المخزني ينتمي لعنصر أخر",
        thisStockNumberBelongToAnotherBudgetLine: "هذا الرقم المخزني ينتمي لسطر ميزانية أخر",
        serialNumbersLengthDoesntMatchQuantity: "طول الرقم التسلسلي لا يطابق الكمية",
        SRFile: "أرفق ملف",
        downloadAttachedFile: "تنزيل الملف المرفق",
        availableQuantityIsNotEnough: "الكمية المتوفرة ليست كافية ({{products}})",
        prescriptionAlreadySpent: "الوصفة الطبية تم صرفها مسبقا",
        prescriptionNotReadyToBeSpent: "الوصفة الطبية ليست جاهزة للصرف!",
        doYouWantToPrintThisPrescription: "هل تريد أن تطبع هذه الوصفة الطبية?",
        prescriptionForAnotherWarehouse: "هذه الوصفة لمستودع أخر",
        createWarehouse: "أنشئ مستودع جديد",
        warehouseName: "أسم المستودع",
        warehouseAddress: "عنوان المستودع",
        disposalItem: "إتلاف المادة",
        receiveDonateItem: "إستلام مادة متبرعة",
        sendDonateItem: "‘رسال مادة متبرعة",
        sendItems: "إرسال مواد",
        product: "منتج",
        project: "مشروع",
        quantity: "كمية",
        serialNumber: "رقم تسلسلي",
        item: "مادة",
        to: "إلى",
        createdBy: "أنشئ من قبل",
        editWarehouse: "تعديل المستودع",
        viewWarehouse: "عرض المستودع",
        warehouseTable: "قائمة المستودعات",
        confirmButton: "تأكيد",
        selectBatchNumber: "إختيار رقم الدفعة",
        focalPointUser: "الشخص المسؤول",
        focalPointUserPhoneNO: "رقم هاتف الشخص المسؤول",
        focalPointUserContactInfo: "معلومات تواصل الشخص المسؤول",
        consumedBy: "أستهلك من قبل",
        disposedBy: "أتلف من قبل",
        operationType: "نوع العملية",
        fromWarehouse: "من مستودع",
        sentBy: "أرسل من قبل",
        warehousePrescriptionConsumptionOperationId: "معرف عملية استهلاك الوصفة الطبية للمستودع",
        toWarehousse: "إلى مستودع",
        numberShort: "الرقم",
        expiryDate: "تاريخ الصلاحية",
        shippingCompany: "شركة الشحن",
        waybillNumber: "رقم التتبع",
        confirm: "تأكيد",
        toProject: "إلى مشروع",
        batchNumber: "رقم الدفعة",
        notes: "ملاحظات",
        from: "من قبل",
        assetsNumbers: "رقم الأصل",
        stockNumber: "رقم المخزون",
        storageLocation: "موقع التخزين",
        importedBy: "مستورد عن طريق",
        patientName: "أسم المريض",
        prescriptionDate: "تاريخ الوصفة الطبية",
        sessionId: "معرف جلسة العمل",
        receivedBy: "أستلم من قبل",
        prescriptionItems: "مواد الوصفة الطبية",
        prescription: "الوصفة الطبية",
        serialNumbers: "الرقم التسلسلي",
        createdAt: "أنشئت في",
        importNewItem: "استيراد عنصر جديد",
        importDate: "تاريخ الإستيراد",
        confirmation: "تأكيد",
        fileAttatched: "الملف المرفق",
        toWarehouse: "إلى مستودع",
        typeBatchnNumberToProductMovements: "اكتب رقم الدفعة لحركات المنتج :",
        expired: "مواد غير صالحة",
    },
    budget: {
        sectioncode: "Section Code",
        sectiondescription: "Section Description",
        sectiontitle: "Section title",
        lineCostAllocation: "Line Cost Allocation",
        lineType: "Line Type",
        durationRecurrence: "Duration Recurrence",
        sectionLineTotal: "Section Line Total",
        reminderAt: "Reminder At",
        reminderUsers: "Reminder Users",
        addNewSection: "Add New Section",
        addNewSectionLine: "Add New Section Line",
        startReminder: "Start Reminder",
        $: " $ ",
        totalBudget: "Budget Total",
        addNewLine: "Add New Line",
        PSCcost: "PSC Cost",
        PSC: "PSC",
        budgetTotalCost: "Total Budget Cost",
        budgetTemplates: "Budget Templates",
        createNewTemplate: "Create New Template",
        templateName: "Template Name",
        BudgetLineItem: "Budget Line Item Code",
        createProcurementPlan: "Plan Procurement",
        procurementPlan: "Procurement Plan",
        addFromTemplates: "Add From Templates",
        budgetLine: "Budget Line",
        total: "Total",
        createIR: "Create IR",
        addNewItem: "Add new item",
        delete: "Delete",
        templateDescription: "Template Description",
        requiredDateToDelivery: "Required date to delivery",
        IRRising: "IR Rising",
        expectedDateToDelivery: "Expected date to delivery",
        personInCharge: "Person in charge",
        fileAttatched: "File attatched",
    },
    permissions: {
        orderComplete: "Order Complete",
        orderDiscussionView: "Order Discussion View",
        viewOrder: "View Order",
        viewNewsFeed: "View news feed",
        viewUsers: "View Users",
        viewCategories: "View Categories",
        viewCurrencies: "View Currencies",
        viewBudgetTemplates: "View Budget Templates",
        viewOffices: "View Offices",
        viewProjects: "View Projects",
        viewProducts: "View Products",
        viewSuppliers: "View Suppliers",
        viewUnits: "View Units",
        viewDonors: "View Donors",
        permissionsList: "Permissions List",
        managePermissions: "Manage Permissions",
        exportWarehouses: "Export Warehouses",
        batchNumberMovements: "Batch Number Movements",
        setupProjectBudget: "Setup Project Budget",
        setupProjectRoles: "Setup Project Roles",
        setupProcurementPlan: "Setup Procurement Plan",
        runProject: "Run Project",
        finishProject: "Finish Project",
        IRDeputyGeneralApproval: "IR Deputy General Approval",
        IRFinanceProjectManagerApproval: "IR Finance Project Manager Approval",
        IRProjectManagerApproval: "IR Project Manager Approval",
        IRSeniorLogisticOfficerApproval: "IR Senior Logistic Officer Approval",
        CBAOfficeManagerApproval: "CBA Office Manager Approval",
        CBALogisticsApproval: "CBA Logistics Approval",
        CBAFinanceProjectsManagerApproval: "CBA Finance Projects Manager Approval",
        CBADeputyGeneralDirectorApproval: "CBA Deputy General Director Approval",
        CBAGeneralDirectorApproval: "CBA General Director Approval",
        POGeneralDirectorApproval: "PO General Director Approval",
        PODeputyGeneralDirectorApproval: "PO Deputy General Director Approval",
        DNReceivedAndCheckedByApproval: "DN Received And Checked By Approval",
        DNApprovedByApproval: "DN Approved By Approval",
        SCNReceivedAndCheckedByApproval: "SCN Received And Checked By Approval",
        SCNApprovedByApproval: "SCN Approved By Approval",
        PRRequestedByApproval: "PR Requested By Approval",
        PRReviwedAndCertifiedByApproval: "PR Reviwed AndC ertified By Approval",
        PRAuthurizedByApproval: "PR Authurized By Approval",
        IRView: "IR View",
        IRCreate: "IR Create",
        IRUpdate: "IR Update",
        IRPublish: "IR Publish",
        IRRevoke: "IR Revoke",
        IRCancel: "IR Cancel",
        IRUploadSignedPapers: "IR Upload Signed Papers",
        IRViewDiscussion: "IR View Discussion",
        IRParticipateInDiscussion: "IR Participate In Discussion",
        IRDownloadSignedCopy: "IR Download Signed Copy",
        IRsTabView: "IRs Tab View",
        RFQView: "RFQ View",
        RFQCreate: "RFQ Create",
        RFQUpdate: "RFQ Update",
        RFQPublish: "RFQ Publish",
        RFQRevoke: "RFQ Revoke",
        RFQCancel: "RFQ Cancel",
        RFQUploadSignedPapers: "RFQ Upload Signed Papers",
        RFQViewDiscussion: "RFQ View Discussion",
        RFQParticipateInDiscussion: "RFQ Participate In Discussion",
        RFQDownloadSignedCopy: "RFQ Download Signed Copy",
        RFQsTabView: "RFQs Tab View",
        CBAView: "CBA View",
        CBACreate: "CBA Create",
        CBAUpdate: "CBA Update",
        CBAPublish: "CBA Publish",
        CBARevoke: "CBA Revoke",
        CBACancel: "CBA Cancel",
        CBAUploadSignedPapers: "CBA Upload Signed Papers",
        CBAViewDiscussion: "CBA View Discussion",
        CBAParticipateInDiscussion: "CBA Participate In Discussion",
        CBADownloadSignedCopy: "CBA Download Signed Copy",
        CBATabView: "CBA Tab View",
        POView: "PO View",
        POCreate: "PO Create",
        POUpdate: "PO Update",
        POPublish: "PO Publish",
        PORevoke: "PO Revoke",
        POCancel: "PO Cancel",
        POUploadSignedPapers: "PO Upload Signed Papers",
        POViewDiscussion: "PO View Discussion",
        POParticipateInDiscussion: "PO Participate In Discussion",
        PODownloadSignedCopy: "PO downlaod Signed Copy",
        POTabView: "PO Tab View",
        DNView: "عرض مذكرة الاستلام ",
        DNCreate: " إنشاء مذكرة الاستلام",
        DNUpdate: "تحديث مذكرة الاستلام",
        DNPublish: " نشر مذكرة الاستلام",
        DNRevoke: " إبطال مذكرة الاستلام",
        DNCancel: " إلغاء مذكرة الاستلام",
        DNUploadSignedPapers: " تحميل أوراق موقعة مذكرة الاستلام",
        DNViewDiscussion: " عرض المناقشة مذكرة الاستلام",
        DNParticipateInDiscussion: " شارك في المناقشة مذكرة الاستلام",
        DNDownloadSignedCopy: " تنزيل نسخة موقعة من  مذكرة الاستلام",
        DNTabView: " عرض علامة التبويب لمذكرة الاستلام",
        SCNView: "SCN View",
        SCNCreate: "SCN Create",
        SCNUpdate: "SCN Update",
        SCNPublish: "SCN Publish",
        SCNRevoke: "SCN Revoke",
        SCNCancel: "SCN Cancel",
        SCNUploadSignedPapers: "SCN Upload Signed Papers",
        SCNViewDiscussion: "SCN View Discussion",
        SCNParticipateInDiscussion: "SCN Participate In Discussion",
        SCNDownloadSignedCopy: "SCN Download Signed Copy",
        SCNTabView: "SCN Tab View",
        PRView: "PR View",
        PRCreate: "PR Create",
        PRUpdate: "PR Update",
        PRPublish: "PR Publish",
        PRRevoke: "PR Revoke",
        PRCancel: "PR Cancel",
        PRUploadSignedPapers: "PR Upload Signed Papers",
        PRViewDiscussion: "PR View Discussion",
        PRParticipateInDiscussion: "PR Participate In Discussion",
        PRDownloadSignedCopy: "PR Download Signed Copy",
        PRTabView: "PR Tab View",
        viewWarehouseItems: "عرض مواد المستودع",
        viewWarehouseImports: "عرض واردات المستودعات",
        viewDisposedWarehouseItems: "عرض مواد المستودعات التي تم التخلص منها",
        viewConsumedWarehouseItems: "عرض مواد المستودعات المستهلكة",
        viewSentWarehouseItems: "عرض مواد المستودعات المرسلة",
        viewReceiveWarehouseItems: "عرض مواد المستودعات المستلمة",
        viewDonateWarehouseItems: "عرض مواد المستودع المتبرع بها",
        viewReceivedDonatedWarehouseItems: "عرض مواد مستودع التبرعات المستلمة",
        viewTransferWarehouseItemsToAnotherProject: "عرض مواد المستودعات المحولة إلى مشروع آخر",
        viewReceiveWarehouseItemsFromDN: "عرض مواد المستودعات المستلمة من مذكرة الإستلام",
        viewPrescriptionconsumption: "عرض استهلاك وصفة طبية",
        viewPrescriptionconsumptionDetails: "عرض تفاصيل استهلاك وصفة طبية",
        createWarehouseImports: "إنشاء واردات المستودع",
        disposeWarehouseItems: "تخلص من مواد المستودع",
        consumeWarehouseItems: "إستهلاك مواد المستودع",
        sendWarehouseItems: "إرسال مواد المستودع",
        receiveWarehouseItems: "إستلام مواد المستودع",
        donateWarehouseItems: "التبرع بمواد المستودع",
        receiveDonatedWarehouseItems: "استلام مواد المستودعات المتبرع بها",
        transferWarehouseItemsToAnotherProject: "نقل مواد المستودع إلى مشروع آخر",
        receiveWarehouseItemsFromDN: "تلقي مواد المستودع من مذكرة الإستلام",
        confirmReceiveFromDN: "تأكيد الاستلام من مذكرة الإستلام",
        confirmReceiveDonation: "تأكيد تلقي التبرع",
        consumeWarehouseHimsPrescriptionPermission: "إذونات الوصفة HIMS إستهلاك المستودع",
        exportWarehouseItems: "تصدير مواد المستودعات",
        exportWarehouseImports: "تصدير واردات المستودع ",
        exportDisposedWarehouseItems: "تصدير مواد المستودعات التي تم التخلص منها",
        exportConsumedWarehouseItems: "تصدير عناصر المستودعات المستهلكة",
        exportSentWarehouseItems: "تصدير عناصر المستودعات المرسلة",
        exportReceiveWarehouseItems: "تصدير عناصر المستودعات المستلمة",
        exportDonateWarehouseItems: "تصدير عناصر المستودعات المتبرع بها",
        exportReceivedDonatedWarehouseItems: " نصدير إستلام مواد المستودعات المتبرع بها",
        exportTransferWarehouseItemsToAnotherProject: "تصدير مواد مستودع المنقولة إلى مشروع آخر",
        exportReceiveWarehouseItemsFromDN: "تصدير إستلام عناصر المستودعات من مذكرة الإستلام",
        exportPrescriptionconsumption: "تصدير استهلاك وصفة طبية",
        exportPrescriptionconsumptionDetails: "تصدير استهلاك وصفة طبية",
        viewWarehouseDiscussion: "عرض مناقشة المستودع",
        participateInWarehouseDiscussion: "المشاركة في مناقشة المستودعات",
        viewWarehouseItemMovement: "عرض حركة مواد المستودع",
        createEditUser: "Create Edit User",
        setupBudget: "Setup Budget",
        setupProcurementPlat: "Setup Procurement Plat",
        createProject: "Create Project",
        createProduct: "Create Product",
        createSupplier: "Create Supplier",
        createEditCurrency: "Create Edit Currency",
        createEditUnit: "Create Edit Unit",
        createEditDonor: "Create Edit Donor",
        createEditCategory: "Create Edit Category",
        createEditOffice: "Create Edit Office",
        createWarehouse: "Create Edit Warehouse",
        createBudgetTemplate: "Create Budget Template",
        editProject: "Edit Project",
        editProduct: "Edit Product",
        editSupplier: "Edit Supplier",
        editCurrency: "Edit Currency",
        editUnit: "Edit Unit",
        editDonor: "Edit Donor",
        editCategory: "Edit Category",
        editOffice: "Edit Office",
        editWarehouse: "Edit Warehouse",
        editBudgetTemplate: "Edit Budget Template",
        editProcurementPlan: "Edit Procurement Plan",
        deleteProcurementPlan: "Delete Procurement Plan",
        viewProject: "View Project",
        viewProduct: "View Product",
        viewSupplier: "View Supplier",
        viewCurrency: "View Currency",
        viewUnit: "View Unit",
        viewDonor: "View Donor",
        viewCategory: "View Category",
        viewOffice: "View Office",
        viewWarehouse: "View Warehouse",
        viewBudgetTemplate: "View BudgetTemplate",
        orderView: "Vew Order",
        viewSuppliersTab: "View SuppliersTab",
        viewProductsTabs: "View ProductsTabs",
        viewProjectsTab: "View ProjectsTab",
        viewWarehousesTab: "View Warehouses Tab",
        viewOrdersTab: "View Orders Tab",
        exportProjects: "Export Projects",
        exportProducts: "Export Products",
        exportSuppliers: "Export Suppliers",
        exportCurrencies: "Export Currencies",
        exportUnits: "Export Units",
        exportDonors: "Export Donors",
        exportCategories: "Export Categories",
        exportOffices: "Export Offices",
        exportUsers: "Export Users",
        warehouseExports: "Warehouse Exports",
        viewWarehouses: "View Warehouses",
        manageUserGlobalPermission: "Manage User Global Permission",
        manageUserProjectAndWarehouseRoles: "Manage User Project And Warehouse Roles",
        manageWarehouseUserRoles: "Manage Warehouse User Roles",
        viewProjectDiscussion: "View Project Discussion",
        viewProductDiscussion: "View Product Discussion",
        viewSupplierDiscussion: "View Supplier Discussion",
        participateInProjectDiscussion: "Participate In Project Discussion",
        participateInProductDiscussion: "Participate In Product Discussion",
        participateInSupplierDiscussion: "Participate In Supplier Discussion",
        ordersTabView: "Orders tab view",
    },
    hims: {
        note: "الملاحظات",
        overallQuantity: "الكمية المطلوبة",
        totalAvailableQuantity: "الكمية المتوفرة",
        storageLocation: "مكان التخزين",
        prescriptionDone: "تم صرف الوصفة",
        prescriptionNotAvailable: "الوصفة غير متوفرة",
        medicine: "الادوية",
        medicineDetails: "تفاصيل الادوية",
        medicineForm: "الشكل الدوائي",
        quantity: "الكمية",
        per: "التواتر",
        duration: "المدة",
        prescriptionInstruction: "يرجى التأكد من استلام جميع محتويات الوصفة قبل مغادرة الصيدلية ومراجعة المسؤول في حال وجود اي نقص ، كما يرجى الالتزام بالجرعة والوقت المحدد لها",
        idp: "نازح",
    },
    discussion: {
        typeYourComment: "اكتب تعليقك...",
    },
};
