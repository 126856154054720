"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var collectionsRef_1 = require("common/db/collectionsRef");
var app_1 = require("firebase/app");
var lodash_1 = require("lodash");
function createSCNs(orderId, transaction) {
    var _a, _b;
    return __awaiter(this, void 0, void 0, function () {
        var orderSN, orderData, projectId, userId, itemsSN, itemsData, IRSN, IRData, uniqSuppliers;
        var _this = this;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, collectionsRef_1.ordersRef.doc(orderId).get()];
                case 1:
                    orderSN = _c.sent();
                    orderData = orderSN.data();
                    projectId = orderData.projectId;
                    userId = (_b = (_a = app_1.auth().currentUser) === null || _a === void 0 ? void 0 : _a.uid) !== null && _b !== void 0 ? _b : "";
                    return [4 /*yield*/, collectionsRef_1.SCRef.where("orderId", "==", orderId)
                            .where("status", "==", "approved")
                            .get()];
                case 2:
                    itemsSN = _c.sent();
                    itemsData = itemsSN.docs.map(function (d) { return (__assign(__assign({}, d.data()), { id: d.id })); });
                    return [4 /*yield*/, collectionsRef_1.IRsRef.doc(orderData.IRId).get()];
                case 3:
                    IRSN = _c.sent();
                    IRData = __assign(__assign({}, IRSN.data()), { id: IRSN.id });
                    uniqSuppliers = lodash_1.uniqBy(itemsData, function (d) { return d.supplierId; });
                    return [4 /*yield*/, Promise.all(lodash_1.map(uniqSuppliers, function (itemsBySupplier) { return __awaiter(_this, void 0, void 0, function () {
                            var SCNRef, data, SCItemsSN, SCItemsData, filteredItems;
                            var _this = this;
                            var _a, _b;
                            return __generator(this, function (_c) {
                                switch (_c.label) {
                                    case 0:
                                        SCNRef = collectionsRef_1.SCNsRef.doc();
                                        data = {
                                            // contractNo: itemsBySupplier?.CONTRACTNO,
                                            orderId: orderId,
                                            SCNNumber: itemsBySupplier.SCNumber,
                                            supplierId: itemsBySupplier.supplierId,
                                            projectId: projectId !== null && projectId !== void 0 ? projectId : "",
                                            projectsIds: (_a = itemsBySupplier.projectsIds) !== null && _a !== void 0 ? _a : [],
                                            status: "draft",
                                            POId: itemsBySupplier.id,
                                            SCId: itemsBySupplier.id,
                                            initiatedBy: userId,
                                            budgetLineId: (_b = IRData === null || IRData === void 0 ? void 0 : IRData.budgetLineId) !== null && _b !== void 0 ? _b : "",
                                            createdAt: new Date(),
                                            consigneName: "Independent Doctors Association (Bağımsız Doktorlar Derneği)",
                                        };
                                        console.log({ data: data });
                                        transaction.set(SCNRef, data);
                                        return [4 /*yield*/, collectionsRef_1.SCItemsRef.where("SCId", "==", itemsBySupplier.id).get()];
                                    case 1:
                                        SCItemsSN = _c.sent();
                                        SCItemsData = SCItemsSN.docs.map(function (d) { return (__assign(__assign({}, d.data()), { id: d.id })); });
                                        filteredItems = SCItemsData.filter(function (d) { return d.supplierId === itemsBySupplier.supplierId; });
                                        return [4 /*yield*/, Promise.all(filteredItems.map(function (SCItemDoc) { return __awaiter(_this, void 0, void 0, function () {
                                                var productId, unitId, quantity, productData, SCNItemData;
                                                var _a, _b, _c;
                                                return __generator(this, function (_d) {
                                                    switch (_d.label) {
                                                        case 0:
                                                            console.log(SCItemDoc === null || SCItemDoc === void 0 ? void 0 : SCItemDoc.budgetLineId, "ss");
                                                            productId = SCItemDoc.productId, unitId = SCItemDoc.unitId, quantity = SCItemDoc.quantity;
                                                            return [4 /*yield*/, collectionsRef_1.productsRef.doc(productId).get()];
                                                        case 1:
                                                            productData = (_a = (_d.sent())) === null || _a === void 0 ? void 0 : _a.data();
                                                            SCNItemData = {
                                                                SCNId: SCNRef.id,
                                                                POItemId: itemsBySupplier.id,
                                                                SCId: itemsBySupplier.id,
                                                                SCItemId: SCItemDoc.id,
                                                                serviceOrdered: quantity,
                                                                serviceReceived: quantity,
                                                                productId: productId,
                                                                unitId: unitId,
                                                                productDescription: productData === null || productData === void 0 ? void 0 : productData.description,
                                                                productName: productData === null || productData === void 0 ? void 0 : productData.name,
                                                                budgetLineId: (_c = (_b = SCItemDoc.budgetLineId) !== null && _b !== void 0 ? _b : IRData === null || IRData === void 0 ? void 0 : IRData.budgetLineId) !== null && _c !== void 0 ? _c : "",
                                                                budgetLinesIds: SCItemDoc.budgetLinesIds,
                                                                budgetLinesPercentages: SCItemDoc.budgetLinesPercentages,
                                                            };
                                                            console.log({ SCNItemData: SCNItemData });
                                                            transaction.set(collectionsRef_1.SCNItemsRef.doc(), SCNItemData);
                                                            return [2 /*return*/];
                                                    }
                                                });
                                            }); }))];
                                    case 2:
                                        _c.sent();
                                        return [2 /*return*/];
                                }
                            });
                        }); }))];
                case 4:
                    _c.sent();
                    return [2 /*return*/];
            }
        });
    });
}
exports.default = createSCNs;
