"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var antd_1 = require("antd");
var collectionsRef_1 = require("common/db/collectionsRef");
var formik_1 = require("formik");
var react_1 = __importStar(require("react"));
var firestore_1 = require("react-firebase-hooks/firestore");
var react_i18next_1 = require("react-i18next");
var TextArea = antd_1.Input.TextArea;
function EditSectionDialog(props) {
    var _this = this;
    var t = react_i18next_1.useTranslation().t;
    var formProps = formik_1.useFormik({
        initialValues: {
            title: '',
            description: '',
            code: '',
        },
        onSubmit: function (values, formProps) { return __awaiter(_this, void 0, void 0, function () {
            var e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, collectionsRef_1.budgetSectionsRef.doc(sectionId).update({
                                code: values.code,
                                description: values.description,
                                title: values.title,
                            })];
                    case 1:
                        _a.sent();
                        setModalState(false);
                        antd_1.message.success(t('alerts.success'));
                        formProps.resetForm();
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        console.log(e_1);
                        antd_1.message.error(t('alerts.error'));
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); },
    });
    var _a = firestore_1.useDocument(collectionsRef_1.budgetSectionsRef.doc(props.sectionId)), budgetSectionsSnapshot = _a[0], loading = _a[1];
    var budgetSection = budgetSectionsSnapshot === null || budgetSectionsSnapshot === void 0 ? void 0 : budgetSectionsSnapshot.data();
    var modalState = props.modalState, sectionId = props.sectionId, setModalState = props.setModalState;
    var _b = react_1.useState(false), loaded = _b[0], setLoaded = _b[1];
    react_1.useEffect(function () {
        if (!budgetSection || loaded) {
            return;
        }
        setLoaded(true);
        formProps.setValues({
            title: budgetSection.title,
            description: budgetSection.description,
            code: budgetSection.code,
        });
    }, [budgetSection, formProps, loaded, loading]);
    if (budgetSection === undefined) {
        return null;
    }
    return (react_1.default.createElement(antd_1.Modal, { title: "Edit Section", visible: modalState, onOk: function () { return formProps.handleSubmit(); }, onCancel: function () { return setModalState(false); }, confirmLoading: formProps.isSubmitting },
        react_1.default.createElement(antd_1.Form, { labelCol: { span: 8 }, wrapperCol: { span: 16 } },
            react_1.default.createElement(antd_1.Form.Item, { validateStatus: (formProps.submitCount || formProps.getFieldMeta('title').touched) && formProps.errors.title && 'error', help: (formProps.submitCount || formProps.getFieldMeta('title').touched) && formProps.errors.title, label: t('budget.sectiontitle') },
                react_1.default.createElement(antd_1.Input, { style: { width: '100%', margin: '5% 0px' }, value: formProps.values.title, onChange: function (e) { return formProps.setFieldValue('title', e.target.value); } })),
            react_1.default.createElement(antd_1.Form.Item, { validateStatus: (formProps.submitCount || formProps.getFieldMeta('description').touched) && formProps.errors.description && 'error', help: (formProps.submitCount || formProps.getFieldMeta('description').touched) && formProps.errors.description, label: t('budget.sectiondescription') },
                react_1.default.createElement(TextArea, { onChange: function (e) { return formProps.setFieldValue('description', e.target.value); }, value: formProps.values.description })),
            react_1.default.createElement(antd_1.Form.Item, { validateStatus: (formProps.submitCount || formProps.getFieldMeta('code').touched) && formProps.errors.code && 'error', help: (formProps.submitCount || formProps.getFieldMeta('code').touched) && formProps.errors.code, label: t('budget.sectioncode') },
                react_1.default.createElement(antd_1.Input, { value: formProps.values.code, onChange: function (e) { return formProps.setFieldValue('code', e.target.value); } })))));
}
exports.default = EditSectionDialog;
