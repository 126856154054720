"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var lodash_1 = require("lodash");
var currenciesList_1 = require("../global/currenciesList");
function useCurrenciesOptions() {
    return react_1.useMemo(function () {
        return lodash_1.map(currenciesList_1.globalCurrenciesData, function (data) {
            return {
                text: data.name,
                value: data.id,
            };
        });
    }, []);
}
exports.default = useCurrenciesOptions;
