"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hasProjectPermission = void 0;
var userProjectRolesData_1 = require("common/global/userProjectRolesData");
var hasProjectPermission = function (projectId, projectPermissions) {
    var userRoles = userProjectRolesData_1.globalUserProjectRolesData
        .filter(function (x) { return x.projectId === projectId; })
        .map(function (doc) {
        return doc.role.toLowerCase();
    });
    return projectPermissions.map(function (x) { return x.toLowerCase(); }).some(function (r) { return userRoles.includes(r); });
};
exports.hasProjectPermission = hasProjectPermission;
