"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateBudgetRefCodes = void 0;
var collectionsRef_1 = require("common/db/collectionsRef");
var updateBudgetRefCodes = function (projectId) { return __awaiter(void 0, void 0, void 0, function () {
    var budgetSectionsSnapshot;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, collectionsRef_1.budgetSectionsRef.where('projectId', '==', projectId).get()];
            case 1:
                budgetSectionsSnapshot = _a.sent();
                return [4 /*yield*/, Promise.all(budgetSectionsSnapshot.docs.map(function (sectionSnapshot) { return __awaiter(void 0, void 0, void 0, function () {
                        var sectionId, sectionData, budgetLinesSnapshot, sectionLineTotal;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    sectionId = sectionSnapshot.id;
                                    sectionData = sectionSnapshot.data();
                                    return [4 /*yield*/, collectionsRef_1.budgetLinesRef
                                            .where('budgetSectionId', '==', sectionId)
                                            .orderBy('createdAt', 'asc')
                                            .get()];
                                case 1:
                                    budgetLinesSnapshot = _a.sent();
                                    sectionLineTotal = 0;
                                    return [4 /*yield*/, Promise.all(budgetLinesSnapshot.docs.map(function (budgetLineSnapshot, lineIdx) { return __awaiter(void 0, void 0, void 0, function () {
                                            var budgetLine, lineCode, budgetLineItemsSnapshot, lineItemsTotal, lineTotal;
                                            return __generator(this, function (_a) {
                                                switch (_a.label) {
                                                    case 0:
                                                        budgetLine = budgetLineSnapshot.data();
                                                        lineCode = sectionData.code + "." + (lineIdx + 1);
                                                        return [4 /*yield*/, collectionsRef_1.budgetLineItemsRef
                                                                .where('budgetLineId', '==', budgetLineSnapshot.id)
                                                                .orderBy('createdAt', 'asc')
                                                                .get()];
                                                    case 1:
                                                        budgetLineItemsSnapshot = _a.sent();
                                                        lineItemsTotal = 0;
                                                        return [4 /*yield*/, Promise.all(budgetLineItemsSnapshot.docs.map(function (budgetLineItemSnapshot, lineItemIdx) { return __awaiter(void 0, void 0, void 0, function () {
                                                                var budgetLineItem, itemTotal;
                                                                return __generator(this, function (_a) {
                                                                    switch (_a.label) {
                                                                        case 0:
                                                                            budgetLineItem = budgetLineItemSnapshot.data();
                                                                            itemTotal = budgetLineItem.quantity * budgetLineItem.unitCost;
                                                                            lineItemsTotal += itemTotal;
                                                                            return [4 /*yield*/, budgetLineItemSnapshot.ref.update({
                                                                                    // code: `${lineCode}.${lineItemIdx + 1}`,
                                                                                    maxQuantity: budgetLineItem.quantity * budgetLine.quantity * budgetLine.durationRecurrence,
                                                                                    plannedQuantity: 0,
                                                                                    total: itemTotal,
                                                                                })];
                                                                        case 1:
                                                                            _a.sent();
                                                                            return [2 /*return*/];
                                                                    }
                                                                });
                                                            }); }))];
                                                    case 2:
                                                        _a.sent();
                                                        lineTotal = ((lineItemsTotal * budgetLine.allocation) / 100) * budgetLine.durationRecurrence * budgetLine.quantity;
                                                        sectionLineTotal += lineTotal;
                                                        return [4 /*yield*/, budgetLineSnapshot.ref.update({
                                                                // code: lineCode,
                                                                total: lineTotal,
                                                            })];
                                                    case 3:
                                                        _a.sent();
                                                        return [2 /*return*/];
                                                }
                                            });
                                        }); }))];
                                case 2:
                                    _a.sent();
                                    return [4 /*yield*/, sectionSnapshot.ref.update({
                                            total: sectionLineTotal,
                                        })];
                                case 3:
                                    _a.sent();
                                    return [2 /*return*/];
                            }
                        });
                    }); }))];
            case 2:
                _a.sent();
                return [2 /*return*/];
        }
    });
}); };
exports.updateBudgetRefCodes = updateBudgetRefCodes;
