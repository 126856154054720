"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.globalOfficesData = void 0;
var collectionsRef_1 = require("../db/collectionsRef");
exports.globalOfficesData = {};
collectionsRef_1.officesRef.onSnapshot(function (data) {
    Object.defineProperty(exports.globalOfficesData, 'loaded', { enumerable: false, value: true });
    data.docs.forEach(function (doc) {
        exports.globalOfficesData[doc.id] = __assign(__assign({}, doc.data()), { id: doc.id });
    });
});
