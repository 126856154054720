"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var antd_1 = require("antd");
var collectionsRef_1 = require("common/db/collectionsRef");
var projectsList_1 = require("common/global/projectsList");
var formik_1 = require("formik");
var permissiontypes_1 = require("portal/pages/Tables/Users/permissiontypes");
var react_1 = __importStar(require("react"));
var firestore_1 = require("react-firebase-hooks/firestore");
var react_i18next_1 = require("react-i18next");
var TextArea = antd_1.Input.TextArea;
var Option = antd_1.Select.Option;
function AddEditBudgetLineDialog(props) {
    var _this = this;
    var _a, _b;
    var t = react_i18next_1.useTranslation().t;
    var projectSN = firestore_1.useCollection(collectionsRef_1.projectsRef)[0];
    var modalState = props.modalState, setModalState = props.setModalState, budgetLine = props.budgetLine, type = props.type;
    var formProps = formik_1.useFormik({
        initialValues: {
            code: "",
            projectId: "",
        },
        onSubmit: function (values) { return __awaiter(_this, void 0, void 0, function () {
            var e_1, e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(type == "add")) return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, collectionsRef_1.budgetLinesRef.add({
                                code: values.code,
                                projectId: values.projectId,
                                createdAt: new Date(),
                            })];
                    case 2:
                        _a.sent();
                        antd_1.message.success(t("alerts.success"));
                        setModalState(false);
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        antd_1.message.success(t("alerts.error"));
                        return [3 /*break*/, 4];
                    case 4:
                        if (!(type == "edit")) return [3 /*break*/, 8];
                        _a.label = 5;
                    case 5:
                        _a.trys.push([5, 7, , 8]);
                        return [4 /*yield*/, collectionsRef_1.budgetLinesRef.doc(budgetLine.id).set({
                                code: values.code,
                                projectId: values.projectId,
                                createdAt: new Date(),
                            })];
                    case 6:
                        _a.sent();
                        antd_1.message.success(t("alerts.success"));
                        setModalState(false);
                        return [3 /*break*/, 8];
                    case 7:
                        e_2 = _a.sent();
                        antd_1.message.success(t("alerts.error"));
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        }); },
    });
    react_1.useEffect(function () {
        if (type == "edit") {
            formProps.setValues(__assign({}, budgetLine));
        }
    }, [type]);
    if (projectSN === undefined) {
        return null;
    }
    var projectsData = projectSN === null || projectSN === void 0 ? void 0 : projectSN.docs.map(function (project) {
        return __assign(__assign({}, project.data()), { id: project.id });
    });
    return (react_1.default.createElement(antd_1.Modal, { title: "Add Template", visible: modalState, onOk: function () { return formProps.handleSubmit(); }, onCancel: function () { return setModalState(false); }, confirmLoading: formProps.isSubmitting },
        react_1.default.createElement(antd_1.Form, { labelCol: { span: 8 }, wrapperCol: { span: 16 } },
            react_1.default.createElement(antd_1.Form.Item, { validateStatus: (formProps.submitCount || formProps.getFieldMeta("code").touched) &&
                    formProps.errors.code &&
                    "error", help: (formProps.submitCount || formProps.getFieldMeta("code").touched) &&
                    formProps.errors.code, label: t("budget.code"), required: true },
                react_1.default.createElement(antd_1.Input, { value: formProps.values.code, onChange: function (e) { return formProps.setFieldValue("code", e.target.value); } })),
            react_1.default.createElement(antd_1.Form.Item, { validateStatus: (formProps.submitCount ||
                    formProps.getFieldMeta("projectId").touched) &&
                    formProps.errors.projectId &&
                    "error", help: (formProps.submitCount ||
                    formProps.getFieldMeta("projectId").touched) &&
                    formProps.errors.projectId, label: t("budget.projectCode"), required: true },
                react_1.default.createElement(antd_1.Select, { placeholder: t("common.project"), showSearch: true, style: { width: "150px" }, optionFilterProp: "children", onSelect: function (e) { return formProps.setFieldValue("projectId", e); }, value: (_b = projectsList_1.globalProjectsData[(_a = formProps === null || formProps === void 0 ? void 0 : formProps.values.projectId) !== null && _a !== void 0 ? _a : ""]) === null || _b === void 0 ? void 0 : _b.code }, projectsData === null || projectsData === void 0 ? void 0 : projectsData.map(function (project) {
                    if (permissiontypes_1.permissionTypes(project.mission)) {
                        return (react_1.default.createElement(Option, { key: project.id, value: project.id }, project === null || project === void 0 ? void 0 : project.code));
                    }
                    else
                        return;
                }))))));
}
exports.default = AddEditBudgetLineDialog;
