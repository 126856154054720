"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hasWarehousePermission = void 0;
var userProjectRolesData_1 = require("common/global/userProjectRolesData");
var hasWarehousePermission = function (warehouseId, warehousePermissions) {
    var userRoles = userProjectRolesData_1.globalUserWarehouseRolesData
        .filter(function (x) { return x.warehouseId === warehouseId; })
        .map(function (doc) {
        return doc.role.toLowerCase();
    });
    return warehousePermissions
        .map(function (x) { return x.toLowerCase(); })
        .some(function (r) { return userRoles.includes(r); });
};
exports.hasWarehousePermission = hasWarehousePermission;
