"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    common: {
        home: "Home",
        roles: "Design Roles",
        budgetLines: "BudgetLines",
        prescriptionDate: "Prescription Date",
        addNewBudgetLine: "Add New Budgetline",
        mission: "Mission",
        unitPrice: "Unit Price",
        totalPrice: "Total Price",
        from: "From",
        warehousePendingMyApproval: "Warehouse News",
        stockNumber: "Stock Number",
        quantityRequested: "Quantity Requested",
        expiryDate: "Expiry Date",
        prescription: "Prescription",
        birthDate: "Birth Date",
        cachedBirthdate: "Birth date",
        cachedCenterName: "Center Name",
        cachedIDP: "IDP",
        cachedPatientName: "Patient Name",
        cachedSex: "Gender",
        department: "Department",
        room: "Room",
        bed: "Bed",
        orders: "Orders",
        signature: "My Signature",
        lang: "Languages",
        languages: "Languages",
        arabic: "العربية",
        english: "English",
        ida: "Independent Doctors Association",
        ok: "OK",
        warning: "Warning",
        error: "Error",
        success: "Success",
        yes: "Yes",
        no: "No",
        IR: "IR",
        RFQ: "RFQ",
        CBA: "CBA",
        PO: "PO",
        DN: "DN",
        SCN: "SCN",
        PR: "PR",
        actions: "Actions",
        view: "View",
        cancel: "Cancel",
        delete: "Delete",
        update: "Update",
        save: "Save",
        send: "Send",
        edit: "Edit",
        sureMsg: "Are you sure?",
        status: "Status",
        delivery: "Delivery",
        service: "Service",
        warehouse: "Warehouse",
        afewsecondsago: "A Few Seconeds ago",
        products: "Products",
        warehouses: "Warehouses",
        supplier: "Supplier",
        news: "News",
        tables: "Tables",
        projects: "Projects",
        suppliers: "Suppliers",
        users: "Users",
        units: "Units",
        currencies: "Currencies",
        offices: "Offices",
        categories: "Categories",
        discussion: "Discussion",
        suppliersList: "Suppliers List",
        unitsList: "Units List",
        usersList: "Users List",
        notifications: "Notifications",
        donors: "Donors",
        donorsList: "Donors List",
        currency: "Currency",
        activate: "Activate",
        deactivate: "Deactivate",
        shortDateTime: "{{date, DD/MM HH:mm}}",
        fullDateTime: "{{date, DD/MM/YYYY HH:mm}}",
        DateTime: "{{date, DD/MM/YYYY}}",
        date: "{{date , DD/MM/YYYY}}",
        logo: "Logo",
        name: "Name",
        requireBatchNumber: "Require batch number ?",
        office: "Facility",
        officeType: "Facility Type",
        exportToExcel: "Export To Excel",
        comments: "Comments",
        typeYourComment: "Type your comment",
        quantity: "Quantity",
        details: "Details",
        setDonorLogo: "Set donor logo",
        perPrescriptionQuantity: "Per prescription quantity",
        jobTitle: "jobTitle",
        unit: "Unit",
        category: "Category",
        productsList: "Products list",
        closed: "Closed",
        running: "Running",
        planning: "Planning",
        product: "Product",
        project: "Project",
        notes: "Notes",
        country: "Country",
        email: "Email",
        website: "Website",
        address: "Address",
        warehouseTable: "Warehouse table",
        print: "Print",
        donor: "Donor",
        welocmeToIndependentDoctorsAssociationLogsitics: "Welcome to Independent Doctors Association Logistics-Software",
        focalPointUser: "Focal Point User",
        youAreIn: "You are in : ",
        specification: "Specification",
        receivedQuantity: "Received quantity",
        addMaintenanceItem: "Add maintenance item",
        maintenance: "Maintenance",
        maintenancePrice: "Maintenance price",
        addRepairedItem: "Add repaired item",
        addedToMaintenanceAt: "Added to maintenance at",
        repairedAt: "Repaired at",
        maintenanceNotes: "Maintenance notes",
        repairNotes: "Repair notes",
        deleteOrder: "Delete order",
        ordersPendingMyApproval: "Pending approval",
    },
    suppliers: {
        code: "Code",
        city: "City",
        physicalAddress: "Physical Address",
        email: "Email",
        contactPerson: "Contact Person",
        registrationDate: "Registeration Date",
        companyNumber: "Company Number",
        website: "Website",
        businessType: "Business Type",
        Discussions: "Discussions",
        addNewSupplier: "Add New Supplier",
        companyName: "Company Name",
        country: "Country",
        mobileNumber: "Mobile Number",
        details: "Details",
        bankInformation: "      Bank Information:",
        searchForSupplierName: "Search for supplier name",
        rate: "Rate",
        iban: "IBAN",
        accountName: "Account Name",
        addSupplier: "Add New Supplier",
        editSupplier: "Edit Supplier",
        viewSupplier: "View Supplier Details",
        ratingAverage: "Average Rating",
        bankName: "Bank Name",
        supplierRating: "Supplier rating",
        rating: "Rating",
        ratedProduct: "Rated product",
        ratedBy: "Rated by",
        ratedAt: "Rated at",
        product: "Product",
        notes: "Notes",
        mobilePhone: "Mobile phone",
    },
    alerts: {
        success: "Success",
        error: "Error",
        successfullyUpdated: "Successfully updated",
        successfullyUploaded: "Successfully uploaded",
        successfullyAdded: "Successfully added",
        emailCanNotBeEditted: "Email can not be editted",
        ratedSuccessfully: "Rated successfully",
        activatedSuccessfully: "Activated successfully",
        deactivatedSuccessfully: "Deactivated successfully",
        supplierDeactivationWarning: "Are you sure you want to deactivate this supplier?, once you deactivate it you wont be able to create new RFQ from him",
        productDeactivationWarning: "Are you sure you want to deactivate this product?, once you deactivate it you wont be able to include it in new IRs",
        cannotMixProductsAndServices: "Cannot mix products and services in the same IR",
        wrongCurrentPassword: "Current Password Is Wrong",
        passwordUpdated: "Password Successfully Updated",
        notValidEmail: "Invalid Email",
        loginUnsuccess: "Log In Failed",
        loginSuccess: "Successfully Logged In",
        invalidFormMsg: "Form Not Valid!",
        runProjectPopMsg: "Are you sure you want to start the project?",
        finishProjectPopMsg: "Are you sure you want to Close and Finish the project?",
        unitNameAlreadyExists: "Unit name already exists",
        categoryNameAlreadyExists: "Category name already exists",
        officeNameAlreadyExists: "Office name already exists",
        currencyNameAlreadyExists: "Currency name already exists",
        roleAlreadyExistsForThisUser: "Role already exists for this user",
        areYouSureYouWantToApproveThisDocument: "Are you sure you want to approve this document?",
        youCannotAddAnExpiredProduct: "You cannot add an expired product",
        areYouSureYouWantToCancelTheDocument: "Are you sure you want to cancel the document?",
        areYouSureYouWantToPublishTheDocument: "Are you sure you want to publish the document?",
        areYouSureYouWantToRevokeTheApprovalsFromThisDocument: "Are you sure you want to revoke the approvals from this document?",
        permissionAlreadyExists: "Permission already exists",
        dnDateCanNotBeBeforePoDate: "Dn date can not be before po date",
        supplierActivationWarning: "Are you sure you want to activate this supplier?",
        productActivationWarning: "Are you sure you want to activate this product?",
        cbaDateCanNotBeBeforeRfqDate: "CBA date can not be before RFQ date",
        youCanSearchByOnlyOneNumber: "You can search by only one number",
        budgetLinedoesNotBelongToDestinationProjectSoItWillBeIgnored: "Budget linedoes not belong to destination project so it will be ignored",
        youCannotReceiveAnExpiredItem: "You cannot receive an expired item",
        srNumberAttachmentFileIsArequiredField: "SR number attachment file is arequired field",
        waybillNumberAttachmentFileIsArequiredField: "Waybill number attachment file is arequired field",
        noAssetFoundWithEnteredSerialNumber: "No asset found with entered serial number",
        rowDeletedSuccessfully: "Row deleted successfully",
        quantityMustBePositive: "Quantity must be positive",
    },
    auth: {
        logOut: "Log Out",
        enterConfirmedPassword: "Enter Confirmed Password",
        confirmNewPassword: "Confirm New Password",
        enterNewPassword: "Enter New Password",
        newPassword: "New Password",
        enterCurrentPassword: "Enter Current Password",
        currentPassword: "Current Password",
        login: "Login",
        forgetPassword: "Forget Password",
        enterPassword: "Enter Password",
        password: "Password",
        enterEmail: "Enter Email",
        verify: "Verify",
        back: "Back",
        verifyEmail: "Verify Email",
        wrongEmail: "Wrong Email",
        checkEmail: "Check Email",
        resetPassword: "Reset Password",
        changePassword: "Change Password",
        email: "Email",
        confirmButton: "Confirm button",
    },
    orders: {
        toWarehouseId: "To Warehouse",
        SRDate: "SR Date",
        deliveryDate: "Delivery Date",
        downloadSignedCopy: "Download Signed Copy",
        revoke: "Revoke",
        publish: "Publish",
        requestedBy: "Requested By",
        requestedAt: "Requested At",
        print: "Print",
        uploadSignedArchive: "Upload Signed Archive",
        startrfq: "Create RFQ",
        addnewPO: "Add New PO",
        addNewInternalDN: "Add New Internal DN",
        setupProjectRole: "Setup Project Role",
        Status: "Status",
        deadLine: "Dead Line",
        viewIR: "View IR",
        addnewPR: "Add New PR",
        viewRFQ: "View RFQ",
        RFQNumber: "RFQ Number",
        ordersTabView: "Orders Tab View",
        approve: "approve",
        startCBA: "Start CBA",
        requiredDateToDelivery: "Required Delivery Date",
        expectedDateToDelivery: "Expected Delivery Date",
        personInCharge: "Person In Charge",
        viewPO: "View PO",
        unitPrice: "Unit Price",
        totalPrice: "Total Price",
        deliveryTerms: "Delivery Terms",
        paymentTerms: "Payment Terms",
        PONumber: "PO Number",
        overallTotal: "Overall Total",
        viewOrder: "View Order",
        completedAt: "Completed At",
        rfqNO: "RFQ No",
        createdAt: "Created At",
        initiatedBy: "Initiated By",
        draft: "Draft",
        pendingApprovals: "Pending Approvals",
        approved: "Approved",
        singedAndArchived: "Signed And Archeived",
        completed: "Completed",
        cancelled: "Cancelled",
        officeName: "Office Name",
        cancel: "Cancel",
        complete: "Complete",
        createPOs: "Create POs",
        createSCs: "Create SCs",
        createDirectSCs: "Create Direct SCs",
        createSCNs: "Create SCNs",
        createDN: "Create DN",
        createDirectDN: "Create Direct DN",
        createPRs: "Create PRs",
        orders: "Orders",
        status: "Status",
        PO: "PO",
        RFQ: "RFQ",
        SC: "SC",
        createPR: "Create PR",
        signedAndArchived: "Signed And Archeived",
        archive: "Archive",
        orderNumber: "Order number",
        preparingRFQs: "Preparing RFQs",
        preparingPOs: "Preparing POs",
        postPOs: "Post POs",
        preparingSCNs: "Preparing SCNs",
        preparingPRs: "Preparing PRs",
        preparingSC: "Preparing SC",
        postSCs: "Post Scs",
        archived: "Archived",
        pendingIrProjectManagerApproval: "Pending IR project manager approval",
        pendingIrSeniorLogisticOfficerApproval: "Pending IR senior logistic officer approval",
        pendingIrFinanceProjectManagerApproval: "Pending IR finance project manager approval",
        pendingIrDeputyGeneralApproval: "Pending IR deputy general approval",
        pendingIrGeneralDirectorApproval: "Pending IR general director approval",
        pendingCBAGeneralDirectorApproval: "Pending CBA general director approval",
        pendingCBADeputyGeneralDirectorApproval: "Pending CBA deputy general director approval",
        pendingCBAFinanceProjectsManagerApproval: "Pending CBA finance projects manager approval",
        pendingCBALogisticsApproval: "Pending CBA logistics approval",
        pendingCBAOfficeManagerApproval: "Pending CBA office manager approval",
        pendingPoGeneralDirectorApproval: "Pending PO general director approval",
        pendingPoDeputyGeneralDirectorApproval: "Pending PO deputy general director approval",
        pendingPoFinanceProjectsManagerApproval: "Pending PO finance projects manager approval",
        pendingDnReceivedAndCheckedByApproval: "Pending DN received and checked by approval",
        pendingDnApprovedByApproval: "Pending DN approved by approval",
        pendingScnReceivedAndCheckedByApproval: "Pending SCN received and checked by approval",
        pendingScnApprovedByApproval: "Pending SCN approved by approval",
        pendingPrRequestedByApproval: "Pending PR requested by approval",
        pendingPrAuthorizedByApproval: "Pending PR authorized by approval",
        description: "Description",
        attachment: "Attachment",
        downloadAttachment: "Download attachment",
    },
    sc: {
        contractNo: "Contract no",
        agreedTerms: "Agreed terms",
        deliveryTerms: "Delivery terms",
        specialProvisionsAndConditions: "Special provisions and conditions",
        notices: "Notices",
        downloadSC: "Download SC",
        consumerDetails: "Consumer details",
        contractValidUntil: "Contract valid until",
        exchangeRate: "Exchange rate",
    },
    rfq: {
        brand: "Brand/Model",
        originCountry: "Origin Country (if applicable)",
        warranty: "Warranty (if applicable)",
        expiryDate: "Expiry Date",
        otherInformation: "Other Information",
        address: "Address",
        representative: "Representative",
        companyName: "Company Name",
        companyAddress: "Company Address",
        rfqNo: "RFQ No",
        rfqDate: "RFQ date",
        projectCode: "Project code",
        projectCodes: "Projects codes",
        budgetLine: "Budget line",
        itemNo: "Item no",
        requestedItemDescription: "Requested item description",
        offeredItem: "Offered item",
        unit: "Unit",
        quantity: "Quantity",
        unitPrice: "Unit price",
        total: "Total",
        taxesKdv: "Taxes kdv",
        shippingCostInsurance: "Shipping cost insurance",
        currency: "Currency",
        overallTotal: "Overall total",
        processedByIda: "Processed by IDA",
        processedBySupplier: "Processed by supplier",
        name: "Name",
        date: "Date",
        signature: "Signature",
        deliveryPlaceAndModeOfTransport: "Delivery place and mode of transport",
        deliveryDatePleaseSpecifyDatesIfPartialDelivery: "Delivery Schedule",
        termsOfPayment: "Terms of payment",
        packingLabellingAndSpecialInstructions: "Packing labelling and special instructions",
        quotationValidity: "Quotation validity",
        requestForQuotationsRfq: "Request for quotations RFQ",
        form: "From",
        supplierName: "Supplier name",
        supplierAddress: "Supplier address",
        tel: "Tel",
        website: "Website",
        email: "Email",
        contactPersonName: "Contact person name",
        contactPersonMobile: "Contact person mobile",
        approve: "Approve",
    },
    scn: {
        scnNumber: "SCN Number",
        projectCode: "Project code",
        scnDate: "SCN date",
        budgetLine: "budgetLine",
        contractNo: "Contract no",
        deliveryLocation: "Delivery location",
        supplierNameAndAddress: "Supplier name and address",
        consigneeName: "Consignee name",
        consigneName: "Consigne name",
        itemNo: "Item no",
        description: "Description",
        unit: "Unit",
        servisOrdered: "Service ordered",
        servisReceived: "Service received",
        remarks: "Remarks",
        textAlign: "Text align",
        approve: "Approve",
        receivedAndCheckedBy: "Received and checked by",
        approvedBy: "Approved by",
        date: "Date",
        nameAndSignature: "Name and signature",
        deliveredBy: "Delivered by",
        nameTitleAndSignature: "Name title and signature",
        serviceCompleteNoteScn: "Service complete note scn",
    },
    cba: {
        budgetLineId: "Budget Line",
        office: "Office",
        nameofsupplier: "Name of supplier",
        currency: "Currency",
        exchangeRate: "Exchange rate",
        comparativeBidAnalysisCBA: "Comparative Bid Analysis CBA",
        cbaNo: "Comparative Bid Anakysis (CBA) No",
        cbaDate: "CBA Date",
        irNo: "Procurement Requisition (PR) No",
        projectCode: "Project code",
        budgetLine: "Budget line",
        items: "Items",
        selectedSupplierNumber: "Selected supplier number",
        itemNo: "Item no",
        itemDescription: "Item description",
        unit: "Unit",
        quantity: "Quantity",
        offeredItem: "Offered item",
        unitPrice: "Unit price",
        totalPrice: "Total price",
        technicalEvolutionMark: "Technical evolution mark",
        total: "Total",
        kdv: "Kdv",
        shippingCostInsurance: "Shipping cost insurance",
        overallTotal: "Overall total",
        bidDetails: "Bid details",
        termsOfPayment: "Terms of payment",
        deliveryTerms: "Delivery terms",
        preparedByLogistics: "Prepared by logistics",
        approve: "Approve",
        selectedSupplierNo: "Selected supplier no",
        approvedByOfficeManager: "Approved by office manager",
        date: "Date",
        SignName: "Sign/name",
        justificationForSelection: "Justification for selection",
        approvedByFinanceProjectsManager: "Approved by finance projects manager",
        approvedByDeputyGeneralDirector: "Approved by deputy general director",
        signName: "Sign/Name",
        approvedByLogistics: "Approved by logistics",
        approvedByGeneralDirector: "Approved by general director",
        pendingCBAGeneralDirectorApproval: "Pending CBA general director approval",
        pendingCBADeputyGeneralDirectorApproval: "Pending CBA deputy general director approval",
        pendingCBAFinanceProjectsManagerApproval: "Pending CBA finance projects manager approval",
        pendingCBALogisticsApproval: "Pending CBA logistics approval",
        deliveryDate: "Delivery date",
        officeManager: "Office manager",
        logistics: "Logistics",
        financeProjectsManager: "Finance project manager",
        deputyGeneralDirector: "Deputy general director",
        generalDirector: "General director",
    },
    ir: {
        title: "Title",
        date: "Date",
        department: "Department",
        projectStartDate: "Project Start Date",
        projectEndDate: "Project End Date",
        budgetLine: "Budget Line",
        internalRequestIr: "Procurement Requisition Form (PR)",
        no: "No",
        itemDescriptionSpecifications: "Item description specifications",
        unit: "Unit",
        quantity: "Quantity",
        location: "Delivery Facility",
        estimatedUnitPrice: "Estimated unit price",
        estimatedTotalPrice: "Estimated total price",
        reqruiedDeliveryDate: "Reqruied delivery date",
        projectManager: "Project manager",
        seniorLogisticsOfficer: "Senior logistics officer",
        financeProjectManager: "Finance project manager",
        deputyGeneral: "Deputy general",
        dateApproved: "Date approved",
        signature: "Signature",
        totalEstimatedCostLocalCurrency: "Total estimated cost local currency",
        totalEstimatedCostInUsd: "Total estimated cost in USD",
        requestedBy: "Requested by",
        date: "Date",
        office: "Office",
        irNo: "PR No",
        projectCode: "Project code",
        descriptionOfFullIr: "Description of full IR",
        deliveryLocation: "Warehouse location",
        exchangeRate1Usd: "Exchange rate for 1 USD",
        approve: "Approve",
        draft: "Draft",
        pendingApprovals: "Pending approvals",
        approved: "Approved",
        singedAndArchived: "Singed and archived",
        cancelled: "cancelled",
        pendingIrProjectManagerApproval: "Pending IR project manager approval",
        pendingIrSeniorLogisticOfficerApproval: "Pending IR senior logistic officer approval",
        pendingIrFinanceProjectManagerApproval: "Pending IR finance project manager approval",
        pendingIrDeputyGeneralApproval: "Pending IR deputy general approval",
        pendingIrGeneralDirectorApproval: "Pending IR general director approval",
        generalDirector: "General director",
        seniorLogisticOfficer: "Senior logistic officer",
    },
    po: {
        indepentDoctorAssociation: "Indepent doctor association",
        poNo: "Po Number",
        address: "Address",
        correspondingIrCbaNo: "Corresponding PR/CBA no",
        projectCode: "Project Code",
        requestedBy: "Requested by",
        name: "Name",
        date: "Date",
        signature: "Signature",
        telephone: "Telephone",
        email: "Email",
        vendor: "Vendor",
        telNo: "Tel no",
        contactPerson: "Contact person",
        paymentTerms: "Payment terms",
        invoiceTo: "Invoice to",
        deliveryTerms: "Delivery terms",
        deliverTo: "Deliver to",
        itemNo: "Item no",
        productName: "Product name",
        itemDescription: "Item description",
        brandModelSerialNo: "Brand/model/serial no",
        unit: "Unit",
        quantity: "Quantity",
        unitPrice: "Unit price",
        subTotal: "Sub total",
        total: "Total",
        kdv: "Kdv",
        shippingCostInsurance: "Shipping cost insurance",
        currency: "Currency",
        overallTotal: "Overall total",
        approvedByFinanceProjectsManager: "Approved by finance projects manager",
        nameAndSignature: "Name and signature",
        approvedByDeputyGeneralDirector: "Approved by deputy general director",
        nameOfSupplierAndStamp: "Name of supplier and stamp",
        purchaseOrderPo: "Purchase order PO",
        approve: "Approve",
        PODate: "PO Date",
        approvedByGeneralDirector: "Approved by general director",
        pendingPoFinanceProjectsManagerApproval: "Pending PO finance projects manager approval",
        pendingPOGeneralDirectorApproval: "Pending PO general director approval",
        pendingPODeputyGeneralDirectorApproval: "Pending PO deputy general director approval",
        financeProjectManager: "Finance project manager",
        deputyGeneralDirector: "Deputy general director",
        generalDirector: "General director",
        budgetLine: "Budget line",
        orderedItem: "Ordered item",
        offeredItem: "Offered item",
    },
    dn: {
        name: "Name",
        deliveryNoteNumber: "Delivery Note number",
        projectCode: "Project code",
        deliveryNoteDate: "Delivery note date",
        poNo: "PO no",
        supplierNameAndAddress: "Supplier name and address",
        consigneeName: "Consignee name",
        itemNo: "Item no",
        productName: "Product name",
        description: "Description",
        brandModel: "Brand/Model",
        unit: "Unit",
        quantityOrdered: "Quantity ordered",
        quantityReceived: "Quantity received",
        remarks: "Remarks",
        receivedAndCheckedBy: "Received and checked by",
        approvedBy: "Approved by",
        date: "Date",
        nameAndSignature: "Name and signature",
        deliveredBy: "Delivered by",
        dateNameTitleAndSignature: "Date name title and signature",
        approve: "Approve",
        deliveryLocation: "Delivery location",
        deliveryNoteDn: "Delivery note DN",
        sign: "Signature",
        budgetLine: "Budget line",
        orderedItem: "Ordered item",
        offeredItem: "Offered item",
    },
    pr: {
        paymentRequestNo: "Payment Request no",
        paymentRequest: "Payment Request",
        from: "From",
        date: "Date",
        subject: "Subject",
        totalAmount: "Total amount",
        syr: "Syr",
        usd: "Usd",
        tl: "Tl",
        totalEquivalentAmountInUsd: "Total equivalent amount in usd",
        purposeOfPayment: "Purpose of payment",
        payeePleaseProvideAsMuchDetailsAsPossible: "Payee please provide as much details as possible",
        dueDate: "Due date",
        name: "Name",
        address: "Address",
        invoiceNumber: "Invoice number",
        contractPoNo: "Contract po no",
        paymentModeRequested: "Payment mode requested",
        forBankTransferOnly: "For bank transfer only",
        bankName: "Bank name",
        branch: "Branch",
        accountName: "Account name",
        swiftNo: "Swift no",
        ibanNo: "Iban no",
        destinationOfFundPleaseProvideAsMuchDetailsAsPossible: "Destination of fund please provide as much details as possible",
        preparedByLogistics: "Prepared by logistics",
        country: "Country",
        office: "Office",
        note: "Note",
        costDistribution: "Cost distribution",
        no: "No",
        paymentDestination: "Payment destination",
        transactionCurrency: "Transaction currency",
        transactionAmount: "Transaction amount",
        exchangeRate: "Exchange rate",
        usdAmountOfTransaction: "USD amount of transaction",
        approve: "Approve",
        requestedBy: "Requested by",
        reviwedAndCertifiedBy: "Reviwed and certified by",
        authorizedBy: "Authorized by",
        signature: "Signature",
        purchaseOrderPo: "Purchase order po",
        euro: "Euro",
        sign: "Sign",
        projectCode: "Project code",
        budgetLine: "Budget line",
        inAccordanceWithTheActivitiesAsDefinedInTheRelatedBudgetOfTheProjectDepartmentMentionedBelowWeHerebyRequestIdaOrganizationFinanceDepartmentToMakeTheFollowingDirectPaymentToThePayeeBelow: "In accordance with the activities as defined in the related budget of the project department mentioned below we hereby request ida organization finance department to make the following direct payment to the payee below",
    },
    notifications: {
        markAsRead: "Mark as read",
        ordersPendingMyApprovals: "Orders pending my approvals",
    },
    products: {
        previousCost: " Previous Cost",
        averageCost: "Average Cost",
        numberOfPurchases: "NO Of Purchases",
        addUnit: "Add Unit",
        description: "Description",
        unit: "Unit",
        category: "Category",
        serialNumbered: "Serial Numbered",
        service: "Service",
        productName: "Product Name",
        addNewProduct: "Add New Product",
        productType: "Product Type",
        asset: "Asset",
        consumable: "Consumable",
        searchForProductName: "Search For Product Name",
        productAverageCost: "Product Average Cost",
        addProduct: "Add New Product",
        editProduct: "Edit Product",
        viewProduct: "View Product",
        productsList: "Products List",
        productCategory: "Product category",
        productBudgetLine: "Product budget line",
        perPrescriptionQuantity: " Per Prescription Quantity",
        name: "Name",
    },
    projects: {
        stockNumber: "Stock Number",
        budgetLines: "Budget-Lines",
        budgetLineCode: "Budget-Line Code",
        SRNO: "SR No",
        GIONO: "GIO No",
        DNNumber: "DN Number",
        donationCertificateNumber: "Donation Number",
        SRNumber: "SR NO",
        expiryDate: "Expiry Date",
        batchNumber: "Batch Number",
        hasSignature: "E-Signature",
        startingDate: "Starting Date",
        endingDate: "End Date",
        planning: "Planning",
        running: "Running",
        closed: "Closed",
        projectBudget: "Project Budget",
        endingDateCannotBeBeforeStartingDate: "Ending date cannot be before starting date",
        donor: "Project Donor",
        projectName: "Project Name",
        projectCode: "Project Code",
        projectCodes: "Projects Codes",
        projectOrders: "Project Orders",
        addProject: "Add New Project",
        viewProject: "View Project",
        projectRoles: "Project Roles",
        create: "Create new Project",
        projectsList: "Projects List",
        enidingDateCannotBeBeforeStartingDate: "Ending date cannot be before starting date",
        createProject: "Create Project",
        createSucessMsg: "Added New Project",
        editProject: "Edit Project",
        userName: "User Name",
        userRoles: "User Roles",
        minTotal: "Min Total",
        maxTotal: "Max Total",
        irNo: "IR No",
        description: "Description",
        requestedBy: "Requested By",
        requestedAt: "Requested At",
        runProject: "Start Project",
        thresholdForRequiringIRFifthApproval: "Threshold for requiring ir fifth approval",
        finishProject: "Finish Project",
        deleteConfirmMsg: "Are you sure delete this Item?",
        thresholdForRequiringCBAFifthApproval: "Threshold for requiring CBA fifth approval",
        PSCValue: "PSC Value",
        setupProjectRole: "Setup project role",
        createIR: "Create IR",
        code: "Code",
    },
    warehouse: {
        discussions: "Discussions",
        expiredAfter: "Expiry After",
        previousStocks: "Previous Stocks(Zero)",
        consumeManyItems: "Consume Many Items",
        storePoint: "Store Point",
        assets: "Assets",
        edit: "Edit",
        changeStorageLocation: "Change Shelf Location",
        diagnosis: "Diagnosis",
        cachedBirthdate: "Birth Date",
        cachedIDP: "IDP",
        cachedSex: "Gender",
        patientID: "Patient ID",
        prescriptionDate: "Prescription Date",
        prescriptionDischargeOperations: "Discharge Prescriptions",
        receiveItemSR: "Receive Items from SR",
        GIODescription: "GIO Description",
        SRNO: "SR NO",
        SRDate: "SR Date",
        SRsSent: "Sent SRs",
        SRsReceive: "Received SRs",
        GIOs: "GIOs",
        GIONO: "GIO Number",
        recipientName: "Recipient Name",
        recipientAddress: "Recipient Address",
        admissionId: "Admission Id",
        delete: "Delete",
        description: "Description",
        receiveDonationDN: "Receive Donation DN",
        DNs: "DNs",
        medications: "Medications",
        destinationStockNumber: "Destination Stock Number",
        consumePrescription: "Consume Prescription",
        CreateGIO: "Create GIO",
        createGIO: "Create GIO",
        GIOLogisticsApproval: "GIO Logistics Approval",
        addNewItem: "Add New Item",
        warehouse: "Warehouse",
        disposeItem: "Dispose Item",
        consumeItem: "Consume Item",
        receiveDonationItem: "Receive Donation Item",
        sendDonationItem: "Send Donation Item",
        transferItems: "Transfer Items",
        transferItemsToAnotherProject: "Change Project",
        movement: "Movement",
        items: "Items",
        importOperations: "Import Operations",
        prescriptionConsumptionOperations: "Prescription Details",
        prescriptionConsumptionOperationDetails: "Prescription Items",
        prescriptionMedicationsConsumptionOperations: "Medications Details",
        prescriptionMedicationsConsumptionOperationDetails: "Medications Items",
        disposeOperations: "Dispose Operations",
        consumeOperations: "Consume Operations",
        sentItems: "Sent Items",
        sendDonationOperations: "Send Donation",
        receiveDonationOperations: "Received Donation",
        receiveItems: "Received Items",
        warehouseItems: "Warehouse Items",
        batchNumberMovements: "Batch Number Movements",
        receivedFromDN: "Received From DN",
        serialNumbersLengthDoesntMatchAssetNumbersLength: "Serial numbers length doesn't match asset numbers length",
        thisStockNumberBelongToAnotherProject: "This stock number belong to another project",
        thisStockNumberBelongToAnotherItem: "This stock number belong to another Item",
        thisStockNumberBelongToAnotherBudgetLine: "This stock number belong to another budget line",
        serialNumbersLengthDoesntMatchQuantity: "Serial numbers length doesn't match quantity",
        SRFile: "SR File",
        downloadAttachedFile: "Download Attached File",
        availableQuantityIsNotEnough: "Available Quantity Is Not Enough ({{products}})",
        prescriptionAlreadySpent: "Prescription already spent",
        prescriptionNotReadyToBeSpent: "Prescription not ready to be spent yet!",
        doYouWantToPrintThisPrescription: "Do you want to print this prescription?",
        prescriptionForAnotherWarehouse: "This prescription belong to another warehouse",
        createWarehouse: "Create New Warehouse",
        warehouseName: "Warehouse Name",
        warehouseAddress: "Warehouse Address",
        disposalItem: "Disposal Item",
        receiveDonateItem: "Receive Donated Item",
        sendDonateItem: "Send Donated Item",
        sendItems: "Sending Items",
        product: "Product",
        project: "Project",
        quantity: "Quantity",
        serialNumber: "Serial Number",
        item: "Item",
        to: "To",
        createdBy: "Created By",
        editWarehouse: "Edit Warehouse",
        viewWarehouse: "View Warehouse",
        warehouseTable: "Warehouses List",
        confirmButton: "Confirm",
        selectBatchNumber: "Select batch number",
        focalPointUser: "Focal Point User",
        focalPointUserPhoneNO: "Focal Point User Phone NO",
        focalPointUserContactInfo: "Focal Point User Contact Info",
        consumedBy: "Consumed By",
        disposedBy: "Disposed By",
        operationType: "Operation type",
        fromWarehouse: "From warehouse",
        sentBy: "Sent By",
        warehousePrescriptionConsumptionOperationId: "Warehouse prescription consumption operation id",
        toWarehousse: "To Warehouse",
        numberShort: "No",
        expiryDate: "Expiry Date",
        shippingCompany: "Shipping Company",
        waybillNumber: "Waybill Number",
        confirm: "Confirm",
        toProject: "To Project",
        batchNumber: "Batch Number",
        notes: "Notes",
        from: "From",
        assetsNumbers: "Assets Numbers",
        stockNumber: "Stock Number",
        storageLocation: "Shelf",
        importedBy: "ImportedBy",
        patientName: "Patient Name",
        sessionId: "Session Id",
        receivedBy: "Received by",
        prescriptionItems: "Prescription Items",
        prescription: "Prescription",
        serialNumbers: "Serial Numbers",
        createdAt: "Created At",
        importNewItem: "Import new item",
        importDate: "Import date",
        confirmation: "Confirmation",
        fileAttatched: "File attatched",
        toWarehouse: "To warehouse",
        typeBatchnNumberToProductMovements: "Type batch number to product movements :",
        warehouseRoles: "Warehouse roles",
        confirmDN: "Confirm DN",
        transferredBy: "Transferred By",
        toBudgetLine: "To Budget Line",
        toStockNumber: "To Stock Number",
        TypeTheNumberYouWantToSearchProductBy: "Type the number you want to search product by",
        selectStockNumber: "Select stock number",
        typeStockNumberToProductMovements: "Type stock number to product movements",
        selectSerialNumber: "Select serial number",
        typeSerialNumberToProductMovements: "Type serial number to product movements",
        typeAssetNumberToProductMovements: "Type asset number to product movements",
        searchProductByNumber: "Search product by number",
        assetNumber: "Asset number",
        typeOneOfTheFollowingNumbersToSeeRelatedProductMovements: "Type one of the following numbers to show related product movements",
        showAllItems: "Show all items",
        showLastMounthItems: "Show last month items",
        sendDonationItems: "Send donation items",
        stockRequestNumber: "Stock request number",
        donationCertificateNumber: "Donation certificate number",
        cancel: "Cancel",
        expiredItems: "Expired items",
        expired: "Expired",
        roadTracking: "Waybill",
        dnNumber: "DN number",
        fromSupplier: "From supplier",
        PONumber: "Po number",
        DNNumber: "Dn number",
        damageReportNumber: "Damage report number",
        NO: "NO",
        approve: "Approve",
        patientBirthDate: "Patient birth date",
        patientGender: "Patient gender",
        dnFile: "DN file",
        filterStartDate: "From: ",
        filterEndDate: "To: ",
        filter: "Filter",
        assignAssetToUser: "Assign asset to user",
        return: "Return",
        assignTo: "Assign to",
        specification: "specification",
        status: "Status",
        assetName: "Asset name",
        assignedAt: "Assigned at",
        returnedAt: "Returned at",
        returnAsset: "Return",
        damageReportFile: "Damage report file",
        donationCertificateNumberAttachment: "Donation certificate number attachment",
        downloadDonationCertificateAttachment: "Download donation certificate attachment",
        dnNumberAttachment: "Dn number attachment",
        downloadDnAttachment: "Download dn attachment",
        sentQuantity: "Sent quantity",
        receivedQuantity: "Received quantity",
        SRAttachment: "SR attachment",
        downloadSRAttachment: "Download SR attachment",
        waybillAttachment: "Waybill attachment",
        downloadWaybillAttachment: "Download waybill attachment",
        downloadDnFile: "Download DN file",
        SRNumber: "SR number",
    },
    budget: {
        code: "Budget-Line Code",
        projectCode: "Project Code",
        sectioncode: "Section Code",
        sectiondescription: "Section Description",
        sectiontitle: "Section title",
        lineCostAllocation: "Line Cost Allocation",
        lineType: "Line Type",
        durationRecurrence: "Duration Recurrence",
        sectionLineTotal: "Section Line Total",
        reminderAt: "Reminder At",
        reminderUsers: "Reminder Users",
        addNewSection: "Add New Section",
        addNewSectionLine: "Add New Section Line",
        startReminder: "Start Reminder",
        $: " $ ",
        totalBudget: "Budget Total",
        addNewLine: "Add New Line",
        PSCcost: "PSC Cost",
        PSC: "PSC",
        budgetTotalCost: "Total Budget Cost",
        budgetTemplates: "Budget Templates",
        createNewTemplate: "Create New Template",
        templateName: "Template Name",
        BudgetLineItem: "Budget Line Item Code",
        createProcurementPlan: "Plan Procurement",
        procurementPlan: "Procurement Plan",
        addFromTemplates: "Add From Templates",
        budgetLine: "Budget Line",
        total: "Total",
        createIR: "Create IR",
        addNewItem: "Add new item",
        delete: "Delete",
        templateDescription: "Template Description",
        requiredDateToDelivery: "Required date to delivery",
        IRRising: "IR Rising",
        expectedDateToDelivery: "Expected date to delivery",
        personInCharge: "Person in charge",
        fileAttatched: "File attatched",
        addToPlan: "Add to plan",
    },
    permissions: {
        roles: "Design Roles",
        createPR: "Create PR",
        showAllOrders: "Show All Orders",
        showAllSignatures: "Able to sign all",
        viewReceivedDonatedWarehouseItemsFromDN: "View Received Donated Warehouse Items From DN",
        viewReceiveWarehouseItemsFromSR: "View Receive Warehouse Items From SR",
        viewDN: "View DN",
        viewGIO: "View GIO",
        viewSR: "View SR",
        createSR: "Create SR",
        SRLogisticsApproval: "SR Logistics Approval",
        SROfficeManagerApproval: "SR Office Manager Approval",
        SRWarehouseSenderApproval: "SR Warehouse Sender Approval",
        SRWarehouseReceiverApproval: "SR Warehouse Receiver Approval",
        createGIO: "Create GIO",
        GIOLogisticsApproval: "GIO Logistics Approval",
        GIOSenderApproval: "GIO Sender Approval",
        viewPrescriptionMedicationsConsumption: "View Prescription Medications Consumption",
        viewPrescriptionMedicationsConsumptionDetails: "View Prescription Medications Consumption Details",
        viewMedicationDetails: "View Medication Details",
        PRAuthorizedByApproval: " PR Authorized BY Approval",
        orderComplete: "Order Complete",
        orderArchive: "Order archive",
        orderDiscussionView: "Order Discussion View",
        viewOrder: "View Order",
        viewNewsFeed: "View news feed",
        viewUsers: "View Users",
        viewCategories: "View Categories",
        viewCurrencies: "View Currencies",
        viewBudgetTemplates: "View Budget Templates",
        viewOffices: "View Offices",
        viewProjects: "View Projects",
        viewProducts: "View Products",
        viewSuppliers: "View Suppliers",
        viewUnits: "View Units",
        viewDonors: "View Donors",
        viewExpiredItems: "View expired items",
        deleteOrder: "Delete Order",
        iraqMission: "IRAQ Mission",
        turkeyMission: "TURKEY Mission",
        permissionsList: "Permissions List",
        managePermissions: "Manage Permissions",
        exportWarehouses: "Export Warehouses",
        batchNumberMovements: "Batch Number Movements",
        setupProjectBudget: "Setup Project Budget",
        setupProjectRoles: "Setup Project Roles",
        setupProcurementPlan: "Setup Procurement Plan",
        runProject: "Run Project",
        finishProject: "Finish Project",
        IRDeputyGeneralApproval: "IR Deputy General Approval",
        IRFinanceProjectManagerApproval: "IR Finance Project Manager Approval",
        IRProjectManagerApproval: "IR Project Manager Approval",
        IRSeniorLogisticOfficerApproval: "IR Senior Logistic Officer Approval",
        IRGeneralDirectorApproval: "IR general director approval",
        CBAOfficeManagerApproval: "CBA Office Manager Approval",
        CBALogisticsApproval: "CBA Logistics Approval",
        CBAFinanceProjectsManagerApproval: "CBA Finance Projects Manager Approval",
        CBADeputyGeneralDirectorApproval: "CBA Deputy General Director Approval",
        CBAGeneralDirectorApproval: "CBA General Director Approval",
        POGeneralDirectorApproval: "PO General Director Approval",
        PODeputyGeneralDirectorApproval: "PO Deputy General Director Approval",
        POFinanceProjectsManagerApproval: "PO finance projects manager approval",
        DNReceivedAndCheckedByApproval: "DN Received And Checked By Approval",
        DNApprovedByApproval: "DN Approved By Approval",
        SCNReceivedAndCheckedByApproval: "SCN Received And Checked By Approval",
        SCNApprovedByApproval: "SCN Approved By Approval",
        PRRequestedByApproval: "PR Requested By Approval",
        PRReviwedAndCertifiedByApproval: "PR Reviwed And Certified By Approval",
        PRAuthurizedByApproval: "PR Authurized By Approval",
        IRView: "IR View",
        IRCreate: "IR Create",
        IRUpdate: "IR Update",
        IRPublish: "IR Publish",
        IRRevoke: "IR Revoke",
        IRCancel: "IR Cancel",
        IRUploadSignedPapers: "IR Upload Signed Papers",
        IRViewDiscussion: "IR View Discussion",
        IRParticipateInDiscussion: "IR Participate In Discussion",
        IRDownloadSignedCopy: "IR Download Signed Copy",
        IRsTabView: "IRs Tab View",
        RFQView: "RFQ View",
        RFQCreate: "RFQ Create",
        RFQUpdate: "RFQ Update",
        RFQPublish: "RFQ Publish",
        RFQRevoke: "RFQ Revoke",
        RFQCancel: "RFQ Cancel",
        RFQUploadSignedPapers: "RFQ Upload Signed Papers",
        RFQViewDiscussion: "RFQ View Discussion",
        RFQParticipateInDiscussion: "RFQ Participate In Discussion",
        RFQDownloadSignedCopy: "RFQ Download Signed Copy",
        RFQsTabView: "RFQs Tab View",
        CBAView: "CBA View",
        CBACreate: "CBA Create",
        CBAUpdate: "CBA Update",
        CBAPublish: "CBA Publish",
        CBARevoke: "CBA Revoke",
        CBACancel: "CBA Cancel",
        CBAUploadSignedPapers: "CBA Upload Signed Papers",
        CBAViewDiscussion: "CBA View Discussion",
        CBAParticipateInDiscussion: "CBA Participate In Discussion",
        CBADownloadSignedCopy: "CBA Download Signed Copy",
        CBATabView: "CBA Tab View",
        POView: "PO View",
        POCreate: "PO Create",
        POUpdate: "PO Update",
        POPublish: "PO Publish",
        PORevoke: "PO Revoke",
        POCancel: "PO Cancel",
        POUploadSignedPapers: "PO Upload Signed Papers",
        POViewDiscussion: "PO View Discussion",
        POParticipateInDiscussion: "PO Participate In Discussion",
        PODownloadSignedCopy: "PO downlaod Signed Copy",
        POTabView: "PO Tab View",
        DNView: "DN View",
        DNCreate: "DN Create",
        DNUpdate: "DN Update",
        DNPublish: "DN Publish",
        DNCancel: "DN Cancel",
        DNUploadSignedPapers: "DN Upload Signed Papers",
        DNViewDiscussion: "DN View Discussion",
        DNParticipateInDiscussion: "DN Participate In Discussion",
        DNDownloadSignedCopy: "DN Download Signed Copy",
        DNTabView: "DN Tab View",
        SCNView: "SCN View",
        SCNCreate: "SCN Create",
        SCNUpdate: "SCN Update",
        SCNPublish: "SCN Publish",
        SCNRevoke: "SCN Revoke",
        SCNCancel: "SCN Cancel",
        SCNUploadSignedPapers: "SCN Upload Signed Papers",
        SCNViewDiscussion: "SCN View Discussion",
        SCNParticipateInDiscussion: "SCN Participate In Discussion",
        SCNDownloadSignedCopy: "SCN Download Signed Copy",
        SCNTabView: "SCN Tab View",
        PRView: "PR View",
        PRCreate: "PR Create",
        PRUpdate: "PR Update",
        PRPublish: "PR Publish",
        PRRevoke: "PR Revoke",
        PRCancel: "PR Cancel",
        PRUploadSignedPapers: "PR Upload Signed Papers",
        PRViewDiscussion: "PR View Discussion",
        PRParticipateInDiscussion: "PR Participate In Discussion",
        PRDownloadSignedCopy: "PR Download Signed Copy",
        PRTabView: "PR Tab View",
        SCView: "SC view",
        SCCreate: "SC create",
        SCUpdate: "SC update",
        SCPrint: "SC print",
        SCPublish: "SC publish",
        SCRevoke: "SC revoke",
        SCCancel: "SC cancel",
        SCUploadSignedPapers: "SC upload signed papers",
        SCViewDiscussion: "SC view discussion",
        SCParticipateInDiscussion: "SC participate in discussion",
        SCDownloadSignedCopy: "SC download signed copy",
        SCTabView: "SC tab view",
        IRPrint: "IR print",
        RFQPrint: "RFQ print",
        CBAPrint: "CBA print",
        POPrint: "PO print",
        DNPrint: "DN print",
        SCNPrint: "SCN print",
        PRPrint: "PR print",
        viewWarehouseItems: "View Warehouse Items",
        viewWarehouseImports: "View Warehouse Imports",
        viewDisposedWarehouseItems: "View Disposed Warehouse Items",
        viewConsumedWarehouseItems: "View Consumed Warehouse Items",
        viewSentWarehouseItems: "View Sent Warehouse Items",
        viewReceiveWarehouseItems: "View Receive Warehouse Items",
        viewDonateWarehouseItems: "View Donate Warehouse Items",
        viewReceivedDonatedWarehouseItems: "View Received Donated Warehouse Items",
        viewTransferWarehouseItemsToAnotherProject: "View Transfer Warehouse Items To Another Project",
        viewReceiveWarehouseItemsFromDN: "View Receive Warehouse Items From Dn",
        viewPrescriptionconsumption: "View prescriptionconsumption",
        viewPrescriptionDischarge: "View prescription Discharge",
        viewPrescriptionconsumptionDetails: "View prescriptionconsumption details",
        createWarehouseImports: "Create Warehouse Imports",
        disposeWarehouseItems: "Dispose Warehouse Items",
        consumeWarehouseItems: "Consume Warehouse Items",
        sendWarehouseItems: "Send Warehouse Items",
        receiveWarehouseItems: "Receive Warehouse Items",
        donateWarehouseItems: "Donate Warehouse Items",
        receiveDonatedWarehouseItems: "Receive Donated Warehouse Items",
        transferWarehouseItemsToAnotherProject: "Transfer Warehouse Items To Another Project",
        receiveWarehouseItemsFromDN: "Receive Warehouse Items From Dn",
        confirmReceiveItem: "Confirm receive item",
        confirmImportItem: "Confirm import item",
        confirmReceiveFromDN: "Confirm receive from dn",
        confirmReceiveDonation: "Confirm receive donation",
        confirmSendItem: "Confirm send item",
        confirmTransferItemToAnotherProject: "Confirm transfer item to another project",
        consumeWarehouseHimsPrescriptionPermission: "Consume warehouse hims prescription permission",
        confirmReceiveDonationItem: "Confirm receive donation item",
        confirmSendDonationItem: "Confirm send donation item",
        approveRecieveFromDn: "Approve recieve from dn",
        approveReceiveFromDonation: "Approve receive from donation",
        approveReceiveItems: "Approve receive items",
        approveSendDonationItem: "Approve send donation item",
        approveTransferItemToAnotherProject: "Approve transfer item to another project",
        exportWarehouseItems: "Export Warehouse Items",
        exportWarehouseImports: "Export Warehouse Imports",
        exportDisposedWarehouseItems: "Export Disposed Warehouse Items",
        exportConsumedWarehouseItems: "Export Consumed Warehouse Items",
        exportSentWarehouseItems: "Export Sent Warehouse Items",
        exportReceiveWarehouseItems: "Export Receive Warehouse Items",
        exportDonateWarehouseItems: "Export Donate Warehouse Items",
        exportReceivedDonatedWarehouseItems: "Export Received Donated Warehouse Items",
        exportTransferWarehouseItemsToAnotherProject: "Export Transfer Warehouse Items To Another Project",
        exportReceiveWarehouseItemsFromDN: "Export Receive Warehouse Items From Dn",
        exportPrescriptionconsumption: "Export prescriptionconsumption",
        exportPrescriptionconsumptionDetails: "Export prescriptionconsumption details",
        viewWarehouseDiscussion: "View Warehouse Discussion",
        participateInWarehouseDiscussion: "Participate In Warehouse Discussion",
        viewWarehouseItemMovement: "View Warehouse Item Movement",
        createEditUser: "Create Edit User",
        setupBudget: "Setup Budget",
        setupProcurementPlat: "Setup Procurement Plat",
        createProject: "Create Project",
        createProduct: "Create Product",
        createSupplier: "Create Supplier",
        createEditCurrency: "Create Edit Currency",
        createEditUnit: "Create Edit Unit",
        createEditDonor: "Create Edit Donor",
        createEditCategory: "Create Edit Category",
        createEditOffice: "Create Edit Office",
        createWarehouse: "Create Edit Warehouse",
        createBudgetTemplate: "Create Budget Template",
        editProject: "Edit Project",
        editProduct: "Edit Product",
        editSupplier: "Edit Supplier",
        editCurrency: "Edit Currency",
        editUnit: "Edit Unit",
        editDonor: "Edit Donor",
        editCategory: "Edit Category",
        editOffice: "Edit Office",
        editWarehouse: "Edit Warehouse",
        editBudgetTemplate: "Edit Budget Template",
        editProcurementPlan: "Edit Procurement Plan",
        deleteProcurementPlan: "Delete Procurement Plan",
        viewProject: "View Project",
        viewProduct: "View Product",
        viewSupplier: "View Supplier",
        viewCurrency: "View Currency",
        viewUnit: "View Unit",
        viewDonor: "View Donor",
        viewCategory: "View Category",
        viewOffice: "View Office",
        viewWarehouse: "View Warehouse",
        viewBudgetTemplate: "View BudgetTemplate",
        orderView: "Vew Order",
        viewSuppliersTab: "View Suppliers Tab",
        viewProductsTabs: "View Products Tabs",
        viewProjectsTab: "View Projects Tab",
        viewWarehousesTab: "View Warehouses Tab",
        viewOrdersTab: "View Orders Tab",
        exportProjects: "Export Projects",
        exportProducts: "Export Products",
        exportSuppliers: "Export Suppliers",
        exportCurrencies: "Export Currencies",
        exportUnits: "Export Units",
        exportDonors: "Export Donors",
        exportCategories: "Export Categories",
        exportOffices: "Export Offices",
        exportUsers: "Export Users",
        activateDeactivateProduct: "Activate deactivate product",
        warehouseExports: "Warehouse Exports",
        viewWarehouses: "View Warehouses",
        manageUserGlobalPermission: "Manage User Global Permission",
        manageUserProjectAndWarehouseRoles: "Manage User Project And Warehouse Roles",
        manageWarehouseUserRoles: "Manage Warehouse User Roles",
        viewProjectDiscussion: "View Project Discussion",
        viewProductDiscussion: "View Product Discussion",
        viewSupplierDiscussion: "View Supplier Discussion",
        participateInProjectDiscussion: "Participate In Project Discussion",
        participateInProductDiscussion: "Participate In Product Discussion",
        participateInSupplierDiscussion: "Participate In Supplier Discussion",
        ordersTabView: "Orders tab view",
        exportMaintenanceItems: "Export maintenance items",
        viewMaintenanceItems: "View maintenance items",
        createMaintenance: "Create maintenance",
        editMaintenanceItems: "Edit maintenance items",
    },
    hims: {
        overallQuantity: "Overall Quantity",
        totalAvailableQuantity: "Total Available Quantity",
        storageLocation: "Storage Location",
        prescriptionDone: "Prescription Done",
        prescriptionNotAvailable: "Prescription Not Available",
        medicine: "Medicine",
        medicineDetails: "Medicine Details",
        medicineForm: "Medicine Form",
        quantity: "Quantity",
        per: "Per",
        duration: "Duration",
        prescriptionInstruction: "Prescription Instruction",
        idp: "IDP",
        note: "Notes",
    },
    discussion: {
        typeYourComment: "Type your comment",
    },
};
