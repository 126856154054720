"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GLOBAL_PERMISSIONS = void 0;
exports.GLOBAL_PERMISSIONS = [
    "setupProjectBudget",
    "setupProcurementPlan",
    "setupProjectRoles",
    "runProject",
    "finishProject",
    "batchNumberMovements",
    "deleteOrder",
    "createProject",
    "createProduct",
    "createSupplier",
    "createEditCurrency",
    "createEditUnit",
    "createEditDonor",
    "createEditCategory",
    "createEditOffice",
    "createEditUser",
    "createWarehouse",
    "createBudgetTemplate",
    "createMaintenance",
    "viewProject",
    "viewProduct",
    "viewSupplier",
    "viewWarehouse",
    "viewBudgetTemplate",
    "viewOrder",
    "viewNewsFeed",
    "viewProjects",
    "viewProducts",
    "viewSuppliers",
    "viewBudgetTemplates",
    "viewUsers",
    "viewDonors",
    "viewUnits",
    "viewCategories",
    "viewCurrencies",
    "viewOffices",
    "activateDeactivateProduct",
    "viewMaintenanceItems",
    "editProject",
    "editProduct",
    "editSupplier",
    "editWarehouse",
    "editBudgetTemplate",
    "editProcurementPlan",
    "deleteProcurementPlan",
    "editMaintenanceItems",
    "exportProjects",
    "exportProducts",
    "exportSuppliers",
    "exportCurrencies",
    "exportUnits",
    "exportDonors",
    "exportCategories",
    "exportOffices",
    "exportWarehouses",
    "exportUsers",
    "exportMaintenanceItems",
    "viewWarehouses",
    "manageUserGlobalPermission",
    "manageUserProjectAndWarehouseRoles",
    "participateInProjectDiscussion",
    "participateInProductDiscussion",
    "participateInSupplierDiscussion",
    "viewProjectDiscussion",
    "viewProductDiscussion",
    "viewSupplierDiscussion",
    "viewSuppliersTab",
    "viewProductsTabs",
    "viewProjectsTab",
    "viewWarehousesTab",
    "viewOrdersTab",
    "iraqMission",
    "turkeyMission",
    "createPR",
    "showAllOrders",
    "showAllSignatures",
    "roles"
];
