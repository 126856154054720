"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.emptyCell = void 0;
var emptyCell = function (_a) {
    var _b = _a.colSpan, colSpan = _b === void 0 ? 1 : _b, backgroundColor = _a.backgroundColor, rowSpan = _a.rowSpan, padding = _a.padding, borderWidth = _a.borderWidth, borderColor = _a.borderColor, borderStyle = _a.borderStyle;
    return {
        borderWidth: borderWidth !== null && borderWidth !== void 0 ? borderWidth : { top: 1, bottom: 1, left: 1, right: 1 },
        borderColor: borderColor !== null && borderColor !== void 0 ? borderColor : { top: 'gray', bottom: 'gray', left: 'gray', right: 'gray' },
        borderStyle: borderStyle !== null && borderStyle !== void 0 ? borderStyle : { top: 'solid', bottom: 'solid', left: 'solid', right: 'solid' },
        type: 'text',
        colSpan: colSpan !== null && colSpan !== void 0 ? colSpan : 1,
        rowSpan: rowSpan,
        padding: padding !== null && padding !== void 0 ? padding : 10,
        backgroundColor: backgroundColor !== null && backgroundColor !== void 0 ? backgroundColor : '#fff',
    };
};
exports.emptyCell = emptyCell;
