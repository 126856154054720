"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hasProjectAndWarehousePermissionWithNoMinMax = void 0;
var userProjectRolesData_1 = require("common/global/userProjectRolesData");
var hasProjectAndWarehousePermissionWithNoMinMax = function (warehouseId, projectId, projectPermissions, amount) {
    var projectPermissionsToLowerCase = projectPermissions.map(function (x) { return x.toLowerCase(); });
    var userRoles = userProjectRolesData_1.globalUserProjectRolesData
        .filter(function (x) { return x.projectId === projectId; })
        .filter(function (x) { return x.warehouseId === warehouseId; })
        .map(function (doc) {
        return {
            role: doc.role.toLowerCase(),
            min: doc.minTotal,
            max: doc.maxTotal,
        };
    });
    return userRoles.some(function (r) { return projectPermissionsToLowerCase.includes(r.role); });
};
exports.hasProjectAndWarehousePermissionWithNoMinMax = hasProjectAndWarehousePermissionWithNoMinMax;
