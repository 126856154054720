"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.startCBA = void 0;
var collectionsRef_1 = require("common/db/collectionsRef");
var app_1 = require("firebase/app");
var lodash_1 = require("lodash");
function startCBA(orderId, transaction) {
    var _a, _b, _c, _d;
    return __awaiter(this, void 0, void 0, function () {
        var orderSN, orderData, userId, IRSN, IRData, RFQsSN, RFQs, RFQsData, newCBA, IRItemsSN, IRItemsData, addedCBAItems;
        var _this = this;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0: return [4 /*yield*/, collectionsRef_1.ordersRef.doc(orderId).get()];
                case 1:
                    orderSN = _e.sent();
                    orderData = __assign(__assign({}, orderSN.data()), { id: orderSN.id });
                    userId = (_b = (_a = app_1.auth().currentUser) === null || _a === void 0 ? void 0 : _a.uid) !== null && _b !== void 0 ? _b : "";
                    return [4 /*yield*/, collectionsRef_1.IRsRef.doc(orderData.IRId).get()];
                case 2:
                    IRSN = _e.sent();
                    IRData = __assign(__assign({}, IRSN.data()), { id: IRSN.id });
                    return [4 /*yield*/, collectionsRef_1.RFQsRef.where("orderId", "==", orderId)
                            .where("status", "in", ["approved", "singedAndArchived"])
                            .get()];
                case 3:
                    RFQsSN = _e.sent();
                    RFQs = RFQsSN.docs.map(function (d) { return (__assign(__assign({}, d.data()), { id: d.id })); });
                    RFQsData = lodash_1.sortBy(RFQs, function (x) { return x.RFQDate; });
                    newCBA = collectionsRef_1.CBAsRef.doc();
                    transaction.set(newCBA, {
                        orderId: orderId,
                        RFQsIds: RFQsData.map(function (d) { return d.id; }),
                        quotationValidities: RFQsData.map(function (d) { return d.quotationValidity; }),
                        deliveryPlaces: RFQsData.map(function (d) { return d.deliveryPlace; }),
                        deliveryDates: RFQsData.map(function (d) { return d.deliveryDate; }),
                        deliveryTermss: RFQsData.map(function (d) { return d.deliveryTerms; }),
                        termsOfPayments: (_c = RFQsData.map(function (d) { return d.termsOfPayment; })) !== null && _c !== void 0 ? _c : [],
                        currenciesIds: RFQsData.map(function (d) { return d.currencyId; }),
                        currenciesExchangeRates: RFQsData.map(function () { return 1; }),
                        RFQsTotals: RFQsData.map(function (d) { return d.total; }),
                        RFQsTaxes: RFQsData.map(function (d) { return d.taxes; }),
                        RFQsShippingCosts: RFQsData.map(function (d) { return d.shippingCosts; }),
                        RFQsOverallTotals: RFQsData.map(function (d) { return d.overallTotal; }),
                        IRId: orderData.IRId,
                        IRref: IRData.ref,
                        ref: IRData.ref,
                        status: "draft",
                        createdAt: new Date(),
                        budgetLineId: (_d = IRData.budgetLineId) !== null && _d !== void 0 ? _d : "",
                        projectId: orderData.projectId,
                        projectsIds: orderData.projectsIds,
                        initiatedBy: userId,
                    });
                    return [4 /*yield*/, collectionsRef_1.IRItemsRef.where("IRId", "==", IRData.id).get()];
                case 4:
                    IRItemsSN = _e.sent();
                    IRItemsData = IRItemsSN.docs.map(function (d) { return (__assign(__assign({}, d.data()), { id: d.id })); });
                    return [4 /*yield*/, Promise.all(IRItemsData.map(function (IRItemDoc) { return __awaiter(_this, void 0, void 0, function () {
                            var quantity, productId, unitId, index, productName, productDescription, productType, data, doc;
                            var _a, _b, _c;
                            return __generator(this, function (_d) {
                                switch (_d.label) {
                                    case 0:
                                        quantity = IRItemDoc.quantity, productId = IRItemDoc.productId, unitId = IRItemDoc.unitId, index = IRItemDoc.index;
                                        return [4 /*yield*/, collectionsRef_1.productsRef.doc(productId).get()];
                                    case 1:
                                        productName = (_a = (_d.sent()).data()) === null || _a === void 0 ? void 0 : _a.name;
                                        return [4 /*yield*/, collectionsRef_1.productsRef.doc(productId).get()];
                                    case 2:
                                        productDescription = (_b = (_d.sent()).data()) === null || _b === void 0 ? void 0 : _b.description;
                                        return [4 /*yield*/, collectionsRef_1.productsRef.doc(productId).get()];
                                    case 3:
                                        productType = (_c = (_d.sent()).data()) === null || _c === void 0 ? void 0 : _c.productType;
                                        data = {
                                            budgetLineId: IRItemDoc.budgetLineId,
                                            budgetLinesIds: IRItemDoc.budgetLinesIds,
                                            budgetLinesPercentages: IRItemDoc.budgetLinesPercentages,
                                            CBAId: newCBA.id,
                                            quantity: quantity,
                                            orderId: orderId,
                                            productId: productId,
                                            productName: productName,
                                            productType: productType,
                                            productDescription: productDescription,
                                            unitId: unitId,
                                            index: index,
                                        };
                                        doc = collectionsRef_1.CBAItemsRef.doc();
                                        transaction.set(doc, data);
                                        return [2 /*return*/, __assign(__assign({}, data), { id: doc.id })];
                                }
                            });
                        }); }))];
                case 5:
                    addedCBAItems = _e.sent();
                    return [4 /*yield*/, Promise.all(RFQsData.map(function (RFQDoc) { return __awaiter(_this, void 0, void 0, function () {
                            var RFQItemsSN, RFQItemsData;
                            var _this = this;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, collectionsRef_1.RFQItemsRef.where("RFQId", "==", RFQDoc.id).get()];
                                    case 1:
                                        RFQItemsSN = _a.sent();
                                        RFQItemsData = RFQItemsSN.docs.map(function (d) { return (__assign(__assign({}, d.data()), { id: d.id })); });
                                        return [4 /*yield*/, Promise.all(RFQItemsData.map(function (RFQItemDoc, idx) { return __awaiter(_this, void 0, void 0, function () {
                                                var offeredItem, unitPrice, total, quantity, productId, index, warranty, expiryDate, originCountry, CBAItemId, productType;
                                                var _a;
                                                return __generator(this, function (_b) {
                                                    switch (_b.label) {
                                                        case 0:
                                                            offeredItem = RFQItemDoc.offeredItem, unitPrice = RFQItemDoc.unitPrice, total = RFQItemDoc.total, quantity = RFQItemDoc.quantity, productId = RFQItemDoc.productId, index = RFQItemDoc.index, warranty = RFQItemDoc.warranty, expiryDate = RFQItemDoc.expiryDate, originCountry = RFQItemDoc.originCountry;
                                                            CBAItemId = addedCBAItems.find(function (i) { return i.productId === productId; });
                                                            return [4 /*yield*/, collectionsRef_1.productsRef.doc(productId).get()];
                                                        case 1:
                                                            productType = (_a = (_b.sent()).data()) === null || _a === void 0 ? void 0 : _a.productType;
                                                            transaction.set(collectionsRef_1.CBARFQItemsRef.doc(), {
                                                                budgetLinesIds: RFQItemDoc.budgetLinesIds,
                                                                budgetLinesPercentages: RFQItemDoc.budgetLinesPercentages,
                                                                budgetLineId: RFQItemDoc.budgetLineId,
                                                                CBAId: newCBA.id,
                                                                RFQId: RFQDoc.id,
                                                                offeredItem: offeredItem,
                                                                warranty: warranty,
                                                                expiryDate: expiryDate,
                                                                originCountry: originCountry,
                                                                RFQItemId: RFQItemDoc.id,
                                                                orderId: orderId,
                                                                unitPrice: unitPrice,
                                                                productType: productType,
                                                                totalPrice: total,
                                                                quantity: quantity,
                                                                supplierId: RFQDoc.supplierId,
                                                                CBAItemId: CBAItemId === null || CBAItemId === void 0 ? void 0 : CBAItemId.id,
                                                                supplierIndex: idx,
                                                                index: index,
                                                                createdAt: new Date(),
                                                            });
                                                            return [2 /*return*/];
                                                    }
                                                });
                                            }); }))];
                                    case 2:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        }); }))];
                case 6:
                    _e.sent();
                    transaction.update(collectionsRef_1.ordersRef.doc(orderId), {
                        status: "preparingCBA",
                    });
                    return [2 /*return*/, newCBA.id];
            }
        });
    });
}
exports.startCBA = startCBA;
