"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TitleCell = void 0;
var TitleCell = function (_a) {
    var title = _a.title, _b = _a.colSpan, colSpan = _b === void 0 ? 1 : _b, _c = _a.backgroundColor, backgroundColor = _c === void 0 ? '#dbe5f1' : _c, textAlign = _a.textAlign, rowSpan = _a.rowSpan, fontSize = _a.fontSize, padding = _a.padding, borderWidth = _a.borderWidth, borderColor = _a.borderColor, borderStyle = _a.borderStyle;
    return {
        borderWidth: borderWidth !== null && borderWidth !== void 0 ? borderWidth : { top: 1, bottom: 1, left: 1, right: 1 },
        borderColor: borderColor !== null && borderColor !== void 0 ? borderColor : { top: 'gray', bottom: 'gray', left: 'gray', right: 'gray' },
        borderStyle: borderStyle !== null && borderStyle !== void 0 ? borderStyle : { top: 'solid', bottom: 'solid', left: 'solid', right: 'solid' },
        type: 'text',
        text: title,
        colSpan: colSpan !== null && colSpan !== void 0 ? colSpan : 1,
        rowSpan: rowSpan,
        padding: padding !== null && padding !== void 0 ? padding : 10,
        backgroundColor: backgroundColor !== null && backgroundColor !== void 0 ? backgroundColor : '#dbe5f1',
        textAlign: textAlign,
        fontSize: fontSize !== null && fontSize !== void 0 ? fontSize : 13,
    };
};
exports.TitleCell = TitleCell;
