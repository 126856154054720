"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValueByMode = void 0;
var valueCell_1 = require("./valueCell");
var valueInputCell_1 = require("./valueInputCell");
var ValueByMode = function (_a) {
    var mode = _a.mode, value = _a.value, viewValue = _a.viewValue, _b = _a.inputType, inputType = _b === void 0 ? 'text' : _b, colSpan = _a.colSpan, _c = _a.path, path = _c === void 0 ? '' : _c, _d = _a.selectData, selectData = _d === void 0 ? [] : _d, rowSpan = _a.rowSpan, borderWidth = _a.borderWidth, borderColor = _a.borderColor, borderStyle = _a.borderStyle, required = _a.required, triedSubmitting = _a.triedSubmitting, selectMode = _a.selectMode;
    switch (mode) {
        case 'view': {
            return valueCell_1.ValueCell({
                value: viewValue !== null && viewValue !== void 0 ? viewValue : value,
                colSpan: colSpan,
                rowSpan: rowSpan,
                borderStyle: borderStyle,
                borderColor: borderColor,
                borderWidth: borderWidth,
            });
        }
        case 'edit': {
            return valueInputCell_1.ValueInputCell({
                colSpan: colSpan,
                inputType: inputType,
                mode: selectMode,
                value: value,
                path: path,
                rowSpan: rowSpan,
                selectData: selectData,
                borderWidth: borderWidth,
                borderColor: borderColor,
                borderStyle: borderStyle,
                required: required,
                triedSubmitting: triedSubmitting,
            });
        }
    }
};
exports.ValueByMode = ValueByMode;
