"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var collectionsRef_1 = require("common/db/collectionsRef");
var usersList_1 = require("common/global/usersList");
var app_1 = require("firebase/app");
var lodash_1 = require("lodash");
function CreateSCs(orderId, transaction) {
    var _a, _b, _c;
    return __awaiter(this, void 0, void 0, function () {
        var orderSN, projectId, orderData, userId, itemsSN, itemsData, uniqSuppliers, IRSN, IRData;
        var _this = this;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0: return [4 /*yield*/, collectionsRef_1.ordersRef.doc(orderId).get()];
                case 1:
                    orderSN = _d.sent();
                    projectId = (_a = orderSN.data()) === null || _a === void 0 ? void 0 : _a.projectId;
                    orderData = __assign(__assign({}, orderSN.data()), { id: orderSN.id });
                    userId = (_c = (_b = app_1.auth().currentUser) === null || _b === void 0 ? void 0 : _b.uid) !== null && _c !== void 0 ? _c : "";
                    return [4 /*yield*/, collectionsRef_1.CBAItemsRef.where("orderId", "==", orderId).get()];
                case 2:
                    itemsSN = _d.sent();
                    itemsData = itemsSN.docs.map(function (d) { return (__assign(__assign({}, d.data()), { id: d.id })); });
                    uniqSuppliers = lodash_1.groupBy(itemsData, function (d) { return d.selectedSupplierId; });
                    return [4 /*yield*/, collectionsRef_1.IRsRef.doc(orderData.IRId).get()];
                case 3:
                    IRSN = _d.sent();
                    IRData = __assign(__assign({}, IRSN.data()), { id: IRSN.id });
                    return [4 /*yield*/, Promise.all(lodash_1.map(uniqSuppliers, function (itemsBySupplier, supplierId) { return __awaiter(_this, void 0, void 0, function () {
                            var SC, _a, selectedRFQId, CBAId, selectedSupplierId, CBANumber, RFQDoc, userName, data;
                            var _this = this;
                            var _b, _c;
                            return __generator(this, function (_d) {
                                switch (_d.label) {
                                    case 0:
                                        SC = collectionsRef_1.SCRef.doc();
                                        _a = itemsBySupplier[0], selectedRFQId = _a.selectedRFQId, CBAId = _a.CBAId, selectedSupplierId = _a.selectedSupplierId;
                                        console.log({ selectedRFQId: selectedRFQId, CBAId: CBAId, selectedSupplierId: selectedSupplierId });
                                        return [4 /*yield*/, collectionsRef_1.CBAsRef.doc(CBAId).get()];
                                    case 1:
                                        CBANumber = (_b = (_d.sent()).data()) === null || _b === void 0 ? void 0 : _b.ref;
                                        return [4 /*yield*/, collectionsRef_1.RFQsRef.doc(selectedRFQId).get()];
                                    case 2:
                                        RFQDoc = (_d.sent()).data();
                                        userName = (_c = usersList_1.globalUsersData[userId]) === null || _c === void 0 ? void 0 : _c.name;
                                        console.log(IRData === null || IRData === void 0 ? void 0 : IRData.ref, CBANumber);
                                        data = {
                                            SCId: SC.id,
                                            SCNumber: IRData === null || IRData === void 0 ? void 0 : IRData.ref,
                                            orderId: orderId,
                                            supplierId: selectedSupplierId,
                                            supplierName: RFQDoc.supplierName,
                                            supplierAddress: RFQDoc.supplierAddress,
                                            supplierPhoneNo: RFQDoc.supplierTel,
                                            projectId: projectId !== null && projectId !== void 0 ? projectId : "",
                                            projectsIds: RFQDoc.projectsIds,
                                            status: "draft",
                                            RFQId: selectedRFQId,
                                            CBAId: CBAId,
                                            initiatedBy: userId,
                                            userName: userName,
                                            CBANumber: CBANumber !== null && CBANumber !== void 0 ? CBANumber : "",
                                            paymentTerms: RFQDoc.termsOfPayment,
                                            // totalValueOfContract: values.totalValueOfContract,
                                            createdAt: new Date().toLocaleDateString(),
                                        };
                                        transaction.set(SC, data);
                                        return [2 /*return*/, Promise.all(itemsBySupplier.map(function (item) { return __awaiter(_this, void 0, void 0, function () {
                                                var CBARFQItemsSN, CBARFQItemsData, cbaRfqItem, offeredItem, totalPrice, unitPrice, quantity, budgetLineId, budgetLinesIds, budgetLinesPercentages, productData, SCItemData;
                                                var _a, _b;
                                                return __generator(this, function (_c) {
                                                    switch (_c.label) {
                                                        case 0: return [4 /*yield*/, collectionsRef_1.CBARFQItemsRef.where("CBAItemId", "==", item.id)
                                                                .where("supplierId", "==", supplierId)
                                                                .get()];
                                                        case 1:
                                                            CBARFQItemsSN = _c.sent();
                                                            CBARFQItemsData = CBARFQItemsSN.docs.map(function (d) { return (__assign(__assign({}, d.data()), { id: d.id })); });
                                                            cbaRfqItem = CBARFQItemsData[0];
                                                            offeredItem = cbaRfqItem.offeredItem, totalPrice = cbaRfqItem.totalPrice, unitPrice = cbaRfqItem.unitPrice, quantity = cbaRfqItem.quantity, budgetLineId = cbaRfqItem.budgetLineId, budgetLinesIds = cbaRfqItem.budgetLinesIds, budgetLinesPercentages = cbaRfqItem.budgetLinesPercentages;
                                                            return [4 /*yield*/, collectionsRef_1.productsRef.doc(item.productId).get()];
                                                        case 2:
                                                            productData = (_a = (_c.sent())) === null || _a === void 0 ? void 0 : _a.data();
                                                            SCItemData = {
                                                                SCId: SC.id,
                                                                supplierId: supplierId,
                                                                CBAItemId: item.id,
                                                                CBARFQItemId: cbaRfqItem.id,
                                                                offeredItem: offeredItem,
                                                                totalPrice: totalPrice,
                                                                unitPrice: unitPrice,
                                                                quantity: quantity,
                                                                productId: item.productId,
                                                                unitId: item.unitId,
                                                                productDescription: productData.description,
                                                                productName: productData.name,
                                                                productType: productData.productType,
                                                                createdAt: new Date().toDateString(),
                                                                status: "draft",
                                                                budgetLineId: (_b = budgetLineId !== null && budgetLineId !== void 0 ? budgetLineId : RFQDoc === null || RFQDoc === void 0 ? void 0 : RFQDoc.budgetLineId) !== null && _b !== void 0 ? _b : "",
                                                                budgetLinesIds: budgetLinesIds !== null && budgetLinesIds !== void 0 ? budgetLinesIds : [],
                                                                budgetLinesPercentages: budgetLinesPercentages !== null && budgetLinesPercentages !== void 0 ? budgetLinesPercentages : [],
                                                            };
                                                            transaction.set(collectionsRef_1.SCItemsRef.doc(), SCItemData);
                                                            return [2 /*return*/];
                                                    }
                                                });
                                            }); }))];
                                }
                            });
                        }); }))];
                case 4:
                    _d.sent();
                    return [2 /*return*/];
            }
        });
    });
}
exports.default = CreateSCs;
