"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hasProjectAndWarehousePermission = void 0;
var userProjectRolesData_1 = require("common/global/userProjectRolesData");
var hasProjectAndWarehousePermission = function (warehouseId, warehouseAndProjectPermissions) {
    // console.log(globalUserWarehouseRolesData)
    var userRoles = userProjectRolesData_1.globalUserWarehouseRolesData
        .filter(function (x) { return x.warehouseId === warehouseId; })
        .map(function (doc) {
        return doc.role.toLowerCase();
    });
    return warehouseAndProjectPermissions
        .map(function (x) { return x.toLowerCase(); })
        .some(function (r) { return userRoles.includes(r); });
};
exports.hasProjectAndWarehousePermission = hasProjectAndWarehousePermission;
