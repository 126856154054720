// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1NKDfrV8NWrg9qlcpvmVbe {\n    width: 70%;\n    padding: 10%;\n    background-color: white;\n    border-radius: 40%;\n    margin: 0 auto;\n    display: block;\n    margin-top: 20px;\n    margin-bottom: 20px;\n}\n\n.fYRbFrj5vBjJlZuCyW5Du {\n    border-bottom: 1px solid #ccc;\n}\nul {\n    border: none !important;\n}\n._30DV5x0Hz9mjzoFEN3EeR2 > span > span > sup {\n    position: absolute;\n    top: 35%;\n    right: 5%;\n}\n", ""]);
// Exports
exports.locals = {
	"logo": "_1NKDfrV8NWrg9qlcpvmVbe",
	"imageBox": "fYRbFrj5vBjJlZuCyW5Du",
	"newMenu": "_30DV5x0Hz9mjzoFEN3EeR2"
};
module.exports = exports;
