"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValueCell = void 0;
var ValueCell = function (_a) {
    var value = _a.value, _b = _a.colSpan, colSpan = _b === void 0 ? 1 : _b, rowSpan = _a.rowSpan, borderWidth = _a.borderWidth, borderColor = _a.borderColor, borderStyle = _a.borderStyle, img = _a.img;
    return {
        borderWidth: borderWidth !== null && borderWidth !== void 0 ? borderWidth : { top: 1, bottom: 1, left: 1, right: 1 },
        borderColor: borderColor !== null && borderColor !== void 0 ? borderColor : { top: 'gray', bottom: 'gray', left: 'gray', right: 'gray' },
        borderStyle: borderStyle !== null && borderStyle !== void 0 ? borderStyle : { top: 'solid', bottom: 'solid', left: 'solid', right: 'solid' },
        textAlign: 'center',
        src: img !== null && img !== void 0 ? img : '',
        width: 175,
        // height:125,
        // height:125,
        type: img ? 'image' : 'text',
        pointerEvents: 'none',
        text: img ? 'no text' : value,
        colSpan: colSpan !== undefined ? colSpan : 1,
        rowSpan: rowSpan,
    };
};
exports.ValueCell = ValueCell;
