"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.actionCell = void 0;
var actionCell = function (_a) {
    var 
    //title,
    _b = _a.colSpan, 
    //title,
    colSpan = _b === void 0 ? 1 : _b, action = _a.action, payload = _a.payload;
    if (action == null) {
        return {
            borderWidth: { top: 1, bottom: 1, left: 1, right: 1 },
            borderColor: { top: 'gray', bottom: 'gray', left: 'gray', right: 'gray' },
            borderStyle: { top: 'solid', bottom: 'solid', left: 'solid', right: 'solid' },
            type: 'text',
            colSpan: colSpan !== undefined ? colSpan : 1,
            padding: 10,
            backgroundColor: '#fff',
        };
    }
    switch (action) {
        case 'delete': {
            return {
                borderWidth: { top: 1, bottom: 1, left: 1, right: 1 },
                borderColor: { top: 'gray', bottom: 'gray', left: 'gray', right: 'gray' },
                borderStyle: { top: 'solid', bottom: 'solid', left: 'solid', right: 'solid' },
                type: 'DeleteButton',
                payload: payload,
                action: action,
                colSpan: colSpan !== undefined ? colSpan : 1,
                padding: 10,
                textAlign: 'center',
                color: 'red',
                confirmAction: true,
                confirmMessage: 'Are you sure delete this Item?',
                extraProps: { mode: 'link' },
            };
        }
        case 'addRow': {
            return {
                borderWidth: { top: 1, bottom: 1, left: 1, right: 1 },
                borderColor: { top: 'gray', bottom: 'gray', left: 'gray', right: 'gray' },
                borderStyle: { top: 'solid', bottom: 'solid', left: 'solid', right: 'solid' },
                type: 'button',
                payload: payload,
                action: action,
                colSpan: colSpan !== undefined ? colSpan : 1,
                padding: 10,
                backgroundColor: '#fff',
            };
        }
        case 'Save': {
            return {
                borderWidth: { top: 1, bottom: 1, left: 1, right: 1 },
                borderColor: { top: 'gray', bottom: 'gray', left: 'gray', right: 'gray' },
                borderStyle: { top: 'solid', bottom: 'solid', left: 'solid', right: 'solid' },
                type: 'button',
                payload: payload,
                action: action,
                colSpan: colSpan !== undefined ? colSpan : 1,
                padding: 10,
                backgroundColor: '#fff',
            };
        }
        case 'Cancel': {
            return {
                borderWidth: { top: 1, bottom: 1, left: 1, right: 1 },
                borderColor: { top: 'gray', bottom: 'gray', left: 'gray', right: 'gray' },
                borderStyle: { top: 'solid', bottom: 'solid', left: 'solid', right: 'solid' },
                type: 'CancelButton',
                payload: payload,
                action: action,
                colSpan: colSpan !== undefined ? colSpan : 1,
                padding: 10,
                backgroundColor: '#fff',
            };
        }
        case 'PublishRFQ': {
            return {
                borderWidth: { top: 1, bottom: 1, left: 1, right: 1 },
                borderColor: { top: 'gray', bottom: 'gray', left: 'gray', right: 'gray' },
                borderStyle: { top: 'solid', bottom: 'solid', left: 'solid', right: 'solid' },
                type: 'PublishRFQ',
                payload: payload,
                action: action,
                colSpan: colSpan !== undefined ? colSpan : 1,
                padding: 10,
                backgroundColor: '#fff',
            };
        }
    }
};
exports.actionCell = actionCell;
