"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var collectionsRef_1 = require("common/db/collectionsRef");
var projectsList_1 = require("common/global/projectsList");
var app_1 = require("firebase/app");
function createDN(POData, transaction) {
    var _a, _b, _c;
    return __awaiter(this, void 0, void 0, function () {
        var projectCode, data, createdDN, POItemsSN, POItemsData;
        var _this = this;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    projectCode = (_b = (_a = projectsList_1.globalProjectsData[POData.projectId]) === null || _a === void 0 ? void 0 : _a.code) !== null && _b !== void 0 ? _b : "";
                    data = {
                        POId: POData.id,
                        RFQId: POData.RFQId,
                        status: "draft",
                        orderId: POData.orderId,
                        projectId: POData.projectId,
                        projectsIds: POData.projectsIds,
                        initiatedBy: (_c = app_1.auth().currentUser.uid) !== null && _c !== void 0 ? _c : "",
                        warehouseId: POData.deliverToWarehouseId,
                        projectCode: projectCode,
                        DNNumber: POData.PONumber,
                        PONumber: POData.PONumber,
                        supplierName: POData.supplierName,
                        supplierAddress: POData.supplierAddress,
                        consigneeName: POData.invoiceTo,
                        createdAt: new Date(),
                    };
                    createdDN = collectionsRef_1.DNsRef.doc();
                    transaction.set(createdDN, data);
                    return [4 /*yield*/, collectionsRef_1.POItemsRef.where("POId", "==", POData.id).get()];
                case 1:
                    POItemsSN = _d.sent();
                    POItemsData = POItemsSN === null || POItemsSN === void 0 ? void 0 : POItemsSN.docs.map(function (d) { return (__assign(__assign({}, d.data()), { id: d.id })); });
                    return [4 /*yield*/, Promise.all(POItemsData.map(function (POItem) { return __awaiter(_this, void 0, void 0, function () {
                            var productId, productDescription, productName, productType, orderedItem, unitId, quantity, budgetLineId, budgetLinesIds, budgetLinesPercentages, index, offeredItem, warranty, expiryDate, originCountry, unitPrice, itemData;
                            return __generator(this, function (_a) {
                                productId = POItem.productId, productDescription = POItem.productDescription, productName = POItem.productName, productType = POItem.productType, orderedItem = POItem.orderedItem, unitId = POItem.unitId, quantity = POItem.quantity, budgetLineId = POItem.budgetLineId, budgetLinesIds = POItem.budgetLinesIds, budgetLinesPercentages = POItem.budgetLinesPercentages, index = POItem.index, offeredItem = POItem.offeredItem, warranty = POItem.warranty, expiryDate = POItem.expiryDate, originCountry = POItem.originCountry, unitPrice = POItem.unitPrice;
                                itemData = {
                                    index: index,
                                    DNId: createdDN.id,
                                    productId: productId,
                                    productName: productName,
                                    productType: productType,
                                    productDescription: productDescription,
                                    orderedItem: orderedItem !== null && orderedItem !== void 0 ? orderedItem : "",
                                    offeredItem: offeredItem,
                                    unitId: unitId,
                                    orderedQuantity: quantity,
                                    createdAt: new Date(),
                                    budgetLineId: budgetLineId,
                                    budgetLinesIds: budgetLinesIds,
                                    budgetLinesPercentages: budgetLinesPercentages,
                                    warranty: warranty,
                                    expiryDate: expiryDate,
                                    originCountry: originCountry,
                                    unitPrice: unitPrice
                                };
                                transaction.set(collectionsRef_1.DNItemsRef.doc(), itemData);
                                return [2 /*return*/];
                            });
                        }); }))];
                case 2:
                    _d.sent();
                    return [2 /*return*/, createdDN.id];
            }
        });
    });
}
exports.default = createDN;
