// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".uz7wYdwqGGzSKJopSTAry ._3S-mrmMRLdk-g6065tFO_x {\n    margin: 0 auto;\n    display: block;\n    margin-top: 12px;\n}\n\n.uz7wYdwqGGzSKJopSTAry {\n    display: flex;\n    flex-direction: column;\n    width: 400px;\n    box-shadow: 0px 1px 10px 2px rgba(0, 0, 0, 0.18);\n    border-radius: 5px;\n    padding: 32px 16px 16px 16px;\n    margin: 24px auto;\n}\n\n._1guUd9UaNA3YZCtG7CwQgQ {\n    display: flex;\n    width: 100vw;\n    height: 100vh;\n    justify-content: center;\n    align-items: center;\n    margin: 0 auto;\n}\n\n.tEU5DyJu0mP0xIk_txo87 {\n    width: 50%;\n    height: auto;\n    margin: 24px auto 48px auto;\n}\n\n._30JHWGSocv4Mnop018lvqy {\n    display: flex;\n    justify-content: space-between;\n}\n\n._2GrlUclhIKW8tYBnG-CCQp {\n    display: flex;\n    width: 100%;\n    height: 100%;\n    justify-content: center;\n    align-items: center;\n    margin: 0 auto;\n}\n._3k_vmn2Cb8hTI2MYI1uzRo {\n    color: 'rgba(0,0,0,.25)';\n}\n", ""]);
// Exports
exports.locals = {
	"formContainer": "uz7wYdwqGGzSKJopSTAry",
	"loginButton": "_3S-mrmMRLdk-g6065tFO_x",
	"Container": "_1guUd9UaNA3YZCtG7CwQgQ",
	"loginLogo": "tEU5DyJu0mP0xIk_txo87",
	"changePasswordButtonContainer": "_30JHWGSocv4Mnop018lvqy",
	"changePasswordContainer": "_2GrlUclhIKW8tYBnG-CCQp",
	"emailInputIcon": "_3k_vmn2Cb8hTI2MYI1uzRo"
};
module.exports = exports;
