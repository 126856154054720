"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var lodash_1 = require("lodash");
var projectsList_1 = require("common/global/projectsList");
function useOfficesOptions() {
    return react_1.useMemo(function () {
        return lodash_1.map(projectsList_1.globalProjectsData, function (data) {
            return {
                text: data.name,
                value: data.id,
            };
        });
    }, []);
}
exports.default = useOfficesOptions;
