"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var warehousesList_1 = require("common/global/warehousesList");
var js_1 = require("qrcode-generator-ts/js");
var react_1 = __importStar(require("react"));
var react_i18next_1 = require("react-i18next");
function PrescriptionDischargePrint(_a) {
    var _b, _c, _d, _e;
    var dischargePrescription = _a.dischargePrescription, printFnRef = _a.printFnRef, type = _a.type;
    var t = react_i18next_1.useTranslation().t;
    var PresDocs = [
        { sessionDetails: dischargePrescription.nonAvailablePrescription, type: 'notAvailable' },
        { sessionDetails: dischargePrescription.logisticsPrescription, type: 'done' },
    ];
    var qr = react_1.useMemo(function () {
        var qr = new js_1.QRCode();
        qr.setTypeNumber(8);
        qr.setErrorCorrectLevel(js_1.ErrorCorrectLevel.L);
        qr.addData(new js_1.QR8BitByte(dischargePrescription.admissionId));
        // qr.addData(new QR8BitByte(';;'))
        // qr.addData(new QR8BitByte(session?.clinic))
        qr.make();
        return qr;
    }, [dischargePrescription.admissionId]);
    var printCb = react_1.useCallback(function () {
        function printElem(elem) {
            var newWindow = window.open('', 'PRINT', 'height=400,width=600');
            newWindow === null || newWindow === void 0 ? void 0 : newWindow.document.write('<html><head><title>' + document.title + '</title>');
            newWindow === null || newWindow === void 0 ? void 0 : newWindow.document.write('</head><body >');
            newWindow === null || newWindow === void 0 ? void 0 : newWindow.document.write(document.getElementById(elem).innerHTML);
            newWindow === null || newWindow === void 0 ? void 0 : newWindow.document.write('</body></html>');
            newWindow === null || newWindow === void 0 ? void 0 : newWindow.document.close(); // necessary for IE >= 10
            newWindow === null || newWindow === void 0 ? void 0 : newWindow.focus(); // necessary for IE >= 10*/
            newWindow === null || newWindow === void 0 ? void 0 : newWindow.print();
            return true;
        }
        printElem("printingDischarge");
    }, [type]);
    printFnRef.current = printCb;
    var arr = {
        '1/24': 'مره في اليوم',
        '1/12': 'كل 12 ساعة',
        '1/8': 'كل 8 ساعات',
        '1/6': 'كل 6 ساعات',
        '1/5': 'كل 5 ساعات',
        onNeed: 'عند الحاجة',
    };
    return (react_1.default.createElement("div", { style: {
            // visibility: "visible"
            display: "" + type,
        } }, (PresDocs[0].sessionDetails == undefined || PresDocs[0].sessionDetails.length == 0) &&
        (PresDocs[1].sessionDetails == undefined || PresDocs[1].sessionDetails.length == 0) ? null : (react_1.default.createElement("div", { id: "printingDischarge" },
        react_1.default.createElement("p", { style: { textAlign: 'center', margin: 2, fontWeight: 'bolder', fontSize: 24 } }, 'منظمة الأطباء المستقلين'),
        react_1.default.createElement("p", { style: { textAlign: 'center', margin: 2 } }, (_c = (_b = warehousesList_1.globalWarehousesData[dischargePrescription === null || dischargePrescription === void 0 ? void 0 : dischargePrescription.centerID]) === null || _b === void 0 ? void 0 : _b.name) !== null && _c !== void 0 ? _c : ''),
        react_1.default.createElement("p", { style: { textAlign: 'center', margin: 2 } }, 'وصفة طبية'),
        react_1.default.createElement("p", { style: { textAlign: 'center', margin: 2 } }, t('common.fullDateTime', { date: (_e = (_d = dischargePrescription === null || dischargePrescription === void 0 ? void 0 : dischargePrescription.prescriptionDate) === null || _d === void 0 ? void 0 : _d.toDate()) !== null && _e !== void 0 ? _e : '' })),
        react_1.default.createElement("br", null),
        react_1.default.createElement("p", { style: { textAlign: 'center', margin: 2 } }, "\u0627\u0633\u0645 \u0627\u0644\u0645\u0631\u064A\u0636: " + (dischargePrescription === null || dischargePrescription === void 0 ? void 0 : dischargePrescription.cachedPatientName) + " "), PresDocs === null || PresDocs === void 0 ? void 0 :
        PresDocs.map(function (pres) {
            return pres.sessionDetails == undefined || pres.sessionDetails.length == 0 ? null : (react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement("div", { style: {
                    // position: 'absolute',
                    // zIndex: 0,
                    // background: 'white',
                    // display: 'block',
                    // minHeight: '50%',
                    // minWidth: '50%',
                    // color: 'yellow',
                    // margin: '0 auto',
                    } },
                    react_1.default.createElement("p", { style: {
                            textAlign: 'center',
                            // color: 'lightgrey',
                            fontSize: '20px',
                        } }, pres.type === 'done' ? 'الوصفة المصروفة' : 'الوصفة الخارجية')),
                react_1.default.createElement("div", { style: {
                        // position: 'absolute',
                        zIndex: 1,
                    } },
                    react_1.default.createElement("ol", { style: { textAlign: 'right', direction: 'rtl' } }, (pres.sessionDetails || []).map(function (item, index) {
                        return (react_1.default.createElement("li", { key: index },
                            react_1.default.createElement("p", { style: { textAlign: 'right', direction: 'rtl', margin: 2 } },
                                react_1.default.createElement("span", null,
                                    'الدواء والتركيز',
                                    " ",
                                    ': '),
                                react_1.default.createElement("span", null, item.cachedProductName),
                                ' - ',
                                react_1.default.createElement("span", null, item.cachedProductDescription),
                                '  __  ',
                                react_1.default.createElement("span", null,
                                    'الشكل الدوائي',
                                    " ",
                                    ': '),
                                react_1.default.createElement("span", null, item.cachedProductUnitName),
                                '  __  ',
                                react_1.default.createElement("span", null,
                                    'الجرعة',
                                    " ",
                                    ': '),
                                react_1.default.createElement("span", null, item.quantity),
                                '  __  ',
                                react_1.default.createElement("span", null,
                                    'التواتر',
                                    " ",
                                    ': '),
                                react_1.default.createElement("span", null, arr[item === null || item === void 0 ? void 0 : item.per]),
                                '  __  ',
                                react_1.default.createElement("span", null,
                                    'المدة/ايام',
                                    " ",
                                    ': '),
                                react_1.default.createElement("span", null, item.duration),
                                '  __  ',
                                react_1.default.createElement("span", null,
                                    'الكمية الكلية',
                                    " ",
                                    ': '),
                                react_1.default.createElement("span", null, item.overallQuantity)),
                            react_1.default.createElement("hr", { width: "100%" })));
                    }))),
                react_1.default.createElement("br", null),
                react_1.default.createElement("hr", { width: "100%" }),
                react_1.default.createElement("br", null)));
        }),
        react_1.default.createElement("img", { alt: "QR-Code", src: qr.toDataURL(), style: {
                margin: 'auto',
                width: 150,
                height: 150,
                cursor: 'pointer',
            } }),
        react_1.default.createElement("br", null),
        'يرجى التأكد من استلام جميع محتويات الوصفة المصروفة قبل مغادرة الصيدلية ومراجعة المسؤول في حال وجود اي نقص ، كما يرجى الالتزام بالجرعة والوقت المحدد لها'))));
}
exports.default = PrescriptionDischargePrint;
