"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var collectionsRef_1 = require("common/db/collectionsRef");
var projectsList_1 = require("common/global/projectsList");
var warehousesList_1 = require("common/global/warehousesList");
var app_1 = require("firebase/app");
function createWarehouseDN(items, transaction, type) {
    var _a, _b, _c, _d;
    return __awaiter(this, void 0, void 0, function () {
        var projectId, warehouseId, DNNumber, supplierName, supplierAddress, budgetLineId, donationCertificateNumber, deliveryLocation, projectCode, PONumber, description, SRNO, data, createdDN;
        var _this = this;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    projectId = items[0].projectId;
                    warehouseId = type == 'donationDN' ? items[0].warehouseId : items[0].toWarehouseId;
                    DNNumber = type == 'donationDN' ? items[0].dnNumber : "DN-" + items[0].SRNO;
                    supplierName = type == 'donationDN' ? items[0].from : warehousesList_1.globalWarehousesData[items[0].fromWarehouseId].name;
                    supplierAddress = type == 'donationDN' ? items[0].from : warehousesList_1.globalWarehousesData[items[0].fromWarehouseId].address;
                    budgetLineId = type == 'donationDN' ? items[0].budgetLineId : items[0].budgetLineId;
                    donationCertificateNumber = (_a = items[0].donationCertificateNumber) !== null && _a !== void 0 ? _a : '';
                    deliveryLocation = warehousesList_1.globalWarehousesData[type == 'donationDN' ? items[0].warehouseId : items[0].toWarehouseId].address;
                    projectCode = (_b = projectsList_1.globalProjectsData[projectId]) === null || _b === void 0 ? void 0 : _b.code;
                    PONumber = type == 'donationDN' ? items[0].donationCertificateNumber : items[0].SRNO;
                    description = type == 'donationDN' ? items[0].description : items[0].GIODescription;
                    SRNO = (_c = items[0].SRNO) !== null && _c !== void 0 ? _c : '';
                    data = {
                        POId: '',
                        RFQId: '',
                        status: 'draft',
                        orderId: 'warehouseDN',
                        projectId: projectId,
                        initiatedBy: (_d = app_1.auth().currentUser.uid) !== null && _d !== void 0 ? _d : '',
                        warehouseId: warehouseId,
                        projectCode: projectCode,
                        DNNumber: DNNumber,
                        PONumber: PONumber,
                        supplierName: supplierName,
                        supplierAddress: supplierAddress,
                        consigneeName: 'Independent Doctors Association(Bağımsız Doktorlar Derneği)',
                        deliveryLocation: deliveryLocation,
                        budgetLineId: budgetLineId,
                        createdAt: new Date(),
                        donationCertificateNumber: donationCertificateNumber,
                        description: description !== null && description !== void 0 ? description : '',
                        type: type,
                        SRNO: SRNO,
                    };
                    createdDN = collectionsRef_1.DNsRef.doc();
                    transaction.set(createdDN, data);
                    return [4 /*yield*/, Promise.all(items.map(function (item, index) { return __awaiter(_this, void 0, void 0, function () {
                            var productData, productId, cachedProductName, quantity, receivedQuantity, itemData;
                            var _a, _b, _c, _d, _e;
                            return __generator(this, function (_f) {
                                switch (_f.label) {
                                    case 0: return [4 /*yield*/, collectionsRef_1.productsRef.doc(item.productId).get()];
                                    case 1:
                                        productData = (_a = (_f.sent())) === null || _a === void 0 ? void 0 : _a.data();
                                        productId = item.productId, cachedProductName = item.cachedProductName, quantity = item.quantity, receivedQuantity = item.receivedQuantity;
                                        itemData = {
                                            donationId: item.id,
                                            index: index + 1,
                                            DNId: createdDN.id,
                                            productId: productId,
                                            productName: cachedProductName,
                                            productType: (_b = productData === null || productData === void 0 ? void 0 : productData.productType) !== null && _b !== void 0 ? _b : 'asset',
                                            productDescription: productData === null || productData === void 0 ? void 0 : productData.description,
                                            orderedItem: (_c = { id: productId, name: cachedProductName, description: productData === null || productData === void 0 ? void 0 : productData.description }) !== null && _c !== void 0 ? _c : '',
                                            offeredItem: (_d = item === null || item === void 0 ? void 0 : item.specification) !== null && _d !== void 0 ? _d : '',
                                            unitId: productData === null || productData === void 0 ? void 0 : productData.unitId,
                                            orderedQuantity: quantity,
                                            receivedQuantity: receivedQuantity !== null && receivedQuantity !== void 0 ? receivedQuantity : quantity,
                                            unitPrice: (_e = item === null || item === void 0 ? void 0 : item.unitPrice) !== null && _e !== void 0 ? _e : 0,
                                            createdAt: new Date(),
                                            budgetLineId: budgetLineId,
                                            expiryDate: item === null || item === void 0 ? void 0 : item.expiryDate,
                                            notes: item === null || item === void 0 ? void 0 : item.notes,
                                        };
                                        transaction.set(collectionsRef_1.DNItemsRef.doc(), itemData);
                                        return [2 /*return*/];
                                }
                            });
                        }); }))];
                case 1:
                    _e.sent();
                    return [2 /*return*/, createdDN.id];
            }
        });
    });
}
exports.default = createWarehouseDN;
