"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
function useReducer(reducer, initialState) {
    var _a = react_1.useState(initialState), state = _a[0], setState = _a[1];
    var ref = react_1.useRef(reducer);
    ref.current = reducer;
    var dispatch = react_1.useCallback(function (action) {
        setState(function (currentState) {
            return ref.current(currentState, action);
        });
    }, []);
    return [state, dispatch];
}
exports.default = useReducer;
