"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var collectionsRef_1 = require("common/db/collectionsRef");
var app_1 = require("firebase/app");
function createPRs(data, transaction) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    return __awaiter(this, void 0, void 0, function () {
        var PRRef, orderSN, projectId, orderData, userId, IRSN, IRData, PONumber, SCNumber, supplierSN, supplierData, PRData, POItemsSN, POItemsData;
        return __generator(this, function (_k) {
            switch (_k.label) {
                case 0:
                    PRRef = collectionsRef_1.PRsRef.doc();
                    return [4 /*yield*/, collectionsRef_1.ordersRef.doc(data.orderId).get()];
                case 1:
                    orderSN = _k.sent();
                    projectId = (_a = orderSN.data()) === null || _a === void 0 ? void 0 : _a.projectId;
                    orderData = __assign(__assign({}, orderSN.data()), { id: orderSN.id });
                    userId = (_c = (_b = app_1.auth().currentUser) === null || _b === void 0 ? void 0 : _b.uid) !== null && _c !== void 0 ? _c : '';
                    return [4 /*yield*/, collectionsRef_1.IRsRef.doc(orderData.IRId).get()];
                case 2:
                    IRSN = _k.sent();
                    IRData = __assign(__assign({}, IRSN.data()), { id: IRSN.id });
                    return [4 /*yield*/, collectionsRef_1.POsRef.doc(data.id).get()];
                case 3:
                    PONumber = (_d = (_k.sent()).data()) === null || _d === void 0 ? void 0 : _d.PONumber;
                    return [4 /*yield*/, collectionsRef_1.SCRef.doc(data.id).get()];
                case 4:
                    SCNumber = (_e = (_k.sent()).data()) === null || _e === void 0 ? void 0 : _e.SCNumber;
                    return [4 /*yield*/, collectionsRef_1.suppliersRef.doc(data.supplierId).get()];
                case 5:
                    supplierSN = _k.sent();
                    supplierData = supplierSN.data();
                    PRData = {
                        POId: data.id,
                        PRNumber: IRData.ref,
                        orderId: data.orderId,
                        projectId: projectId,
                        supplierId: data.supplierId,
                        supplierName: supplierData.companyName,
                        supplierAddress: supplierData.physicalAddress,
                        supplierAccountName: (_f = supplierData.accountName) !== null && _f !== void 0 ? _f : '',
                        supplierBankName: (_g = supplierData.bankName) !== null && _g !== void 0 ? _g : '',
                        supplierIban: (_h = supplierData.iban) !== null && _h !== void 0 ? _h : '',
                        supplierBranch: (_j = supplierData.country + "/" + supplierData.city) !== null && _j !== void 0 ? _j : '',
                        status: 'draft',
                        initiatedBy: userId,
                        PONumber: PONumber !== null && PONumber !== void 0 ? PONumber : SCNumber,
                        createdAt: new Date(),
                    };
                    transaction.set(PRRef, PRData);
                    return [4 /*yield*/, collectionsRef_1.POItemsRef.where('POId', '==', data.id).get()];
                case 6:
                    POItemsSN = _k.sent();
                    POItemsData = POItemsSN === null || POItemsSN === void 0 ? void 0 : POItemsSN.docs.map(function (d) { return (__assign(__assign({}, d.data()), { id: d.id })); });
                    transaction.set(collectionsRef_1.PRItemsRef.doc(), { PRId: PRRef.id, createdAt: new Date() });
                    return [2 /*return*/, PRRef.id];
            }
        });
    });
}
exports.default = createPRs;
