"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var budgetLinesList_1 = require("../global/budgetLinesList");
var react_1 = require("react");
function useBudgetLinesOptions(state) {
    var budgetLines = lodash_1.filter(budgetLinesList_1.globalBudgetLinesData, function o(budgetLineData) {
        return state.projectsIds.includes(budgetLineData.projectId);
        // budgetLineData.projectId === state.projectId
    });
    return react_1.useMemo(function () {
        return lodash_1.map(budgetLines, function (data) { return ({
            // description: data?.description,
            // text: `${data?.code} ${data?.description}`,
            text: data === null || data === void 0 ? void 0 : data.code,
            value: data === null || data === void 0 ? void 0 : data.id,
        }); });
    }, [budgetLines]);
}
exports.default = useBudgetLinesOptions;
